import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { compact } from 'lodash';
import { DistributionItemByProjectCodeQuery } from '../API';

export const useListDistributionItemByProjectCode = (project_code: string | undefined) => {
  return useQuery(
    ['useListDistributionItemByProjectCode', project_code],
    () =>
      API.graphql({
        query: queries.distributionItemByProjectCode,
        variables: {
          project_code: project_code || 'never',
        },
      }) as Promise<GraphQLResult<DistributionItemByProjectCodeQuery>>,
    {
      enabled: !!project_code,
      select: (data) => {
        if (data.data?.distributionItemByProjectCode) {
          return compact(data.data.distributionItemByProjectCode.items);
        }
        return undefined;
      },
    }
  );
};

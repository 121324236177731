import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { compact, difference } from 'lodash';
import React, { FC, useMemo } from 'react';
import {
  CreateNGMeetingInput,
  CreateNGMeetingMutation,
  DeleteNGMeetingInput,
  DeleteNGMeetingMutation,
  GetNeighborGroupCustomQuery,
  GetNGMeetingForEditQuery,
  UpdateNGMeetingInput,
  UpdateNGMeetingMutation,
  DeleteNGMeetingStaffFacilitatorInput,
} from '../../../API';
import { useAppSelector } from '../../../redux/hooks';
import { useListStaffByPdCode } from '../../../hooks/useListStaffByPdCode';
import {
  Button,
  Modal,
  Space,
  Form,
  Spin,
  Input,
  Select,
  Typography,
  Transfer,
  DatePicker,
  message,
  Popconfirm,
} from 'antd';
import { useGetProjectDioceseByPdCode, useListNutritionTopics } from '../../../hooks';
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Text } = Typography;

interface NGMeetingModalFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingNgMeetingId: string | undefined;
}

const NGMeetingModalForm: FC<NGMeetingModalFormProps> = ({
  modalVisible,
  setModalVisible,
  editingNgMeetingId,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  const [targetKeys, setTargetKeys] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const currentlySelectedNeighborGroupId = useAppSelector(
    (state) => state.neighborgroups.selectedMenuKeys.currentSelection
  );

  const ng = queryClient.getQueryData(['getNeighborGroup', currentlySelectedNeighborGroupId]) as
    | GraphQLResult<GetNeighborGroupCustomQuery>
    | undefined;

  const neighborGroup = ng?.data?.getNeighborGroup;
  const plws = useMemo(
    () =>
      neighborGroup?.plws
        ? compact(neighborGroup.plws.items).sort((a, b) => a.member_number - b.member_number)
        : [],
    [neighborGroup]
  );

  const {
    data: projectDiocese,
    isLoading: isProjectDioceseLoading,
    isError: isProjectDioceseError,
    error: projectDioceseError,
  } = useGetProjectDioceseByPdCode(neighborGroup?.project_diocese_code);
  const {
    data: staff,
    isLoading: isStaffLoading,
    isError: isStaffError,
    error: staffError,
  } = useListStaffByPdCode(neighborGroup?.project_diocese_code);

  const {
    data: nutritionTopics,
    isLoading: isNutritionTopicLoading,
    isError: isNutritionTopicError,
    error: nutritionTopicError,
  } = useListNutritionTopics();

  // MUTATIONS
  const invalidateQueries = () => {
    if (neighborGroup) {
      queryClient.invalidateQueries(['getNeighborGroup', neighborGroup.id]);
      queryClient.invalidateQueries(['getCareGroup', neighborGroup.care_group.id]);
      // queryClient.invalidateQueries(['listCareGroups', neighborGroup.project_diocese_code]);
    }
  };
  // INSERT
  const addNgMeetingMutation = useMutation(
    (input: CreateNGMeetingInput) =>
      API.graphql({
        query: mutations.createNGMeeting,
        variables: { input },
      }) as Promise<GraphQLResult<CreateNGMeetingMutation>>
  );
  // UPDATE
  const updateNgMeetingMutation = useMutation(
    (input: UpdateNGMeetingInput) =>
      API.graphql({
        query: mutations.updateNGMeeting,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateNGMeetingMutation>>
  );
  // DELETE
  const deleteNgMeetingMutation = useMutation(
    (input: DeleteNGMeetingInput) =>
      API.graphql({
        query: mutations.deleteNGMeeting,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteNGMeetingMutation>>
  );

  const { data: editingNgMeeting } = useQuery(
    ['getNgMeetingForEdit', editingNgMeetingId],
    () =>
      API.graphql({
        query: queries.getNGMeetingForEdit,
        variables: { id: editingNgMeetingId || 'never' },
      }) as Promise<GraphQLResult<GetNGMeetingForEditQuery>>,
    {
      staleTime: 0,
      enabled: modalVisible === 'editing' && !!editingNgMeetingId,
      select: (data) => data.data?.getNGMeeting,
      onSuccess: (ngm) => {
        let participants: string[] = [];
        let topic_ids: string[] = [];
        if (ngm && ngm.participants) {
          const temp_plws = compact(ngm.participants.items).sort(
            (a, b) => a.plw.member_number - b.plw.member_number
          );
          participants = temp_plws.map((plw) => plw.plw.id);
        }

        if (ngm && ngm.topics) {
          const temp_topics = compact(ngm.topics.items).sort((a, b) => {
            const topic_a = nutritionTopics?.find((topic) => topic.id === a.nutrition_topic.id);
            const topic_b = nutritionTopics?.find((topic) => topic.id === b.nutrition_topic.id);
            if (topic_a && topic_b) {
              const topic_text_a = topic_a.topic_text.split('.')[0];
              const topic_text_b = topic_b.topic_text.split('.')[0];
              const a_position = parseInt(topic_text_a);
              const b_position = parseInt(topic_text_b);
              if (isNaN(a_position) || isNaN(b_position)) {
                return topic_text_a.localeCompare(topic_text_b);
              } else {
                return a_position - b_position;
              }
            } else {
              return 0;
            }
          });
          topic_ids = temp_topics.map((topic) => topic.nutrition_topic.id);
        }

        if (ngm) {
          form.setFieldsValue({
            meeting_date: ngm.meeting_date ? moment(ngm.meeting_date) : null,
            plw_facilitator_id: ngm.plw_facilitator_id,
            staff_facilitators: ngm.staff_facilitators?.items.map((stf) => stf?.staff.id),
            topic_ids,
            description: ngm.description,
            participants: participants,
          });
          setTargetKeys(participants);
        }
      },
    }
  );

  const addNgMeeting = async () => {
    if (!neighborGroup) {
      message.error('No Neighbor Group Loaded.', 5);
      return;
    }
    try {
      const values = await form.validateFields();
      const new_ngm_result = await addNgMeetingMutation.mutateAsync({
        project_diocese_code: neighborGroup.project_diocese_code,
        neighbor_group_id: neighborGroup.id,
        plw_facilitator_id: values.plw_facilitator_id,
        meeting_date: values.meeting_date ? values.meeting_date.format('YYYY-MM-DD') : null,
        description: values.description,
      });
      if (new_ngm_result.data?.createNGMeeting) {
        const new_ng_meeting = new_ngm_result.data.createNGMeeting;
        let staff_promises: Promise<any>[] = [],
          topic_promises: Promise<any>[] = [],
          participant_promises: Promise<any>[] = [];
        if (values.staff_facilitators) {
          staff_promises = values.staff_facilitators.map((staff_id: string) =>
            API.graphql({
              query: mutations.createNGMeetingStaffFacilitator,
              variables: {
                input: {
                  ng_meeting_id: new_ng_meeting.id,
                  staff_id: staff_id,
                  project_diocese_code: neighborGroup.project_diocese_code,
                },
              },
            })
          );
        }
        if (values.topic_ids) {
          topic_promises = values.topic_ids.map((topic_id: string) =>
            API.graphql({
              query: mutations.createNGMeetingNutritionTopic,
              variables: {
                input: {
                  ng_meeting_id: new_ng_meeting.id,
                  nutrition_topic_id: topic_id,
                  project_diocese_code: neighborGroup.project_diocese_code,
                },
              },
            })
          );
        }
        if (values.participants) {
          participant_promises = values.participants.map((plw_id: string) =>
            API.graphql({
              query: mutations.createNGMeetingPLW,
              variables: {
                input: {
                  ng_meeting_id: new_ng_meeting.id,
                  plw_id: plw_id,
                  project_diocese_code: neighborGroup.project_diocese_code,
                },
              },
            })
          );
        }
        await Promise.all([...staff_promises, ...topic_promises, ...participant_promises]);
      }
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const saveEditedNgMeeting = async () => {
    if (!editingNgMeetingId || !editingNgMeeting) {
      message.error('No ng meeting id provided.');
      return;
    }
    if (!neighborGroup) {
      message.error('No Neighbor Group Loaded.', 5);
      return;
    }
    try {
      const values = await form.validateFields();
      await updateNgMeetingMutation.mutateAsync({
        id: editingNgMeeting.id,
        plw_facilitator_id: values.plw_facilitator_id,
        meeting_date: values.meeting_date ? values.meeting_date.format('YYYY-MM-DD') : null,
        description: values.description,
      });

      const original_staff_ids = editingNgMeeting.staff_facilitators
        ? compact(editingNgMeeting.staff_facilitators.items).map((item) => item.staff.id)
        : [];
      const updated_staff_ids = values.staff_facilitators || [];
      const staff_to_add = difference(updated_staff_ids, original_staff_ids);
      let staff_to_delete = difference(original_staff_ids, updated_staff_ids);
      staff_to_delete = compact(editingNgMeeting.staff_facilitators?.items || [])
        .filter((stffac) => staff_to_delete.includes(stffac.staff.id))
        .map((stffcac) => stffcac.id);

      const original_topic_ids = editingNgMeeting.topics
        ? compact(editingNgMeeting.topics.items).map((item) => item.nutrition_topic.id)
        : [];
      const updated_topic_ids = values.topic_ids || [];
      const topics_to_add = difference(updated_topic_ids, original_topic_ids);
      let topics_to_delete = difference(original_topic_ids, updated_topic_ids);
      topics_to_delete = compact(editingNgMeeting.topics?.items || [])
        .filter((ngmtp) => topics_to_delete.includes(ngmtp.nutrition_topic.id))
        .map((ngmtp) => ngmtp.id);

      const original_plw_ids = editingNgMeeting.participants
        ? compact(editingNgMeeting.participants.items).map((item) => item.plw.id)
        : [];
      const updated_plw_ids = values.participants || [];
      const plws_to_add = difference(updated_plw_ids, original_plw_ids);
      let plws_to_delete = difference(original_plw_ids, updated_plw_ids);
      plws_to_delete = compact(editingNgMeeting.participants?.items || [])
        .filter((pptplw) => plws_to_delete.includes(pptplw.plw.id))
        .map((pptplw) => pptplw.id);

      const promise_array: Promise<GraphQLResult>[] = [
        ...staff_to_add.map(
          (staff_id) =>
            API.graphql({
              query: mutations.createNGMeetingStaffFacilitator,
              variables: {
                input: {
                  ng_meeting_id: editingNgMeeting.id,
                  staff_id: staff_id,
                  project_diocese_code: neighborGroup.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...staff_to_delete.map(
          (staff_id) =>
            API.graphql({
              query: mutations.deleteNGMeetingStaffFacilitator,
              variables: {
                input: {
                  id: staff_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...topics_to_add.map(
          (topic_id) =>
            API.graphql({
              query: mutations.createNGMeetingNutritionTopic,
              variables: {
                input: {
                  ng_meeting_id: editingNgMeeting.id,
                  nutrition_topic_id: topic_id,
                  project_diocese_code: neighborGroup.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...topics_to_delete.map(
          (topic_id) =>
            API.graphql({
              query: mutations.deleteNGMeetingNutritionTopic,
              variables: {
                input: {
                  id: topic_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...plws_to_add.map(
          (plw_id) =>
            API.graphql({
              query: mutations.createNGMeetingPLW,
              variables: {
                input: {
                  ng_meeting_id: editingNgMeeting.id,
                  plw_id: plw_id,
                  project_diocese_code: neighborGroup.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...plws_to_delete.map(
          (plw_id) =>
            API.graphql({
              query: mutations.deleteNGMeetingPLW,
              variables: {
                input: {
                  id: plw_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
      ];
      await Promise.all(promise_array);
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || 'Error');
    }
  };

  const deleteNgMeeting = async () => {
    if (!editingNgMeetingId || !editingNgMeeting) {
      message.error('No ng meeting id provided.');
      return;
    }
    try {
      let staff_promises: Promise<any>[] = [];
      let topic_promises: Promise<any>[] = [];
      let participant_promises: Promise<any>[] = [];

      if (editingNgMeeting.staff_facilitators) {
        staff_promises = editingNgMeeting.staff_facilitators.items.map(
          (stf) =>
            API.graphql({
              query: mutations.deleteNGMeetingStaffFacilitator,
              variables: {
                input: {
                  id: stf?.id,
                },
              },
            }) as any
        );
      }
      if (editingNgMeeting.topics) {
        topic_promises = editingNgMeeting.topics.items.map(
          (topic) =>
            API.graphql({
              query: mutations.deleteNGMeetingNutritionTopic,
              variables: {
                input: {
                  id: topic?.id,
                },
              },
            }) as any
        );
      }
      if (editingNgMeeting.participants) {
        participant_promises = editingNgMeeting.participants.items.map(
          (plw) =>
            API.graphql({
              query: mutations.deleteNGMeetingPLW,
              variables: {
                input: {
                  id: plw?.id,
                },
              },
            }) as any
        );
      }
      await Promise.all([...staff_promises, ...topic_promises, ...participant_promises]);
      await deleteNgMeetingMutation.mutateAsync({
        id: editingNgMeetingId,
      });
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || 'Error');
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
    setTargetKeys([]);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addNgMeeting();
            } else if (modalVisible === 'editing') {
              saveEditedNgMeeting();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Popconfirm
          title={
            <div>
              <div>Are you sure you want to delete this NG meeting?</div>
              <div>All information related to this meeting will be deleted.</div>
              <div>This action is not recoverable.</div>
            </div>
          }
          icon={<ExclamationCircleFilled style={{ color: 'orangered', fontSize: 16 }} />}
          onConfirm={deleteNgMeeting}
          okButtonProps={{ danger: true }}
          disabled={!user.isEditor}
        >
          <Button danger disabled={!user.isEditor}>
            Delete
          </Button>
        </Popconfirm>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding'
          ? `Adding a new NG Meeting Record to 
        ${neighborGroup?.care_group.group_number}.${neighborGroup?.group_number}`
          : modalVisible === 'editing'
          ? `Editing NG Meeting (${editingNgMeeting?.meeting_date} @ ${neighborGroup?.care_group.group_number}.${neighborGroup?.group_number}), 
        ${projectDiocese?.diocese.name} Diocese, 
        ${projectDiocese?.project.name} Project`
          : 'NG Meeting Form'
      }
      open={modalVisible === 'adding' || modalVisible === 'editing'}
      footer={customModalFooter}
      closable={false}
      width={650}
    >
      {isStaffError || isNutritionTopicError || isProjectDioceseError || !neighborGroup ? (
        <div>
          <div>
            Error in loading Staff and/or Nutrition Topics from the cloud. Possibly connection
            error.
          </div>
          <>
            {!!projectDioceseError && <div>{`${projectDioceseError}`}</div>}
            {!!staffError && <div>{`${staffError}`}</div>}
            {!!nutritionTopicError && <div>{`${nutritionTopicError}`}</div>}
          </>
        </div>
      ) : isStaffLoading || isProjectDioceseLoading || isNutritionTopicLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <div style={{ maxHeight: 500, overflow: 'auto', paddingRight: 10 }}>
          <Form form={form} name='ng_meeting_form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              name='meeting_date'
              label='Meeting date'
              rules={[{ required: true, message: 'Required.' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name='plw_facilitator_id'
              label='PLW Facilitator'
              rules={[{ required: true, message: 'PLW facilitator is required.' }]}
            >
              <Select allowClear>
                {plws.map((plw) => {
                  const member_number = `${neighborGroup.care_group.group_number}.${neighborGroup.group_number}.${plw.member_number}`;
                  return (
                    <Select.Option key={plw.id} value={plw.id}>
                      {member_number} - {plw.name}
                      {plw.lead_mother ? ' *' : ''}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name='staff_facilitators' label='Staff Facilitators'>
              <Select allowClear mode='multiple'>
                {(staff || [])
                  .sort(
                    (a, b) =>
                      (b.is_active && b.is_staff ? 1 : 0) - (a.is_active && a.is_staff ? 1 : 0)
                  )
                  .map((stf) => {
                    const staffName = stf.first_name + ' ' + stf.last_name;
                    const position = stf.position.name.replace(/([A-Z])[a-zA-Z]*\s?/g, '$1');
                    return (
                      <Select.Option
                        key={stf.id}
                        value={stf.id}
                        disabled={!stf.is_staff || !stf.is_active}
                      >
                        <Text
                          style={{ width: 250, color: 'inherit' }}
                          ellipsis={{ tooltip: stf.email }}
                        >
                          {staffName} ({position}) {stf.email}
                        </Text>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name='topic_ids'
              label='Topics'
              rules={[{ required: true, message: 'Need at least one topic.' }]}
            >
              <Select allowClear mode='multiple'>
                {nutritionTopics.map((nt) => {
                  return (
                    <Select.Option key={nt.id} value={nt.id}>
                      <Text style={{ width: 300 }} ellipsis={{ tooltip: nt.topic_text }}>
                        {nt.topic_text}
                      </Text>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name='description' label='Description'>
              <Input.TextArea />
            </Form.Item>
            <Form.Item name='participants' wrapperCol={{ span: 24 }}>
              <Transfer
                dataSource={plws.map((plw) => {
                  return {
                    key: plw.id,
                    name: plw.name,
                    member_number: plw.member_number,
                    lead_mother: plw.lead_mother,
                    complete_member_number: `${neighborGroup.care_group.group_number}.${neighborGroup.group_number}.${plw.member_number}`,
                  };
                })}
                titles={['PLWs', 'Participants']}
                listStyle={{ width: 300, height: 350 }}
                oneWay
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                render={(item) =>
                  `${item.complete_member_number} - ${item.name} ${item.lead_mother ? ' *' : ''}`
                }
                onChange={(nextTargetKey: string[]) => {
                  setTargetKeys(nextTargetKey);
                }}
                onSelectChange={(sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
                  setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
                }}
              />
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default NGMeetingModalForm;

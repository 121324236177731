import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const hgdFilterTypes = [
  'project',
  'diocese',
  'care_group',
  'neighbor_group',
  'distribution_date',
  'distribution_plan',
] as const;

export type HgdFilterType = typeof hgdFilterTypes[number];

interface HgdState {
  selectedMenuKeys: {
    keys: string[];
    selectAll: boolean;
    currentSelection: string | undefined;
  };
  distributionFilters: Record<HgdFilterType, (boolean | string | number)[] | null>;
}

export const initialState: HgdState = {
  selectedMenuKeys: {
    keys: [],
    selectAll: false,
    currentSelection: undefined,
  },
  distributionFilters: {
    project: null,
    diocese: null,
    care_group: null,
    neighbor_group: null,
    distribution_date: null,
    distribution_plan: null,
  },
};

export const hgdSlice = createSlice({
  name: 'hgd',
  initialState,
  reducers: {
    setSelectedMenuKeys: (state, action: PayloadAction<{ keys: string[]; selectAll: boolean }>) => {
      state.selectedMenuKeys.keys = action.payload.keys;
      state.selectedMenuKeys.selectAll = action.payload.selectAll;
      if (!action.payload.selectAll && action.payload.keys.length > 0) {
        state.selectedMenuKeys.currentSelection = action.payload.keys[0];
      } else {
        state.selectedMenuKeys.currentSelection = undefined;
      }
    },
    setDistributionFilters: (state, action: PayloadAction<HgdState['distributionFilters']>) => {
      state.distributionFilters = action.payload;
    },
  },
});

export const { setSelectedMenuKeys, setDistributionFilters } = hgdSlice.actions;

export default hgdSlice.reducer;

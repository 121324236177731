import { ColumnsType } from 'antd/lib/table';
import React, { FC, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { NgMeetingType, PLWType } from '../NeighborGroups';
import { Typography, Table, Space, Button, Popover } from 'antd';
import Styles from '../NeighborGroups.module.scss';
import NGMeetingModalForm from './NGMeetingModalForm';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Link } = Typography;

interface NgMeetingTableProps {
  isLoading: boolean;
  ngMeetings: NgMeetingType[];
  plws: PLWType[];
}

const NGMeetingTable: FC<NgMeetingTableProps> = ({ isLoading, ngMeetings, plws }) => {
  const [isNgMeetingModalVisible, setIsNgMeetingModalVisible] = useState<
    'adding' | 'editing' | false
  >(false);
  const [editingNgMeetingId, setEditingNgMeetingId] = useState<string | undefined>();
  const selectedMenuKeys = useAppSelector((state) => state.neighborgroups.selectedMenuKeys);
  const user = useGetUserInfo();

  const plwMap = useMemo(() => {
    const plwMap = new Map<string, PLWType>();
    plws.forEach((plw) => plwMap.set(plw.id, plw));
    return plwMap;
  }, [plws]);

  const columns: ColumnsType<NgMeetingType> = [
    {
      title: 'Neighbor Group',
      key: 'neighbor_group',
      width: 150,
      render: (_, record) => (
        <div
          style={{ paddingLeft: 30 }}
        >{`${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`}</div>
      ),
      sorter: (a, b) => a.neighbor_group.group_number.localeCompare(b.neighbor_group.group_number),
    },
    {
      title: 'Date',
      dataIndex: 'meeting_date',
      key: 'meeting_date',
      sorter: (a, b) => b.meeting_date!.localeCompare(a.meeting_date!),
    },
    {
      title: 'PLW Facilitator',
      dataIndex: 'plw_facilitator',
      key: 'plw_facilitator',
      ellipsis: true,
      render: (value, record) => {
        const plw = plwMap.get(record.plw_facilitator.id);
        if (plw) {
          return `${plw.neighbor_group.care_group.group_number}.${
            plw.neighbor_group.group_number
          }.${plw.member_number} - ${plw.name} ${plw.lead_mother ? '*' : ''}`;
        } else {
          return '';
        }
      },
    },
    {
      title: 'Staff Facilitator',
      dataIndex: 'staff_facilitators',
      key: 'staff_facilitators',
      render: (value, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        const staffFacilitators = record.staff_facilitators?.items.map((item) => {
          return {
            key: item?.staff.id,
            name: `${item?.staff.first_name} ${item?.staff.last_name}`,
            position: `${item?.staff.position.name}`,
            //  name: `${item?.node?.kmssUser.firstName} ${item?.node?.kmssUser.lastName}`,
            //  position: `${item?.node?.position.name}`,
          };
        });
        if (staffFacilitators) {
          return (
            <Popover
              placement='rightTop'
              title={`${completeNgNumber} @ ${record.meeting_date}`}
              content={
                <Space direction='vertical'>
                  {staffFacilitators?.map((item) => {
                    return <Space key={item.key}>{`${item.name} (${item.position})`}</Space>;
                  })}
                </Space>
              }
            >
              {staffFacilitators.length > 0
                ? staffFacilitators.length > 1
                  ? `${staffFacilitators.length} staff facilitated`
                  : staffFacilitators[0].name
                : ''}
            </Popover>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Topics',
      dataIndex: 'topics',
      key: 'topics',
      render: (value, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return (
          <Popover
            placement='left'
            title={`${completeNgNumber} @ ${record.meeting_date}`}
            trigger={'click'}
            content={
              <Space
                direction='vertical'
                style={{
                  maxWidth: 500,
                  maxHeight: 300,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {record.topics?.items
                  .sort((a, b) => {
                    const firstNum = parseInt(a!.nutrition_topic.topic_text.split('.')[0]) || false;
                    const secondNum =
                      parseInt(b!.nutrition_topic.topic_text.split('.')[0]) || false;
                    if (firstNum && secondNum) {
                      return firstNum - secondNum;
                    }
                    return a!.nutrition_topic.topic_text.localeCompare(
                      b!.nutrition_topic.topic_text
                    );
                  })
                  .map((item) => {
                    if (item && item.nutrition_topic) {
                      return (
                        <Space key={item?.nutrition_topic?.id}>
                          {item?.nutrition_topic?.topic_text}
                        </Space>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Space>
            }
          >
            <Link>
              {record.topics
                ? `${record.topics.items.length} ${
                    record.topics.items.length === 1 ? 'topic' : 'topics'
                  } covered`
                : ''}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
      align: 'right',
      render: (value, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              title={`${completeNgNumber} @ ${record.meeting_date}`}
              placement='left'
              trigger={'click'}
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                  }}
                >
                  {record.participants?.items
                    .sort(
                      (a, b) =>
                        (plwMap.get(a?.plw.id || '')?.member_number || 0) -
                        (plwMap.get(b?.plw.id || '')?.member_number || 0)
                    )
                    .map((ppt) => {
                      if (ppt && ppt.plw) {
                        const plw = plwMap.get(ppt.plw.id);
                        const completeMemberNumber = `${
                          record.neighbor_group.care_group.group_number
                        }.${record.neighbor_group.group_number}.${
                          plw?.member_number === undefined ? '-' : plw.member_number
                        }`;
                        return (
                          <Space key={ppt.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{plw?.name}</span>
                            {plw?.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.participants?.items.length}</Link>
            </Popover>
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Link
            disabled={!user.isEditor || selectedMenuKeys.selectAll}
            onClick={() => showEditNgMeetingModal(record.id)}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  const showAddNgMeetingModal = () => {
    setEditingNgMeetingId(undefined);
    setIsNgMeetingModalVisible('adding');
  };

  const showEditNgMeetingModal = (editingNgMeetingId: string) => {
    setEditingNgMeetingId(editingNgMeetingId);
    setIsNgMeetingModalVisible('editing');
  };

  return (
    <div className={Styles.dataTable}>
      <div className={Styles.tableHeaderRow}>
        <div className={Styles.buttonContainer}>
          <Space>
            <Button
              className={Styles.exportButton}
              onClick={showAddNgMeetingModal}
              disabled={
                !user.isEditor ||
                isLoading ||
                selectedMenuKeys.selectAll ||
                selectedMenuKeys.keys.length < 1
              }
            >
              Add New Neighbor Group Meeting
            </Button>
          </Space>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={ngMeetings}
        size='small'
        rowKey={'id'}
        loading={isLoading}
        showSorterTooltip={false}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 50,
          style: { marginRight: 25 },
          showTotal: (total, range) => `Total ${total}`,
        }}
        scroll={{ x: 'calc(100vw - 400px)', y: 'calc(100vh - 350px)' }}
      />
      {!!selectedMenuKeys.currentSelection && (
        <NGMeetingModalForm
          modalVisible={isNgMeetingModalVisible}
          setModalVisible={setIsNgMeetingModalVisible}
          editingNgMeetingId={editingNgMeetingId}
        />
      )}
    </div>
  );
};

export default NGMeetingTable;

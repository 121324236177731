import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

type UserType = {
  username: string | undefined;
  email: string | undefined;
  groups: string[];
  projects: string[];
  dioceses: string[];
  projectCount: number;
  dioceseCount: number;
  isAdmin: boolean;
  isEditor: boolean;
};

export const useGetUserInfo = (): UserType => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<UserType['email']>();
  const [groups, setGroups] = useState<UserType['groups']>([]);
  const [projects, setProjects] = useState<UserType['projects']>([]);
  const [dioceses, setDioceses] = useState<UserType['dioceses']>([]);
  const [projectCount, setProjectCount] = useState<UserType['projectCount']>(0);
  const [dioceseCount, setDioceseCount] = useState<UserType['dioceseCount']>(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    if (user) {
      // console.log(user);
      setUsername(user.username);
      const signInuserSession = user.getSignInUserSession();
      if (user.attributes) {
        setEmail(user.attributes.email);
      } else {
        setEmail(undefined);
      }
      if (signInuserSession) {
        const accessToken = signInuserSession.getAccessToken();
        let allGroups: string[] = accessToken.payload['cognito:groups'];
        const isAdmin = allGroups.includes('Admins');
        const isEditor = allGroups.includes('Editors');
        const groups = allGroups
          .filter((group) => !(group === 'Admins' || group === 'Editors'))
          .sort();
        const user_project = [...new Set(groups.map((group_name) => group_name.split('_')[0]))];
        const user_dioceses = [...new Set(groups.map((group_name) => group_name.split('_')[1]))];
        setGroups(groups);
        setProjects(user_project);
        setDioceses(user_dioceses);
        setProjectCount(user_project.length);
        setDioceseCount(user_dioceses.length);
        setIsAdmin(isAdmin);
        setIsEditor(isEditor);
      }
    } else {
      setUsername(undefined);
      setGroups([]);
      setProjects([]);
      setDioceses([]);
      setProjectCount(0);
      setDioceseCount(0);
      setIsAdmin(false);
      setIsEditor(false);
    }
  }, [user]);

  return {
    username,
    email,
    groups,
    projects,
    dioceses,
    projectCount,
    dioceseCount,
    isAdmin,
    isEditor,
  };
};

import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Col, Modal, Row, Upload } from 'antd';
import type { UploadFile } from 'antd';
import type { RcFile } from 'antd/es/upload';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const HomePagePhotoAlbum = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [photoList, setPhotoList] = useState<UploadFile[]>([]);

  const { isAdmin } = useGetUserInfo();

  useEffect(() => {
    const getPhotoList = async () => {
      try {
        const result = await Storage.list('');
        // console.log(result);
        let uploadedFiles: UploadFile[] = [];
        for (let file of result) {
          // console.log(file.eTag);
          if (file.key && file.eTag) {
            const signedUrl = await Storage.get(file.key);
            uploadedFiles.push({ url: signedUrl, name: file.key, uid: file.key, status: 'done' });
          }
        }
        setPhotoList(uploadedFiles);
      } catch {
        console.error('Error in fetching photos.');
      }
    };
    getPhotoList();
  }, []);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const beforeUpload = async (file: RcFile) => {
    // https://github.com/nanxiaobei/antd-img-crop/issues/123#issuecomment-801167450
    try {
      const reader = new FileReader();
      const newFileName = `${file.lastModified.toString()}_${file.name}`;
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPhotoList((prev) => [
          ...prev,
          { ...file, url: reader.result as string, name: newFileName, status: 'uploading' },
        ]);
      };
      await Storage.put(newFileName, file);
      setPhotoList((prev) => {
        const filtered = prev.filter((f) => f.uid === file.uid)[0];
        if (filtered) {
          filtered.status = undefined;
        }
        return prev;
      });
    } catch (error) {
      console.error(error);
    } finally {
      return false;
    }
  };

  const onRemove = async (file: UploadFile) => {
    try {
      await Storage.remove(file.name);
      setPhotoList((prev) => prev.filter((f) => f.uid !== file.uid));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row>
      <Col xs={1} sm={3} md={4} lg={6} xl={7}></Col>
      <Col xs={22} sm={18} md={16} lg={12} xl={10}>
        <h3 style={{ marginTop: 35, marginBottom: 25 }}>Images to display at home page.</h3>
        <ImgCrop
          quality={1}
          aspect={9 / 6}
          grid={true}
          modalTitle={'Edit image before upload'}
          modalOk={'Upload'}
        >
          <Upload
            accept='.jpg, .png'
            fileList={photoList}
            listType='picture-card'
            onPreview={handlePreview}
            maxCount={15}
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            showUploadList={{
              showDownloadIcon: true,
              showRemoveIcon: isAdmin,
              showPreviewIcon: true,
            }}
          >
            {isAdmin ? (photoList.length <= 14 ? uploadButton : null) : null}
          </Upload>
        </ImgCrop>
      </Col>
      <Col xs={1} sm={3} md={4} lg={6} xl={7}></Col>

      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Row>
  );
};

export default HomePagePhotoAlbum;

import { Button, Descriptions } from 'antd';
import { FC } from 'react';
import { CGMeetingType } from './CareGroupMeetings';
import { TableFilterType } from '../common_types';

interface PropsType {
  filteredCgMeetings: CGMeetingType[];
  cgMeetingFilters: Record<string, (boolean | string | number)[] | null>;
  facilitatorFilter: Array<TableFilterType>;
  topicFilter: Array<TableFilterType>;
  hide: () => void;
}

const CGMSummary: FC<PropsType> = ({ filteredCgMeetings, cgMeetingFilters, facilitatorFilter, topicFilter, hide }) => {
  const numberOfMeeting = filteredCgMeetings.length;

  const careGroupIDSet = new Set<string>();
  const participantIDSet = new Set<string>();
  const dioceseNameSet = new Set<string>();
  const projectNameSet = new Set<string>();
  const facilitatorMap = new Map<string, string>();
  const topicMap = new Map<string, string>();
  const meetingDateSet = new Set<string>();

  filteredCgMeetings.forEach((fcgm) => {
    careGroupIDSet.add(fcgm.care_group.id);
    meetingDateSet.add(fcgm.meeting_date || '');
    if (fcgm.participants) {
      fcgm.participants.items.forEach((participant) => {
        if (participant && participant.plw) {
          participantIDSet.add(participant.plw.id);
        }
      });
    }

    if (fcgm.care_group.project_diocese.diocese) {
      dioceseNameSet.add(fcgm.care_group.project_diocese.diocese.name);
    }

    if (fcgm.care_group.project_diocese.project) {
      projectNameSet.add(fcgm.care_group.project_diocese.project.name);
    }

    if (fcgm.facilitators) {
      fcgm.facilitators.items.forEach((facilitator) => {
        if (facilitator && facilitator.staff) {
          facilitatorMap.set(
            facilitator.staff.id,
            `${facilitator.staff.first_name} ${facilitator.staff.last_name} (${facilitator.staff.position.name
              .match(/[A-Z]/g)
              ?.join('')})`
          );
        }
      });
    }

    if (fcgm.topics) {
      fcgm.topics.items.forEach((topic) => {
        if (topic && topic.nutrition_topic) {
          topicMap.set(topic.nutrition_topic.id, topic.nutrition_topic.topic_text);
        }
      });
    }
  });
  const meetingDateArray = [...meetingDateSet].sort();
  return (
    <Descriptions
      title='Care Group Meetings Summary'
      // size='small'
      column={3}
      bordered
      extra={<Button onClick={hide}>Close</Button>}
    >
      <Descriptions.Item label='Number of Meetings' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {numberOfMeeting}
      </Descriptions.Item>
      <Descriptions.Item label='Number of Care Groups' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {careGroupIDSet.size}
      </Descriptions.Item>
      <Descriptions.Item label='Total Participants' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {participantIDSet.size}
      </Descriptions.Item>

      <Descriptions.Item label='Projects'>
        {[...projectNameSet]
          .sort((a, b) => a.localeCompare(b))
          .map((projectName) => (
            <div key={projectName}>
              <span>{projectName}</span>
              <br />
            </div>
          ))}
      </Descriptions.Item>

      <Descriptions.Item label='From'>{meetingDateArray[0]}</Descriptions.Item>
      <Descriptions.Item label='To'>{meetingDateArray[meetingDateArray.length - 1]}</Descriptions.Item>

      <Descriptions.Item label='Dioceses'>
        {[...dioceseNameSet]
          .sort((a, b) => a.localeCompare(b))
          .map((dioceseName) => (
            <div key={dioceseName}>
              <span>{dioceseName}</span>
              <br />
            </div>
          ))}
      </Descriptions.Item>

      <Descriptions.Item label='Topics Covered' span={2}>
        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
          {[...topicMap]
            .sort((a, b) => {
              let A = parseInt(a[1].split('.')[0]);
              let B = parseInt(b[1].split('.')[0]);
              if (isNaN(A) || isNaN(B)) return 0;
              return A - B;
            })
            .map((topic) => (
              <div key={topic[0]}>
                <span>{topic[1]}</span>
                <br />
              </div>
            ))}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label='Facilitators'>
        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
          {[...facilitatorMap]
            .sort((a, b) => a[1].localeCompare(b[1]))
            .map((staff) => (
              <div key={staff[0]}>
                <span>{staff[1]}</span>
                <br />
              </div>
            ))}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label='Filtered by' span={2}>
        <div style={{ maxHeight: 150, overflow: 'auto' }}>
          {Object.keys(cgMeetingFilters).map((filter) => {
            if (cgMeetingFilters[filter] && cgMeetingFilters[filter]!.length > 0) {
              const spaced = filter.replace('_', ' ');
              const title = spaced.charAt(0).toUpperCase() + spaced.slice(1);
              return (
                <div key={filter} style={{ padding: '4px 0px 0px 8px' }}>
                  <span>
                    {title} ({cgMeetingFilters[filter]?.length}):{' '}
                    <span style={{ color: 'darkred' }}>
                      {cgMeetingFilters[filter]!.map((id) => {
                        switch (filter) {
                          case 'facilitators':
                            return facilitatorFilter.find((ff) => ff.value === id)?.text;
                          case 'topics':
                            return topicFilter.find((tf) => tf.value === id)?.text;
                          case 'diocese':
                          case 'meeting_date':
                          case 'care_group':
                          case 'project':
                            return id;
                          default:
                            return null;
                        }
                      }).join(', ')}
                    </span>
                  </span>
                  <br></br>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CGMSummary;

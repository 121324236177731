import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify, Analytics, Auth } from 'aws-amplify';
import awsExport from './aws-exports';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './index.css';
import { Authenticator } from '@aws-amplify/ui-react';

// import Analytics from '@aws-amplify/analytics';
// import Auth from '@aws-amplify/auth';

const amplifyConfig = {
  Auth: {
    identityPoolId: 'COGNITO_IDENTITY_POOL_ID',
    region: 'ap-southeast-1',
  },
};
//Initialize Amplify
Auth.configure(amplifyConfig);

const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: 'fce1a771b9894dddaebfd9141643b21f',
    // Amazon service region
    region: 'ap-southeast-1',
    mandatorySignIn: false,
  },
};

Analytics.configure(analyticsConfig);

//Record an event
Analytics.record('some-event-name');

//Record a custom event
Analytics.record({
  name: 'Album',
  attributes: { genre: 'Rock', year: '1989' },
});

Amplify.configure(awsExport);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

Analytics.autoTrack('session', {
  enable: true,
});

Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'pageView',
  type: 'SPA',
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
});

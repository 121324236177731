export const getNeighborGroupCustom = /* GraphQL */ `
  query GetNeighborGroupCustom($id: ID!) {
    getNeighborGroup(id: $id) {
      id
      group_number
      project_diocese_code
      care_group {
        id
        group_number
      }
      plws {
        items {
          id
          neighbor_group {
            group_number
            care_group {
              group_number
            }
          }
          name
          father_name
          member_number
          date_of_birth
          date_of_registry
          lead_mother
          contact_number
          disabled
          disability_type
          lost_contact
          birth_histories {
            items {
              id
              lmp
              miscarriage_date
              delivered_date
              child_name
              child_gender
              date_of_death
            }
          }
        }
      }
      ng_meetings {
        items {
          id
          meeting_date
          description
          neighbor_group {
            group_number
            care_group {
              group_number
            }
          }
          plw_facilitator {
            id
          }
          topics {
            items {
              nutrition_topic {
                id
                topic_text
                topic_text_myanmar
              }
            }
          }
          staff_facilitators {
            items {
              staff {
                id
                first_name
                last_name
                position {
                  name
                }
              }
            }
          }
          participants {
            items {
              plw {
                id
              }
            }
          }
        }
      }
      distributions {
        items {
          id
          distribution_date
          description
          neighbor_group {
            group_number
            care_group {
              group_number
            }
          }
          distribution_item {
            id
            title
            description
            planned_distribution_start_date
            planned_distribution_end_date
          }
          recipients {
            items {
              plw {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const getNeighborGroupForEdit = /* GraphQL */ `
  query GetNeighborGroupForEdit($id: ID!) {
    getNeighborGroup(id: $id) {
      id
      group_number
      care_group {
        id
        group_number
      }
      village {
        id
        villageTract {
          id
        }
      }
      camp {
        id
      }
      plws(limit: 1) {
        items {
          id
        }
      }
      ng_meetings(limit: 1) {
        items {
          id
        }
      }
      distributions(limit: 1) {
        items {
          id
        }
      }
    }
  }
`;

export const neighborGroupByVillageCustom = /* GraphQL */ `
  query NeighborGroupByVillageCustom(
    $village_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNeighborGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    neighborGroupByVillage(
      village_id: $village_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group_number
        care_group {
          id
          group_number
          project_diocese {
            id
            project_diocese_code
            project {
              id
              name
            }
            diocese {
              id
              name
            }
          }
        }
        village {
          id
          name
          name_mm
          villageTract {
            id
            name
            name_mm
            township {
              id
              name
              name_mm
            }
          }
        }
        camp {
          id
          name
          name_mm
          township {
            id
            name
            name_mm
          }
        }
      }
    }
  }
`;

export const neighborGroupByVillageForMapDrawer = /* GraphQL */ `
  query NeighborGroupByVillageForMapDrawer($id: ID!) {
    getVillage(id: $id) {
      id
      name
      name_mm
      village_pcode
      villageTract {
        id
        name
        name_mm
        vt_pcode
        township {
          id
          name
          name_mm
          ts_pcode
        }
      }
      neighbor_groups {
        items {
          id
          group_number
          care_group {
            id
            group_number
            project_diocese {
              id
              project {
                id
                name
              }
              diocese {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Styles from './GoogleMap.module.scss';
import { useGetUserInfo } from '../../hooks/auth_hooks';
import CustomMarker from './CustomMarker';
import { CustomLatLng, CustomLatLngBounds } from './LatLngBounds';
import { compact } from 'lodash';
import { CareGroupForMapType } from './Map';
import { useAppDispatch } from '../../redux/hooks';
import { setSelectedVillageId } from '../../redux/mapSlice';
import MapDrawer from './MapDrawer';

const icon_color_array = [
  'orange',
  'violet',
  'red',
  'light_blue',
  'teal',
  'pink',
  'green',
  'brown',
  'blue',
  'black',
];

// const MANDALAY = { lat: 21.94, lng: 96.08 };
const NAYPYIDAW = { lat: 19.842134, lng: 96.34945 };
const defaultMapCenter = NAYPYIDAW;
interface GoogleMapCompProps {
  isDataLoading: boolean;
  careGroups: CareGroupForMapType[] | undefined;
}

type VillageLatLngType = {
  village_id: string;
  village_name: string;
  village_name_mm: string | undefined;
  township_id: string;
  care_group_number: string;
  latitude: number | undefined;
  longitude: number | undefined;
};

type VillageLatLngCollectionType = {
  [village_id: string]: VillageLatLngType;
};

type CampLatLngType = {
  camp_id: string;
  camp_name: string;
  camp_name_mm: string | undefined;
  township_id: string;
  care_group_number: string;
  latitude: number | undefined;
  longitude: number | undefined;
};

type CampLatLngCollectionType = {
  [camp_id: string]: CampLatLngType;
};

type TownshipLatLngType = {
  township_id: string;
  township_name: string;
  township_name_mm: string | undefined;
  care_group_count: number;
  latLngBound: CustomLatLngBounds;
  villages: VillageLatLngCollectionType;
  camps: CampLatLngCollectionType;
};

type TownshipLatLngCollectionType = {
  [township_id: string]: TownshipLatLngType;
};

type ProjectDioceseLatLngType = {
  project_diocese_code: string;
  project_id: string;
  project_name: string;
  diocese_id: string;
  diocese_name: string;
  care_group_count: number;
  latLngBound: CustomLatLngBounds;
  townships: TownshipLatLngCollectionType;
};

type MapDataStructure = {
  [project_diocese_code: string]: ProjectDioceseLatLngType;
};

const GoogleMapComp: FC<GoogleMapCompProps> = ({ isDataLoading, careGroups }) => {
  const dispatch = useAppDispatch();

  // const [mapCenter, setMapCenter] = useState(defaultMapCenter);
  const [projectDioceseLatLng, setProjectDioceseLatLng] = useState<MapDataStructure>({});

  const [gmap, setGmap] = useState<google.maps.Map | null>(null);
  const [showLabels, setShowLabels] = useState(true);

  const [projectDioceseMarkers, setProjectDioceseMarkers] = useState<JSX.Element[]>([]);
  const [townshipMarkers, setTownshipsMarkers] = useState<JSX.Element[]>([]);
  const [villageMarkers, setVillageMarkers] = useState<JSX.Element[]>([]);
  const [displayMarkers, setDisplayMarkers] = useState<JSX.Element[]>([]);

  const [selectedTownshipId, setSelectedTownshipId] = useState<string>();

  const { groups } = useGetUserInfo();
  const pd_color_map = useMemo(() => {
    const pd_color_map: { [key: string]: string } = {};
    groups.forEach((group, index) => {
      pd_color_map[group] = icon_color_array[index % 10];
    });
    return pd_color_map;
  }, [groups]);
  // console.log(pd_color_map);

  useEffect(() => {
    if (careGroups) {
      // console.log(careGroups.length);
      const pd_latlng: MapDataStructure = {};
      careGroups.forEach((cg) => {
        const proj_dioc = cg.project_diocese;
        const proj_dioc_code = proj_dioc.project_diocese_code;
        const tsp = cg.township;

        if (!(proj_dioc_code in pd_latlng)) {
          pd_latlng[proj_dioc.project_diocese_code] = {
            project_diocese_code: proj_dioc_code,
            project_id: proj_dioc.project.id,
            project_name: proj_dioc.project.name,
            diocese_id: proj_dioc.diocese.id,
            diocese_name: proj_dioc.diocese.name,
            care_group_count: 0,
            latLngBound: new CustomLatLngBounds(),
            townships: {},
          };
        }
        const current_pd = pd_latlng[proj_dioc_code];
        current_pd.care_group_count += 1;

        if (!(cg.township.id in current_pd.townships)) {
          current_pd.townships[tsp.id] = {
            township_id: tsp.id,
            township_name: tsp.name,
            township_name_mm: tsp.name_mm || undefined,
            care_group_count: 0,
            latLngBound: new CustomLatLngBounds(),
            villages: {},
            camps: {},
          };
        }
        const current_township = current_pd.townships[tsp.id];
        current_township.care_group_count += 1;

        if (cg.neighbor_groups) {
          cg.neighbor_groups.items.forEach((ng) => {
            if (ng) {
              if (ng.village) {
                const village = ng.village;
                if (!(village.id in current_township.villages)) {
                  current_township.villages[village.id] = {
                    village_id: village.id,
                    village_name: village.name,
                    village_name_mm: village.name_mm || undefined,
                    township_id: current_township.township_id,
                    care_group_number: cg.group_number,
                    latitude: village.latitude || undefined,
                    longitude: village.longitude || undefined,
                  };
                  if (village.latitude && village.longitude) {
                    current_township.latLngBound.extend(
                      new CustomLatLng(village.latitude, village.longitude)
                    );
                    current_pd.latLngBound.extend(
                      new CustomLatLng(village.latitude, village.longitude)
                    );
                  }
                }
              }
              if (ng.camp) {
                const camp = ng.camp;
                if (!(camp.id in current_township.camps)) {
                  current_township.camps[camp.id] = {
                    camp_id: camp.id,
                    camp_name: camp.name,
                    camp_name_mm: camp.name_mm || undefined,
                    township_id: current_township.township_id,
                    care_group_number: cg.group_number,
                    latitude: camp.latitude || undefined,
                    longitude: camp.longitude || undefined,
                  };
                  if (camp.latitude && camp.longitude) {
                    current_township.latLngBound.extend(
                      new CustomLatLng(camp.latitude, camp.longitude)
                    );
                    current_pd.latLngBound.extend(new CustomLatLng(camp.latitude, camp.longitude));
                  }
                }
              }
            }
          });
        }
      });
      setProjectDioceseLatLng(pd_latlng);
    }
  }, [careGroups]);

  useEffect(() => {
    if (gmap) {
      const pdMarkerArray = Object.values(projectDioceseLatLng).map((pd, index) => {
        const color = pd_color_map[pd.project_diocese_code] || 'green';
        const { latitude, longitude } = pd.latLngBound.getCenter();
        return (
          <CustomMarker
            index={index}
            key={pd.project_diocese_code}
            marker_key={pd.project_diocese_code}
            color={color}
            label={pd.diocese_name + ' Diocese'}
            inner_label={pd.care_group_count.toString()}
            lat={latitude}
            lng={longitude}
            showLabels={showLabels}
            onClick={() => {
              if (gmap) {
                gmap.setZoom(8);
                gmap.panTo({ lat: latitude, lng: longitude });
              }
            }}
          />
        );
      });

      const tspMarkerArray = Object.values(projectDioceseLatLng).flatMap((pd, pd_index) => {
        const color = pd_color_map[pd.project_diocese_code] || 'green';
        return Object.values(pd.townships).map((tsp, tsp_index) => {
          const { latitude, longitude } = tsp.latLngBound.getCenter();
          return (
            <CustomMarker
              index={pd_index * 100 + tsp_index}
              key={tsp.township_id}
              marker_key={tsp.township_id}
              color={color}
              label={tsp.township_name + ' Tsp'}
              inner_label={tsp.care_group_count.toString()}
              lat={latitude}
              lng={longitude}
              showLabels={showLabels}
              onClick={() => {
                if (gmap) {
                  gmap.setZoom(10);
                  gmap.panTo({ lat: latitude, lng: longitude });
                  setSelectedTownshipId(tsp.township_id);
                }
              }}
            />
          );
        });
      });

      const villageMarkerArray = Object.values(projectDioceseLatLng).flatMap((pd, pd_index) => {
        const color = pd_color_map[pd.project_diocese_code] || 'green';
        return Object.values(pd.townships).flatMap((tsp, tsp_index) => {
          return [
            ...Object.values(tsp.villages).map((village, v_index) => {
              if (village.latitude && village.longitude) {
                return (
                  <CustomMarker
                    index={pd_index * 10000 + tsp_index * 100 + v_index}
                    key={village.village_id}
                    marker_key={village.village_id}
                    color={
                      !selectedTownshipId
                        ? color
                        : selectedTownshipId === village.township_id
                        ? color
                        : 'gray'
                    }
                    label={village.village_name}
                    inner_label={village.care_group_number}
                    lat={village.latitude}
                    lng={village.longitude}
                    showLabels={showLabels}
                    onClick={() => {
                      console.log('click village');
                      dispatch(setSelectedVillageId(village.village_id));
                    }}
                  />
                );
              } else {
                return null;
              }
            }),
            ...Object.values(tsp.camps).map((camp, c_index) => {
              if (camp.latitude && camp.longitude) {
                return (
                  <CustomMarker
                    index={pd_index * 100000 + tsp_index * 1000 + c_index}
                    key={camp.camp_id}
                    marker_key={camp.camp_id}
                    color={
                      !selectedTownshipId
                        ? color
                        : selectedTownshipId === camp.township_id
                        ? color
                        : 'gray'
                    }
                    label={camp.camp_name}
                    inner_label={camp.care_group_number}
                    lat={camp.latitude}
                    lng={camp.longitude}
                    showLabels={showLabels}
                    onClick={() => {
                      console.log('click camp');
                    }}
                  />
                );
              } else {
                return null;
              }
            }),
          ];
        });
      });
      setProjectDioceseMarkers(pdMarkerArray);
      setTownshipsMarkers(tspMarkerArray);
      setVillageMarkers(compact(villageMarkerArray));
      if (gmap) {
        const currentZoomLevel = gmap.getZoom();
        if (currentZoomLevel) {
          if (currentZoomLevel <= 7) {
            setDisplayMarkers(pdMarkerArray);
          } else if (currentZoomLevel <= 9) {
            setDisplayMarkers(tspMarkerArray);
          } else {
            setDisplayMarkers(compact(villageMarkerArray));
          }
        }
      }
    }
  }, [projectDioceseLatLng, gmap, showLabels, selectedTownshipId, dispatch, pd_color_map]);

  // Following useRef tries to avoid unnecessary setState and thus unnecessary render
  const previousZoomLevel = useRef<number | undefined>(gmap?.getZoom() || 5);

  useEffect(() => {
    previousZoomLevel.current = gmap?.getZoom();
  }, [gmap, gmap?.getZoom()]);

  const onZoomChanged = useCallback(() => {
    if (gmap) {
      const currentZoomLevel = gmap.getZoom();
      if (currentZoomLevel) {
        if (currentZoomLevel <= 7) {
          if (previousZoomLevel.current && previousZoomLevel.current <= 7) {
            return;
          }
          setDisplayMarkers(projectDioceseMarkers);
          setSelectedTownshipId(undefined);
        } else if (currentZoomLevel <= 9) {
          // only if return from higher zoom level
          if (previousZoomLevel.current && previousZoomLevel.current > 9) {
            setSelectedTownshipId(undefined);
          }
          if (
            previousZoomLevel.current &&
            previousZoomLevel.current > 7 &&
            previousZoomLevel.current <= 9
          ) {
            return;
          }
          setDisplayMarkers(townshipMarkers);
        } else if (currentZoomLevel > 9) {
          if (previousZoomLevel.current && previousZoomLevel.current > 9) {
            return;
          }
          setDisplayMarkers(villageMarkers);
        }
      }
    }
  }, [gmap, projectDioceseMarkers, villageMarkers, townshipMarkers]);
  // console.log(displayMarkers.length);

  const onMapClick = useCallback(() => {
    dispatch(setSelectedVillageId(undefined));
  }, [dispatch]);

  const onMapCenterChange = useCallback(() => {
    if (gmap) {
      // const newLatLng = gmap.getCenter();
      // setMapCenter({ lat: newLatLng?.lat() || 0, lng: newLatLng?.lng() || 0 });
    }
  }, [gmap]);

  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBhl1bH6BySyi6vfmZ9EVxZSykSlkRJC_s',
  });

  if (mapLoadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isMapLoaded) {
    return (
      <div style={{ display: 'none' }}>
        <span>Loading ...</span>
      </div>
    );
  }

  function createCheckBoxControl(
    map: google.maps.Map,
    setShowLabels: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    const checkBoxContainer = document.createElement('div');
    checkBoxContainer.style.display = 'flex';
    checkBoxContainer.style.justifyContent = 'center';

    const checkBoxControl = document.createElement('input');

    checkBoxContainer.style.width = '125px';
    checkBoxContainer.style.backgroundColor = '#fff';
    checkBoxContainer.style.border = '2px solid #fff';
    checkBoxContainer.style.borderRadius = '3px';
    checkBoxContainer.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    checkBoxContainer.style.color = 'rgb(25,25,25)';
    checkBoxContainer.style.cursor = 'pointer';
    checkBoxContainer.style.fontFamily = 'Roboto,Arial,sans-serif';
    checkBoxContainer.style.fontSize = '16px';
    checkBoxContainer.style.lineHeight = '36px';
    checkBoxContainer.style.margin = '8px 0 22px';
    checkBoxContainer.style.padding = '0 5px';
    checkBoxContainer.style.textAlign = 'center';

    checkBoxControl.id = 'checkbox';
    checkBoxControl.type = 'checkbox';
    checkBoxControl.textContent = 'Show label';
    checkBoxControl.checked = true;
    // checkBoxControl.style.display = 'inline-block';
    checkBoxControl.style.marginRight = '5px';

    const label = document.createElement('label');
    label.htmlFor = 'checkbox';
    label.innerHTML = 'Show label';
    label.style.marginRight = '6px';
    label.style.flex = '1';
    label.style.paddingLeft = '5px';

    checkBoxControl.addEventListener('change', (event: any) => {
      setShowLabels(event?.target?.checked);
    });

    checkBoxContainer.appendChild(label);
    checkBoxContainer.appendChild(checkBoxControl);

    return checkBoxContainer;
  }

  function createCenterControl(map: google.maps.Map, clickCallBack: VoidFunction) {
    const controlButton = document.createElement('button');

    // Set CSS for the control.
    controlButton.style.width = '75px';
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '36px';
    controlButton.style.margin = '8px 0 22px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';

    controlButton.textContent = 'Reset';
    controlButton.title = 'Click to reset the map';
    controlButton.type = 'button';

    controlButton.addEventListener('click', clickCallBack);

    return controlButton;
  }

  const onMapLoad = (map: google.maps.Map) => {
    setGmap(map);

    // Create the DIV to hold the control.
    const centerControlDiv = document.createElement('div');
    centerControlDiv.style.display = 'flex';
    centerControlDiv.style.width = '210px';
    centerControlDiv.style.justifyContent = 'space-between';
    // Create the control.
    const centerControl = createCenterControl(map, () => {
      map.setCenter(defaultMapCenter);
      map.setZoom(5.8);
    });
    const checkBoxControl = createCheckBoxControl(map, setShowLabels);
    // Append the control to the DIV.
    centerControlDiv.appendChild(checkBoxControl);
    centerControlDiv.appendChild(centerControl);
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
  };

  return (
    <GoogleMap
      mapContainerClassName={Styles.MapContainer}
      center={defaultMapCenter}
      zoom={5.8}
      onLoad={onMapLoad}
      onClick={onMapClick}
      onCenterChanged={onMapCenterChange}
      onZoomChanged={onZoomChanged}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeId: 'roadmap',
        mapTypeControlOptions: {
          mapTypeIds: ['satellite', 'hybrid', 'roadmap'],
        },
        // restriction: {
        //   latLngBounds: {
        //     north: 29.11,
        //     south: 9.31,
        //     west: 91.56,
        //     east: 101.45,
        //   },
        //   strictBounds: false,
        // },
      }}
    >
      {displayMarkers}
      <MapDrawer drawerWidth={700} />
    </GoogleMap>
  );
};

export default GoogleMapComp;

import React, { useState } from 'react';
import { Button, MenuProps } from 'antd';
import { Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import kmssLogo from '../../assets/images/kmss-logo-red.png';
import Styles from './styles/MainMenu.module.scss';
// import { DataStore } from 'aws-amplify';
import { useQueryClient } from '@tanstack/react-query';

import { useAppSelector } from '../../redux/hooks';

const MainMenu = () => {
  const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut]);
  const navigate = useNavigate();
  const [current, setCurrent] = useState<string>();
  const opened_care_group_id = useAppSelector((state) => state.caregroups.openedCareGroupID);

  const queryClient = useQueryClient();

  let menuItems: MenuProps['items'] = [
    {
      label: <Link to='/'>Home</Link>,
      key: 'mail',
    },
  ];

  let authenticatedMenuItems: MenuProps['items'] = [
    {
      label: <Link to='/'>Home</Link>,
      key: 'mail',
    },
    {
      label: (
        <Link to={opened_care_group_id ? `/caregroups/${opened_care_group_id}` : '/caregroups'}>
          Care Groups
        </Link>
      ),
      key: 'app',
    },
    {
      label: (
        <span>
          Activity Reports <CaretDownOutlined />
        </span>
      ),
      key: 'app1',
      children: [
        {
          label: <Link to='/activities/caregroupmeetings'>Care Group Meetings</Link>,
          key: 'cgm',
        },
        {
          label: <Link to='/activities/neighborgroupmeetings'>Neighbor Group Meetings</Link>,
          key: 'ngm',
        },
        // {
        //   label: 'Home Garden Trainings',
        //   key: 'hgt',
        // },
        {
          label: <Link to='/activities/homegardendistributions'>Home Garden Distributions</Link>,
          key: 'hgd',
        },
        {
          label: <Link to='/activities/otheractivities'>Other Activities</Link>,
          key: 'oa',
        },
      ],
    },
    {
      label: (
        <span>
          Setup <CaretDownOutlined />
        </span>
      ),
      key: 'setup',
      children: [
        {
          label: <NavLink to={'/setup/nutritionmessages'}>Nutrition Messages</NavLink>,
          key: 'nm',
        },
        {
          label: <NavLink to={'/setup/distributionplans'}>Distribution Plans</NavLink>,
          key: 'dp',
        },
        {
          label: <NavLink to={'/setup/otheractivitytypes'}>Other Activity Types</NavLink>,
          key: 'oat',
        },
        {
          type: 'divider',
        },
        {
          label: <NavLink to={'/setup/photos'}>Photo Album</NavLink>,
          key: 'photos',
        },
        // {
        //   label: 'Home Garden Training Topics',
        //   key: 'hgtt',
        // },
        // {
        //   label: 'Home Garden Distribution Items',
        //   key: 'hgdi',
        // },
        // {
        //   label: 'Other Activity Types',
        //   key: 'oat',
        // },
        // {
        //   type: 'divider',
        // },
        // {
        //   label: <Link to='/setup/staff'>Staff</Link>,
        //   key: 'staff',
        // },
        // {
        //   label: 'Projects',
        //   key: 'project',
        // },
      ],
    },
    // {
    //   label: 'Charts',
    //   key: 'app2',
    // },
    {
      label: <Link to='/map'>Map</Link>,
      key: 'app3',
    },
    // {
    //   label: <Link to='/uploader'>Uploader</Link>,
    //   key: 'uploader',
    // },
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    // console.log('click', e);
    setCurrent(e.key);
  };

  async function logout() {
    // await DataStore.stop();
    // await DataStore.clear();
    signOut();
    navigate('/login');
    queryClient.clear();
  }

  return (
    <>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
      <div className={Styles.menubar}>
        <div style={{ height: '2.8rem', padding: '3px 10px' }}>
          <img src={kmssLogo} alt='Logo' height={'100%'} />
        </div>
        <Menu
          onClick={onClick}
          selectedKeys={current ? [current] : []}
          mode='horizontal'
          items={route === 'authenticated' ? authenticatedMenuItems : menuItems}
          style={{
            // width: route === 'authenticated' ? 700 : 80,
            width: 'calc(100vw - 200px)',
            justifyContent: 'center',
          }}
          // style={{ width: 300, justifyContent: 'center' }}
        />

        <div>
          {route !== 'authenticated' ? (
            <Button
              type='link'
              onClick={() => navigate('/login')}
              style={{ margin: '8px 20px 0 0' }}
            >
              Login
            </Button>
          ) : (
            <Button type='text' onClick={() => logout()} style={{ margin: '8px 20px 0 0' }}>
              Logout
            </Button>
          )}
        </div>
      </div>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default MainMenu;

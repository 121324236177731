import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NeighborGroupsState {
  selectedMenuKeys: {
    keys: string[];
    selectAll: boolean;
    currentSelection: string | undefined;
  };
}

export const initialState: NeighborGroupsState = {
  selectedMenuKeys: {
    keys: [],
    selectAll: false,
    currentSelection: undefined,
  },
};

export const neighborgroupsSlice = createSlice({
  name: 'neighborgroups',
  initialState,
  reducers: {
    setSelectedMenuKeys: (state, action: PayloadAction<{ keys: string[]; selectAll: boolean }>) => {
      state.selectedMenuKeys.keys = action.payload.keys;
      state.selectedMenuKeys.selectAll = action.payload.selectAll;
      if (!action.payload.selectAll && action.payload.keys.length > 0) {
        state.selectedMenuKeys.currentSelection = action.payload.keys[0];
      } else {
        state.selectedMenuKeys.currentSelection = undefined;
      }
    },
  },
});

export const { setSelectedMenuKeys } = neighborgroupsSlice.actions;

export default neighborgroupsSlice.reducer;

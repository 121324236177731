import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const activityFilterTypes = [
  'project',
  'diocese',
  'care_group',
  'facilitators',
  'activity_date',
  'other_activity_type',
] as const;

export type ActivityFilterType = typeof activityFilterTypes[number];

interface OaState {
  selectedMenuKeys: {
    keys: string[];
    selectAll: boolean;
    currentSelection: string | undefined;
  };
  activityFilters: Record<ActivityFilterType, (boolean | string | number)[] | null>;
}

export const initialState: OaState = {
  selectedMenuKeys: {
    keys: [],
    selectAll: false,
    currentSelection: undefined,
  },
  activityFilters: {
    project: null,
    diocese: null,
    care_group: null,
    facilitators: null,
    activity_date: null,
    other_activity_type: null,
  },
};

export const oaSlice = createSlice({
  name: 'oa',
  initialState,
  reducers: {
    setSelectedMenuKeys: (state, action: PayloadAction<{ keys: string[]; selectAll: boolean }>) => {
      state.selectedMenuKeys.keys = action.payload.keys;
      state.selectedMenuKeys.selectAll = action.payload.selectAll;
      if (!action.payload.selectAll && action.payload.keys.length > 0) {
        state.selectedMenuKeys.currentSelection = action.payload.keys[0];
      } else {
        state.selectedMenuKeys.currentSelection = undefined;
      }
    },
    setActivityFilters: (state, action: PayloadAction<OaState['activityFilters']>) => {
      state.activityFilters = action.payload;
    },
  },
});

export const { setSelectedMenuKeys, setActivityFilters } = oaSlice.actions;

export default oaSlice.reducer;

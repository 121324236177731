import { ExclamationCircleFilled } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, DatePicker, Form, Input, message, Modal, Space } from 'antd';
import { API } from 'aws-amplify';
import moment from 'moment';
import { FC, useEffect } from 'react';
import {
  CreateDistributionItemInput,
  CreateDistributionItemMutation,
  DeleteDistributionItemInput,
  DeleteDistributionItemMutation,
  UpdateDistributionItemInput,
  UpdateDistributionItemMutation,
} from '../../../API';
import * as mutations from '../../../graphql/mutations';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

type ProjectType = {
  id: string;
  project_code: string;
};

type DistributionPlanType = {
  id: string;
  title: string;
  description?: string | null | undefined;
  planned_distribution_start_date?: string | null | undefined;
  planned_distribution_end_date?: string | null | undefined;
  distributions?:
    | {
        items: ({
          id: string;
        } | null)[];
      }
    | null
    | undefined;
};

interface DistributionPlanFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingDistributionPlan: DistributionPlanType | undefined;
  project: ProjectType;
}

const DistributionPlanForm: FC<DistributionPlanFormProps> = (props) => {
  const { modalVisible, setModalVisible, editingDistributionPlan, project } = props;

  const [form] = Form.useForm();
  const user = useGetUserInfo();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (modalVisible === 'editing' && editingDistributionPlan) {
      form.setFieldsValue({
        title: editingDistributionPlan.title,
        description: editingDistributionPlan.description,
        planned_distribution_start_date: moment(
          editingDistributionPlan.planned_distribution_start_date
        ),
        planned_distribution_end_date: moment(
          editingDistributionPlan.planned_distribution_end_date
        ),
      });
    } else {
      form.resetFields();
    }
  }, [modalVisible, editingDistributionPlan]);

  // MUTATIONS
  const invalidateQueries = () => {
    queryClient.invalidateQueries(['listDistributionPlansByProjectCode', project.project_code]);
  };
  // INSERT
  const addDistributionPlanMutation = useMutation(
    (input: CreateDistributionItemInput) =>
      API.graphql({
        query: mutations.createDistributionItem,
        variables: { input },
      }) as Promise<GraphQLResult<CreateDistributionItemMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );
  // UPDATE
  const updateDistributionPLanMutation = useMutation(
    (input: UpdateDistributionItemInput) =>
      API.graphql({
        query: mutations.updateDistributionItem,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateDistributionItemMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );
  // DELETE
  const deleteDistributionPlanMutation = useMutation(
    (input: DeleteDistributionItemInput) =>
      API.graphql({
        query: mutations.deleteDistributionItem,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteDistributionItemMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );
  // HANDLERS
  const addDistributionPlan = async () => {
    try {
      const values = await form.validateFields();
      await addDistributionPlanMutation.mutateAsync({
        project_id: project.id,
        project_code: project.project_code,
        title: values.title,
        description: values.description,
        planned_distribution_start_date:
          values.planned_distribution_start_date.format('YYYY-MM-DD'),
        planned_distribution_end_date: values.planned_distribution_end_date.format('YYYY-MM-DD'),
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  const saveEditedDistributionPlan = async () => {
    if (!editingDistributionPlan) {
      message.error('No distribution plan selected');
      return;
    }
    try {
      const values = await form.validateFields();
      await updateDistributionPLanMutation.mutateAsync({
        id: editingDistributionPlan.id,
        title: values.title,
        description: values.description,
        planned_distribution_start_date:
          values.planned_distribution_start_date.format('YYYY-MM-DD'),
        planned_distribution_end_date: values.planned_distribution_end_date.format('YYYY-MM-DD'),
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  const deleteDistributionPlan = async () => {
    if (!editingDistributionPlan) {
      message.error('No distribution plan selected');
      return;
    }
    const distribution_count = editingDistributionPlan.distributions?.items.length || 0;
    if (distribution_count > 0) {
      Modal.warning({
        title: 'Cannot delete distribution plan',
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            <div>Following table has associated records to this distribution plan.</div>
            <ul>
              <li>Distributions</li>
            </ul>
          </div>
        ),
      });
      return;
    }
    try {
      await deleteDistributionPlanMutation.mutateAsync({
        id: editingDistributionPlan.id,
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addDistributionPlan();
            } else if (modalVisible === 'editing') {
              saveEditedDistributionPlan();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Button danger onClick={deleteDistributionPlan} disabled={!(user.isAdmin && user.isEditor)}>
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding' ? 'Adding a new Distribution Plan' : 'Editing Distribution Plan'
      }
      open={modalVisible !== false}
      footer={customModalFooter}
      closable={false}
      width={650}
    >
      <Form
        form={form}
        name='distributionPlanForm'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          name='title'
          label='Title'
          rules={[{ required: true, message: 'Please enter title!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='planned_distribution_start_date'
          label='Planned Start Date'
          rules={[{ required: true, message: 'Please enter planned start date!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name='planned_distribution_end_date'
          label='Planned End Date'
          rules={[{ required: true, message: 'Please enter planned end date!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item name='description' label='Description'>
          <Input.TextArea rows={8} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DistributionPlanForm;

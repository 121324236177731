import React, { FC } from 'react';
import {
  Typography,
  Form,
  Modal,
  message,
  Space,
  Button,
  Popconfirm,
  Spin,
  DatePicker,
  Select,
  Input,
  Transfer,
} from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  CreateCGMeetingInput,
  CreateCGMeetingMutation,
  DeleteCGMeetingInput,
  DeleteCGMeetingMutation,
  GetCareGroupCustomQuery,
  GetCGMeetingForEditQuery,
  UpdateCGMeetingInput,
  UpdateCGMeetingMutation,
} from '../../../API';
import { GraphQLResult } from '@aws-amplify/api';
import {
  useGetProjectDioceseByPdCode,
  useListNutritionTopics,
  useListPlwByCareGroupId,
  useListStaffByPdCode,
} from '../../../hooks';
import * as mutations from '../../../graphql/mutations';
import * as queires from '../../../graphql/custom_queries';
import { API } from 'aws-amplify';
import { compact, difference } from 'lodash';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Text } = Typography;

interface CGMeetingModalFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingCgMeetingId: string | undefined;
}
const CGMeetingModalForm: FC<CGMeetingModalFormProps> = ({
  modalVisible,
  setModalVisible,
  editingCgMeetingId,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  const [targetKeys, setTargetKeys] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const { cg_id } = useParams();
  const cg = queryClient.getQueryData(['getCareGroup', cg_id]) as
    | GraphQLResult<GetCareGroupCustomQuery>
    | undefined;
  const careGroup = cg?.data?.getCareGroup;

  const {
    data: plws,
    isError: isPlwError,
    error: plwError,
    isLoading: isPlwLoading,
  } = useListPlwByCareGroupId(careGroup?.id);

  const {
    data: projectDiocese,
    isLoading: isProjectDioceseLoading,
    isError: isProjectDioceseError,
    error: projectDioceseError,
  } = useGetProjectDioceseByPdCode(careGroup?.project_diocese.project_diocese_code);

  const {
    data: staff,
    isLoading: isStaffLoading,
    isError: isStaffError,
    error: staffError,
  } = useListStaffByPdCode(careGroup?.project_diocese.project_diocese_code);

  const {
    data: nutritionTopics,
    isLoading: isNutritionTopicLoading,
    isError: isNutritionTopicError,
    error: nutritionTopicError,
  } = useListNutritionTopics();

  const { data: editingCgMeeting } = useQuery(
    ['getCgMeetingForEdit', editingCgMeetingId || 'never'],
    () =>
      API.graphql({
        query: queires.getCGMeetingForEdit,
        variables: { id: editingCgMeetingId || 'never' },
      }) as Promise<GraphQLResult<GetCGMeetingForEditQuery>>,
    {
      staleTime: 0,
      enabled: modalVisible === 'editing' && !!editingCgMeetingId,
      select: (data) => data.data?.getCGMeeting,
      onSuccess: (cgm) => {
        let participants: string[] = [];
        let topic_ids: string[] = [];
        if (cgm && cgm.participants) {
          const temp_plws = compact(cgm.participants.items).sort((a, b) => {
            if (a.plw.neighbor_group.group_number === b.plw.neighbor_group.group_number) {
              return a.plw.member_number - b.plw.member_number;
            } else {
              return a.plw.neighbor_group.group_number.localeCompare(
                b.plw.neighbor_group.group_number
              );
            }
          });
          participants = temp_plws.map((plw) => plw.plw.id);
        }
        if (cgm && cgm.topics) {
          const temp_topics = compact(cgm.topics.items).sort((a, b) => {
            const topic_a = nutritionTopics?.find((topic) => topic.id === a.nutrition_topic.id);
            const topic_b = nutritionTopics?.find((topic) => topic.id === b.nutrition_topic.id);
            if (topic_a && topic_b) {
              const topic_text_a = topic_a.topic_text.split('.')[0];
              const topic_text_b = topic_b.topic_text.split('.')[0];
              const a_position = parseInt(topic_text_a);
              const b_position = parseInt(topic_text_b);
              if (isNaN(a_position) || isNaN(b_position)) {
                return topic_text_a.localeCompare(topic_text_b);
              } else {
                return a_position - b_position;
              }
            } else {
              return 0;
            }
          });
          topic_ids = temp_topics.map((topic) => topic.nutrition_topic.id);
        }

        if (cgm) {
          form.setFieldsValue({
            meeting_date: cgm.meeting_date ? moment(cgm.meeting_date) : null,
            topic_ids,
            description: cgm.description,
            facilitators: cgm.facilitators?.items.map((fac) => fac?.staff.id),
            participants: participants,
          });
          setTargetKeys(participants);
        }
      },
    }
  );

  // MUTATIONS
  const invalidateQueries = () => {
    if (careGroup) {
      queryClient.invalidateQueries(['getCareGroup', careGroup.id]);
      // queryClient.invalidateQueries([
      //   'listCareGroups',
      //   careGroup.project_diocese.project_diocese_code,
      // ]);
    }
  };
  // INSERT
  const addCgMeetingMutation = useMutation(
    (input: CreateCGMeetingInput) =>
      API.graphql({
        query: mutations.createCGMeeting,
        variables: { input },
      }) as Promise<GraphQLResult<CreateCGMeetingMutation>>
  );
  // UPDATE
  const updateCgMeetingMutation = useMutation(
    (input: UpdateCGMeetingInput) =>
      API.graphql({
        query: mutations.updateCGMeeting,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateCGMeetingMutation>>
  );
  // DELETE
  const deleteCgMeetingMutation = useMutation(
    (input: DeleteCGMeetingInput) =>
      API.graphql({
        query: mutations.deleteCGMeeting,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteCGMeetingMutation>>
  );

  // COMPONENT SPECIFIC FUNCTIONS
  const addCgMeeting = async () => {
    if (!careGroup) {
      message.error('No Care Group Loaded.');
      return;
    }
    try {
      const values = await form.validateFields();
      const new_cgm_result = await addCgMeetingMutation.mutateAsync({
        project_diocese_code: careGroup.project_diocese.project_diocese_code,
        care_group_id: careGroup.id,
        meeting_date: values.meeting_date ? values.meeting_date.format('YYYY-MM-DD') : null,
        description: values.description,
      });
      if (new_cgm_result.data?.createCGMeeting) {
        const new_cgm = new_cgm_result.data.createCGMeeting;
        const promise_array: Promise<any>[] = [];

        if (values.facilitators) {
          promise_array.push(
            ...values.facilitators.map((staff_id: string) =>
              API.graphql({
                query: mutations.createCGMeetingStaff,
                variables: {
                  input: {
                    cg_meeting_id: new_cgm.id,
                    staff_id,
                    project_diocese_code: careGroup.project_diocese.project_diocese_code,
                  },
                },
              })
            )
          );
        }
        if (values.topic_ids) {
          promise_array.push(
            ...values.topic_ids.map((topic_id: string) =>
              API.graphql({
                query: mutations.createCGMeetingNutritionTopic,
                variables: {
                  input: {
                    cg_meeting_id: new_cgm.id,
                    nutrition_topic_id: topic_id,
                    project_diocese_code: careGroup.project_diocese.project_diocese_code,
                  },
                },
              })
            )
          );
        }
        if (values.participants) {
          promise_array.push(
            ...values.participants.map((plw_id: string) =>
              API.graphql({
                query: mutations.createCGMeetingPLW,
                variables: {
                  input: {
                    cg_meeting_id: new_cgm.id,
                    plw_id,
                    project_diocese_code: careGroup.project_diocese.project_diocese_code,
                  },
                },
              })
            )
          );
        }
        await Promise.all(promise_array);
      }
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const saveEditCgMeeting = async () => {
    if (!editingCgMeetingId || !editingCgMeeting) {
      message.error('No cg meeting id provided');
      return;
    } else if (!careGroup) {
      message.error('No Care Group Loaded.');
      return;
    }
    try {
      const values = await form.validateFields();
      await updateCgMeetingMutation.mutateAsync({
        id: editingCgMeetingId,
        meeting_date: values.meeting_date ? values.meeting_date.format('YYYY-MM-DD') : null,
        description: values.description,
      });

      const original_staff_ids = editingCgMeeting.facilitators
        ? compact(editingCgMeeting.facilitators.items).map((item) => item.staff.id)
        : [];
      const updated_staff_ids = values.facilitators || [];
      const staff_to_add = difference(updated_staff_ids, original_staff_ids);
      let staff_to_delete = difference(original_staff_ids, updated_staff_ids);
      staff_to_delete = compact(editingCgMeeting.facilitators?.items || [])
        .filter((stffac) => staff_to_delete.includes(stffac.staff.id))
        .map((stffcac) => stffcac.id);

      const original_topic_ids = editingCgMeeting.topics
        ? compact(editingCgMeeting.topics.items).map((item) => item.nutrition_topic.id)
        : [];
      const updated_topic_ids = values.topic_ids || [];
      const topics_to_add = difference(updated_topic_ids, original_topic_ids);
      let topics_to_delete = difference(original_topic_ids, updated_topic_ids);
      topics_to_delete = compact(editingCgMeeting.topics?.items || [])
        .filter((cgmtp) => topics_to_delete.includes(cgmtp.nutrition_topic.id))
        .map((cgmtp) => cgmtp.id);

      const original_plw_ids = editingCgMeeting.participants
        ? compact(editingCgMeeting.participants.items).map((item) => item.plw.id)
        : [];
      const updated_plw_ids = values.participants || [];
      const plws_to_add = difference(updated_plw_ids, original_plw_ids);
      let plws_to_delete = difference(original_plw_ids, updated_plw_ids);
      plws_to_delete = compact(editingCgMeeting.participants?.items || [])
        .filter((pptplw) => plws_to_delete.includes(pptplw.plw.id))
        .map((pptplw) => pptplw.id);

      const promise_array: Promise<GraphQLResult>[] = [
        ...staff_to_add.map(
          (staff_id) =>
            API.graphql({
              query: mutations.createCGMeetingStaff,
              variables: {
                input: {
                  cg_meeting_id: editingCgMeeting.id,
                  staff_id: staff_id,
                  project_diocese_code: careGroup.project_diocese.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...staff_to_delete.map(
          (staff_id) =>
            API.graphql({
              query: mutations.deleteCGMeetingStaff,
              variables: {
                input: {
                  id: staff_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...topics_to_add.map(
          (topic_id) =>
            API.graphql({
              query: mutations.createCGMeetingNutritionTopic,
              variables: {
                input: {
                  cg_meeting_id: editingCgMeeting.id,
                  nutrition_topic_id: topic_id,
                  project_diocese_code: careGroup.project_diocese.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...topics_to_delete.map(
          (topic_id) =>
            API.graphql({
              query: mutations.deleteCGMeetingNutritionTopic,
              variables: {
                input: {
                  id: topic_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...plws_to_add.map(
          (plw_id) =>
            API.graphql({
              query: mutations.createCGMeetingPLW,
              variables: {
                input: {
                  cg_meeting_id: editingCgMeeting.id,
                  plw_id: plw_id,
                  project_diocese_code: careGroup.project_diocese.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...plws_to_delete.map(
          (plw_id) =>
            API.graphql({
              query: mutations.deleteCGMeetingPLW,
              variables: {
                input: {
                  id: plw_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
      ];
      await Promise.all(promise_array);
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const deleteCGMeeting = async () => {
    if (!editingCgMeetingId || !editingCgMeeting) {
      message.error('No cg meeting id provided.');
      return;
    }
    try {
      const promise_array: Promise<any>[] = [];
      if (editingCgMeeting.facilitators) {
        promise_array.push(
          ...editingCgMeeting.facilitators.items.map(
            (fac) =>
              API.graphql({
                query: mutations.deleteCGMeetingStaff,
                variables: { input: { id: fac?.id } },
              }) as any
          )
        );
      }
      if (editingCgMeeting.topics) {
        promise_array.push(
          ...editingCgMeeting.topics.items.map(
            (item) =>
              API.graphql({
                query: mutations.deleteCGMeetingNutritionTopic,
                variables: { input: { id: item?.id } },
              }) as any
          )
        );
      }
      if (editingCgMeeting.participants) {
        promise_array.push(
          ...editingCgMeeting.participants.items.map(
            (pptplw) =>
              API.graphql({
                query: mutations.deleteCGMeetingPLW,
                variables: {
                  input: {
                    id: pptplw?.id,
                  },
                },
              }) as any
          )
        );
      }
      await Promise.all(promise_array);
      await deleteCgMeetingMutation.mutateAsync({
        id: editingCgMeetingId,
      });
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
    setTargetKeys([]);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addCgMeeting();
            } else if (modalVisible === 'editing') {
              saveEditCgMeeting();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Popconfirm
          title={
            <div>
              <div>Are you sure you want to delete this CG meeting?</div>
              <div>All information related to this meeting will be deleted.</div>
              <div>This action is not recoverable.</div>
            </div>
          }
          icon={<ExclamationCircleFilled style={{ color: 'orangered', fontSize: 16 }} />}
          onConfirm={deleteCGMeeting}
          okButtonProps={{ danger: true }}
          disabled={!user.isEditor}
        >
          <Button danger disabled={!user.isEditor}>
            Delete
          </Button>
        </Popconfirm>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding'
          ? `Adding a new CG Meeting Record to 
          ${careGroup?.group_number}, 
          ${projectDiocese?.diocese.name} Diocese, 
          ${projectDiocese?.project.name} Project`
          : modalVisible === 'editing'
          ? `Editing CG Meeting (${editingCgMeeting?.meeting_date} @ ${careGroup?.group_number}), 
          ${projectDiocese?.diocese.name} Diocese, 
          ${projectDiocese?.project.name} Project`
          : 'CG Meeting Form'
      }
      open={modalVisible === 'adding' || modalVisible === 'editing'}
      footer={customModalFooter}
      closable={false}
      width={650}
    >
      {isStaffError ||
      isNutritionTopicError ||
      isProjectDioceseError ||
      isPlwError ||
      !careGroup ? (
        <div>
          <div>
            Error in loading PLW, Staff and/or Nutrition Topics from the cloud. Possibly connection
            error.
          </div>
          <>
            {!!projectDioceseError && <div>{`${projectDioceseError}`}</div>}
            {!!staffError && <div>{`${staffError}`}</div>}
            {!!nutritionTopicError && <div>{`${nutritionTopicError}`}</div>}
            {!!plwError && <div>{`${plwError}`}</div>}
          </>
        </div>
      ) : isStaffLoading || isProjectDioceseLoading || isNutritionTopicLoading || isPlwLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <div style={{ maxHeight: 500, overflow: 'auto', paddingRight: 10 }}>
          <Form form={form} name='cg_meeting_form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              name='meeting_date'
              label='Meeting date'
              rules={[{ required: true, message: 'Required.' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name='topic_ids'
              label='Topics'
              rules={[{ required: true, message: 'Need at least one topic.' }]}
            >
              <Select allowClear mode='multiple'>
                {nutritionTopics.map((nt) => {
                  return (
                    <Select.Option key={nt.id} value={nt.id}>
                      <Text style={{ width: 300 }} ellipsis={{ tooltip: nt.topic_text }}>
                        {nt.topic_text}
                      </Text>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name='description' label='Description'>
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name='facilitators'
              label='Facilitators'
              rules={[{ required: true, message: 'One or more facilitators is required.' }]}
            >
              <Select allowClear mode='multiple'>
                {(staff || [])
                  .sort(
                    (a, b) =>
                      (b.is_active && b.is_staff ? 1 : 0) - (a.is_active && a.is_staff ? 1 : 0)
                  )
                  .map((stf) => {
                    const staffName = stf.first_name + ' ' + stf.last_name;
                    const position = stf.position.name.replace(/([A-Z])[a-zA-Z]*\s?/g, '$1');
                    return (
                      <Select.Option
                        key={stf.id}
                        value={stf.id}
                        disabled={!stf.is_staff || !stf.is_active}
                      >
                        <Text
                          style={{ width: 250, color: 'inherit' }}
                          ellipsis={{ tooltip: stf.email }}
                        >
                          {staffName} ({position}) {stf.email}
                        </Text>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item name='participants' wrapperCol={{ span: 24 }}>
              <Transfer
                dataSource={plws.map((plw) => {
                  return {
                    key: plw.id,
                    name: plw.name,
                    member_number: plw.member_number,
                    lead_mother: plw.lead_mother,
                    complete_member_number: plw.complete_member_number,
                  };
                })}
                titles={['PLWs', 'Participants']}
                listStyle={{ width: 300, height: 350 }}
                oneWay
                showSearch
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                render={(item) =>
                  `${item.complete_member_number} - ${item.name} ${item.lead_mother ? ' *' : ''}`
                }
                onChange={(nextTargetKey: string[]) => {
                  setTargetKeys(nextTargetKey);
                }}
                onSelectChange={(sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
                  setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
                }}
              />
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default CGMeetingModalForm;

import { Button, DatePicker, Drawer, Form, Input, message, Select, Space } from 'antd';
import React, { FC, useEffect } from 'react';
import { BirthHistoryType, PLWType } from './PLWModalForm';
import {
  CreateBirthHistoryInput,
  CreateBirthHistoryMutation,
  DeleteBirthHistoryInput,
  DeleteBirthHistoryMutation,
  GENDER,
  UpdateBirthHistoryInput,
  UpdateBirthHistoryMutation,
} from '../../../API';
import moment, { Moment } from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

interface BirthHistoryDrawerFormProps {
  addingOrEditing: 'adding' | 'editing' | false;
  setAddingOrEditing: (value: 'adding' | 'editing' | false) => void;
  editingBirthHistory: BirthHistoryType | undefined;
  parentPLW: PLWType;
}

const BirthHistoryDrawerForm: FC<BirthHistoryDrawerFormProps> = ({
  addingOrEditing,
  setAddingOrEditing,
  editingBirthHistory,
  parentPLW,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  useEffect(() => {
    if (addingOrEditing === 'editing' && !!editingBirthHistory) {
      const { lmp, miscarriage_date, delivered_date, child_name, child_gender, date_of_death } =
        editingBirthHistory;
      form.setFieldsValue({
        lmp: lmp ? moment(lmp) : null,
        miscarriage_date: miscarriage_date ? moment(miscarriage_date) : null,
        delivered_date: delivered_date ? moment(delivered_date) : null,
        child_name,
        child_gender,
        date_of_death: date_of_death ? moment(date_of_death) : null,
      });
    }
  }, [addingOrEditing, editingBirthHistory]);

  /// MUTATIONS
  const invalidateQueries = () => {
    queryClient.invalidateQueries(['getPlwForEdit', parentPLW.id]);
    queryClient.invalidateQueries(['getNeighborGroup', parentPLW.neighbor_group.id]);
    queryClient.invalidateQueries(['getCareGroup', parentPLW.neighbor_group.care_group.id]);
    queryClient.invalidateQueries(['listCareGroups', parentPLW.project_diocese_code]);
  };
  // CREATE
  const addBirthHistoryMutation = useMutation(
    (input: CreateBirthHistoryInput) =>
      API.graphql({
        query: mutations.createBirthHistory,
        variables: { input },
      }) as Promise<GraphQLResult<CreateBirthHistoryMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );
  // UPDATE
  const updateBirthHistoryMutation = useMutation(
    (input: UpdateBirthHistoryInput) =>
      API.graphql({
        query: mutations.updateBirthHistory,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateBirthHistoryMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );
  // DELETE
  const deleteBirthHistoryMutation = useMutation(
    (input: DeleteBirthHistoryInput) =>
      API.graphql({
        query: mutations.deleteBirthHistory,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteBirthHistoryMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );

  //COMPONENT SPECIFIC FUNCTIONS
  //VALIDATORS
  const lmpValidator = async (_: any, lmp: Moment | null) => {
    const delivered_date = form.getFieldValue('delivered_date');
    if (!lmp && !delivered_date) {
      return Promise.reject('Either LMP or Delivered date is required.');
    } else {
      return Promise.resolve();
    }
  };

  const miscarriageDateValidator = async (_: any, miscarriage_date: Moment | null) => {
    const { lmp, delivered_date } = form.getFieldsValue(['lmp', 'delivered_date']);
    if (!!miscarriage_date && !lmp) {
      return Promise.reject('Miscarriage without LMP');
    } else if (miscarriage_date && lmp && miscarriage_date.isBefore(lmp)) {
      return Promise.reject('Miscarriage cannot be earlier than LMP.');
    } else if (!!miscarriage_date && !!delivered_date) {
      return Promise.reject('The same pregnancy cannot be miscarriage and delivered.');
    }
    return Promise.resolve();
  };

  const deliveredDateValidator = async (_: any, delivered_date: Moment | null) => {
    const { lmp, miscarriage_date } = form.getFieldsValue(['lmp', 'miscarriage_date']);
    if (!delivered_date && !lmp) {
      return Promise.reject('Either LMP or Delivered date is required.');
    } else if (lmp && delivered_date && delivered_date.isBefore(lmp)) {
      return Promise.reject('Delivered date cannot be earlier than LMP.');
    } else if (!!miscarriage_date && !!delivered_date) {
      return Promise.reject('The same pregnancy cannot be miscarriage and delivered.');
    }
    return Promise.resolve();
  };

  const childNameValidator = async (_: any, child_name: string | undefined | null) => {
    const delivered_date = form.getFieldValue('delivered_date');
    if (!!child_name && !delivered_date) {
      return Promise.reject("Child's name without delivered date.");
    }
    return Promise.resolve();
  };

  const childGenderValidator = async (_: any, child_gender: string | undefined | null) => {
    const delivered_date = form.getFieldValue('delivered_date');
    if (!!delivered_date && !child_gender) {
      return Promise.reject("Child's gender is required when there is delivered date.");
    } else if (!delivered_date && !!child_gender) {
      return Promise.reject("Child's gender without delivered date.");
    }
    return Promise.resolve();
  };

  const dateOfDeathValidator = async (_: any, date_of_death: Moment | null) => {
    const delivered_date = form.getFieldValue('delivered_date');
    if (!!date_of_death && !delivered_date) {
      return Promise.reject('Date of death with no delivered date.');
    } else if (!!date_of_death && !!delivered_date && date_of_death.isBefore(delivered_date)) {
      return Promise.reject('Date of death cannot be earlier than delivered date.');
    }
    return Promise.resolve();
  };

  const addingBirthHistory = async () => {
    try {
      const values = await form.validateFields().catch(() => {
        throw new Error('Validation Error.');
      });
      const { lmp, miscarriage_date, delivered_date, child_name, child_gender, date_of_death } =
        values;
      await addBirthHistoryMutation.mutateAsync({
        plw_id: parentPLW.id,
        project_diocese_code: parentPLW.project_diocese_code,
        lmp: lmp ? lmp.format('YYYY-MM-DD') : null,
        miscarriage_date: miscarriage_date ? miscarriage_date.format('YYYY-MM-DD') : null,
        delivered_date: delivered_date ? delivered_date.format('YYYY-MM-DD') : null,
        child_name,
        child_gender,
        date_of_death: date_of_death ? date_of_death.format('YYYY-MM-DD') : null,
      });
      closeDrawer();
    } catch (error: any) {
      console.error(error.message || 'Error in adding Birth History.');
    }
  };
  const saveEditedBirthHistory = async () => {
    if (!editingBirthHistory) {
      message.error('No birth history provided.');
      return;
    }
    try {
      const values = await form.validateFields().catch(() => {
        throw new Error('Validation Error.');
      });
      const { lmp, miscarriage_date, delivered_date, child_name, child_gender, date_of_death } =
        values;
      await updateBirthHistoryMutation.mutateAsync({
        id: editingBirthHistory.id,
        lmp: lmp ? lmp.format('YYYY-MM-DD') : null,
        miscarriage_date: miscarriage_date ? miscarriage_date.format('YYYY-MM-DD') : null,
        delivered_date: delivered_date ? delivered_date.format('YYYY-MM-DD') : null,
        child_name,
        child_gender: child_gender || null,
        date_of_death: date_of_death ? date_of_death.format('YYYY-MM-DD') : null,
      });

      closeDrawer();
    } catch (error: any) {
      console.error(error.message || 'Error in editing Birth History.');
    }
  };

  const deleteBirthHistory = async () => {
    if (!editingBirthHistory) {
      message.error('No birth history to delete.');
      return;
    }
    try {
      await deleteBirthHistoryMutation.mutateAsync({
        id: editingBirthHistory.id,
      });
      closeDrawer();
    } catch (error: any) {
      console.error(error.message || 'Error in deleting Birth History.');
    }
  };

  const closeDrawer = () => {
    form.resetFields();
    setAddingOrEditing(false);
  };

  return (
    <Drawer
      title={
        addingOrEditing === 'adding'
          ? 'Adding new Birth History record'
          : addingOrEditing === 'editing'
          ? 'Editing Birth History'
          : 'Error'
      }
      placement='right'
      getContainer={false}
      closable={false}
      onClose={closeDrawer}
      style={{ position: 'absolute' }}
      open={addingOrEditing !== false}
      width={500}
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }}>
        <Form.Item noStyle shouldUpdate>
          {({ validateFields }) => (
            <Form.Item
              label='LMP'
              name='lmp'
              rules={[{ validator: lmpValidator, validateTrigger: 'onChange' }]}
            >
              <DatePicker
                onChange={() => {
                  validateFields(['miscarriage_date', 'delivered_date']);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ validateFields }) => (
            <Form.Item
              label='Miscarriage date'
              name='miscarriage_date'
              rules={[{ validator: miscarriageDateValidator, validateTrigger: 'onChange' }]}
            >
              <DatePicker
                onChange={() => {
                  validateFields(['lmp', 'delivered_date']);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ validateFields }) => (
            <Form.Item
              label='Delivered date'
              name='delivered_date'
              rules={[{ validator: deliveredDateValidator, validateTrigger: 'onChange' }]}
            >
              <DatePicker
                onChange={() => {
                  validateFields([
                    'lmp',
                    'miscarriage_date',
                    'child_name',
                    'child_gender',
                    'date_of_death',
                  ]);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="Child's name"
          name='child_name'
          rules={[{ validator: childNameValidator, validateTrigger: 'onChange' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label='Gender'
          name='child_gender'
          rules={[{ validator: childGenderValidator, validateTrigger: 'onChange' }]}
        >
          <Select allowClear>
            {Object.values(GENDER).map((val) => (
              <Select.Option key={val} value={val}>
                {val}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Date of death'
          name='date_of_death'
          rules={[{ validator: dateOfDeathValidator, validateTrigger: 'onChange' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} style={{ marginTop: 40 }}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Space>
              <Button onClick={closeDrawer}>Cancel</Button>
              <Button
                type='primary'
                onClick={() => {
                  if (addingOrEditing === 'adding') {
                    addingBirthHistory();
                  } else if (addingOrEditing === 'editing') {
                    saveEditedBirthHistory();
                  }
                }}
                disabled={!user.isEditor}
              >
                Save
              </Button>
            </Space>
            <div>
              {addingOrEditing === 'editing' ? (
                <Button danger onClick={deleteBirthHistory} disabled={!user.isEditor}>
                  Delete
                </Button>
              ) : null}
            </div>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default BirthHistoryDrawerForm;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, DatePicker, Drawer, Form, message, Select, Space } from 'antd';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import { FC, useEffect } from 'react';
import {
  CreateStaffCareGroupInput,
  CreateStaffCareGroupMutation,
  DeleteStaffCareGroupInput,
  DeleteStaffCareGroupMutation,
  UpdateStaffCareGroupInput,
  UpdateStaffCareGroupMutation,
} from '../../API';
import { CareGroupForEditType, StaffCareGroupType, StaffType } from './CareGroupModalForm';
import * as mutations from '../../graphql/mutations';
import moment from 'moment';
import { compact } from 'lodash';
import { useGetUserInfo } from '../../hooks/auth_hooks';

interface StaffCareGroupFormProps {
  addingOrEditing: 'adding' | 'editing' | false;
  setAddingOrEditing: (value: 'adding' | 'editing' | false) => void;
  editingStaffCareGroup: StaffCareGroupType | undefined;
  parentCareGroup: CareGroupForEditType;
  staff: StaffType[];
}

const StaffCareGroupDrawerForm: FC<StaffCareGroupFormProps> = ({
  addingOrEditing,
  setAddingOrEditing,
  editingStaffCareGroup,
  parentCareGroup,
  staff,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  const addStaffCareGroupMutation = useMutation((input: CreateStaffCareGroupInput) => {
    return API.graphql({
      query: mutations.createStaffCareGroup,
      variables: {
        input,
      },
    }) as Promise<GraphQLResult<CreateStaffCareGroupMutation>>;
  });

  const updateStaffCareGroupMutation = useMutation((input: UpdateStaffCareGroupInput) => {
    return API.graphql({
      query: mutations.updateStaffCareGroup,
      variables: {
        input,
      },
    }) as Promise<GraphQLResult<UpdateStaffCareGroupMutation>>;
  });

  const deleteStaffCareGroupMutation = useMutation(
    (input: DeleteStaffCareGroupInput) => {
      return API.graphql({
        query: mutations.deleteStaffCareGroup,
        variables: {
          input,
        },
      }) as Promise<GraphQLResult<DeleteStaffCareGroupMutation>>;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getCareGroupForEdit', parentCareGroup.id]);
        queryClient.invalidateQueries([
          'listCareGroups',
          parentCareGroup.project_diocese.project_diocese_code,
        ]);
      },
    }
  );

  useEffect(() => {
    if (addingOrEditing === 'editing' && !!editingStaffCareGroup) {
      form.setFieldsValue({
        assigned_staff: editingStaffCareGroup.staff.id,
        start_date: editingStaffCareGroup.start_date
          ? moment(editingStaffCareGroup.start_date)
          : null,
        end_date: editingStaffCareGroup.end_date ? moment(editingStaffCareGroup.end_date) : null,
        current_assignment: editingStaffCareGroup.current_assignment,
      });
    }
  }, [addingOrEditing, editingStaffCareGroup]);

  const closeDrawer = () => {
    form.resetFields();
    setAddingOrEditing(false);
  };

  const addStaffCareGroup = async () => {
    const values = await form.validateFields();
    try {
      await addStaffCareGroupMutation.mutateAsync({
        care_group_id: parentCareGroup.id,
        staff_id: values.assigned_staff,
        current_assignment: values.current_assignment,
        start_date: values.start_date.format('YYYY-MM-DD'),
        end_date: values.end_date ? values.end_date.format('YYYY-MM-DD') : null,
      });
      if (values.current_assignment === true) {
        if (parentCareGroup.staff_caregroups) {
          const currentlyAssignStaff = compact(parentCareGroup.staff_caregroups.items).filter(
            (scg) => scg.current_assignment === true
          );
          for (const scg of currentlyAssignStaff) {
            await updateStaffCareGroupMutation.mutateAsync({
              id: scg.id,
              current_assignment: false,
            });
          }
        }
      }
      queryClient.invalidateQueries(['getCareGroupForEdit', parentCareGroup.id]);
      queryClient.invalidateQueries([
        'listCareGroups',
        parentCareGroup.project_diocese.project_diocese_code,
      ]);
      closeDrawer();
    } catch (err: any) {
      console.log(err?.message || 'Error');
    }
  };

  const saveEditedStaffCareGroup = async () => {
    if (!editingStaffCareGroup) {
      message.error('No staff care group id provided.');
      return;
    }
    const values = await form.validateFields();

    try {
      await updateStaffCareGroupMutation.mutateAsync({
        id: editingStaffCareGroup.id,
        staff_id: values.assigned_staff,
        current_assignment: values.current_assignment,
        start_date: values.start_date ? values.start_date.format('YYYY-MM-DD') : null,
        end_date: values.end_date ? values.end_date.format('YYYY-MM-DD') : null,
      });
      if (values.current_assignment === true) {
        if (parentCareGroup.staff_caregroups) {
          const otherCurrentlyAssignStaff = compact(parentCareGroup.staff_caregroups.items).filter(
            (scg) => scg.id !== editingStaffCareGroup.id && scg.current_assignment === true
          );
          for (const scg of otherCurrentlyAssignStaff) {
            await updateStaffCareGroupMutation.mutateAsync({
              id: scg.id,
              current_assignment: false,
            });
          }
        }
      }
      queryClient.invalidateQueries(['getCareGroupForEdit', parentCareGroup.id]);
      queryClient.invalidateQueries([
        'listCareGroups',
        parentCareGroup.project_diocese.project_diocese_code,
      ]);
      closeDrawer();
    } catch (err: any) {
      console.log(err?.message || 'Error');
    }
  };

  const deleteStaffCareGroup = async () => {
    if (!editingStaffCareGroup) {
      message.error('No staff care group id provided.');
      return;
    }
    try {
      deleteStaffCareGroupMutation.mutate({
        id: editingStaffCareGroup.id,
      });
      closeDrawer();
    } catch (err: any) {
      console.log(err.message || 'Error');
    }
  };

  return (
    <Drawer
      title={
        addingOrEditing === 'adding'
          ? 'Adding new staff assignment'
          : addingOrEditing === 'editing'
          ? 'Editing staff assignment'
          : 'Error'
      }
      placement='right'
      getContainer={false}
      closable={false}
      onClose={closeDrawer}
      style={{ position: 'absolute' }}
      open={addingOrEditing !== false}
      width={500}
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item
          name='assigned_staff'
          label='Assigned Staff'
          rules={[{ required: true, message: 'Required.' }]}
        >
          <Select dropdownMatchSelectWidth={400}>
            {staff
              .sort(
                (a, b) => (b.is_active && b.is_staff ? 1 : 0) - (a.is_active && a.is_staff ? 1 : 0)
              )
              .map((stf) => (
                <Select.Option
                  key={stf.id}
                  value={stf.id}
                  disabled={!stf.is_staff || !stf.is_active}
                >
                  {stf.first_name} {stf.last_name} |{' '}
                  {stf.position.name.replace(/([A-Z])[a-zA-Z]*\s?/g, '$1')} | {stf.email}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='start_date'
          label='From'
          rules={[{ required: true, message: 'Required.' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item name='end_date' label='to'>
          <DatePicker />
        </Form.Item>
        <Form.Item name='current_assignment' label='Current Assignment' valuePropName='checked'>
          <Checkbox />
        </Form.Item>
        <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Space>
              <Button onClick={closeDrawer}>Cancel</Button>
              <Button
                type='primary'
                onClick={() => {
                  if (addingOrEditing === 'adding') {
                    addStaffCareGroup();
                  } else if (addingOrEditing === 'editing') {
                    saveEditedStaffCareGroup();
                  }
                }}
                disabled={!user.isEditor}
              >
                Save
              </Button>
            </Space>
            <div>
              {addingOrEditing === 'editing' ? (
                <Button danger onClick={deleteStaffCareGroup} disabled={!user.isEditor}>
                  Delete
                </Button>
              ) : null}
            </div>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default StaffCareGroupDrawerForm;

import { useEffect } from 'react';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router-dom';

export default function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location: { state: any } = useLocation();
  const navigate = useNavigate();

  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <div
      style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Authenticator hideSignUp> </Authenticator>
    </div>
  );
}

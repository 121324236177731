export const townshipByDioceseCodeCustom = /* GraphQL */ `
  query TownshipByDioceseCodeCustom(
    $diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTownshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    townshipByDioceseCode(
      diocese_code: $diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_mm
        active
      }
    }
  }
`;

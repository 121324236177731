import { configureStore } from '@reduxjs/toolkit';

import caregroupsReducer from './caregroupsSlice';
import neighborgroupsReducer from './neighborGroupsSlice';
import cgmReducer from './cgmSlice';
import ngmReducer from './ngmSlice';
import hgdReducer from './hgdSlice';
import oaReducer from './oaSlice';
import mapReducer from './mapSlice';

const store = configureStore({
  reducer: {
    caregroups: caregroupsReducer,
    neighborgroups: neighborgroupsReducer,
    cgm: cgmReducer,
    ngm: ngmReducer,
    hgd: hgdReducer,
    oa: oaReducer,
    map: mapReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;

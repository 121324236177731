import saveAs from 'file-saver';
import moment from 'moment';
import { TableFilterType } from '../common_types';
import { DistributionItemType, DistributionType } from './HGDistributions';
import ExcelJS from 'exceljs';

export const exportToExcel = async (
  filteredHGDistributions: DistributionType[],
  hgDistributionFilters: Record<string, (boolean | string | number)[] | null>,
  distributionItems: DistributionItemType[]
) => {
  const workbook = new ExcelJS.Workbook();

  const dioceseNameSet = new Set<string>();
  const careGroupNumberMap = new Map<string, { careGroupNumber: string; dioceseName: string; projectName: string }>();
  const neighborGroupNumberMap = new Map<
    string,
    { neighborGroupNumber: string; careGroupNumber: string; dioceseName: string; projectName: string }
  >();
  const distributionPlanMap = new Map<
    string,
    { title: string; description: string; plannedStartDate: string; plannedEndDate: string; projectName: string }
  >();
  const recipientMap = new Map<
    string,
    {
      memberNumber: string;
      name: string;
      fatherName: string;
      dob: string;
      leadMother: boolean;
      careGroup: string;
      neighborGroup: string;
      diocese: string;
      project: string;
    }
  >();

  const distributionSheet = workbook.addWorksheet('Home Garden Distributions');
  distributionSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Distribution Date', key: 'date', width: 15 },
    { header: 'Description', key: 'description', width: 20 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Neighbor Group', key: 'neighborGroup', width: 20 },
    { header: 'Distribution Plan', key: 'plan', width: 25 },
    { header: 'Recipients #', key: 'recipients', width: 15 },
  ];
  distributionSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  const distributionDetailSheet = workbook.addWorksheet('Meeting Details');
  distributionDetailSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Distribution Date', key: 'date', width: 15 },
    { header: 'Description', key: 'description', width: 20 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Neighbor Group', key: 'neighborGroup', width: 20 },
    { header: 'Distribution Plan', key: 'plan', width: 25 },
    { header: 'Recipients #', key: 'recipientNumber', width: 15 },
    { header: 'Recipient Name', key: 'recipientName', width: 20 },
    { header: 'Lead Mother', key: 'leadMother', width: 15 },
  ];
  distributionDetailSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  filteredHGDistributions.forEach((fhd, index) => {
    dioceseNameSet.add(fhd.neighbor_group.care_group.project_diocese.diocese.name);
    careGroupNumberMap.set(fhd.neighbor_group.care_group.id, {
      careGroupNumber: fhd.neighbor_group.care_group.group_number,
      dioceseName: fhd.neighbor_group.care_group.project_diocese.diocese.name,
      projectName: fhd.neighbor_group.care_group.project_diocese.project.name,
    });

    const completeNgNumber = `${fhd.neighbor_group.care_group.group_number}.${fhd.neighbor_group.group_number}`;
    neighborGroupNumberMap.set(fhd.neighbor_group.id, {
      neighborGroupNumber: completeNgNumber,
      careGroupNumber: fhd.neighbor_group.care_group.group_number,
      dioceseName: fhd.neighbor_group.care_group.project_diocese.diocese.name,
      projectName: fhd.neighbor_group.care_group.project_diocese.project.name,
    });

    const distributionItem = distributionItems.find((distItem) => distItem.id === fhd.distribution_item.id);
    distributionPlanMap.set(fhd.distribution_item.id, {
      title: fhd.distribution_item.title,
      description: distributionItem?.description || '',
      plannedStartDate: distributionItem?.planned_distribution_start_date || '',
      plannedEndDate: distributionItem?.planned_distribution_end_date || '',
      projectName: distributionItem?.project.name || '',
    });

    if (fhd.recipients) {
      fhd.recipients.items.forEach((recipient) => {
        if (recipient && recipient.plw) {
          const plw = recipient.plw;
          const completeMemberNumber = `${completeNgNumber}.${plw.member_number}`;
          recipientMap.set(plw.id, {
            memberNumber: completeMemberNumber,
            name: plw.name,
            fatherName: plw.father_name || '',
            dob: plw.date_of_birth || '',
            leadMother: plw.lead_mother || false,
            careGroup: fhd.neighbor_group.care_group.group_number,
            neighborGroup: completeNgNumber,
            diocese: fhd.neighbor_group.care_group.project_diocese.diocese.name,
            project: fhd.neighbor_group.care_group.project_diocese.project.name,
          });
          //////////////////////////////////////////

          distributionDetailSheet.addRow({
            project: fhd.neighbor_group.care_group.project_diocese.project.name,
            diocese: fhd.neighbor_group.care_group.project_diocese.diocese.name,
            date: fhd.distribution_date,
            description: fhd.description,
            careGroup: fhd.neighbor_group.care_group.group_number,
            neighborGroup: completeNgNumber,
            plan: distributionItem?.title || '',
            recipientNumber: completeMemberNumber,
            recipientName: plw.name,
            leadMother: plw.lead_mother ? 'TRUE' : '',
          });

          const lastRow = distributionDetailSheet.lastRow!;
          lastRow.eachCell({ includeEmpty: true }, (cell) => {
            if (index % 2 === 0) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D1D1D1' },
                // bgColor: { argb: 'DDDDDD' },
              };
            }
            cell.border = {
              top: { style: 'dotted' },
              right: { style: 'dotted' },
              bottom: { style: 'dotted' },
              left: { style: 'dotted' },
            };
          });
        }
      });
    }

    ///////////////////////////
    distributionSheet.addRow({
      project: fhd.neighbor_group.care_group.project_diocese.project.name,
      diocese: fhd.neighbor_group.care_group.project_diocese.diocese.name,
      date: fhd.distribution_date,
      description: fhd.description,
      careGroup: fhd.neighbor_group.care_group.group_number,
      neighborGroup: completeNgNumber,
      plan: distributionItem?.title || '',
      recipients: fhd.recipients?.items.length || 0,
    });
  });

  ///////////////////////////
  const filterSheet = workbook.addWorksheet('Filters');

  filterSheet.getColumn(1).width = 22;
  filterSheet.getColumn(2).width = 50;
  filterSheet.addRow(['Filter Name', 'Filer Value']);
  const lastRow = filterSheet.lastRow!;
  lastRow.eachCell((cell) => {
    cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true };
  });

  Object.keys(hgDistributionFilters).forEach((filter) => {
    if (hgDistributionFilters[filter] && hgDistributionFilters[filter]!.length) {
      const spaced = filter.replace(/([A-Z])/g, ' $1');
      const filterTitle = spaced.charAt(0).toUpperCase() + spaced.slice(1);
      hgDistributionFilters[filter]!.forEach((id, index) => {
        let filterValue = id;
        if (index === 0) {
          filterSheet.addRow([filterTitle, filterValue]);
        } else {
          filterSheet.addRow(['', filterValue]);
        }
      });
    }
  });

  const dioceseSheet = workbook.addWorksheet('Dioceses');
  dioceseSheet.columns = [{ header: 'Participating Dioceses', key: 'diocese', width: 25 }];
  dioceseSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...dioceseNameSet].sort().forEach((name) => {
    dioceseSheet.addRow([name]);
  });

  const careGroupSheet = workbook.addWorksheet('Care Groups');
  careGroupSheet.columns = [
    { header: 'CG Number', key: 'cgNumber', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  careGroupSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));
  [...careGroupNumberMap]
    .sort((a, b) => a[1].careGroupNumber.localeCompare(b[1].careGroupNumber))
    .forEach((cg) => {
      careGroupSheet.addRow({
        cgNumber: cg[1].careGroupNumber,
        diocese: cg[1].dioceseName,
        project: cg[1].projectName,
      });
    });

  const neighborGroupSheet = workbook.addWorksheet('Neighbor Groups');
  neighborGroupSheet.columns = [
    { header: 'NG Number', key: 'ngNumber', width: 15 },
    { header: 'Care group', key: 'cgNumber', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  neighborGroupSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...neighborGroupNumberMap]
    .sort((a, b) => a[1].neighborGroupNumber.localeCompare(b[1].neighborGroupNumber))
    .forEach((ng) => {
      neighborGroupSheet.addRow({
        ngNumber: ng[1].neighborGroupNumber,
        cgNumber: ng[1].careGroupNumber,
        diocese: ng[1].dioceseName,
        project: ng[1].projectName,
      });
    });

  const distributionPlanSheet = workbook.addWorksheet('Distribution Plans');
  distributionPlanSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Plan Title', key: 'title', width: 25 },
    { header: 'Description', key: 'description', width: 15 },
    { header: 'Planned Start Date', key: 'plannedStartDate', width: 25 },
    { header: 'Planned End Date', key: 'planned_end_dateplannedEndDate', width: 25 },
  ];
  distributionPlanSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...distributionPlanMap].forEach((plan) => {
    distributionPlanSheet.addRow({
      project: plan[1].projectName,
      title: plan[1].title,
      description: plan[1].description,
      planned_start_date: plan[1].plannedStartDate,
      planned_end_date: plan[1].plannedEndDate,
    });
  });

  const recipientSheet = workbook.addWorksheet('Recipient');
  recipientSheet.columns = [
    { header: 'Member Number', key: 'memberNumber', width: 15 },
    { header: 'Name', key: 'name', width: 15 },
    { header: 'Father Name', key: 'fatherName', width: 15 },
    { header: 'DOB', key: 'dob', width: 15 },
    { header: 'Lead Mother', key: 'leadMother', width: 10 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Neighbor Group', key: 'neighborGroup', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  recipientSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...recipientMap]
    .sort((a, b) => a[1].memberNumber.localeCompare(b[1].memberNumber))
    .forEach((par) => {
      const participant = par[1];
      recipientSheet.addRow({
        memberNumber: participant.memberNumber,
        name: participant.name,
        fatherName: participant.fatherName,
        dob: participant.dob,
        leadMother: participant.leadMother ? 'TRUE' : '',
        careGroup: participant.careGroup,
        neighborGroup: participant.neighborGroup,
        diocese: participant.diocese,
        project: participant.project,
      });
    });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `Home Garden Distribution Data ${moment().format('YYYY-MM-DD h-mm a')}.xlsx`);
};

export const otherActivityTypeByProjectCodeCustom = /* GraphQL */ `
  query OtherActivityTypeByProjectCodeCustom($project_code: String!) {
    otherActivityTypeByProjectCode(project_code: $project_code, limit: 100) {
      items {
        id
        activity_name
        description
        activities(limit: 1) {
          items {
            id
          }
        }
      }
    }
  }
`;

import { Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { BirthHistoryType } from './PLWModalForm';

const { Link } = Typography;

interface BirthHistoryTableProps {
  addOrEditBirthHistory: 'adding' | 'editing' | false;
  setAddOrEditBirthHistory: (value: 'adding' | 'editing' | false) => void;
  birthHistories: BirthHistoryType[];
  setEditingBirthHistory: (birthHistory: BirthHistoryType) => void;
}

const BirthHistoryTable: FC<BirthHistoryTableProps> = ({
  addOrEditBirthHistory,
  setAddOrEditBirthHistory,
  birthHistories,
  setEditingBirthHistory,
}) => {
  const user = useGetUserInfo();
  const openDrawer = (state: 'adding' | 'editing') => {
    setAddOrEditBirthHistory(state);
  };

  const columns: ColumnsType<BirthHistoryType> = [
    {
      title: 'LMP',
      dataIndex: 'lmp',
      key: 'lmp',
      width: 125,
    },
    {
      title: 'Miscarriage Date',
      dataIndex: 'miscarriage_date',
      key: 'miscarriage_date',
      width: 125,
    },
    {
      title: 'Delivered Date',
      dataIndex: 'delivered_date',
      key: 'delivered_date',
      width: 125,
    },
    {
      title: "Child's name",
      dataIndex: 'child_name',
      key: 'child_name',
      ellipsis: true,
      width: 180,
    },
    {
      title: 'Gender',
      dataIndex: 'child_gender',
      key: 'child_gender',
      width: 80,
    },
    {
      title: 'Date of Death',
      dataIndex: 'date_of_death',
      key: 'date_of_death',
      width: 125,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 70,
      align: 'center',
      render: (_, record) => (
        <Link
          onClick={() => {
            setEditingBirthHistory(record);
            openDrawer('editing');
          }}
          style={!user.isEditor ? { pointerEvents: 'none', opacity: 0.4 } : {}}
        >
          Edit
        </Link>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Button
        size='small'
        style={{ width: 250, marginBottom: 5 }}
        onClick={() => openDrawer('adding')}
        disabled={!user.isEditor}
      >
        Add New Birth History Record
      </Button>
      <Table
        dataSource={birthHistories}
        bordered
        rowKey={'id'}
        columns={columns}
        size='small'
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        scroll={{ y: 150 }}
      />
    </div>
  );
};

export default BirthHistoryTable;

export const listProjectDioceseCustom = /* GraphQL */ `
  query ListProjectDioceseCustom(
    $id: ID
    $filter: ModelProjectDioceseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjectDiocese(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        project_diocese_code
        project {
          id
          project_code
          name
        }
        diocese {
          id
          diocese_code
          name
        }
      }
    }
  }
`;

export const getProjectDioceseByPdCodeCustom = /* GraphQL */ `
  query GetProjectDioceseByPdCodeCustom(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDioceseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProjectDioceseByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_diocese_code
        project {
          id
          project_code
          name
        }
        diocese {
          id
          diocese_code
          name
        }
      }
    }
  }
`;

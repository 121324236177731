export const careGroupByPdCodeCustom = /* GraphQL */ `
  query CareGroupByPdCodeCustom(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCareGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careGroupByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group_number
        project_diocese {
          id
          project_diocese_code
          project {
            id
            project_code
            name
          }
          diocese {
            id
            diocese_code
            name
          }
        }
        township {
          id
          name
          name_mm
        }
        staff_caregroups {
          items {
            current_assignment
            start_date
            end_date
            staff {
              id
              first_name
              last_name
              email
              is_active
              position {
                id
                name
              }
            }
          }
        }
        neighbor_groups {
          items {
            id
            group_number
            village {
              id
              name
              name_mm
              villageTract {
                id
                name
                name_mm
              }
            }
            camp {
              id
              name
              name_mm
            }
            plws {
              items {
                id
                name
                member_number
                father_name
                education_level {
                  id
                  education_level
                  education_level_mm
                }
                occupation_type {
                  id
                  occupation_type
                  occupation_type_mm
                }
                lead_mother
                lost_contact
                contact_number
                date_of_birth
                date_of_registry
                disabled
                birth_histories {
                  items {
                    id
                    lmp
                    miscarriage_date
                    delivered_date
                    child_name
                    child_gender
                    date_of_death
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const careGroupByPdCodeForGoogleMap = /* GraphQL */ `
  query CareGroupByPdCodeForGoogleMap(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCareGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careGroupByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group_number
        project_diocese {
          id
          project_diocese_code
          project {
            id
            name
          }
          diocese {
            id
            name
          }
        }
        township {
          id
          name
          name_mm
        }
        neighbor_groups {
          items {
            id
            group_number
            village {
              id
              name
              name_mm
              latitude
              longitude
              villageTract {
                id
                name
                name_mm
              }
            }
            camp {
              id
              name
              name_mm
              latitude
              longitude
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const careGroupByGroupNumberCustom = /* GraphQL */ `
  query CareGroupByGroupNumberCustom($group_number: String!) {
    careGroupByGroupNumber(group_number: $group_number) {
      items {
        id
        group_number
        project_diocese {
          project {
            id
            project_code
          }
        }
      }
    }
  }
`;

export const getCareGroupCustom = /* GraphQL */ `
  query GetCareGroupCustom($id: ID!) {
    getCareGroup(id: $id) {
      id
      group_number
      project_diocese {
        id
        project_diocese_code
        project {
          id
          name
          project_code
        }
        diocese {
          id
          name
          diocese_code
        }
      }
      township {
        id
        name
      }
      neighbor_groups {
        items {
          id
          group_number
          village {
            id
            name
            name_mm
            villageTract {
              id
              name
              name_mm
            }
          }
          camp {
            id
            name
            name_mm
          }
        }
      }
      cg_meetings {
        items {
          id
          meeting_date
          description
          topics {
            items {
              nutrition_topic {
                id
                topic_text
              }
            }
          }
          facilitators {
            items {
              staff {
                id
                first_name
                last_name
                position {
                  name
                }
              }
            }
          }
          participants {
            items {
              plw {
                id
                member_number
                name
                lead_mother
                neighbor_group {
                  group_number
                }
              }
            }
          }
        }
      }
      other_activities {
        items {
          id
          other_activity_type {
            id
            activity_name
            description
          }
          activity_date
          description
          facilitators {
            items {
              staff {
                id
                first_name
                last_name
                position {
                  name
                }
              }
            }
          }
          participants {
            items {
              plw {
                id
                name
                member_number
                lead_mother
                neighbor_group {
                  group_number
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getCareGroupForEdit = /* GraphQL */ `
  query GetCareGroupForEdit($id: ID!) {
    getCareGroup(id: $id) {
      id
      group_number
      project_diocese {
        id
        project_diocese_code
        project {
          id
          name
        }
        diocese {
          id
          name
        }
      }
      township {
        id
        name
        name_mm
      }
      staff_caregroups {
        items {
          id
          current_assignment
          start_date
          end_date
          staff {
            id
            first_name
            last_name
            is_active
            is_staff
            email
            position {
              id
              name
            }
          }
        }
      }
      neighbor_groups(limit: 1) {
        items {
          id
        }
      }
      cg_meetings(limit: 1) {
        items {
          id
        }
      }
      other_activities(limit: 1) {
        items {
          id
        }
      }
      hg_trainings(limit: 1) {
        items {
          id
        }
      }
    }
  }
`;

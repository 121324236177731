import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ngmFilterTypes = [
  'project',
  'diocese',
  'meeting_date',
  'care_group',
  'neighbor_group',
  'topics',
] as const;

export type NgmFilterType = typeof ngmFilterTypes[number];

interface NgmState {
  selectedMenuKeys: {
    keys: string[];
    selectAll: boolean;
    currentSelection: string | undefined;
  };
  ngMeetingFilters: Record<NgmFilterType, (boolean | string | number)[] | null>;
}

export const initialState: NgmState = {
  selectedMenuKeys: {
    keys: [],
    selectAll: false,
    currentSelection: undefined,
  },
  ngMeetingFilters: {
    project: null,
    diocese: null,
    meeting_date: null,
    care_group: null,
    neighbor_group: null,
    topics: null,
  },
};

export const ngmSlice = createSlice({
  name: 'ngm',
  initialState,
  reducers: {
    setSelectedMenuKeys: (state, action: PayloadAction<{ keys: string[]; selectAll: boolean }>) => {
      state.selectedMenuKeys.keys = action.payload.keys;
      state.selectedMenuKeys.selectAll = action.payload.selectAll;
      if (!action.payload.selectAll && action.payload.keys.length > 0) {
        state.selectedMenuKeys.currentSelection = action.payload.keys[0];
      } else {
        state.selectedMenuKeys.currentSelection = undefined;
      }
    },
    setNgMeetingFilters: (state, action: PayloadAction<NgmState['ngMeetingFilters']>) => {
      state.ngMeetingFilters = action.payload;
    },
  },
});

export const { setSelectedMenuKeys, setNgMeetingFilters } = ngmSlice.actions;

export default ngmSlice.reducer;

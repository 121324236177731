import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { GetProjectDioceseByPdCodeCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';

const listDiocesesByPdCode = async (pdCode: string) => {
  const result = (await API.graphql({
    query: queries.getProjectDioceseByPdCodeCustom,
    variables: { project_diocese_code: pdCode },
  })) as GraphQLResult<GetProjectDioceseByPdCodeCustomQuery>;
  return result;
};

export const useGetProjectDioceseByPdCode = (pdCode: string | undefined) => {
  return useQuery(
    ['getProjectDioceseByPdCode', pdCode],
    () => listDiocesesByPdCode(pdCode || 'never'),
    {
      select: (data) => {
        if (
          data.data &&
          data.data.getProjectDioceseByPdCode &&
          data.data.getProjectDioceseByPdCode.items.length > 0
        ) {
          return data.data.getProjectDioceseByPdCode.items[0];
        } else {
          return undefined;
        }
      },
      enabled: !!pdCode,
    }
  );
};

import { CheckSquareFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Modal,
  Spin,
  Form,
  Select,
  Input,
  Typography,
  message,
  Table,
  Button,
  Tooltip,
  Space,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { API } from 'aws-amplify';
import { compact } from 'lodash';
import moment from 'moment';
import React, { FC, useState } from 'react';
import {
  CareGroupByGroupNumberCustomQuery,
  CreateCareGroupInput,
  CreateCareGroupMutation,
  CreateStaffCareGroupInput,
  CreateStaffCareGroupMutation,
  StaffByProjectDioceseCustomQuery,
  GetCareGroupForEditQuery,
  UpdateCareGroupInput,
  UpdateCareGroupMutation,
  DeleteCareGroupInput,
  DeleteCareGroupMutation,
} from '../../API';
import * as queries from '../../graphql/custom_queries';
import * as mutations from '../../graphql/mutations';
import { useGetUserInfo } from '../../hooks/auth_hooks';
import { useGetProjectDioceseByPdCode } from '../../hooks/useGetProjectDioceseByPdCode';
import { useListStaffByPdCode } from '../../hooks/useListStaffByPdCode';
import { useListTownshipByDioceseCode } from '../../hooks/useListTownshipByDioceseCode';
import StaffCareGroupDrawerForm from './StaffCareGroupDrawerForm';

const { Text, Link } = Typography;
const { warning: warningModal } = Modal;

export type StaffType = NonNullable<
  NonNullable<StaffByProjectDioceseCustomQuery['staffByProjectDiocese']>['items'][number]
>;

export type CareGroupForEditType = NonNullable<GetCareGroupForEditQuery['getCareGroup']>;

export type StaffCareGroupType = NonNullable<
  NonNullable<CareGroupForEditType['staff_caregroups']>['items'][number]
>;

interface CareGroupModalFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingCareGroupId: string | undefined;
  project_diocese_code: string;
}

const CareGroupModalForm: FC<CareGroupModalFormProps> = ({
  modalVisible,
  setModalVisible,
  // editingCareGroup,
  editingCareGroupId,
  project_diocese_code,
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { data: projectDiocese } = useGetProjectDioceseByPdCode(project_diocese_code);
  const [addOrEditStaff, setAddOrEditStaff] = useState<'adding' | 'editing' | false>(false);
  const [editingStaff, setEditingStaff] = useState<StaffCareGroupType | undefined>(undefined);

  const user = useGetUserInfo();

  const { data: careGroup, isLoading: isCareGroupLoading } = useQuery(
    ['getCareGroupForEdit', editingCareGroupId],
    () => {
      return API.graphql({
        query: queries.getCareGroupForEdit,
        variables: { id: editingCareGroupId },
      }) as Promise<GraphQLResult<GetCareGroupForEditQuery>>;
    },
    {
      staleTime: 0,
      enabled: modalVisible === 'editing' && !!editingCareGroupId,
      select: (data) => data.data?.getCareGroup,
      onSuccess: (care_group) => {
        if (care_group) {
          form.setFieldsValue({
            township_id: care_group.township.id,
            group_number: care_group.group_number,
          });
        }
      },
    }
  );

  const addCareGroupMutation = useMutation((input: CreateCareGroupInput) => {
    return API.graphql({
      query: mutations.createCareGroup,
      variables: {
        input,
      },
    }) as Promise<GraphQLResult<CreateCareGroupMutation>>;
  });

  const updateCareGroupMutation = useMutation(
    (input: UpdateCareGroupInput) => {
      return API.graphql({
        query: mutations.updateCareGroup,
        variables: {
          input,
        },
      }) as Promise<GraphQLResult<UpdateCareGroupMutation>>;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listCareGroups', project_diocese_code]);
      },
    }
  );

  const deleteCareGroupMutation = useMutation(
    (input: DeleteCareGroupInput) => {
      return API.graphql({
        query: mutations.deleteCareGroup,
        variables: {
          input,
        },
      }) as Promise<GraphQLResult<DeleteCareGroupMutation>>;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listCareGroups', project_diocese_code]);
      },
    }
  );

  const addStaffCareGroupMutation = useMutation((input: CreateStaffCareGroupInput) => {
    return API.graphql({
      query: mutations.createStaffCareGroup,
      variables: {
        input,
      },
    }) as Promise<GraphQLResult<CreateStaffCareGroupMutation>>;
  });

  // listTownshipByDioceseCode
  const {
    isLoading: isTownshipLoading,
    isError: isTownshipError,
    error: townshipError,
    data: townships,
  } = useListTownshipByDioceseCode(projectDiocese?.diocese.diocese_code);
  // listStaffByProjectDioceseId
  const {
    data: staff,
    isError: isStaffError,
    error: staffError,
    isLoading: isStaffLoading,
  } = useListStaffByPdCode(project_diocese_code);

  // StaffCareGroup Table Columns
  const staffCareGroupTableColumns: ColumnsType<StaffCareGroupType> = [
    {
      title: 'Assigned Staff',
      dataIndex: 'staff',
      key: 'staff',
      width: 250,
      render: (_, record) => {
        return (
          <Tooltip placement='left' title={record.staff.email}>
            {record.staff.first_name} {record.staff.last_name} |{' '}
            {record.staff.position.name.replace(/([A-Z])[a-zA-Z]*\s?/g, '$1')}
          </Tooltip>
        );
      },
    },
    {
      title: 'From',
      dataIndex: 'start_date',
      key: 'from',
      width: 100,
    },
    {
      title: 'To',
      dataIndex: 'end_date',
      key: 'to',
      width: 100,
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      width: 100,
      align: 'center',
      render: (_, record) =>
        record.current_assignment === true ? (
          <CheckSquareFilled style={{ color: '#1890ff', fontSize: 16 }} />
        ) : null,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <Link
          onClick={() => {
            setEditingStaff(record);
            openDrawer('editing');
          }}
          style={!user.isEditor ? { pointerEvents: 'none', opacity: 0.3 } : {}}
        >
          Edit
        </Link>
      ),
    },
  ];

  const addCareGroup = async () => {
    if (!projectDiocese) {
      message.error('No Project Diocese Loaded.', 5);
      return;
    }

    try {
      const values = await form.validateFields().catch(() => {
        throw new Error('Validation Error');
      });
      const newCareGroup = await addCareGroupMutation.mutateAsync({
        group_number: values.group_number,
        township_id: values.township_id,
        project_diocese_id: projectDiocese.id,
        project_diocese_code,
      });
      if (newCareGroup.data?.createCareGroup) {
        await addStaffCareGroupMutation.mutateAsync({
          care_group_id: newCareGroup.data.createCareGroup.id,
          staff_id: values.staff_id,
          current_assignment: true,
          start_date: moment().format('YYYY-MM-DD'),
        });
        queryClient.invalidateQueries(['listCareGroups', project_diocese_code]);
      } else {
        throw new Error('Creating Care Group failed.');
      }
    } catch (err: any) {
      message.error(err.message || err);
    }

    closeModal();
  };

  const saveEditedCareGroup = async () => {
    if (!editingCareGroupId) {
      message.error('No care group id provided.');
      return;
    }
    const values = await form.validateFields();
    try {
      updateCareGroupMutation.mutate({
        id: editingCareGroupId,
        group_number: values.group_number,
        township_id: values.township_id,
      });
      closeModal();
    } catch (err: any) {
      console.log(err.message || 'Error.');
    }
  };

  const deleteCareGroup = async () => {
    if (!editingCareGroupId || !careGroup) {
      message.error('No care group id provided.');
      return;
    }
    let neighbor_group_count = careGroup.neighbor_groups?.items.length || 0;
    let cg_meeting_count = careGroup.cg_meetings?.items.length || 0;
    let staff_caregroup_count = careGroup.staff_caregroups?.items.length || 0;
    let other_activity_count = careGroup.other_activities?.items.length || 0;
    let hg_training_count = careGroup.hg_trainings?.items.length || 0;

    if (
      [
        neighbor_group_count,
        cg_meeting_count,
        staff_caregroup_count,
        other_activity_count,
        hg_training_count,
      ].some((item) => item > 0)
    ) {
      warningModal({
        title: 'This Care Group cannot be deleted.',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <div>Following tables have associated records to this Care Group.</div>
            <ul>
              {staff_caregroup_count > 0 && <li>Staff</li>}
              {neighbor_group_count > 0 && <li>Neighbor Groups</li>}
              {cg_meeting_count > 0 && <li>Care Group Meetings</li>}
              {other_activity_count > 0 && <li>Other Type of Activity</li>}
              {hg_training_count > 0 && <li>Home Garden Training</li>}
            </ul>
            <div>You have to delete those records first.</div>
          </div>
        ),
      });
      return;
    }

    try {
      deleteCareGroupMutation.mutate({
        id: editingCareGroupId,
      });
      closeModal();
    } catch (err: any) {
      console.log(err.message || 'Error.');
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
    setAddOrEditStaff(false);
  };

  const validateCareGroupNumber = async (_: any, value: string | undefined) => {
    if (!value) return Promise.resolve();

    if (value === careGroup?.group_number) {
      return Promise.resolve();
    }

    const returnValue = (await API.graphql({
      query: queries.careGroupByGroupNumberCustom,
      variables: {
        group_number: value,
      },
    })) as GraphQLResult<CareGroupByGroupNumberCustomQuery>;

    if (returnValue.data && returnValue.data.careGroupByGroupNumber) {
      if (returnValue.data.careGroupByGroupNumber.items.length === 0) {
        return Promise.resolve();
      } else {
        const sameGroupNumberExist = returnValue.data.careGroupByGroupNumber.items.some((cg) => {
          return cg?.project_diocese.project.project_code === project_diocese_code.split('_')[0];
        });
        if (sameGroupNumberExist) {
          return Promise.reject(`Group number (${value}) is already used.`);
        } else {
          return Promise.resolve();
        }
      }
    } else {
      return Promise.reject('Connection Error.');
    }
  };

  const openDrawer = (state: 'adding' | 'editing') => {
    setAddOrEditStaff(state);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addCareGroup();
            } else if (modalVisible === 'editing') {
              saveEditedCareGroup();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Button danger onClick={deleteCareGroup} disabled={!user.isEditor}>
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding'
          ? `Adding a new Care Group to 
          ${projectDiocese?.diocese.name} Diocese, 
          ${projectDiocese?.project.name} Project`
          : modalVisible === 'editing'
          ? `Editing Care Group (${careGroup?.group_number || '---'}), 
          ${projectDiocese?.diocese.name} Diocese, 
          ${projectDiocese?.project.name} Project`
          : 'Care Group Form'
      }
      open={modalVisible === 'adding' || modalVisible === 'editing'}
      footer={customModalFooter}
      closable={false}
      width={700}
    >
      {isTownshipError || isStaffError ? (
        <div>
          <div>Error during loading Township and Staff data from the cloud.</div>
          <>
            {!!townshipError && <div>{`${townshipError}`}</div>}
            {!!staffError && <div>{`${staffError}`}</div>}
          </>
        </div>
      ) : isTownshipLoading ||
        isStaffLoading ||
        (modalVisible === 'editing' && isCareGroupLoading) ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <div style={{ minHeight: 250 }}>
          <Form form={form} name='care_group_form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              name='township_id'
              label='Township'
              rules={[{ required: true, message: 'Township field is required.' }]}
            >
              <Select>
                {townships?.map((tsp) => (
                  <Select.Option key={tsp.id} value={tsp.id}>
                    {tsp.name} ({tsp.name_mm})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {modalVisible === 'adding' ? (
              <Form.Item
                name='staff_id'
                label='Staff'
                rules={[{ required: true, message: 'Staff field is required.' }]}
              >
                <Select>
                  {(staff || [])
                    .sort(
                      (a, b) =>
                        (b.is_active && b.is_staff ? 1 : 0) - (a.is_active && a.is_staff ? 1 : 0)
                    )
                    .map((stf) => (
                      <Select.Option
                        key={stf.id}
                        value={stf.id}
                        disabled={!stf.is_active || !stf.is_staff}
                      >
                        {stf.first_name} {stf.last_name} (
                        {stf.position.name
                          .split(' ')
                          .map((item) => item[0].toUpperCase())
                          .join('')}{' '}
                        | {stf.email})
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item
              name='group_number'
              label='Care Group Number'
              validateTrigger={['onBlur', 'onChange']}
              rules={[
                { required: true, message: 'Care Group Number is required.' },
                {
                  pattern: new RegExp(/^\d{1,3}\.\d{1,2}$/),
                  message: 'required format: 000.00',
                  validateTrigger: ['onChange'],
                },
                {
                  validator: validateCareGroupNumber,
                  validateTrigger: ['onBlur'],
                },
              ]}
            >
              <Input placeholder='000.00' />
            </Form.Item>
          </Form>
          {modalVisible === 'editing' && !!careGroup && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Button
                size='small'
                style={{ width: 150 }}
                onClick={() => openDrawer('adding')}
                disabled={!user.isEditor}
              >
                Add Staff Assignment
              </Button>
              <Table
                dataSource={
                  careGroup.staff_caregroups ? compact(careGroup.staff_caregroups.items) : []
                }
                columns={staffCareGroupTableColumns}
                size='small'
                rowKey={'id'}
                pagination={{
                  defaultPageSize: 50,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
              />
              <StaffCareGroupDrawerForm
                addingOrEditing={addOrEditStaff}
                setAddingOrEditing={setAddOrEditStaff}
                editingStaffCareGroup={editingStaff}
                parentCareGroup={careGroup}
                staff={staff || []}
              />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CareGroupModalForm;

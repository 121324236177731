import { CheckSquareOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
// import { ColumnsType } from 'rc-table/lib/interface';
import { API } from 'aws-amplify';
import { compact } from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import React, { FC, useMemo } from 'react';
import { PlwByNeighborGroupCustomQuery } from '../../API';
import { plwByNeighborGroupCustom } from '../../graphql/custom_queries';
import { useAppSelector } from '../../redux/hooks';
import Styles from './PLWMiniTable.module.scss';

type plwMiniType = NonNullable<
  NonNullable<NonNullable<PlwByNeighborGroupCustomQuery>['plwByNeighborGroup']>['items'][number]
>;

type ExtendedPLWType = plwMiniType & {
  isPregnant: boolean;
  has_u6m_child: boolean;
  has_u2y_child: boolean;
  has_overdue_pregnancy: boolean;
};

interface PLWMiniTableProps {
  neighborGroupId: string;
  completeNgNumber: string;
}
const PLWMiniTable: FC<PLWMiniTableProps> = ({ neighborGroupId, completeNgNumber }) => {
  const baseDate = useAppSelector((state) => state.caregroups.baseDate);
  const {
    data: plws,
    // isError: isPlwError,
    isLoading: isPlwLoading,
    // error: plwError,
  } = useQuery(
    ['plwByNgIdMini', neighborGroupId],
    () => {
      return API.graphql({
        query: plwByNeighborGroupCustom,
        variables: {
          neighbor_group_id: neighborGroupId,
          limit: 100,
        },
      }) as Promise<GraphQLResult<PlwByNeighborGroupCustomQuery>>;
    },
    {
      select: (data) => {
        if (data.data?.plwByNeighborGroup) {
          let base_date: Moment;
          if (baseDate) {
            base_date = moment(baseDate);
          } else {
            base_date = moment();
          }
          const plws = compact(data.data.plwByNeighborGroup.items).sort(
            (a, b) => a.member_number - b.member_number
          );
          const extendedPlws: ExtendedPLWType[] = plws.map((plw) => {
            let isPregnant = false;
            let has_u2y_child = false;
            let has_u6m_child = false;
            let has_overdue_pregnancy = false;
            if (plw.birth_histories) {
              plw.birth_histories.items.forEach((bh) => {
                if (bh) {
                  if (!!bh.lmp && !bh.miscarriage_date && !bh.delivered_date && !bh.date_of_death) {
                    if (base_date.diff(moment(bh.lmp), 'days') > 0) {
                      isPregnant = true;
                      if (base_date.diff(moment(bh.lmp), 'days') > 294) {
                        has_overdue_pregnancy = true;
                      }
                    }
                  }
                  if (!!bh.delivered_date && !bh.date_of_death) {
                    if (base_date.diff(moment(bh.delivered_date), 'days') > 0) {
                      if (base_date.diff(moment(bh.delivered_date), 'days') <= 180) {
                        has_u6m_child = true;
                      } else if (base_date.diff(moment(bh.delivered_date), 'days') <= 730) {
                        has_u2y_child = true;
                      }
                    }
                  }
                }
              });
            }
            return { ...plw, isPregnant, has_u2y_child, has_u6m_child, has_overdue_pregnancy };
          });

          return extendedPlws;
        }
      },
    }
  );

  const { totalPLWs, lostContactPlws, totalPregnancy, totalOverdue, totalU6M, totalU2Y } =
    useMemo(() => {
      let totalPLWs = 0;
      let lostContactPlws = 0;
      let totalPregnancy = 0;
      let totalOverdue = 0;
      let totalU6M = 0;
      let totalU2Y = 0;
      if (plws) {
        plws.forEach((plw) => {
          totalPLWs += 1;
          if (plw.lost_contact) {
            lostContactPlws += 1;
          }
          if (plw.isPregnant) {
            totalPregnancy += 1;
            if (plw.has_overdue_pregnancy) {
              totalOverdue += 1;
            }
          }
          if (plw.has_u6m_child) {
            totalU6M += 1;
          }
          if (plw.has_u2y_child) {
            totalU2Y += 1;
          }
        });
      }
      return {
        totalPLWs,
        lostContactPlws,
        totalPregnancy,
        totalOverdue,
        totalU6M,
        totalU2Y,
      };
    }, [plws]);

  const columns: ColumnsType<ExtendedPLWType> = [
    {
      title: (
        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <span>Members</span>
          <span>
            ({totalPLWs - lostContactPlws} + {lostContactPlws})
          </span>
        </span>
      ),
      dataIndex: 'member_number',
      key: 'member_number',
      width: 100,
      render: (value, record) => {
        let completeMemberNumber = `${completeNgNumber}.${value}`;
        if (!!record.lead_mother) {
          completeMemberNumber += ' *';
        }
        return (
          <>
            {record.lost_contact ? (
              <Tooltip title='lost contact'>
                <Tag color='cyan'> {completeMemberNumber}</Tag>
              </Tooltip>
            ) : (
              completeMemberNumber
            )}
          </>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <span>Pregnant</span>
          <span>
            ({totalPregnancy} / {totalOverdue})
          </span>
        </span>
      ),
      dataIndex: 'isPregnant',
      key: 'isPregnant',
      width: 90,
      render: (value, record) => {
        return (
          <>
            {value ? (
              record.has_overdue_pregnancy ? (
                <Tooltip title='overdue' className={Styles.TableIcon}>
                  <CheckSquareOutlined style={{ color: 'red', fontSize: 16 }} />
                </Tooltip>
              ) : (
                <CheckSquareOutlined style={{ fontSize: 16 }} className={Styles.TableIcon} />
              )
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <span>U6M</span>
          <span>( {totalU6M} )</span>
        </span>
      ),
      dataIndex: 'has_u6m_child',
      key: 'has_u6m_child',
      width: 90,
      render: (value) => {
        return (
          <>
            {value ? (
              <CheckSquareOutlined style={{ fontSize: 16 }} className={Styles.TableIcon} />
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <span>U2Y</span>
          <span>({totalU2Y})</span>
        </span>
      ),
      dataIndex: 'has_u2y_child',
      key: 'has_u2y_child',
      width: 90,
      render: (value) => {
        return (
          <>
            {value ? (
              <CheckSquareOutlined style={{ fontSize: 16 }} className={Styles.TableIcon} />
            ) : (
              ''
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <Table
        size='small'
        dataSource={plws}
        rowKey={'id'}
        loading={isPlwLoading}
        columns={columns}
        scroll={{ y: 250 }}
        bordered={true}
        pagination={{
          pageSize: 50,
          hideOnSinglePage: true,
        }}
        style={{ marginBottom: 6 }}
        // summary={(currentData) => {
        //   return <p>Total PLWs: {currentData.length}</p>;
        // }}
      />
      <span style={{ color: 'darkslateblue' }}>
        Birth History data is calculated using this date : <strong>{baseDate}</strong>
      </span>
    </>
  );
};

export default PLWMiniTable;

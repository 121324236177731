import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { StaffByProjectDioceseCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';
import { useGetProjectDioceseByPdCode } from './useGetProjectDioceseByPdCode';
import { compact } from 'lodash';

export const useListStaffByPdCode = (pdCode: string | undefined) => {
  const { data: projectDiocese } = useGetProjectDioceseByPdCode(pdCode);

  return useQuery(
    ['listStaffByProjectDioceseId', projectDiocese?.id || ''],
    async () =>
      API.graphql({
        query: queries.staffByProjectDioceseCustom,
        variables: {
          limit: 100,
          project_diocese_id: projectDiocese?.id || 'never',
        },
      }) as Promise<GraphQLResult<StaffByProjectDioceseCustomQuery>>,
    {
      enabled: !!projectDiocese,
      select: (data) => {
        if (data.data && data.data.staffByProjectDiocese) {
          return compact(data.data.staffByProjectDiocese.items).sort((a, b) =>
            (a.first_name || '').localeCompare(b.first_name || '')
          );
        }
        return undefined;
      },
    }
  );
};

import { Button, Descriptions } from 'antd';
import { FC } from 'react';
import { TableFilterType } from '../common_types';
import { NGMeetingType } from './NeighborGroupMeetings';

interface PropsType {
  filteredNgMeetings: NGMeetingType[];
  ngMeetingFilters: Record<string, (boolean | string | number)[] | null>;
  topicFilter: Array<TableFilterType>;
  hide: () => void;
}

const NGMSummary: FC<PropsType> = ({ filteredNgMeetings, ngMeetingFilters, topicFilter, hide }) => {
  const numberOfMeeting = filteredNgMeetings.length;

  const careGroupIDSet = new Set<string>();
  const neighborGroupIDSet = new Set<string>();
  const projectNameSet = new Set<string>();
  const dioceseNameSet = new Set<string>();
  const participantIDSet = new Set<string>();
  const topicMap = new Map<string, string>();
  const meetingDateSet = new Set<string>();

  filteredNgMeetings.forEach((fngm) => {
    careGroupIDSet.add(fngm.neighbor_group.care_group.id);
    neighborGroupIDSet.add(fngm.neighbor_group.id);
    projectNameSet.add(fngm.neighbor_group.care_group.project_diocese.project.name);

    dioceseNameSet.add(fngm.neighbor_group.care_group.project_diocese.diocese.name);

    if (fngm.participants) {
      fngm.participants.items.forEach((participant) => {
        if (participant && participant.plw) {
          participantIDSet.add(participant.plw.id);
        }
      });
    }
    if (fngm.topics) {
      fngm.topics.items.forEach((topic) => {
        if (topic && topic.nutrition_topic) {
          topicMap.set(topic.nutrition_topic.id, topic.nutrition_topic.topic_text);
        }
      });
    }
    meetingDateSet.add(fngm.meeting_date!);
  });
  const meetingDateArray = [...meetingDateSet].sort();
  return (
    <Descriptions
      title='Neighbor Group Meetings Summary'
      bordered
      extra={<Button onClick={hide}>Close</Button>}
      column={3}
    >
      <Descriptions.Item label='Number of Meetings' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {numberOfMeeting}
      </Descriptions.Item>
      <Descriptions.Item label='Number of Care Groups' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {careGroupIDSet.size}
      </Descriptions.Item>
      <Descriptions.Item label='Number of Neighbor Groups' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {neighborGroupIDSet.size}
      </Descriptions.Item>
      <Descriptions.Item label='Total Participants'>{participantIDSet.size}</Descriptions.Item>
      <Descriptions.Item label='From'>{meetingDateArray[0]}</Descriptions.Item>
      <Descriptions.Item label='To'>{meetingDateArray[meetingDateArray.length - 1]}</Descriptions.Item>
      <Descriptions.Item label='Projects'>
        {[...projectNameSet].sort().map((projectName) => (
          <div key={projectName}>
            <span>{projectName}</span>
            <br />
          </div>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label='Topics Covered' span={2}>
        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
          {[...topicMap]
            .sort((a, b) => {
              let A = parseInt(a[1].split('.')[0]);
              let B = parseInt(b[1].split('.')[0]);
              if (isNaN(A) || isNaN(B)) return 0;
              return A - B;
            })
            .map((topic) => (
              <div key={topic[0]}>
                <span>{topic[1]}</span>
                <br />
              </div>
            ))}
        </div>
      </Descriptions.Item>

      <Descriptions.Item label='Dioceses'>
        {[...dioceseNameSet].sort().map((dioceseName) => (
          <div key={dioceseName}>
            <span>{dioceseName}</span>
            <br />
          </div>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label='Filtered by' span={2}>
        <div style={{ maxHeight: 150, overflow: 'auto' }}>
          {Object.keys(ngMeetingFilters).map((filter) => {
            if (ngMeetingFilters[filter] && ngMeetingFilters[filter]!.length > 0) {
              const spaced = filter.replace('_', ' ');
              const title = spaced.charAt(0).toUpperCase() + spaced.slice(1);
              return (
                <div key={filter} style={{ padding: '4px 0px 0px 8px' }}>
                  <span>
                    {title} ({ngMeetingFilters[filter]?.length}):{' '}
                    <span style={{ color: 'darkred' }}>
                      {ngMeetingFilters[filter]!.map((id) => {
                        switch (filter) {
                          case 'topics':
                            return topicFilter.find((tf) => tf.value === id)?.text;
                          case 'diocese':
                          case 'meeting_date':
                          case 'care_group':
                          case 'project':
                          case 'neighbor_group':
                            return id;
                          default:
                            return null;
                        }
                      }).join(', ')}
                    </span>
                  </span>
                  <br></br>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default NGMSummary;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

export const careGroupFilterTypes = [
  'care_group',
  'project',
  'diocese',
  'township',
  'staff',
] as const;

export type CareGroupFilterType = typeof careGroupFilterTypes[number];

interface CareGroupsState {
  baseDate: string;
  selectedMenuKeys: {
    keys: string[];
    selectAll: boolean;
    currentSelection: string | undefined;
  };
  careGroupFilters: Record<CareGroupFilterType, (boolean | string | number)[] | null>;
  openedCareGroupID: string | undefined;
}

export const initialState: CareGroupsState = {
  baseDate: moment().format('YYYY-MM-DD'),
  selectedMenuKeys: {
    keys: [],
    selectAll: false,
    currentSelection: undefined,
  },
  careGroupFilters: {
    care_group: null,
    project: null,
    diocese: null,
    township: null,
    staff: null,
  },
  openedCareGroupID: undefined,
};

export const caregroupsSlice = createSlice({
  name: 'caregroups',
  initialState,
  reducers: {
    setBaseDate: (state, action) => {
      state.baseDate = action.payload;
    },
    setSelectedMenuKeys: (state, action: PayloadAction<{ keys: string[]; selectAll: boolean }>) => {
      state.selectedMenuKeys.keys = action.payload.keys;
      state.selectedMenuKeys.selectAll = action.payload.selectAll;
      if (!action.payload.selectAll && action.payload.keys.length > 0) {
        state.selectedMenuKeys.currentSelection = action.payload.keys[0];
      } else {
        state.selectedMenuKeys.currentSelection = undefined;
      }
    },
    setCareGroupFilters: (state, action: PayloadAction<CareGroupsState['careGroupFilters']>) => {
      state.careGroupFilters = action.payload;
    },
    setOpenedCareGroupID: (state, action: PayloadAction<string | undefined>) => {
      state.openedCareGroupID = action.payload;
    },
  },
});

export const { setBaseDate, setSelectedMenuKeys, setCareGroupFilters, setOpenedCareGroupID } =
  caregroupsSlice.actions;

export default caregroupsSlice.reducer;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Tooltip, Table, Button, Space, Typography, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExtendedPLWType, PLWType } from '../NeighborGroups';
import { CheckSquareOutlined, CheckSquareFilled } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { useAppSelector } from '../../../redux/hooks';
import Styles from '../NeighborGroups.module.scss';
import PLWModalForm from './PLWModalForm';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Link } = Typography;

const CustomCell = ({
  isIcon,
  children,
  ...restProps
}: {
  isIcon: boolean;
  children: React.ReactNode;
}) => {
  return isIcon ? (
    <td
      {...restProps}
      style={{
        padding: '0px 10px 0px 40px',
        textAlign: 'left',
      }}
    >
      {children}
    </td>
  ) : (
    <td {...restProps}>{children}</td>
  );
};

interface PLWTableProps {
  isLoading: boolean;
  plws: PLWType[];
}

const PLWTable: FC<PLWTableProps> = ({ plws, isLoading }) => {
  const [isPlwModalFormVisible, setisPlwModalFormVisible] = useState<'adding' | 'editing' | false>(
    false
  );
  const [editingPlwId, setEditingPlwId] = useState<string | undefined>(undefined);
  const user = useGetUserInfo();

  const baseDate = useAppSelector((state) => state.caregroups.baseDate);
  const selectedMenuKeys = useAppSelector((state) => state.neighborgroups.selectedMenuKeys);

  const extendedPlws: ExtendedPLWType[] = useMemo(() => {
    let base_date: Moment;
    if (baseDate) {
      base_date = moment(baseDate);
    } else {
      base_date = moment();
    }
    const extendedPlws: ExtendedPLWType[] = [];
    plws.forEach((plw) => {
      let extendedPLW: ExtendedPLWType = {
        ...plw,
        isPregnant: false,
        has_u6m_child: false,
        has_u2y_child: false,
        has_overdue_pregnancy: false,
      };
      if (plw.birth_histories) {
        plw.birth_histories.items.forEach((bh) => {
          if (bh) {
            if (!!bh.lmp && !bh.miscarriage_date && !bh.delivered_date && !bh.date_of_death) {
              if (base_date.diff(moment(bh.lmp)) > 0) {
                extendedPLW.isPregnant = true;
                if (base_date.diff(moment(bh.lmp), 'days') > 294) {
                  extendedPLW.has_overdue_pregnancy = true;
                }
              }
            }
            if (!!bh.delivered_date && !bh.date_of_death) {
              if (base_date.diff(moment(bh.delivered_date)) > 0) {
                if (base_date.diff(moment(bh.delivered_date), 'days') <= 180) {
                  extendedPLW.has_u6m_child = true;
                } else if (base_date.diff(moment(bh.delivered_date), 'days') <= 730) {
                  extendedPLW.has_u2y_child = true;
                }
              }
            }
          }
        });
      }
      extendedPlws.push(extendedPLW);
    });

    return extendedPlws;
    // .sort((a, b) => {
    //   if (a.neighbor_group.group_number === b.neighbor_group.group_number) {
    //     return a.member_number - b.member_number;
    //   } else {
    //     return a.neighbor_group.group_number.localeCompare(b.neighbor_group.group_number);
    //   }
    // });
  }, [plws]);

  const columns: ColumnsType<ExtendedPLWType> = [
    {
      title: 'Member #',
      dataIndex: 'completeMemberNumber',
      key: 'completeMemberNumber',
      width: 110,
      fixed: 'left',
      render: (value, record) => {
        const complete_member_number = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}.${record.member_number}`;
        return (
          <span>
            {record.lost_contact ? (
              <Tooltip title='lost contact'>
                <Tag color='cyan'>{complete_member_number}</Tag>
              </Tooltip>
            ) : (
              complete_member_number
            )}
          </span>
        );
      },
      sorter: (a, b) => {
        if (a.neighbor_group.group_number === b.neighbor_group.group_number) {
          return a.member_number - b.member_number;
        } else {
          return a.neighbor_group.group_number.localeCompare(b.neighbor_group.group_number);
        }
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ellipsis: true,
      sorter: (a, b) => a.name!.localeCompare(b.name!),
    },
    {
      title: 'Date of Registry',
      dataIndex: 'date_of_registry',
      key: 'date_of_registry',
      width: 140,
      sorter: (a, b) => {
        const strA = a.date_of_registry ? a.date_of_registry.toString() : '000';
        const strB = b.date_of_registry ? b.date_of_registry.toString() : '000';
        return strA.localeCompare(strB);
      },
    },
    {
      title: 'Lead Mohter',
      dataIndex: 'lead_mother',
      key: 'lead_mother',
      width: 130,
      onCell: () =>
        ({
          isIcon: true,
        } as any),
      sorter: (a, b) => +a.lead_mother! - +b.lead_mother!,
      render: (text) => {
        return <>{text ? <CheckSquareFilled style={{ color: 'green', fontSize: 16 }} /> : ''}</>;
      },
    },
    {
      title: 'Contact #',
      dataIndex: 'contact_number',
      key: 'contact_number',
      width: 150,
      ellipsis: true,
    },
    {
      title: `Pregnant (${extendedPlws.filter((plw) => plw.isPregnant).length})`,
      dataIndex: 'isPregnant',
      key: 'isPregnant',
      width: 130,
      onCell: () =>
        ({
          isIcon: true,
        } as any),
      sorter: (a, b) => +a.isPregnant! - +b.isPregnant!,
      render: (text, record) => {
        return (
          <>
            {!!text ? (
              record.has_overdue_pregnancy ? (
                <Tooltip title='overdue pregnancy'>
                  <CheckSquareOutlined style={{ color: 'red', fontSize: 16 }} />
                </Tooltip>
              ) : (
                <CheckSquareOutlined style={{ fontSize: 16 }} />
              )
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: `Has U6M (${extendedPlws.filter((plw) => plw.has_u6m_child).length})`,
      dataIndex: 'has_u6m_child',
      key: 'has_u6m_child',
      width: 130,
      onCell: () =>
        ({
          isIcon: true,
        } as any),
      sorter: (a, b) => +a.has_u6m_child! - +b.has_u6m_child!,
      render: (text) => {
        return <>{!!text ? <CheckSquareOutlined style={{ fontSize: 16 }} /> : ''}</>;
      },
    },
    {
      title: `Has U2Y (${extendedPlws.filter((plw) => plw.has_u2y_child).length})`,
      dataIndex: 'has_u2y_child',
      key: 'has_u2y_child',
      width: 130,
      onCell: () =>
        ({
          isIcon: true,
        } as any),
      sorter: (a, b) => +a.has_u2y_child! - +b.has_u2y_child!,
      render: (text) => {
        return <>{text ? <CheckSquareOutlined width='20' height='20' /> : ''}</>;
      },
    },

    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 90,
      render: (_, record) => {
        // if (activeNgId !== undefined) {
        return (
          <Link
            onClick={() => showEditPlwModalForm(record.id)}
            disabled={selectedMenuKeys.selectAll || !user.isEditor}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  const showAddPlwModalForm = () => {
    setEditingPlwId(undefined);
    setisPlwModalFormVisible('adding');
  };

  const showEditPlwModalForm = (editingPlwId: string) => {
    setEditingPlwId(editingPlwId);
    setisPlwModalFormVisible('editing');
  };

  return (
    <div className={Styles.dataTable}>
      <div className={Styles.tableHeaderRow}>
        <div className={Styles.buttonContainer}>
          <Space>
            <Button
              className={Styles.exportButton}
              onClick={showAddPlwModalForm}
              disabled={!user.isEditor || isLoading || !selectedMenuKeys.currentSelection}
            >
              Add New PLW
            </Button>
          </Space>
          <div className={Styles.infoText}>
            Birth History data is calculated using this date : <strong>{baseDate}</strong>
          </div>
        </div>
      </div>
      <Table
        components={{
          body: {
            cell: CustomCell,
          },
        }}
        showSorterTooltip={false}
        columns={columns}
        dataSource={extendedPlws}
        size='small'
        rowKey={'id'}
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 50,
          style: { marginRight: 25 },
          showTotal: (total, range) => `Total ${total}`,
        }}
        scroll={{ x: 'calc(100vw - 400px)', y: 'calc(100vh - 350px)' }}
      />
      {!!selectedMenuKeys.currentSelection && (
        <PLWModalForm
          modalVisible={isPlwModalFormVisible}
          setModalVisible={setisPlwModalFormVisible}
          editingPlwId={editingPlwId}
        />
      )}
    </div>
  );
};

export default PLWTable;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Input, Modal, Space } from 'antd';
import { API } from 'aws-amplify';
import React, { FC, useEffect } from 'react';
import {
  CreateNutritionTopicInput,
  CreateNutritionTopicMutation,
  DeleteNutritionTopicInput,
  DeleteNutritionTopicMutation,
  UpdateNutritionTopicInput,
  UpdateNutritionTopicMutation,
} from '../../../API';
import { NutritionTopicType } from './NutritionMessages';
import * as mutations from '../../../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

interface TopicFormProps {
  addOrEdit: 'add' | 'edit' | undefined;
  setAddOrEdit: React.Dispatch<React.SetStateAction<'add' | 'edit' | undefined>>;
  editingTopic?: NutritionTopicType | undefined;
}

const TopicForm: FC<TopicFormProps> = ({ addOrEdit, setAddOrEdit, editingTopic }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  useEffect(() => {
    if (addOrEdit === 'edit' && !!editingTopic) {
      form.setFieldsValue(editingTopic);
    } else {
      form.resetFields();
    }
  }, [addOrEdit, editingTopic]);

  // MUTATIONS
  // CREATE
  const addTopicMutation = useMutation(
    (input: CreateNutritionTopicInput) => {
      return API.graphql({
        query: mutations.createNutritionTopic,
        variables: { input },
      }) as Promise<GraphQLResult<CreateNutritionTopicMutation>>;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listNutritionTopics']);
      },
    }
  );
  // UPDATE
  const editTopicMutation = useMutation(
    (input: UpdateNutritionTopicInput) => {
      return API.graphql({
        query: mutations.updateNutritionTopic,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateNutritionTopicMutation>>;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listNutritionTopics']);
      },
    }
  );
  // DELETE
  const deleteTopicMutation = useMutation(
    (input: DeleteNutritionTopicInput) =>
      API.graphql({
        query: mutations.deleteNutritionTopic,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteNutritionTopicMutation>>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listNutritionTopics']);
      },
    }
  );

  const addNutritionTopic = async () => {
    try {
      const values = await form.validateFields();
      const input: CreateNutritionTopicInput = {
        ...values,
      };
      await addTopicMutation.mutateAsync(input);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };
  const saveEditedNutritionTopic = async () => {
    if (!editingTopic) return;
    try {
      const values = await form.validateFields();
      const input: UpdateNutritionTopicInput = {
        id: editingTopic?.id,
        topic_text: values.topic_text,
        topic_text_myanmar: values.topic_text_myanmar,
        description: values.description,
      };
      await editTopicMutation.mutateAsync(input);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };
  const deleteNutritionTopic = async () => {
    if (!editingTopic) return;
    const message_count = editingTopic?.nutrition_messages?.items?.length || 0;
    if (message_count > 0) {
      Modal.warning({
        title: 'Cannot delete topic',
        content: `This topic has ${message_count} messages associated with it. Please delete the messages first.`,
      });
      return;
    }

    try {
      const input: DeleteNutritionTopicInput = {
        id: editingTopic?.id,
      };
      await deleteTopicMutation.mutateAsync(input);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setAddOrEdit(undefined);
  };

  const customModalFooter = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '2.5rem',
      }}
    >
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (addOrEdit === 'add') {
              addNutritionTopic();
            } else if (addOrEdit === 'edit') {
              saveEditedNutritionTopic();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {addOrEdit === 'edit' ? (
        <Button danger onClick={deleteNutritionTopic} disabled={!(user.isAdmin && user.isEditor)}>
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      open={addOrEdit === 'add' || addOrEdit === 'edit'}
      title={addOrEdit === 'add' ? 'Adding new Nutrition Topic' : 'Editing Nutrition Topic'}
      footer={customModalFooter}
      closable={false}
      width={800}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} name='topicForm'>
        <Form.Item
          label='Topic Text'
          name='topic_text'
          rules={[{ required: true, message: 'Topic text is required.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Topic Text in Myanmar'
          name='topic_text_myanmar'
          rules={[
            {
              required: true,
              message: 'Topic text in Myanmar is required.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label='Description' name='description'>
          <Input.TextArea rows={10} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TopicForm;

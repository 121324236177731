import * as queries from '../../graphql/custom_queries';
import { Button, Col, Tabs, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { API } from 'aws-amplify';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Styles from './NeighborGroups.module.scss';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { GetCareGroupCustomQuery, GetNeighborGroupCustomQuery } from '../../API';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setOpenedCareGroupID } from '../../redux/caregroupsSlice';
import { compact } from 'lodash';
import { setSelectedMenuKeys } from '../../redux/neighborGroupsSlice';
import PLWTable from './PLW/PLWTable';
import { SyncOutlined } from '@ant-design/icons';
import NGMeetingTable from './NGMeeting/NGMeetingTable';
import DistributionTable from './Distribution/DistributionTable';
import CGMeetingTable from './CGMeeting/CGMeetingTable';
import OtherActivityTable from './OtherActivity/OtherActivityTable';
import NeighborGroupTable from './NeighborGroup/NeighborGroupTable';

export type CareGroupType = NonNullable<GetCareGroupCustomQuery['getCareGroup']>;

export type NeighborGroupType = NonNullable<
  NonNullable<CareGroupType['neighbor_groups']>['items'][number]
>;
export type IndividualNeighborGroupType = NonNullable<
  GetNeighborGroupCustomQuery['getNeighborGroup']
>;

export type CGMeetingType = NonNullable<NonNullable<CareGroupType['cg_meetings']>['items'][number]>;
export type OtherActivityType = NonNullable<
  NonNullable<CareGroupType['other_activities']>['items'][number]
>;

export type PLWType = NonNullable<
  NonNullable<IndividualNeighborGroupType['plws']>['items'][number]
>;
export type NgMeetingType = NonNullable<
  NonNullable<IndividualNeighborGroupType['ng_meetings']>['items'][number]
>;
export type DistributionType = NonNullable<
  NonNullable<IndividualNeighborGroupType['distributions']>['items'][number]
>;

export type ExtendedPLWType = PLWType & {
  isPregnant: boolean;
  has_u6m_child: boolean;
  has_u2y_child: boolean;
  has_overdue_pregnancy: boolean;
};

const NeighborGroups = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cg_id } = useParams();

  const selectedMenuKeys = useAppSelector((state) => state.neighborgroups.selectedMenuKeys);

  const {
    data: careGroup,
    isLoading: isCareGroupLoading,
    isError: isCareGroupError,
    error: careGroupError,
    refetch: careGroupRefetch,
    isFetching: isCareGroupFetching,
  } = useQuery(
    ['getCareGroup', cg_id],
    () => {
      return API.graphql({
        query: queries.getCareGroupCustom,
        variables: {
          id: cg_id,
        },
      }) as Promise<GraphQLResult<GetCareGroupCustomQuery>>;
    },
    {
      select: (data) => data.data?.getCareGroup,
      enabled: !!cg_id,
    }
  );

  const { cgMeetings, otherActivities, neighborGroups } = useMemo(() => {
    const cgMeetings: CGMeetingType[] = [];
    const otherActivities: OtherActivityType[] = [];
    const neighborGroups: NeighborGroupType[] = [];

    if (careGroup && careGroup.cg_meetings) {
      cgMeetings.push(...compact(careGroup.cg_meetings.items));
    }
    if (careGroup && careGroup.other_activities) {
      otherActivities.push(...compact(careGroup.other_activities.items));
    }
    if (careGroup && careGroup.neighbor_groups) {
      neighborGroups.push(...compact(careGroup.neighbor_groups.items));
    }

    cgMeetings.sort((a, b) => b.meeting_date!.localeCompare(a.meeting_date!));
    otherActivities.sort((a, b) => b.activity_date.localeCompare(a.activity_date));
    neighborGroups.sort((a, b) => a.group_number.localeCompare(b.group_number));
    return { cgMeetings, otherActivities, neighborGroups };
  }, [careGroup]);

  const neighborGroupsQueryResult = useQueries({
    queries: selectedMenuKeys.keys.map((ng_id) => {
      return {
        queryKey: ['getNeighborGroup', ng_id],
        queryFn: () =>
          API.graphql({
            query: queries.getNeighborGroupCustom,
            variables: {
              id: ng_id,
            },
          }) as Promise<GraphQLResult<GetNeighborGroupCustomQuery>>,
        select: (data: GraphQLResult<GetNeighborGroupCustomQuery>) => data.data?.getNeighborGroup,
      };
    }),
  });

  const {
    isLoading: isNeighborGroupsLoading,
    isError: isNeighborGroupError,
    error: neighborGroupsError,
    refetch: neighborGroupsRefetch,
    isFetching: isNeighborGroupsFetching,
    data: individualNeighborGroups,
    updateStatusFlag,
  } = useMemo(() => {
    let data: IndividualNeighborGroupType[] | undefined = undefined;
    const isLoading = neighborGroupsQueryResult.some((result) => result.isLoading === true);
    const isError = neighborGroupsQueryResult.some((result) => result.isError === true);
    const isFetching = neighborGroupsQueryResult.some((result) => result.isFetching === true);
    const error = compact(neighborGroupsQueryResult.map((result) => result.error as Error));
    const updateStatusFlag = neighborGroupsQueryResult.reduce(
      (acc, cur) => acc + cur.dataUpdatedAt,
      0
    );

    const refetch = () => {
      neighborGroupsQueryResult.forEach((result) => result.refetch());
    };
    if (!isLoading && !isError) {
      data = neighborGroupsQueryResult.reduce(
        (acc, cur) => [...acc, cur.data as IndividualNeighborGroupType],
        [] as IndividualNeighborGroupType[]
      );
    }
    return {
      isLoading,
      isError,
      error,
      refetch,
      isFetching,
      data,
      updateStatusFlag,
    };
  }, [neighborGroupsQueryResult]);

  const { plws, ngMeetings, distributions } = useMemo(() => {
    const plws: PLWType[] = [];
    const ngMeetings: NgMeetingType[] = [];
    const distributions: DistributionType[] = [];

    if (individualNeighborGroups) {
      individualNeighborGroups.forEach((ng) => {
        if (ng.plws) {
          plws.push(...compact(ng.plws.items));
        }
        if (ng.ng_meetings) {
          ngMeetings.push(...compact(ng.ng_meetings.items));
        }
        if (ng.distributions) {
          distributions.push(...compact(ng.distributions.items));
        }
      });
    }
    plws.sort((a, b) => {
      if (a.neighbor_group.group_number === b.neighbor_group.group_number) {
        return a.member_number - b.member_number;
      } else {
        return a.neighbor_group.group_number.localeCompare(b.neighbor_group.group_number);
      }
    });

    ngMeetings.sort((a, b) => b.meeting_date!.localeCompare(a.meeting_date!));
    distributions.sort((a, b) => b.distribution_date!.localeCompare(a.distribution_date!));
    return { plws, ngMeetings, distributions };
  }, [updateStatusFlag]);

  const goBackToCareGroups = () => {
    dispatch(setOpenedCareGroupID(undefined));
    dispatch(setSelectedMenuKeys({ keys: [], selectAll: false }));
    navigate('/caregroups');
  };

  if (isCareGroupError) {
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>{(careGroupError as Error).message}</div>
      <Button onClick={goBackToCareGroups}>Go Back</Button>
    </div>;
  }

  const onMenuSelect = ({ target: { value } }: RadioChangeEvent) => {
    if (value === 'all') {
      dispatch(
        setSelectedMenuKeys({
          keys: compact(careGroup?.neighbor_groups?.items.map((item) => item?.id) || []),
          selectAll: true,
        })
      );
    } else {
      dispatch(setSelectedMenuKeys({ keys: [value], selectAll: false }));
    }
  };

  const isSomethingLoading = isCareGroupLoading || isNeighborGroupsLoading;
  // ||
  // isCareGroupFetching ||
  // isNeighborGroupsFetching;
  const tabBarItems = [
    {
      label: 'PLWs',
      key: 'plws',
      children: <PLWTable plws={plws} isLoading={isSomethingLoading} />,
    },
    {
      label: 'Neighbor Group Meetings',
      key: 'ng_meetings',
      children: (
        <NGMeetingTable ngMeetings={ngMeetings} plws={plws} isLoading={isSomethingLoading} />
      ),
    },
    {
      label: 'Distributions',
      key: 'distributions',
      children: (
        <DistributionTable
          distributions={distributions}
          plws={plws}
          isLoading={isSomethingLoading}
        />
      ),
    },
  ];

  selectedMenuKeys.selectAll &&
    careGroup &&
    tabBarItems.push(
      {
        label: 'Care Group Meetings',
        key: 'cg_meetings',
        children: (
          <CGMeetingTable
            plws={plws}
            cgMeetings={cgMeetings}
            isLoading={isSomethingLoading}
            cgNumber={careGroup?.group_number || ''}
          />
        ),
      },
      {
        label: 'Other Types of Activities',
        key: 'other_activities',
        children: (
          <OtherActivityTable
            plws={plws}
            otherActivities={otherActivities}
            isLoading={isSomethingLoading}
            cgNumber={careGroup?.group_number || ''}
          />
        ),
      },
      {
        label: 'Neighbor Groups',
        key: 'neighbor_groups',
        children: (
          <NeighborGroupTable
            isLoading={isSomethingLoading}
            careGroup={careGroup}
            neighborGroups={neighborGroups}
          />
        ),
      }
    );

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        <Button className={Styles.goBackButton} type='link' onClick={goBackToCareGroups}>
          Go back to Care Groups
        </Button>
        CARE GROUP -{' '}
        {isCareGroupLoading
          ? '... loading'
          : `${careGroup?.group_number} |
           ${careGroup?.project_diocese.diocese.name} Diocese |
           ${careGroup?.project_diocese.project.name} Project |
           ${careGroup?.township.name} Township`}
      </div>
      <div className={Styles.contents}>
        <div className={Styles.selectionPanel}>
          <h2 style={{ paddingLeft: 20 }}>
            Neighbor Groups ( {careGroup?.neighbor_groups?.items.length || 0} )
          </h2>
          <Radio.Group
            onChange={onMenuSelect}
            optionType='button'
            style={{ width: '100%' }}
            value={selectedMenuKeys.selectAll ? 'all' : selectedMenuKeys.keys[0] || undefined}
          >
            <Space
              direction='vertical'
              style={{
                width: '100%',
                maxHeight: 'calc(100vh - 200px)',
                overflow: 'auto',
              }}
            >
              {careGroup && careGroup.neighbor_groups
                ? [
                    ...compact(careGroup.neighbor_groups?.items)
                      .sort((a, b) => a?.group_number.localeCompare(b?.group_number))
                      .map((ng) => (
                        <Radio
                          value={ng.id}
                          key={ng.id}
                          style={{ height: 'fit-content', width: '100%' }}
                        >
                          <Row>
                            <Col span={10}> Group Number</Col>
                            <Col span={14}>
                              {careGroup.group_number}.{ng.group_number}
                            </Col>
                            {ng.village && (
                              <>
                                <Col span={10}>Village</Col>
                                <Col span={14}> {ng.village.name}</Col>
                                <Col span={10}>Village Tract</Col>
                                <Col span={14}> {ng.village.villageTract.name}</Col>
                              </>
                            )}
                            {ng.camp && (
                              <>
                                <Col span={10}>Camp</Col>
                                <Col span={14}> {ng.camp.name}</Col>
                              </>
                            )}
                          </Row>
                        </Radio>
                      )),
                    <Radio
                      value={'all'}
                      key={'all'}
                      style={{
                        height: 60,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      All Neighbor Groups
                    </Radio>,
                  ]
                : []}
            </Space>
          </Radio.Group>
        </div>
        <div className={Styles.displayPanel}>
          <Tabs
            style={{ padding: '5px 20px' }}
            items={tabBarItems}
            tabBarExtraContent={{
              right: (
                <Button
                  className={Styles.syncButton}
                  loading={
                    isCareGroupLoading ||
                    isNeighborGroupsLoading ||
                    isCareGroupFetching ||
                    isNeighborGroupsFetching
                  }
                  icon={<SyncOutlined />}
                  onClick={async () => {
                    await careGroupRefetch();
                    neighborGroupsRefetch();
                  }}
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NeighborGroups;

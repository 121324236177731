import React, { FC, useEffect } from 'react';
import { NutritionMessageType } from './NutritionMessages';
import { Button, Form, Input, Modal, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import {
  CreateNutritionMessageInput,
  CreateNutritionMessageMutation,
  DeleteNutritionMessageMutation,
  UpdateNutritionMessageInput,
  UpdateNutritionMessageMutation,
} from '../../../API';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';

interface MessageFormProps {
  addOrEdit: 'add' | 'edit' | undefined;
  setAddOrEdit: React.Dispatch<React.SetStateAction<'add' | 'edit' | undefined>>;
  editingMessage?: NutritionMessageType | undefined;
  nutritionTopicId?: string;
}

const MessageForm: FC<MessageFormProps> = ({
  addOrEdit,
  setAddOrEdit,
  editingMessage,
  nutritionTopicId,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  useEffect(() => {
    if (addOrEdit === 'edit' && !!editingMessage) {
      form.setFieldsValue(editingMessage);
    } else {
      form.resetFields();
    }
  }, [addOrEdit, editingMessage]);

  // MUTATIONS
  // CREATE
  const addMessageMutation = useMutation(
    (input: CreateNutritionMessageInput) =>
      API.graphql({
        query: mutations.createNutritionMessage,
        variables: { input: { ...input, nutrition_topic_id: nutritionTopicId } },
      }) as Promise<GraphQLResult<CreateNutritionMessageMutation>>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listNutritionTopics']);
      },
    }
  );
  // UPDATE
  const editMessageMutation = useMutation(
    (input: UpdateNutritionMessageInput) => {
      return API.graphql({
        query: mutations.updateNutritionMessage,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateNutritionMessageMutation>>;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listNutritionTopics']);
      },
    }
  );
  // DELETE
  const deleteMessageMutation = useMutation(
    (input: { id: string }) => {
      return API.graphql({
        query: mutations.deleteNutritionMessage,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteNutritionMessageMutation>>;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listNutritionTopics']);
      },
    }
  );

  const addNutritionMessage = async () => {
    if (!nutritionTopicId) return;
    try {
      const values = await form.validateFields();
      await addMessageMutation.mutateAsync(values);
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };
  const saveEditedNutritionMessage = async () => {
    if (!editingMessage) return;
    try {
      const values = await form.validateFields();
      await editMessageMutation.mutateAsync({ ...values, id: editingMessage.id });
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };
  const deleteNutritionMessage = async () => {
    if (!editingMessage) return;
    const question_count = editingMessage.nutrition_questions?.items.length || 0;
    if (question_count > 0) {
      Modal.warning({
        title: 'Cannot delete message',
        content: `This message has ${question_count} questions associated with it. Please delete those questions first.`,
      });
      return;
    }
    try {
      await deleteMessageMutation.mutateAsync({ id: editingMessage.id });
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setAddOrEdit(undefined);
  };

  const customModalFooter = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '2.5rem',
      }}
    >
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (addOrEdit === 'add') {
              addNutritionMessage();
            } else if (addOrEdit === 'edit') {
              saveEditedNutritionMessage();
            }
          }}
          disabled={!(user.isAdmin && user.isEditor)}
        >
          Save
        </Button>
      </Space>
      {addOrEdit === 'edit' ? (
        <Button danger onClick={deleteNutritionMessage} disabled={!(user.isAdmin && user.isEditor)}>
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      open={addOrEdit === 'add' || addOrEdit === 'edit'}
      title={addOrEdit === 'add' ? 'Add a new Nutrition Message' : 'Edit Nutrition Message'}
      footer={customModalFooter}
      closable={false}
      width={800}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} name='messageForm'>
        <Form.Item
          label='Message Text'
          name='message_text'
          rules={[{ required: true, message: 'Message text is required.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label='Message Text in Myanmar' name='message_text_myanmar'>
          <Input />
        </Form.Item>
        <Form.Item label='Description' name='description'>
          <Input.TextArea rows={10} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MessageForm;

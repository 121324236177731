import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { compact } from 'lodash';
import { ListEducationLevelsQuery } from '../API';
import { listEducationLevels } from '../graphql/queries';

export const useListEducationLevels = () => {
  return useQuery(
    ['listEdicationLevels'],
    () =>
      API.graphql({
        query: listEducationLevels,
      }) as Promise<GraphQLResult<ListEducationLevelsQuery>>,
    {
      staleTime: 1000 * 60 * 60 * 8, // 8 hours
      cacheTime: 1000 * 60 * 60 * 8, // 8 hrs
      select: (data) => {
        if (data.data && data.data.listEducationLevels) {
          return compact(data.data.listEducationLevels.items).sort(
            (a, b) => (a.order || 0) - (b.order || 0)
          );
        }
      },
    }
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Transfer,
  Typography,
} from 'antd';
import { API } from 'aws-amplify';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { GraphQLResult } from '@aws-amplify/api';
import {
  CreateOtherActivityInput,
  CreateOtherActivityMutation,
  DeleteOtherActivityInput,
  DeleteOtherActivityMutation,
  GetCareGroupCustomQuery,
  GetOtherActivityForEditQuery,
  UpdateOtherActivityInput,
  UpdateOtherActivityMutation,
} from '../../../API';
import {
  useGetProjectDioceseByPdCode,
  useListOtherActivityTypeByProjectCode,
  useListPlwByCareGroupId,
  useListStaffByPdCode,
} from '../../../hooks';
import * as queries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import { compact, difference } from 'lodash';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Text } = Typography;

interface OtherActivityModalFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingActivityId: string | undefined;
}

const OtherActivityModalForm: FC<OtherActivityModalFormProps> = ({
  modalVisible,
  setModalVisible,
  editingActivityId,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  const [targetKeys, setTargetKeys] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const { cg_id } = useParams();
  const cg = queryClient.getQueryData(['getCareGroup', cg_id]) as
    | GraphQLResult<GetCareGroupCustomQuery>
    | undefined;
  const careGroup = cg?.data?.getCareGroup;

  const {
    data: plws,
    isError: isPlwError,
    error: plwError,
    isLoading: isPlwLoading,
  } = useListPlwByCareGroupId(careGroup?.id);

  const {
    data: projectDiocese,
    isLoading: isProjectDioceseLoading,
    isError: isProjectDioceseError,
    error: projectDioceseError,
  } = useGetProjectDioceseByPdCode(careGroup?.project_diocese.project_diocese_code);

  const {
    data: staff,
    isLoading: isStaffLoading,
    isError: isStaffError,
    error: staffError,
  } = useListStaffByPdCode(careGroup?.project_diocese.project_diocese_code);

  const {
    data: otherActivityTypes,
    isLoading: isOtherActivityTypeLoading,
    isError: isOtherActivityTypeError,
    error: otherActivitieTypeError,
  } = useListOtherActivityTypeByProjectCode(careGroup?.project_diocese.project.project_code);

  const { data: editingActivity } = useQuery(
    ['get', editingActivityId || 'never'],
    () =>
      API.graphql({
        query: queries.getOtherActivityForEdit,
        variables: {
          id: editingActivityId || 'never',
        },
      }) as Promise<GraphQLResult<GetOtherActivityForEditQuery>>,
    {
      staleTime: 0,
      enabled: modalVisible === 'editing' && !!editingActivityId,
      select: (data) => data.data?.getOtherActivity,
      onSuccess: (oa) => {
        let participants: string[] = [];
        if (oa && oa.participants) {
          const temp_plws = compact(oa.participants.items).sort((a, b) => {
            if (a.plw.neighbor_group.group_number === b.plw.neighbor_group.group_number) {
              return a.plw.member_number - b.plw.member_number;
            } else {
              return a.plw.neighbor_group.group_number.localeCompare(
                b.plw.neighbor_group.group_number
              );
            }
          });
          participants = temp_plws.map((plw) => plw.plw.id);
        }
        if (oa) {
          form.setFieldsValue({
            other_activity_type_id: oa.other_activity_type_id,
            activity_date: oa.activity_date ? moment(oa.activity_date) : null,
            description: oa.description,
            facilitators: oa.facilitators?.items.map((fac) => fac?.staff.id),
            participants: participants,
          });
        }
        setTargetKeys(participants);
      },
    }
  );

  // MUTATIONS
  const invalidateQueries = () => {
    if (careGroup) {
      queryClient.invalidateQueries(['getCareGroup', careGroup.id]);
      // queryClient.invalidateQueries([
      //   'listCareGroups',
      //   careGroup.project_diocese.project_diocese_code,
      // ]);
    }
  };
  // INSERT
  const addOtherActivityMutation = useMutation(
    (input: CreateOtherActivityInput) =>
      API.graphql({
        query: mutations.createOtherActivity,
        variables: { input },
      }) as Promise<GraphQLResult<CreateOtherActivityMutation>>
  );
  // UPDATE
  const updateOtherActivityMutation = useMutation(
    (input: UpdateOtherActivityInput) =>
      API.graphql({
        query: mutations.updateOtherActivity,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateOtherActivityMutation>>
  );
  // DELETE
  const deleteOtherActivityMutation = useMutation(
    (input: DeleteOtherActivityInput) =>
      API.graphql({
        query: mutations.deleteOtherActivity,
        variables: {
          input,
        },
      }) as Promise<GraphQLResult<DeleteOtherActivityMutation>>
  );

  // COMPONENT SPECIFIC FUNCTIONS
  const addOtherActivity = async () => {
    if (!careGroup) {
      message.error('No Care Group Loaded.');
      return;
    }
    try {
      const values = await form.validateFields();
      const new_oa_result = await addOtherActivityMutation.mutateAsync({
        project_diocese_code: careGroup.project_diocese.project_diocese_code,
        care_group_id: careGroup.id,
        other_activity_type_id: values.other_activity_type_id,
        activity_date: values.activity_date ? values.activity_date.format('YYYY-MM-DD') : null,
        description: values.description,
      });
      if (new_oa_result.data?.createOtherActivity) {
        const new_oa = new_oa_result.data.createOtherActivity;
        const promise_array: Promise<any>[] = [];

        if (values.facilitators) {
          promise_array.push(
            ...values.facilitators.map((staff_id: string) =>
              API.graphql({
                query: mutations.createOtherActivityFacilitators,
                variables: {
                  input: {
                    other_activity_id: new_oa.id,
                    staff_id,
                    project_diocese_code: careGroup.project_diocese.project_diocese_code,
                  },
                },
              })
            )
          );
        }
        if (values.participants) {
          promise_array.push(
            ...values.participants.map((plw_id: string) =>
              API.graphql({
                query: mutations.createOtherActivityParticipants,
                variables: {
                  input: {
                    other_activity_id: new_oa.id,
                    plw_id,
                    project_diocese_code: careGroup.project_diocese.project_diocese_code,
                  },
                },
              })
            )
          );
        }
        await Promise.all(promise_array);
      }
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const saveEditOtherActivity = async () => {
    if (!editingActivityId || !editingActivity) {
      message.error('No activity id provided');
      return;
    } else if (!careGroup) {
      message.error('No Care Group Loaded.');
      return;
    }

    try {
      const values = await form.validateFields();
      await updateOtherActivityMutation.mutateAsync({
        id: editingActivityId,
        other_activity_type_id: values.other_activity_type_id,
        activity_date: values.activity_date ? values.activity_date.format('YYYY-MM-DD') : null,
        description: values.description,
      });

      const original_staff_ids = editingActivity.facilitators
        ? compact(editingActivity.facilitators.items).map((item) => item.staff.id)
        : [];
      const updated_staff_ids = values.facilitators || [];
      const staff_to_add = difference(updated_staff_ids, original_staff_ids);
      let staff_to_delete = difference(original_staff_ids, updated_staff_ids);
      staff_to_delete = compact(editingActivity.facilitators?.items || [])
        .filter((stffac) => staff_to_delete.includes(stffac.staff.id))
        .map((stffcac) => stffcac.id);

      const original_plw_ids = editingActivity.participants
        ? compact(editingActivity.participants.items).map((item) => item.plw.id)
        : [];
      const updated_plw_ids = values.participants || [];
      const plws_to_add = difference(updated_plw_ids, original_plw_ids);
      let plws_to_delete = difference(original_plw_ids, updated_plw_ids);
      plws_to_delete = compact(editingActivity.participants?.items || [])
        .filter((pptplw) => plws_to_delete.includes(pptplw.plw.id))
        .map((pptplw) => pptplw.id);

      const promise_array: Promise<GraphQLResult>[] = [
        ...staff_to_add.map(
          (staff_id) =>
            API.graphql({
              query: mutations.createOtherActivityFacilitators,
              variables: {
                input: {
                  other_activity_id: editingActivity.id,
                  staff_id: staff_id,
                  project_diocese_code: careGroup.project_diocese.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...staff_to_delete.map(
          (staff_id) =>
            API.graphql({
              query: mutations.deleteOtherActivityFacilitators,
              variables: {
                input: {
                  id: staff_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...plws_to_add.map(
          (plw_id) =>
            API.graphql({
              query: mutations.createOtherActivityParticipants,
              variables: {
                input: {
                  other_activity_id: editingActivity.id,
                  plw_id: plw_id,
                  project_diocese_code: careGroup.project_diocese.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...plws_to_delete.map(
          (plw_id) =>
            API.graphql({
              query: mutations.deleteOtherActivityParticipants,
              variables: {
                input: {
                  id: plw_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
      ];

      await Promise.all(promise_array);
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const deleteOtherActivity = async () => {
    if (!editingActivityId || !editingActivity) {
      message.error('No activity id provided.');
      return;
    }
    try {
      const promise_array: Promise<any>[] = [];
      if (editingActivity.facilitators) {
        promise_array.push(
          ...editingActivity.facilitators.items.map(
            (fac) =>
              API.graphql({
                query: mutations.deleteOtherActivityFacilitators,
                variables: { input: { id: fac?.id } },
              }) as any
          )
        );
      }
      if (editingActivity.participants) {
        promise_array.push(
          ...editingActivity.participants.items.map(
            (pptplw) =>
              API.graphql({
                query: mutations.deleteOtherActivityParticipants,
                variables: {
                  input: {
                    id: pptplw?.id,
                  },
                },
              }) as any
          )
        );
      }
      await Promise.all(promise_array);
      await deleteOtherActivityMutation.mutateAsync({
        id: editingActivityId,
      });
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
    setTargetKeys([]);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addOtherActivity();
            } else if (modalVisible === 'editing') {
              saveEditOtherActivity();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Popconfirm
          title={
            <div>
              <div>Are you sure you want to delete this Activity?</div>
              <div>All information related to this activity will be deleted.</div>
              <div>This action is not recoverable.</div>
            </div>
          }
          icon={<ExclamationCircleFilled style={{ color: 'orangered', fontSize: 16 }} />}
          onConfirm={deleteOtherActivity}
          okButtonProps={{ danger: true }}
          disabled={!user.isEditor}
        >
          <Button danger disabled={!user.isEditor}>
            Delete
          </Button>
        </Popconfirm>
      ) : null}
    </div>
  );
  return (
    <Modal
      title={
        modalVisible === 'adding'
          ? `Adding a new Activity Record to 
        ${careGroup?.group_number}, 
        ${projectDiocese?.diocese.name} Diocese, 
        ${projectDiocese?.project.name} Project`
          : modalVisible === 'editing'
          ? `Editing CG Meeting (${editingActivity?.activity_date} @ ${careGroup?.group_number}), 
        ${projectDiocese?.diocese.name} Diocese, 
        ${projectDiocese?.project.name} Project`
          : 'CG Meeting Form'
      }
      open={modalVisible === 'adding' || modalVisible === 'editing'}
      footer={customModalFooter}
      closable={false}
      width={650}
    >
      {isStaffError ||
      isOtherActivityTypeError ||
      isProjectDioceseError ||
      isPlwError ||
      !careGroup ? (
        <div>
          <div>
            Error in loading PLW, Staff and/or Other Activity Type from the cloud. Possibly
            connection error.
          </div>
          <>
            {!!projectDioceseError && <div>{`${projectDioceseError}`}</div>}
            {!!staffError && <div>{`${staffError}`}</div>}
            {!!otherActivitieTypeError && <div>{`${otherActivitieTypeError}`}</div>}
            {!!plwError && <div>{`${plwError}`}</div>}
          </>
        </div>
      ) : isStaffLoading ||
        isProjectDioceseLoading ||
        isOtherActivityTypeLoading ||
        isPlwLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <div style={{ maxHeight: 500, overflow: 'auto', paddingRight: 10 }}>
          <Form form={form} name='cg_meeting_form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              name='activity_date'
              label='Activity date'
              rules={[{ required: true, message: 'Required.' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name='other_activity_type_id'
              label='Activity Type'
              rules={[{ required: true, message: 'Activity type is required.' }]}
            >
              <Select allowClear>
                {otherActivityTypes.map((oat) => {
                  return (
                    <Select.Option key={oat.id} value={oat.id}>
                      {oat.activity_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name='description' label='Description'>
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name='facilitators'
              label='Facilitators'
              rules={[{ required: true, message: 'One or more facilitators is required.' }]}
            >
              <Select allowClear mode='multiple'>
                {(staff || [])
                  .sort(
                    (a, b) =>
                      (b.is_active && b.is_staff ? 1 : 0) - (a.is_active && a.is_staff ? 1 : 0)
                  )
                  .map((stf) => {
                    const staffName = stf.first_name + ' ' + stf.last_name;
                    const position = stf.position.name.replace(/([A-Z])[a-zA-Z]*\s?/g, '$1');
                    return (
                      <Select.Option
                        key={stf.id}
                        value={stf.id}
                        disabled={!stf.is_staff || !stf.is_active}
                      >
                        <Text
                          style={{ width: 250, color: 'inherit' }}
                          ellipsis={{ tooltip: stf.email }}
                        >
                          {staffName} ({position}) {stf.email}
                        </Text>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item name='participants' wrapperCol={{ span: 24 }}>
              <Transfer
                dataSource={plws.map((plw) => {
                  return {
                    key: plw.id,
                    name: plw.name,
                    member_number: plw.member_number,
                    lead_mother: plw.lead_mother,
                    complete_member_number: plw.complete_member_number,
                  };
                })}
                titles={['PLWs', 'Participants']}
                listStyle={{ width: 300, height: 350 }}
                oneWay
                showSearch
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                render={(item) =>
                  `${item.complete_member_number} - ${item.name} ${item.lead_mother ? ' *' : ''}`
                }
                onChange={(nextTargetKey: string[]) => {
                  setTargetKeys(nextTargetKey);
                }}
                onSelectChange={(sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
                  setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
                }}
              />
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default OtherActivityModalForm;

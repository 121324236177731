import { Marker } from '@react-google-maps/api';
import React, { FC } from 'react';
import Styles from './CustomMarker.module.scss';

type CustomMarkerProps = {
  index: number;
  marker_key: string;
  color: string;
  label: string;
  inner_label: string;
  lat: number;
  lng: number;
  showLabels: boolean;
  onClick: VoidFunction;
};

const CustomMarker: FC<CustomMarkerProps> = ({
  index,
  marker_key,
  color,
  label,
  inner_label,
  lat,
  lng,
  showLabels,
  onClick,
}) => {
  return (
    <Marker
      key={marker_key}
      icon={{
        url: require(`../../assets/icons/circle_${color}.png`),
        scaledSize: new google.maps.Size(50, 50),
        size: new google.maps.Size(50, 50),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 20),
        labelOrigin: new google.maps.Point(19, 50),
      }}
      label={
        showLabels
          ? {
              text: label,
              color: '#9f3437',
              fontWeight: 'bold',
              fontSize: '16px',
              className: Styles.MarkerLabel,
            }
          : undefined
      }
      position={{
        lat,
        lng,
      }}
      zIndex={index}
    >
      <Marker
        key={marker_key + 'a'}
        icon={{
          url: require(`../../assets/icons/circle_inner_white.png`),
          scaledSize: new google.maps.Size(28, 28),
          size: new google.maps.Size(28, 28),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, 14),
          labelOrigin: new google.maps.Point(12, 14),
        }}
        position={{
          lat,
          lng,
        }}
        label={{
          text: inner_label,
          color: '#9f3437',
          fontWeight: 'bold',
          fontSize: '10px',
        }}
        zIndex={index + 1}
        onClick={onClick}
      />
    </Marker>
  );
};

export default CustomMarker;

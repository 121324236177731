import React, { FC, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Collapse, Descriptions, Drawer, Tabs } from 'antd';
import { API } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSelectedVillageId } from '../../redux/mapSlice';
import { neighborGroupByVillageForMapDrawer } from '../../graphql/custom_queries';
import { NeighborGroupByVillageForMapDrawerQuery } from '../../API';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { compact } from 'lodash';
import Styles from './MapDrawer.module.scss';
import PLWMiniTable from './PLWMiniTable';
import NgMeetingMiniTable from './NgMeetingMiniTable';
import DistributionMiniTable from './DistributionMiniTable';

type VillageType = NonNullable<NonNullable<NeighborGroupByVillageForMapDrawerQuery>['getVillage']>;

export type MapDrawerProps = {
  drawerWidth: number;
};

const MapDrawer: FC<MapDrawerProps> = ({ drawerWidth }) => {
  const dispatch = useAppDispatch();
  const selectedVillageId = useAppSelector((state) => state.map.selectedVillageId);

  const onDrawerClose = useCallback(() => {
    dispatch(setSelectedVillageId(undefined));
  }, [dispatch]);

  const {
    data: village,
    isLoading: isVillageLoading,
    // isError: isVillageError,
    // error: villageError,
  } = useQuery(
    ['neighborGroupByVillageForMapDrawer', selectedVillageId],
    () => {
      return API.graphql({
        query: neighborGroupByVillageForMapDrawer,
        variables: {
          id: selectedVillageId || 'never',
          limit: 100,
        },
      }) as Promise<GraphQLResult<NeighborGroupByVillageForMapDrawerQuery>>;
    },
    {
      enabled: !!selectedVillageId,
      select: (data) => {
        return data.data?.getVillage;
      },
    }
  );

  const neighborGroups = useMemo(() => {
    if (village && village.neighbor_groups) {
      return compact(village.neighbor_groups.items).sort((a, b) =>
        a.group_number.localeCompare(b.group_number)
      );
    } else {
      return [];
    }
  }, [village]);

  const villageName = useMemo(() => {
    if (village) {
      let villageFullName = village.name + ' Village';
      if (village.name_mm) {
        villageFullName += ' (' + village.name_mm + 'ကျေးရွာ)';
      }
      return villageFullName;
    }
  }, [village]);

  return (
    <Drawer
      title={
        isVillageLoading ? 'Loading ... ' : !!villageName ? villageName : 'Error loading village'
      }
      width={drawerWidth}
      open={!!selectedVillageId}
      closable={false}
      onClose={onDrawerClose}
      getContainer={false}
      mask={false}
    >
      {village && (
        <VillageDescription
          village={village}
          style={{ marginBottom: 10 }}
          labelStyle={{ color: '#E86E0C' }}
        />
      )}
      <Collapse destroyInactivePanel accordion>
        {neighborGroups.map((ng) => {
          const completeNgNumber = `${ng.care_group.group_number}.${ng.group_number}`;
          return (
            <Collapse.Panel header={completeNgNumber} key={ng.id}>
              <CustomTabs ngId={ng.id} completeNgNumber={completeNgNumber} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </Drawer>
  );
};

export default MapDrawer;

interface CustomTabsProps {
  ngId: string;
  completeNgNumber: string;
}

const CustomTabs: FC<CustomTabsProps> = ({ ngId, completeNgNumber }) => {
  const tabBarItems = [
    {
      label: 'PLWs',
      key: 'plws',
      children: <PLWMiniTable neighborGroupId={ngId} completeNgNumber={completeNgNumber} />,
    },
    {
      label: 'Neighbor Group Meetings',
      key: 'ng_meetings',
      children: <NgMeetingMiniTable neighborGroupId={ngId} completeNgNumber={completeNgNumber} />,
    },
    {
      label: 'Distributions',
      key: 'distributions',
      children: (
        <DistributionMiniTable neighborGroupId={ngId} completeNgNumber={completeNgNumber} />
      ),
    },
  ];

  return (
    <Tabs
      size='small'
      items={tabBarItems}
      defaultActiveKey='1'
      onChange={() => {
        console.log('tabChanged');
      }}
      className={Styles.MapDrawerTabs}
      destroyInactiveTabPane
    />
  );
};

interface VillageDescriptionProps {
  village: VillageType;
  style?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

const VillageDescription: FC<VillageDescriptionProps> = ({
  village,
  style,
  itemStyle,
  labelStyle,
}) => {
  return (
    <Descriptions column={6} size='small' bordered style={style}>
      <Descriptions.Item label='Project' span={2} style={itemStyle} labelStyle={labelStyle}>
        {village.neighbor_groups?.items[0]?.care_group.project_diocese.project.name || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='Diocese' span={2} style={itemStyle} labelStyle={labelStyle}>
        {village.neighbor_groups?.items[0]?.care_group.project_diocese.diocese.name || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='Care Group' span={2} style={itemStyle} labelStyle={labelStyle}>
        {village.neighbor_groups?.items[0]?.care_group.group_number || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='Village Tract' span={3} style={itemStyle} labelStyle={labelStyle}>
        {village.villageTract.name} ({village.villageTract.name_mm})
      </Descriptions.Item>
      <Descriptions.Item label='Township' span={3} style={itemStyle} labelStyle={labelStyle}>
        {village.villageTract.township.name} ({village.villageTract.township.name_mm})
      </Descriptions.Item>
    </Descriptions>
  );
};

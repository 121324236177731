import { Button, Col, Row } from 'antd';
import { compact } from 'lodash';
import React, { FC, ReactNode, useMemo } from 'react';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { NutritionMessageType } from './NutritionMessages';
import Styles from './NutritionMessages.module.scss';

interface MessageProps {
  message: NutritionMessageType;
  children: ReactNode;
  setEditingMessage: (topic: NutritionMessageType | undefined) => void;
  setAddOrEditMessage: (value: 'edit') => void;
  setAddOrEditQuestion: (value: 'add') => void;
  user: ReturnType<typeof useGetUserInfo>;
}
const Message: FC<MessageProps> = ({
  message,
  children,
  setAddOrEditMessage,
  setEditingMessage,
  setAddOrEditQuestion,
  user,
}) => {
  return (
    <div className={Styles.MessageContainer}>
      <h3 className={Styles.MessageTitle}>
        Message {message.message_text}
        <Button
          type='link'
          disabled={!(user.isAdmin && user.isEditor)}
          onClick={() => {
            setAddOrEditMessage('edit');
            setEditingMessage(message);
          }}
        >
          edit message
        </Button>
      </h3>
      <Row>
        <Col span={22} offset={1}>
          <strong style={{ fontSize: '1rem', marginBottom: 12, display: 'block' }}>
            {message?.message_text_myanmar}
          </strong>

          <p style={{ whiteSpace: 'pre-wrap' }}>{message?.description}</p>
          <h4 className={Styles.QuestionTableTitle}>
            <span>Questions</span>
            <Button
              type='link'
              disabled={!(user.isAdmin && user.isEditor)}
              onClick={() => {
                setAddOrEditQuestion('add');
                setEditingMessage(message);
              }}
            >
              add new question
            </Button>
          </h4>
          <table className={Styles.QuestionsTable}>
            <tbody>{children}</tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default Message;

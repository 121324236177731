import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { VillageTractByTownshipCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';
import { compact } from 'lodash';

export const useListVillageTractByTownshipId = (township_id: string | undefined) => {
  return useQuery(
    ['villageTractByTownship', township_id || 'never'],
    () =>
      API.graphql({
        query: queries.villageTractByTownshipCustom,
        variables: { township_id: township_id || 'never' },
      }) as Promise<GraphQLResult<VillageTractByTownshipCustomQuery>>,
    {
      enabled: !!township_id,
      select: (data) =>
        data.data?.villageTractByTownship && compact(data.data.villageTractByTownship.items),
    }
  );
};

export const listDioceseCustom = /* GraphQL */ `
  query ListDioceseCustom(
    $id: ID
    $filter: ModelDioceseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDiocese(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        diocese_id
        diocese_code
        name
        description
      }
    }
  }
`;

import { Button, Col, Descriptions, Row, Space } from 'antd';
import { FC } from 'react';
import { ExtendedCareGroupType } from './CareGroups';
import { TableFilterType } from '../Activities/common_types';
import { CareGroupFilterType } from '../../redux/caregroupsSlice';

interface PropsType {
  filteredCareGroups: ExtendedCareGroupType[];
  careGroupFilters: Record<CareGroupFilterType, (boolean | string | number)[] | null>;
  birth_history_base_date: string;
  staffFilter: TableFilterType[];
  hide: () => void;
}

const CareGroupSummary: FC<PropsType> = ({
  filteredCareGroups,
  careGroupFilters,
  birth_history_base_date,
  staffFilter,
  hide,
}) => {
  const cg_count = filteredCareGroups.length;

  const dioceseNameSet = new Set<string>();
  const projectNameSet = new Set<string>();
  const townshipNameSet = new Set<string>();
  const staffIDSet = new Set<string>();

  let assigned_staff_count = 0;

  let ng_count = 0;
  let plw_count = 0;
  let lost_contact_plw_count = 0;
  let pregnancy_count = 0;
  let lost_contact_pregnancy_count = 0;
  let overdue_pregnancy_count = 0;
  let lost_contact_overdue_pregnancy_count = 0;
  let u6m_count = 0;
  let lost_contact_u6m_count = 0;
  let u2y_count = 0;
  let lost_contact_u2y_count = 0;

  filteredCareGroups.forEach((cg) => {
    dioceseNameSet.add(cg.project_diocese.diocese.name);
    projectNameSet.add(cg.project_diocese.project.name);
    townshipNameSet.add(cg.township.name);

    ng_count += cg.ng_count;
    plw_count += cg.plw_count;
    lost_contact_plw_count += cg.lost_contact_plw_count;
    pregnancy_count += cg.pregnancy_count;
    lost_contact_pregnancy_count += cg.lost_contact_pregnancy_count;
    overdue_pregnancy_count += cg.overdue_pregnancy_count;
    lost_contact_overdue_pregnancy_count += cg.lost_contact_overdue_pregnancy_count;
    u6m_count += cg.u6m_count;
    lost_contact_u6m_count += cg.lost_contact_u6m_count;
    u2y_count += cg.u2y_count;
    lost_contact_u2y_count += cg.lost_contact_u2y_count;

    if (cg.staff_caregroups) {
      cg.staff_caregroups.items.forEach((scg) => {
        if (scg && scg.current_assignment === true) {
          staffIDSet.add(scg.staff.id);
        }
      });
    }
  });

  return (
    <div style={{ height: 'calc(100vh - 100px)', overflow: 'auto' }}>
      <Descriptions title='Care Groups Summary' column={3} bordered extra={<Button onClick={hide}>Close</Button>}>
        <Descriptions.Item label='Number of CGs' labelStyle={{ width: 150 }} contentStyle={{ width: 200 }}>
          {cg_count}
        </Descriptions.Item>
        <Descriptions.Item label='Number of NGs' labelStyle={{ width: 150 }} contentStyle={{ width: 200 }}>
          {ng_count}
        </Descriptions.Item>
        <Descriptions.Item label='Base date' labelStyle={{ width: 150 }} contentStyle={{ width: 200 }}>
          {birth_history_base_date}
        </Descriptions.Item>
        <Descriptions.Item label='Number of Townships' labelStyle={{ width: 150 }} contentStyle={{ width: 200 }}>
          {townshipNameSet.size}
        </Descriptions.Item>
        <Descriptions.Item label='PLW'>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={12}>Total:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {plw_count.toLocaleString()}
            </Col>
            <Col span={12}>Active:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {(plw_count - lost_contact_plw_count).toLocaleString()}
            </Col>
            <Col span={12}>LC:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {lost_contact_plw_count.toLocaleString()}
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Pregnancy'>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={12}>Total:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {pregnancy_count.toLocaleString()}
            </Col>
            <Col span={12}>Active:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {(pregnancy_count - lost_contact_pregnancy_count).toLocaleString()}
            </Col>
            <Col span={12}>LC:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {lost_contact_pregnancy_count.toLocaleString()}
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Overdue Pregnancy'>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={12}>Total:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {overdue_pregnancy_count.toLocaleString()}
            </Col>
            <Col span={12}>Active:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {(overdue_pregnancy_count - lost_contact_overdue_pregnancy_count).toLocaleString()}
            </Col>
            <Col span={12}>LC:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {lost_contact_overdue_pregnancy_count.toLocaleString()}
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Mohters of U6M Children'>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={12}>Total:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {u6m_count.toLocaleString()}
            </Col>
            <Col span={12}>Active:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {(u6m_count - lost_contact_u6m_count).toLocaleString()}
            </Col>
            <Col span={12}>LC:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {lost_contact_u6m_count.toLocaleString()}
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Mothers of U2Y Children'>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={12}>Total:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {u2y_count.toLocaleString()}
            </Col>
            <Col span={12}>Active:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {(u2y_count - lost_contact_u2y_count).toLocaleString()}
            </Col>
            <Col span={12}>LC:</Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {lost_contact_u2y_count.toLocaleString()}
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Number of Asssigned Staff' labelStyle={{ width: 150 }} contentStyle={{ width: 200 }}>
          {staffIDSet.size}
        </Descriptions.Item>
        <Descriptions.Item label='Projects'>
          {[...projectNameSet].sort().map((projectName) => (
            <div key={projectName}>
              <span>{projectName}</span>
              <br />
            </div>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label='Dioceses'>
          {[...dioceseNameSet].sort().map((dioceseName) => (
            <div key={dioceseName}>
              <span>{dioceseName}</span>
              <br />
            </div>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label='Filtered by' span={3}>
          <div style={{ maxHeight: 150, overflow: 'auto' }}>
            {Object.keys(careGroupFilters).map((filter) => {
              if (
                careGroupFilters[filter as CareGroupFilterType] &&
                careGroupFilters[filter as CareGroupFilterType]!.length > 0
              ) {
                const spaced = filter.replace('_', ' ');
                const title = spaced.charAt(0).toUpperCase() + spaced.slice(1);
                return (
                  <div key={filter} style={{ padding: '4px 0px 0px 8px' }}>
                    <span>
                      {title} ({careGroupFilters[filter as CareGroupFilterType]?.length}):{' '}
                      <span style={{ color: 'darkred' }}>
                        {careGroupFilters[filter as CareGroupFilterType]!.map((id) => {
                          if (filter === 'staff') {
                            return staffFilter.find((staff) => staff.value === id)?.text || id;
                          }
                          return id;
                        }).join(', ')}
                      </span>
                    </span>
                    <br></br>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </Descriptions.Item>
      </Descriptions>
      <div style={{ marginTop: 10, marginLeft: 10 }}>LC: lost contact</div>
    </div>
  );
};

export default CareGroupSummary;

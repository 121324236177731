import React, { FC, useEffect, useState } from 'react';
import { Card, Carousel, Image as AntImage } from 'antd';
import Styles from './Home.module.scss';
import ReactGA from 'react-ga4';
import { Storage } from 'aws-amplify';
import { AlbumPhotoType } from '../../App';
import { Flex } from '@aws-amplify/ui-react';
import fallback from '../../assets/images/fallback.png';

interface HomeProps {
  photoList: AlbumPhotoType[];
}

const Home: FC<HomeProps> = ({ photoList }) => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/home',
    });
  }, []);

  return (
    <div className={Styles.HomePage}>
      <Carousel
        autoplay
        effect={'fade'}
        // easing='ease'
        useCSS={true}
        cssEase='ease-in-out'
        autoplaySpeed={5000}
        pauseOnHover={true}
        className={Styles.Carousel}
      >
        {photoList.map((photo) => {
          return (
            <div key={photo.name} className={Styles.ImageContainerStyle}>
              <AntImage
                src={photo.url}
                className={Styles.ImageStyle}
                preview={false}
                fallback={fallback}
              />
            </div>
          );
        })}
      </Carousel>
      <div className={Styles.TitleCardContainer}>
        <Card className={Styles.TitleCard}>
          <h1 className={Styles.BigTitle}>Karuna Myanmar Social Service</h1>
          <h2 className={Styles.SubTitle1}>Care Group Model</h2>
          <h3 className={Styles.SubTitle2}>Monitoring Information Management System</h3>
        </Card>
      </div>
    </div>
  );
};
export default Home;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { OtherActivityTypeByProjectCodeQuery } from '../API';
import * as queries from '../graphql/queries';
import { compact } from 'lodash';

export const useListOtherActivityTypeByProjectCode = (project_code: string | undefined) => {
  return useQuery(
    ['useListOtherActivityTypeByProjectCode', project_code],
    () =>
      API.graphql({
        query: queries.otherActivityTypeByProjectCode,
        variables: {
          project_code: project_code || 'never',
        },
      }) as Promise<GraphQLResult<OtherActivityTypeByProjectCodeQuery>>,
    {
      enabled: !!project_code,
      select: (data) => {
        if (data.data?.otherActivityTypeByProjectCode) {
          return compact(data.data.otherActivityTypeByProjectCode.items);
        }
        return [];
      },
    }
  );
};

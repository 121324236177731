import { ColumnsType } from 'antd/lib/table';
import React, { FC, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { OtherActivityType, PLWType } from '../NeighborGroups';
import { Typography, Table, Space, Button, Popover } from 'antd';
import Styles from '../NeighborGroups.module.scss';
import OtherActivityModalForm from './OtherActivityModalForm';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Link } = Typography;

interface PropType {
  isLoading: boolean;
  cgNumber: string;
  otherActivities: OtherActivityType[];
  plws: PLWType[];
}

const OtherActivityTable: FC<PropType> = ({ isLoading, cgNumber, otherActivities, plws }) => {
  const [isOtherActivityModalVisible, setIsOtherActivityModalVisible] = useState<
    'adding' | 'editing' | false
  >(false);
  const [editingOtherActivityId, setEditingOtherActivityId] = useState<string | undefined>();
  const selectedMenuKeys = useAppSelector((state) => state.neighborgroups.selectedMenuKeys);
  const user = useGetUserInfo();

  const plwMap = useMemo(() => {
    const plwMap = new Map<string, PLWType>();
    plws.forEach((plw) => plwMap.set(plw.id, plw));
    return plwMap;
  }, [plws]);

  const columns: ColumnsType<OtherActivityType> = [
    {
      title: 'Date',
      dataIndex: 'activity_date',
      key: 'activity_date',
      sorter: (a, b) => b.activity_date!.localeCompare(a.activity_date!),
    },
    {
      title: 'Facilitators',
      dataIndex: 'facilitators',
      key: 'facilitators',
      render: (value, record) => {
        const facilitators = record.facilitators?.items.map((item) => {
          return {
            key: item?.staff.id,
            name: `${item?.staff.first_name} ${item?.staff.last_name}`,
            position: `${item?.staff.position.name}`,
          };
        });
        if (facilitators) {
          return (
            <Popover
              placement='rightTop'
              title={`${cgNumber} @ ${record.activity_date}`}
              trigger={'click'}
              content={
                <Space direction='vertical'>
                  {facilitators?.map((item) => {
                    return <Space key={item.key}>{`${item.name} (${item.position})`}</Space>;
                  })}
                </Space>
              }
            >
              {facilitators.length > 0 ? (
                facilitators.length > 1 ? (
                  <Link>{facilitators.length} staff facilitated</Link>
                ) : (
                  facilitators[0].name
                )
              ) : (
                ''
              )}
            </Popover>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Activity Type',
      dataIndex: 'activity_type',
      key: 'activity_type',
      render: (value, record) => {
        return (
          <Popover
            placement='right'
            trigger='click'
            content={
              <div>
                {record.other_activity_type.description
                  ? record.other_activity_type.description
                  : record.other_activity_type.activity_name}
              </div>
            }
          >
            <Link>{record.other_activity_type.activity_name}</Link>
          </Popover>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
      align: 'right',
      render: (value, record) => {
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              title={`${cgNumber} @ ${record.activity_date}`}
              placement='left'
              trigger={'click'}
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                  }}
                >
                  {record.participants?.items
                    .sort(
                      (a, b) =>
                        (plwMap.get(a?.plw.id || '')?.member_number || 0) -
                        (plwMap.get(b?.plw.id || '')?.member_number || 0)
                    )
                    .map((ppt) => {
                      if (ppt && ppt.plw) {
                        const plw = plwMap.get(ppt.plw.id);
                        const completeMemberNumber = `${cgNumber}.${
                          ppt.plw.neighbor_group.group_number
                        }.${plw?.member_number == undefined ? '-' : plw.member_number}`;
                        return (
                          <Space key={ppt.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{plw?.name}</span>
                            {plw?.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.participants?.items.length}</Link>
            </Popover>
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Link
            disabled={!user.isEditor || !selectedMenuKeys.selectAll}
            onClick={() => showEditOtherActivityModal(record.id)}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  const showAddOtherActivityModal = () => {
    setEditingOtherActivityId(undefined);
    setIsOtherActivityModalVisible('adding');
  };

  const showEditOtherActivityModal = (editingOtherActivityId: string) => {
    setEditingOtherActivityId(editingOtherActivityId);
    setIsOtherActivityModalVisible('editing');
  };

  return (
    <div className={Styles.dataTable}>
      <div className={Styles.tableHeaderRow}>
        <div className={Styles.buttonContainer}>
          <Space>
            <Button
              className={Styles.exportButton}
              onClick={showAddOtherActivityModal}
              disabled={!user.isEditor || isLoading || selectedMenuKeys.selectAll === false}
            >
              Add New Activity
            </Button>
          </Space>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={otherActivities}
        showSorterTooltip={false}
        size='small'
        rowKey={'id'}
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 50,
          style: { marginRight: 25 },
          showTotal: (total, range) => `Total ${total}`,
        }}
        scroll={{ x: 'calc(100vw - 400px)', y: 'calc(100vh - 350px)' }}
      />
      {selectedMenuKeys.selectAll && (
        <OtherActivityModalForm
          modalVisible={isOtherActivityModalVisible}
          setModalVisible={setIsOtherActivityModalVisible}
          editingActivityId={editingOtherActivityId}
        />
      )}
    </div>
  );
};

export default OtherActivityTable;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, List, Radio, Row, Spin, Typography } from 'antd';
import { API } from 'aws-amplify';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { OtherActivityTypeByProjectCodeCustomQuery } from '../../../API';
import { otherActivityTypeByProjectCodeCustom } from '../../../graphql/custom_queries';
import { useListProjects } from '../../../hooks';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import OtherActivityTypeForm from './OtherActivityTypeForm';

const { Paragraph } = Typography;

const OtherActivityTypes = () => {
  type ProjectType = typeof projects[number];
  type OtherActivityTypeType = NonNullable<typeof otherActivityTypes>[number];

  const user = useGetUserInfo();
  const { data: allProjects } = useListProjects();
  const [selectedProject, setSelectedProject] = useState<ProjectType | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState<'adding' | 'editing' | false>(false);
  const [editingOtherActivityType, setEditingOtherActivityType] = useState<
    OtherActivityTypeType | undefined
  >(undefined);

  const projects = useMemo(() => {
    if (user && user.projects && allProjects) {
      return compact(allProjects).filter((project) => user.projects.includes(project.project_code));
    } else {
      return [];
    }
  }, [user, allProjects]);

  // QUERY
  const {
    data: otherActivityTypes,
    isError,
    error,
    isLoading,
  } = useQuery(
    ['listOtherActivityTypesByProjectCode', selectedProject?.project_code],
    () => {
      return API.graphql({
        query: otherActivityTypeByProjectCodeCustom,
        variables: {
          project_code: selectedProject?.project_code,
        },
      }) as Promise<GraphQLResult<OtherActivityTypeByProjectCodeCustomQuery>>;
    },
    {
      select: (data) => {
        if (data.data?.otherActivityTypeByProjectCode) {
          return compact(data.data.otherActivityTypeByProjectCode.items).sort((a, b) =>
            a.activity_name.localeCompare(b.activity_name)
          );
        } else {
          return [];
        }
      },
      enabled: !!selectedProject,
    }
  );

  const startAdding = () => {
    setEditingOtherActivityType(undefined);
    setModalVisible('adding');
  };

  const startEditing = (otherActivityType: OtherActivityTypeType) => {
    setEditingOtherActivityType(otherActivityType);
    setModalVisible('editing');
  };

  if (isError) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Error loading other activity types.
      </div>
    );
  }

  return (
    <Row gutter={[0, 12]} style={{ marginTop: 20 }}>
      <Col xs={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 4 }} xl={{ span: 12, offset: 6 }}>
        <h2 style={{ color: '#9b4d04' }}>Other Activity Types</h2>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 4 }} xl={{ span: 12, offset: 6 }}>
        <Radio.Group
          defaultValue={projects[0]?.project_code}
          size='large'
          onChange={(e) =>
            setSelectedProject(projects.filter((proj) => proj.id === e.target.value)[0])
          }
        >
          {projects.map((project) => (
            <Radio.Button value={project.id} key={project.id}>
              {project.name}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Col>
      <Col span={6} />
      <Col xs={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 4 }} xl={{ span: 12, offset: 6 }}>
        {!!selectedProject ? (
          isLoading ? (
            <div
              style={{
                display: 'flex',
                height: 300,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin size='large' />
            </div>
          ) : (
            <>
              {user.isAdmin && user.isEditor && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                  <Button
                    type='link'
                    onClick={startAdding}
                    disabled={!(user.isAdmin && user.isEditor)}
                  >
                    Add New Activity Type
                  </Button>
                </div>
              )}
              <List
                itemLayout='horizontal'
                dataSource={otherActivityTypes}
                style={{ maxHeight: 'calc(100vh - 350px)', overflow: 'auto' }}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        key='edit'
                        type='link'
                        disabled={!(user.isAdmin && user.isEditor)}
                        onClick={() => startEditing(item)}
                      >
                        edit
                      </Button>,
                    ]}
                    style={{ minHeight: 70 }}
                  >
                    <List.Item.Meta
                      title={item.activity_name}
                      description={
                        <Paragraph style={{ whiteSpace: 'pre-wrap' }}>{item.description}</Paragraph>
                      }
                    />
                  </List.Item>
                )}
              />
              <OtherActivityTypeForm
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                editingOtherActivityType={editingOtherActivityType}
                project={selectedProject}
              />
            </>
          )
        ) : (
          <p style={{ marginTop: 12, marginLeft: 12 }}>Select project.</p>
        )}
      </Col>
    </Row>
  );
};

export default OtherActivityTypes;

export const topicByCgMeetingCustom = /* GraphQL */ `
  query TopicByCgMeetingCustom(
    $cg_meeting_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCGMeetingNutritionTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicByCgMeeting(
      cg_meeting_id: $cg_meeting_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
        }
      }
    }
  }
`;

export const listNutritionTopicsCustom = /* GraphQL */ `
  query ListNutritionTopicsCustom(
    $id: ID
    $filter: ModelNutritionTopicFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNutritionTopics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        topic_text
        topic_text_myanmar
        description
        nutrition_messages {
          items {
            id
            message_text
            message_text_myanmar
            description
            nutrition_questions {
              items {
                id
                question_text
                question_text_myanmar
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const getPLWforEdit = /* GraphQL */ `
  query GetPLWforEdit($id: ID!) {
    getPLW(id: $id) {
      id
      member_number
      neighbor_group {
        id
        group_number
        care_group {
          id
          group_number
        }
      }
      project_diocese_code
      date_of_registry
      lead_mother
      name
      father_name
      date_of_birth
      education_level_id
      occupation_type_id
      disabled
      disability_type
      disability_type_other_description
      contact_number
      date_of_death
      lost_contact
      birth_histories {
        items {
          id
          birth_history_id
          plw_id
          project_diocese_code
          lmp
          miscarriage_date
          delivered_date
          child_name
          child_gender
          date_of_death
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings_attended(limit: 1) {
        items {
          id
        }
      }
      ng_meetings_facilitated(limit: 1) {
        items {
          id
        }
      }
      ng_meetings_attended(limit: 1) {
        items {
          id
        }
      }
      distributions_recieved(limit: 1) {
        items {
          id
        }
      }
      other_activities_attended(limit: 1) {
        items {
          id
        }
      }
      hg_training_attended(limit: 1) {
        items {
          id
        }
      }
    }
  }
`;

export const listPlwsByCareGroupId = /* GraphQL */ `
  query ListPlwsByCareGroupId($id: ID!) {
    getCareGroup(id: $id) {
      id
      group_number
      project_diocese_code
      neighbor_groups {
        items {
          id
          group_number
          plws {
            items {
              id
              member_number
              name
              lead_mother
            }
          }
        }
      }
    }
  }
`;

export const plwByNeighborGroupCustom = /* GraphQL */ `
  query PlwByNeighborGroupCustom(
    $neighbor_group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPLWFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plwByNeighborGroup(
      neighbor_group_id: $neighbor_group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        member_number
        lead_mother
        name
        lost_contact
        birth_histories {
          items {
            id
            lmp
            miscarriage_date
            delivered_date
            date_of_death
          }
        }
      }
    }
  }
`;

export const plwByCgMeetingCustom = /* GraphQL */ `
  query PlwByCgMeetingCustom(
    $cg_meeting_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCGMeetingPLWFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plwByCgMeeting(
      cg_meeting_id: $cg_meeting_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        plw {
          id
          member_number
          neighbor_group {
            group_number
          }
          lead_mother
          name
        }
      }
    }
  }
`;

import { Button, Drawer, Popover, RadioChangeEvent, Space } from 'antd';
import { Typography, Radio } from 'antd';
import { API } from 'aws-amplify';
import React, { useEffect, useMemo, useState } from 'react';
import Styles from './HGDistributions.module.scss';
import * as queries from '../../../graphql/custom_queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { DistributionByItemCustomQuery, ListDistributionItemsCustomQuery } from '../../../API';
import { useQueries, useQuery } from '@tanstack/react-query';
import { compact } from 'lodash';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import Table, { ColumnsType } from 'antd/lib/table';
import { SyncOutlined } from '@ant-design/icons';
import { TableFilterType } from '../common_types';
import HGDSummary from './HGDSummary';
import { exportToExcel } from './hgdExcelExporter';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setSelectedMenuKeys, setDistributionFilters, initialState } from '../../../redux/hgdSlice';
import type { HgdFilterType } from '../../../redux/hgdSlice';
import ReactGA from 'react-ga4';

const { Link } = Typography;

export type DistributionItemType = Exclude<
  Exclude<
    ListDistributionItemsCustomQuery['listDistributionItems'],
    null | undefined
  >['items'][number],
  null
>;

export type DistributionType = Exclude<
  Exclude<DistributionByItemCustomQuery['distributionByItem'], null | undefined>['items'][number],
  null
>;

const defaultDistributionFilters = initialState.distributionFilters;

const HGDistributions = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/activities/homegardendistributions',
    });
  }, []);

  const dispatch = useAppDispatch();

  const [filteredDistributions, setFilteredDistributions] = useState<DistributionType[]>([]);
  // const [distributionFilters, setDistributionFilters] = useState<Record<string, (boolean | string | number)[] | null>>(
  //   {}
  // );

  const [projectFilter, setProjectFilter] = useState<Array<TableFilterType>>([]);
  const [dioceseFilter, setDioceseFilter] = useState<Array<TableFilterType>>([]);
  const [careGroupFilter, setCareGroupFilter] = useState<Array<TableFilterType>>([]);
  const [neighborGroupFilter, setNeighborGroupFilter] = useState<Array<TableFilterType>>([]);
  const [dateFilter, setDateFilter] = useState<Array<TableFilterType>>([]);
  const [distributionPlanFilter, setDistributionPlanFilter] = useState<Array<TableFilterType>>([]);

  const user = useGetUserInfo();

  const [summaryDrawerVisible, setSummaryDrawerVisible] = useState(false);

  // const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);

  const { selectedMenuKeys, distributionFilters } = useAppSelector((state) => state.hgd);

  const getDistributionItems = async () => {
    const result = (await API.graphql({
      query: queries.listDistributionItemsCustom,
      variables: {
        limit: 100,
      },
    })) as GraphQLResult<ListDistributionItemsCustomQuery>;
    if (result.data && result.data.listDistributionItems) {
      return compact(result.data.listDistributionItems.items);
    } else {
      return [];
    }
  };

  const {
    isLoading: items_isLoading,
    isError: item_isError,
    error: item_error,
    data: distribution_items,
  } = useQuery<DistributionItemType[], Error>(['distributionItems'], getDistributionItems, {
    select: (data) => data.filter((item) => user.projects.includes(item.project_code)),
  });

  const listDistributions = async (distribution_item_id: string) => {
    let distributions: DistributionType[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const result = (await API.graphql({
        query: queries.distributionByItemCustom,
        variables: {
          limit: 100,
          nextToken,
          distribution_item_id,
        },
      })) as GraphQLResult<DistributionByItemCustomQuery>;
      if (result.data && result.data.distributionByItem) {
        distributions = [...distributions, ...compact(result.data.distributionByItem.items)];
        nextToken = result.data.distributionByItem.nextToken || undefined;
      }
    } while (nextToken);
    return distributions;
  };

  const distributionQueryResult = useQueries<DistributionType[]>({
    queries: selectedMenuKeys.keys.map((dist_item_id) => {
      return {
        queryKey: ['distributions', dist_item_id],
        queryFn: () => listDistributions(dist_item_id),
      };
    }),
  });

  const { isLoading, isError, error, refetch, isFetching, data } = useMemo(() => {
    let data: DistributionType[] | undefined = undefined;
    const isLoading = distributionQueryResult.some((result) => result.isLoading === true);
    const isError = distributionQueryResult.some((result) => result.isError === true);
    const isFetching = distributionQueryResult.some((result) => result.isFetching === true);
    const error = compact(distributionQueryResult.map((result) => result.error as Error));
    const refetch = () => {
      distributionQueryResult.forEach((result) => result.refetch());
    };
    if (!isLoading && !isError) {
      data = distributionQueryResult.reduce(
        (acc, cur) => [...acc, ...(cur.data as DistributionType[])],
        [] as DistributionType[]
      );
    }
    return { isLoading, isError, error, refetch, isFetching, data };
  }, [distributionQueryResult]);

  useEffect(() => {
    // re-populate filtered records on page re-visit
    let tempDistributionList = data || [];
    Object.keys(distributionFilters).forEach((filteringField) => {
      let indFilterList = distributionFilters[filteringField as HgdFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempDistributionList = tempDistributionList.filter((dist) =>
        filterDistributions(filteringField as HgdFilterType, indFilterList!, dist)
      );
      // this effect is to create filters when a page has been returned from other pages.
      // the filters are to be used in summary and excel exporter
      drawDropDownForFilter(filteringField as HgdFilterType);
    });
    setFilteredDistributions(tempDistributionList);
  }, []);

  if (isError) {
    if (error) {
      return <span>Error: {error.join('. ')}</span>;
    } else {
      return <span>Error in reqct query.</span>;
    }
  }

  const drawDropDownForFilter = (field: HgdFilterType) => {
    let tempDistributionList = data || []; // to filter the Distribution array with all filter parameters except value in field variable
    Object.keys(distributionFilters).forEach((filteringField) => {
      if (filteringField === field) return; // filter except the current column / field

      let indFilterList = distributionFilters[filteringField as HgdFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempDistributionList = tempDistributionList.filter((distribution) =>
        filterDistributions(filteringField as HgdFilterType, indFilterList!, distribution)
      );
    });
    switch (field) {
      case 'project':
        const projectSet = new Set<string>();
        tempDistributionList.forEach((dist) => {
          projectSet.add(dist.neighbor_group.care_group.project_diocese.project.name);
        });
        const projectFilter: TableFilterType[] = [];
        projectSet.forEach((key) => {
          projectFilter.push({ text: key, value: key });
        });
        setProjectFilter(projectFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'diocese':
        const dioceseSet = new Set<string>();
        tempDistributionList.forEach((dist) => {
          dioceseSet.add(dist.neighbor_group.care_group.project_diocese.diocese.name);
        });
        const diocFilter: TableFilterType[] = [];
        dioceseSet.forEach((key) => {
          diocFilter.push({ text: key, value: key });
        });
        setDioceseFilter(diocFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'distribution_date':
        const dateMap: { [key: string]: { [key: string]: string } } = {};
        tempDistributionList.forEach((dist) => {
          if (dist.distribution_date) {
            const year = dist.distribution_date.split('-')[0];
            const yearMonth = dist.distribution_date.split('-', 2).join('-');
            if (!dateMap[year]) dateMap[year] = {};
            dateMap[year][yearMonth] = yearMonth;
          }
        });

        const dateFilter: TableFilterType[] = [];
        for (const outer in dateMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(dateMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = { text: inner, value: inner };
              outerFilter.children!.push(innerFilter);
            });
          dateFilter.push(outerFilter);
        }
        setDateFilter(dateFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'neighbor_group':
        const ngNumberMap: { [key: string]: { [key: string]: string } } = {};
        tempDistributionList.forEach((dist) => {
          const completeNgNumber = `${dist.neighbor_group.care_group.group_number}.${dist.neighbor_group.group_number}`;
          const cgNum = dist.neighbor_group.care_group.group_number;
          if (!ngNumberMap[cgNum]) ngNumberMap[cgNum] = {};
          ngNumberMap[cgNum][completeNgNumber] = completeNgNumber;
        });

        const ngNumberFilter: TableFilterType[] = [];
        Object.keys(ngNumberMap)
          .sort()
          .forEach((firstLevel) => {
            let outerFilter: TableFilterType = {
              text: firstLevel,
              value: firstLevel,
              children: [],
            };
            Object.keys(ngNumberMap[firstLevel])
              .sort()
              .forEach((secondLevel) => {
                let innerFilter = {
                  text: secondLevel,
                  value: secondLevel,
                };
                outerFilter.children!.push(innerFilter);
              });
            ngNumberFilter.push(outerFilter);
          });
        setNeighborGroupFilter(ngNumberFilter);
        break;
      case 'care_group':
        const cgNumberMap: { [key: string]: { [key: string]: string } } = {};
        tempDistributionList.forEach((dist) => {
          const cgFirstNum = dist.neighbor_group.care_group.group_number.split('.')[0];
          const cgNum = dist.neighbor_group.care_group.group_number;
          if (!cgNumberMap[cgFirstNum]) cgNumberMap[cgFirstNum] = {};
          cgNumberMap[cgFirstNum][cgNum] = cgNum;
        });
        const cgNumberFilter: TableFilterType[] = [];
        for (const outer in cgNumberMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(cgNumberMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = {
                text: inner,
                value: cgNumberMap[outer][inner],
              };
              outerFilter.children!.push(innerFilter);
            });

          cgNumberFilter.push(outerFilter);
        }
        setCareGroupFilter(cgNumberFilter.sort());
        break;
      case 'distribution_plan':
        const planSet = new Set<string>();
        tempDistributionList.forEach((dist) => {
          planSet.add(dist.distribution_item.title);
        });

        const distributionPlanFilter: TableFilterType[] = [];
        planSet.forEach((key) => {
          distributionPlanFilter.push({ text: key, value: key });
        });
        setDistributionPlanFilter(distributionPlanFilter);
        break;
      default:
    }
  };

  const filterDistributions = (
    field: HgdFilterType,
    value: string | number | boolean | (string | number | boolean)[],
    record: DistributionType
  ): boolean => {
    switch (field) {
      case 'project':
        if (Array.isArray(value)) {
          return value.includes(record.neighbor_group.care_group.project_diocese.project.name);
        }
        return record.neighbor_group.care_group.project_diocese.project.name === value;
      case 'diocese':
        if (Array.isArray(value)) {
          return value.includes(record.neighbor_group.care_group.project_diocese.diocese.name);
        }
        return record.neighbor_group.care_group.project_diocese.diocese.name === value;
      case 'distribution_date':
        if (!record.distribution_date) return false;
        if (Array.isArray(value)) {
          const yearMonth = record.distribution_date.split('-', 2).join('-');
          return value.includes(yearMonth);
        }
        return record.distribution_date.split('-', 2).join('-') === value;
      case 'care_group':
        if (Array.isArray(value)) {
          return value.includes(record.neighbor_group.care_group.group_number);
        }
        return record.neighbor_group.care_group.group_number === value;

      case 'neighbor_group':
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        if (Array.isArray(value)) {
          return value.includes(completeNgNumber);
        }
        return completeNgNumber === value;
      case 'distribution_plan':
        if (Array.isArray(value)) {
          return value.includes(record.distribution_item.title);
        }
        return record.distribution_item.title === value;
      default:
        return false;
    }
  };

  const columns: ColumnsType<DistributionType> = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      ellipsis: true,
      render: (_, record) => {
        return record.neighbor_group.care_group.project_diocese.project.name;
      },
      filters: projectFilter,
      filteredValue: distributionFilters['project'] || null,
      onFilter: (value, record) => filterDistributions('project', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('project'),
      sorter: (a, b) => {
        return a.neighbor_group.care_group.project_diocese.project.name.localeCompare(
          b.neighbor_group.care_group.project_diocese.project.name
        );
      },
    },
    {
      title: 'Diocese',
      dataIndex: 'diocese',
      key: 'diocese',
      render: (_, record) => record.neighbor_group.care_group.project_diocese.diocese.name,
      filters: dioceseFilter,
      filteredValue: (distributionFilters['diocese'] as any) || null,
      onFilter: (value, record) => filterDistributions('diocese', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('diocese'),
      sorter: (a, b) => {
        return a.neighbor_group.care_group.project_diocese.diocese.name.localeCompare(
          b.neighbor_group.care_group.project_diocese.diocese.name
        );
      },
    },
    {
      title: 'Care Group',
      dataIndex: 'care_group',
      key: 'care_group',
      filters: careGroupFilter,
      filteredValue: (distributionFilters['care_group'] as any) || null,
      onFilter: (value, record) => filterDistributions('care_group', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('care_group'),
      render: (_, record) => {
        return record.neighbor_group.care_group.group_number;
      },
      sorter: (a, b) =>
        a.neighbor_group.care_group.group_number.localeCompare(
          b.neighbor_group.care_group.group_number
        ),
    },
    {
      title: 'Neighbor Group',
      dataIndex: 'neighbor_group',
      key: 'neighbor_group',
      filters: neighborGroupFilter,
      filteredValue: (distributionFilters['neighbor_group'] as any) || null,
      onFilter: (value, record) => filterDistributions('neighbor_group', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('neighbor_group'),
      render: (_, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return completeNgNumber;
      },
      sorter: (a, b) => {
        const aCompleteNgNumber = `${a.neighbor_group.care_group.group_number}.${a.neighbor_group.group_number}`;
        const bCompleteNgNumber = `${b.neighbor_group.care_group.group_number}.${b.neighbor_group.group_number}`;
        return aCompleteNgNumber.localeCompare(bCompleteNgNumber);
      },
    },
    {
      title: 'Distribution Date',
      dataIndex: 'distribution_date',
      key: 'distribution_date',
      filters: dateFilter,
      filteredValue: (distributionFilters['distribution_date'] as any) || null,
      onFilter: (value, record) => filterDistributions('distribution_date', value, record),
      onFilterDropdownOpenChange: (visible) =>
        visible && drawDropDownForFilter('distribution_date'),
      sorter: (a, b) => a.distribution_date!.localeCompare(b.distribution_date!),
    },
    {
      title: 'Distribution Plan',
      dataIndex: 'distribution_plan',
      key: 'distribution_plan',
      filters: distributionPlanFilter,
      filteredValue: (distributionFilters['distribution_plan'] as any) || null,
      onFilter: (value, record) => filterDistributions('distribution_plan', value, record),
      onFilterDropdownOpenChange: (visible) =>
        visible && drawDropDownForFilter('distribution_plan'),
      render: (_, record) => record.distribution_item.title,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Recipients',
      dataIndex: 'recipients',
      key: 'recipients',
      align: 'right',
      render: (value, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              title={`${completeNgNumber} @ ${record.distribution_date}`}
              placement='left'
              trigger={'click'}
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                  }}
                >
                  {record.recipients?.items
                    .sort((a, b) => (a?.plw.member_number || 0) - (b?.plw.member_number || 0))
                    .map((recipient) => {
                      if (recipient && recipient.plw) {
                        const completeMemberNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}.${recipient.plw.member_number}`;
                        return (
                          <Space key={recipient.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{recipient.plw.name}</span>
                            {recipient.plw.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.recipients?.items.length}</Link>
            </Popover>
          </div>
        );
      },
    },
  ];

  const showSummaryDrawer = () => {
    setSummaryDrawerVisible(true);
  };

  const hideSummaryDrawer = () => {
    setSummaryDrawerVisible(false);
  };

  const reload = () => {
    refetch();
  };
  const clearAllFilters = () => {
    dispatch(setDistributionFilters(defaultDistributionFilters));
    setFilteredDistributions(data || []);
  };
  const excelExportHandler = () => {
    exportToExcel(
      filteredDistributions.length > 0 ? filteredDistributions : data || [],
      distributionFilters,
      distribution_items || []
    );
  };

  const onMenuSelect = ({ target: { value } }: RadioChangeEvent) => {
    if (value === 'all') {
      dispatch(
        setSelectedMenuKeys({
          keys: distribution_items?.map((item) => item.id) || [],
          selectAll: true,
        })
      );
    } else {
      dispatch(setSelectedMenuKeys({ keys: [value], selectAll: false }));
    }
    dispatch(setDistributionFilters(defaultDistributionFilters));
    setFilteredDistributions([]);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>HOME GARDEN DISTRIBUTIONS</div>
      <div className={Styles.contents}>
        <div className={Styles.selectionPanel} style={{ paddingRight: 7 }}>
          <Radio.Group
            onChange={onMenuSelect}
            optionType='button'
            style={{ width: '100%' }}
            value={selectedMenuKeys.selectAll ? 'all' : selectedMenuKeys.keys[0] || undefined}
          >
            <Space direction='vertical' style={{ width: '100%' }}>
              {distribution_items
                ? [
                    ...distribution_items
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((item) => {
                        return (
                          <Radio
                            value={item.id}
                            key={item.id}
                            style={{ height: 'fit-content', width: '100%' }}
                          >
                            {item.title} | {item.project.name}
                          </Radio>
                        );
                      }),
                    <Radio
                      value={'all'}
                      key={'all'}
                      style={{ height: 'fit-content', width: '100%' }}
                    >
                      All Distribution Items
                    </Radio>,
                  ]
                : null}
            </Space>
          </Radio.Group>
        </div>
        <div className={Styles.displayPanel}>
          <div
            className={Styles.buttonContainer}
            style={{ display: !data || data.length === 0 ? 'none' : 'inherit' }}
          >
            <Space className={Styles.innerButtonContainer}>
              <Button
                className={Styles.syncButton}
                onClick={reload}
                loading={isLoading || isFetching}
                icon={<SyncOutlined />}
              ></Button>
              <Button
                className={Styles.clearFilterButton}
                onClick={clearAllFilters}
                disabled={isLoading || isFetching}
              >
                Clear All Filters
              </Button>
              <Button
                className={Styles.exportButton}
                onClick={excelExportHandler}
                disabled={isLoading || isFetching}
              >
                Export
              </Button>
              <Button
                className={Styles.summaryButton}
                onClick={showSummaryDrawer}
                disabled={isLoading || isFetching}
              >
                Show Summary
              </Button>
            </Space>
          </div>
          <div className={Styles.dataTable}>
            <Table
              dataSource={data}
              columns={columns}
              loading={isLoading}
              rowKey='id'
              size='small'
              showSorterTooltip={false}
              pagination={{
                position: ['topRight'],
                defaultPageSize: 50,
                style: { marginRight: 25 },
                showTotal: (total) => `Total ${total}`,
              }}
              onChange={(_pagination, filters, _sorter, extra) => {
                if (extra.action === 'filter') {
                  dispatch(setDistributionFilters(filters));
                  setFilteredDistributions(extra.currentDataSource);
                }
              }}
              scroll={{ y: 'calc(100vh - 300px)' }}
            />
          </div>
        </div>
        <Drawer
          placement='right'
          closable={false}
          onClose={hideSummaryDrawer}
          open={summaryDrawerVisible}
          getContainer={false}
          width={1000}
          style={{ position: 'absolute' }}
        >
          <HGDSummary
            filteredDistributions={
              filteredDistributions.length > 0 ? filteredDistributions : data || []
            }
            distributionFilters={distributionFilters}
            hide={hideSummaryDrawer}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default HGDistributions;

import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Styles from './Map.module.scss';

import * as queries from '../../graphql/custom_queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { CareGroupByPdCodeForGoogleMapQuery } from '../../API';
import { compact } from 'lodash';
import { API } from 'aws-amplify';
import { useQueries } from '@tanstack/react-query';
import { useGetUserInfo } from '../../hooks/auth_hooks';
import { useListDioceses, useListProjects } from '../../hooks';
import { setSelectedMenuKeys } from '../../redux/mapSlice';
import { Button, Menu } from 'antd';
import { LoadingOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import GoogleMapComp from './GoogleMap';

import ReactGA from 'react-ga4';

export type CareGroupForMapType = NonNullable<
  NonNullable<CareGroupByPdCodeForGoogleMapQuery['careGroupByPdCode']>['items'][number]
>;

const Map = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/map',
    });
  }, []);
  const dispatch = useAppDispatch();

  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const selectedMenuKeys = useAppSelector((state) => state.map.selectedMenuKeys);
  const user = useGetUserInfo();
  const { data: projects } = useListProjects();
  const { data: dioceses } = useListDioceses();

  const listCareGroups = async (project_diocese_code: string) => {
    let care_groups: CareGroupForMapType[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const result = (await API.graphql({
        query: queries.careGroupByPdCodeForGoogleMap,
        variables: {
          limit: 100,
          nextToken,
          project_diocese_code,
        },
      })) as GraphQLResult<CareGroupByPdCodeForGoogleMapQuery>;
      if (result.data && result.data.careGroupByPdCode) {
        care_groups = [...care_groups, ...compact(result.data.careGroupByPdCode.items)];
        nextToken = result.data.careGroupByPdCode.nextToken || undefined;
      }
    } while (nextToken);
    return care_groups;
  };

  const listCareGroupsQueryResult = useQueries<CareGroupForMapType[]>({
    queries: selectedMenuKeys.keys.map((proj_dioc_code) => {
      return {
        queryKey: ['listCareGroups', proj_dioc_code],
        queryFn: () => listCareGroups(proj_dioc_code),
      };
    }),
  });

  const {
    isLoading,
    isError,
    data: careGroups,
    error,
    refetch,
    isFetching,
    updateStatusFlag,
  } = useMemo(() => {
    let data: CareGroupForMapType[] | undefined = undefined;
    const isLoading = listCareGroupsQueryResult.some((result) => result.isLoading === true);
    const isError = listCareGroupsQueryResult.some((result) => result.isError === true);
    const isFetching = listCareGroupsQueryResult.some((result) => result.isFetching === true);
    const error = compact(listCareGroupsQueryResult.map((result) => result.error as Error));
    const updateStatusFlag = listCareGroupsQueryResult.reduce(
      (acc, cur) => acc + cur.dataUpdatedAt,
      0
    );

    const refetch = () => {
      listCareGroupsQueryResult.forEach((result) => result.refetch());
    };
    if (!isLoading && !isError) {
      data = listCareGroupsQueryResult.reduce(
        (acc, cur) => [...acc, ...(cur.data as CareGroupForMapType[])],
        [] as CareGroupForMapType[]
      );
    }
    return {
      isLoading,
      isError,
      error,
      refetch,
      isFetching,
      data,
      updateStatusFlag,
    };
  }, [listCareGroupsQueryResult]);

  if (isError) {
    if (error.length > 0) {
      return <span>Error: {error.join('. ')} </span>;
    } else {
      return <span>Error in react query.</span>;
    }
  }

  const proj_dioc_menu_items = [
    ...user.groups.map((group) => {
      const project = projects?.find((proj) => proj?.project_code === group.split('_')[0])?.name;
      const diocese = dioceses?.find((dioc) => dioc?.diocese_code === group.split('_')[1])?.name;
      if (project && diocese) {
        return {
          label: `${diocese} (${project})`,
          key: group,
        };
      } else {
        return {
          label: '...loading',
          key: group,
        };
      }
    }),
    {
      label: 'All Dioceses',
      key: 'all',
    },
  ];

  const onMenuSelect = ({ key }: { key: string }) => {
    if (key === 'all') {
      dispatch(setSelectedMenuKeys({ keys: user.groups, selectAll: true }));
    } else {
      dispatch(setSelectedMenuKeys({ keys: [key], selectAll: false }));
    }
  };

  const toggleMenuCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>CARE GROUPS LOCATION MAP</div>
      <div className={Styles.contents}>
        <div className={Styles.selectionPanel}>
          <Button onClick={toggleMenuCollapsed} style={{ margin: '0 14px 8px 14px' }}>
            {isLoading ? (
              <LoadingOutlined />
            ) : menuCollapsed ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined />
            )}
          </Button>
          <Menu
            mode='inline'
            items={proj_dioc_menu_items}
            onSelect={onMenuSelect}
            selectedKeys={selectedMenuKeys.selectAll ? ['all'] : selectedMenuKeys.keys}
            inlineCollapsed={menuCollapsed}
          />
        </div>
        <div className={Styles.displayPanel}>
          <GoogleMapComp isDataLoading={isLoading} careGroups={careGroups} />
        </div>
      </div>
    </div>
  );
};
export default Map;

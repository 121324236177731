import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { VillageByVillageTractCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';
import { compact } from 'lodash';

export const useListVillageByVillageTractId = (village_tract_id: string | undefined) => {
  return useQuery(
    ['villageByVillageTract', village_tract_id || 'never'],
    () =>
      API.graphql({
        query: queries.villageByVillageTractCustom,
        variables: { villageTract_id: village_tract_id || 'never' },
      }) as Promise<GraphQLResult<VillageByVillageTractCustomQuery>>,
    {
      enabled: !!village_tract_id,
      select: (data) =>
        data.data?.villageByVillageTract && compact(data.data.villageByVillageTract.items),
    }
  );
};

/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCampFilterInput = {
  id?: ModelIDInput | null,
  camp_id?: ModelIntInput | null,
  township_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  description?: ModelStringInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelCampFilterInput | null > | null,
  or?: Array< ModelCampFilterInput | null > | null,
  not?: ModelCampFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCampConnection = {
  __typename: "ModelCampConnection",
  items:  Array<Camp | null >,
  nextToken?: string | null,
};

export type Camp = {
  __typename: "Camp",
  id: string,
  camp_id?: number | null,
  township_id: string,
  township: Township,
  name: string,
  name_mm?: string | null,
  description?: string | null,
  longitude?: number | null,
  latitude?: number | null,
  diocese_code?: string | null,
  active?: boolean | null,
  neighbor_groups?: ModelNeighborGroupConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Township = {
  __typename: "Township",
  id: string,
  ts_pcode?: string | null,
  d_pcode?: string | null,
  sr_pcode?: string | null,
  name: string,
  name_mm?: string | null,
  diocese_id?: string | null,
  diocese?: Diocese | null,
  diocese_code?: string | null,
  active?: boolean | null,
  villageTracts?: ModelVillageTractConnection | null,
  camps?: ModelCampConnection | null,
  care_groups?: ModelCareGroupConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Diocese = {
  __typename: "Diocese",
  id: string,
  diocese_id?: number | null,
  diocese_code: string,
  name: string,
  description?: string | null,
  project_dioceses?: ModelProjectDioceseConnection | null,
  townships?: ModelTownshipConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelProjectDioceseConnection = {
  __typename: "ModelProjectDioceseConnection",
  items:  Array<ProjectDiocese | null >,
  nextToken?: string | null,
};

export type ProjectDiocese = {
  __typename: "ProjectDiocese",
  id: string,
  project_diocese_id?: number | null,
  projectID: string,
  project: Project,
  dioceseID: string,
  diocese: Diocese,
  project_diocese_code: string,
  care_groups?: ModelCareGroupConnection | null,
  staff?: ModelStaffConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Project = {
  __typename: "Project",
  id: string,
  project_id?: number | null,
  project_code: string,
  name: string,
  sector: PROGRAM_SECTORS,
  description?: string | null,
  donor?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  project_dioceses?: ModelProjectDioceseConnection | null,
  distribution_items?: ModelDistributionItemConnection | null,
  other_activitie_types?: ModelOtherActivityTypeConnection | null,
  hg_training_topics?: ModelHgTrainingTopicConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum PROGRAM_SECTORS {
  LIVELIHOOD_SECTOR = "LIVELIHOOD_SECTOR",
  EDUCATION_SECTOR = "EDUCATION_SECTOR",
  HEALTH_SECTOR = "HEALTH_SECTOR",
  EMERGENCY_SECTOR = "EMERGENCY_SECTOR",
  LEADERSHIP_SECTOR = "LEADERSHIP_SECTOR",
}


export type ModelDistributionItemConnection = {
  __typename: "ModelDistributionItemConnection",
  items:  Array<DistributionItem | null >,
  nextToken?: string | null,
};

export type DistributionItem = {
  __typename: "DistributionItem",
  id: string,
  distribution_item_id?: number | null,
  project_id: string,
  project: Project,
  project_code: string,
  title: string,
  description?: string | null,
  planned_distribution_start_date?: string | null,
  planned_distribution_end_date?: string | null,
  distributions?: ModelDistributionConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelDistributionConnection = {
  __typename: "ModelDistributionConnection",
  items:  Array<Distribution | null >,
  nextToken?: string | null,
};

export type Distribution = {
  __typename: "Distribution",
  id: string,
  distribution_id?: number | null,
  project_diocese_code: string,
  distribution_item_id: string,
  distribution_item: DistributionItem,
  neighbor_group_id: string,
  neighbor_group: NeighborGroup,
  distribution_date?: string | null,
  description?: string | null,
  recipients?: ModelDistributionRecipientsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type NeighborGroup = {
  __typename: "NeighborGroup",
  id: string,
  neighbor_group_id?: number | null,
  group_number: string,
  project_diocese_code: string,
  care_group_id: string,
  care_group: CareGroup,
  village_id?: string | null,
  village?: Village | null,
  camp_id?: string | null,
  camp?: Camp | null,
  plws?: ModelPLWConnection | null,
  ng_meetings?: ModelNGMeetingConnection | null,
  distributions?: ModelDistributionConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type CareGroup = {
  __typename: "CareGroup",
  id: string,
  care_group_id?: number | null,
  group_number: string,
  project_diocese_id: string,
  project_diocese: ProjectDiocese,
  project_diocese_code: string,
  township_id: string,
  township: Township,
  staff_caregroups?: ModelStaffCareGroupConnection | null,
  neighbor_groups?: ModelNeighborGroupConnection | null,
  cg_meetings?: ModelCGMeetingConnection | null,
  other_activities?: ModelOtherActivityConnection | null,
  hg_trainings?: ModelHgTrainingConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelStaffCareGroupConnection = {
  __typename: "ModelStaffCareGroupConnection",
  items:  Array<StaffCareGroup | null >,
  nextToken?: string | null,
};

export type StaffCareGroup = {
  __typename: "StaffCareGroup",
  id: string,
  staff_caregroup_id?: number | null,
  care_group_id: string,
  care_group?: CareGroup | null,
  staff_id: string,
  staff: Staff,
  current_assignment?: boolean | null,
  start_date?: string | null,
  end_date?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Staff = {
  __typename: "Staff",
  id: string,
  staff_id?: number | null,
  project_diocese_id: string,
  project_diocese: ProjectDiocese,
  position_id: string,
  position: Position,
  staff_caregroup?: ModelStaffCareGroupConnection | null,
  current_position?: boolean | null,
  from_date?: string | null,
  to_date?: string | null,
  email: string,
  first_name?: string | null,
  last_name?: string | null,
  gender?: GENDER | null,
  is_staff?: boolean | null,
  is_active?: boolean | null,
  date_joined?: string | null,
  cg_meetings_facilitated?: ModelCGMeetingStaffConnection | null,
  ng_meetings_facilitated?: ModelNGMeetingStaffFacilitatorConnection | null,
  other_activities_facilitated?: ModelOtherActivityFacilitatorsConnection | null,
  hg_trainings_facilitated?: ModelHgTrainingFacilitatorsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Position = {
  __typename: "Position",
  id: string,
  position_id?: number | null,
  name: string,
  level?: POSITION_LEVELS | null,
  description?: string | null,
  staff?: ModelStaffConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum POSITION_LEVELS {
  FIELD_LEVEL = "FIELD_LEVEL",
  PROJECT_LEVEL = "PROJECT_LEVEL",
  SECTOR_LEVEL = "SECTOR_LEVEL",
  DIOCESE_LEVEL = "DIOCESE_LEVEL",
}


export type ModelStaffConnection = {
  __typename: "ModelStaffConnection",
  items:  Array<Staff | null >,
  nextToken?: string | null,
};

export enum GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
}


export type ModelCGMeetingStaffConnection = {
  __typename: "ModelCGMeetingStaffConnection",
  items:  Array<CGMeetingStaff | null >,
  nextToken?: string | null,
};

export type CGMeetingStaff = {
  __typename: "CGMeetingStaff",
  id: string,
  cg_meeting_id: string,
  cg_meeting: CGMeeting,
  staff_id: string,
  staff: Staff,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type CGMeeting = {
  __typename: "CGMeeting",
  id: string,
  cg_meeting_id?: number | null,
  project_diocese_code: string,
  care_group_id: string,
  care_group: CareGroup,
  meeting_date?: string | null,
  description?: string | null,
  topics?: ModelCGMeetingNutritionTopicConnection | null,
  facilitators?: ModelCGMeetingStaffConnection | null,
  participants?: ModelCGMeetingPLWConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCGMeetingNutritionTopicConnection = {
  __typename: "ModelCGMeetingNutritionTopicConnection",
  items:  Array<CGMeetingNutritionTopic | null >,
  nextToken?: string | null,
};

export type CGMeetingNutritionTopic = {
  __typename: "CGMeetingNutritionTopic",
  id: string,
  cg_meeting_id: string,
  cg_meeting: CGMeeting,
  nutrition_topic_id: string,
  nutrition_topic: NutritionTopic,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type NutritionTopic = {
  __typename: "NutritionTopic",
  id: string,
  nutrition_topic_id?: number | null,
  topic_text: string,
  topic_text_myanmar?: string | null,
  description?: string | null,
  projects?: Array< string | null > | null,
  nutrition_messages?: ModelNutritionMessageConnection | null,
  cg_meetings?: ModelCGMeetingNutritionTopicConnection | null,
  ng_meetings?: ModelNGMeetingNutritionTopicConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelNutritionMessageConnection = {
  __typename: "ModelNutritionMessageConnection",
  items:  Array<NutritionMessage | null >,
  nextToken?: string | null,
};

export type NutritionMessage = {
  __typename: "NutritionMessage",
  id: string,
  nutrition_message_id?: number | null,
  nutrition_topic_id: string,
  nutrition_topic: NutritionTopic,
  message_text: string,
  message_text_myanmar?: string | null,
  description?: string | null,
  nutrition_questions?: ModelNutritionQuestionConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelNutritionQuestionConnection = {
  __typename: "ModelNutritionQuestionConnection",
  items:  Array<NutritionQuestion | null >,
  nextToken?: string | null,
};

export type NutritionQuestion = {
  __typename: "NutritionQuestion",
  id: string,
  nutrition_question_id?: number | null,
  nutrition_message_id: string,
  nutrition_message: NutritionMessage,
  question_text: string,
  question_text_myanmar?: string | null,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelNGMeetingNutritionTopicConnection = {
  __typename: "ModelNGMeetingNutritionTopicConnection",
  items:  Array<NGMeetingNutritionTopic | null >,
  nextToken?: string | null,
};

export type NGMeetingNutritionTopic = {
  __typename: "NGMeetingNutritionTopic",
  id: string,
  ng_meeting_id: string,
  ng_meeting: NGMeeting,
  nutrition_topic_id: string,
  nutrition_topic: NutritionTopic,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type NGMeeting = {
  __typename: "NGMeeting",
  id: string,
  ng_meeting_id?: number | null,
  project_diocese_code: string,
  neighbor_group_id: string,
  neighbor_group: NeighborGroup,
  plw_facilitator_id: string,
  plw_facilitator: PLW,
  meeting_date?: string | null,
  description?: string | null,
  topics?: ModelNGMeetingNutritionTopicConnection | null,
  staff_facilitators?: ModelNGMeetingStaffFacilitatorConnection | null,
  participants?: ModelNGMeetingPLWConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type PLW = {
  __typename: "PLW",
  id: string,
  plw_id?: number | null,
  member_number: number,
  neighbor_group_id: string,
  neighbor_group: NeighborGroup,
  project_diocese_code: string,
  date_of_registry?: string | null,
  lead_mother?: boolean | null,
  name: string,
  father_name?: string | null,
  date_of_birth?: string | null,
  education_level_id?: string | null,
  education_level?: EducationLevel | null,
  occupation_type_id?: string | null,
  occupation_type?: OccupationType | null,
  disabled?: boolean | null,
  disability_type?: Array< string | null > | null,
  disability_type_other_description?: string | null,
  contact_number?: string | null,
  date_of_death?: string | null,
  lost_contact?: boolean | null,
  birth_histories?: ModelBirthHistoryConnection | null,
  cg_meetings_attended?: ModelCGMeetingPLWConnection | null,
  ng_meetings_facilitated?: ModelNGMeetingConnection | null,
  ng_meetings_attended?: ModelNGMeetingPLWConnection | null,
  distributions_recieved?: ModelDistributionRecipientsConnection | null,
  other_activities_attended?: ModelOtherActivityParticipantsConnection | null,
  hg_training_attended?: ModelHgTrainingParticipantsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type EducationLevel = {
  __typename: "EducationLevel",
  id: string,
  education_level_id?: number | null,
  education_level: string,
  education_level_mm?: string | null,
  description?: string | null,
  order?: number | null,
  plws?: ModelPLWConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPLWConnection = {
  __typename: "ModelPLWConnection",
  items:  Array<PLW | null >,
  nextToken?: string | null,
};

export type OccupationType = {
  __typename: "OccupationType",
  id: string,
  occupation_type_id?: number | null,
  occupation_type: string,
  occupation_type_mm?: string | null,
  description?: string | null,
  order?: number | null,
  plws?: ModelPLWConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBirthHistoryConnection = {
  __typename: "ModelBirthHistoryConnection",
  items:  Array<BirthHistory | null >,
  nextToken?: string | null,
};

export type BirthHistory = {
  __typename: "BirthHistory",
  id: string,
  birth_history_id?: number | null,
  plw_id: string,
  plw: PLW,
  project_diocese_code: string,
  lmp?: string | null,
  miscarriage_date?: string | null,
  delivered_date?: string | null,
  child_name?: string | null,
  child_gender?: GENDER | null,
  date_of_death?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCGMeetingPLWConnection = {
  __typename: "ModelCGMeetingPLWConnection",
  items:  Array<CGMeetingPLW | null >,
  nextToken?: string | null,
};

export type CGMeetingPLW = {
  __typename: "CGMeetingPLW",
  id: string,
  cg_meeting_id: string,
  cg_meeting: CGMeeting,
  plw_id: string,
  plw: PLW,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelNGMeetingConnection = {
  __typename: "ModelNGMeetingConnection",
  items:  Array<NGMeeting | null >,
  nextToken?: string | null,
};

export type ModelNGMeetingPLWConnection = {
  __typename: "ModelNGMeetingPLWConnection",
  items:  Array<NGMeetingPLW | null >,
  nextToken?: string | null,
};

export type NGMeetingPLW = {
  __typename: "NGMeetingPLW",
  id: string,
  ng_meeting_id: string,
  ng_meeting: NGMeeting,
  plw_id: string,
  plw: PLW,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelDistributionRecipientsConnection = {
  __typename: "ModelDistributionRecipientsConnection",
  items:  Array<DistributionRecipients | null >,
  nextToken?: string | null,
};

export type DistributionRecipients = {
  __typename: "DistributionRecipients",
  id: string,
  distribution_id: string,
  distribution: Distribution,
  plw_id: string,
  plw: PLW,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelOtherActivityParticipantsConnection = {
  __typename: "ModelOtherActivityParticipantsConnection",
  items:  Array<OtherActivityParticipants | null >,
  nextToken?: string | null,
};

export type OtherActivityParticipants = {
  __typename: "OtherActivityParticipants",
  id: string,
  other_activity_id: string,
  other_activity: OtherActivity,
  plw_id: string,
  plw: PLW,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type OtherActivity = {
  __typename: "OtherActivity",
  id: string,
  other_activity_id?: number | null,
  project_diocese_code: string,
  care_group_id: string,
  care_group: CareGroup,
  other_activity_type_id: string,
  other_activity_type: OtherActivityType,
  activity_date: string,
  description?: string | null,
  facilitators?: ModelOtherActivityFacilitatorsConnection | null,
  participants?: ModelOtherActivityParticipantsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type OtherActivityType = {
  __typename: "OtherActivityType",
  id: string,
  other_activity_type_id?: number | null,
  project_id: string,
  project: Project,
  project_code: string,
  activity_name: string,
  description?: string | null,
  activities?: ModelOtherActivityConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelOtherActivityConnection = {
  __typename: "ModelOtherActivityConnection",
  items:  Array<OtherActivity | null >,
  nextToken?: string | null,
};

export type ModelOtherActivityFacilitatorsConnection = {
  __typename: "ModelOtherActivityFacilitatorsConnection",
  items:  Array<OtherActivityFacilitators | null >,
  nextToken?: string | null,
};

export type OtherActivityFacilitators = {
  __typename: "OtherActivityFacilitators",
  id: string,
  other_activity_id: string,
  other_activity: OtherActivity,
  staff_id: string,
  staff: Staff,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelHgTrainingParticipantsConnection = {
  __typename: "ModelHgTrainingParticipantsConnection",
  items:  Array<HgTrainingParticipants | null >,
  nextToken?: string | null,
};

export type HgTrainingParticipants = {
  __typename: "HgTrainingParticipants",
  id: string,
  hg_training_id: string,
  hg_training: HgTraining,
  plw_id: string,
  plw: PLW,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type HgTraining = {
  __typename: "HgTraining",
  id: string,
  hg_training_id?: number | null,
  project_diocese_code: string,
  care_group_id: string,
  care_group: CareGroup,
  training_date: string,
  description?: string | null,
  topics?: ModelHgTrainingTrainingTopicConnection | null,
  facilitators?: ModelHgTrainingFacilitatorsConnection | null,
  participants?: ModelHgTrainingParticipantsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelHgTrainingTrainingTopicConnection = {
  __typename: "ModelHgTrainingTrainingTopicConnection",
  items:  Array<HgTrainingTrainingTopic | null >,
  nextToken?: string | null,
};

export type HgTrainingTrainingTopic = {
  __typename: "HgTrainingTrainingTopic",
  id: string,
  hg_training_id: string,
  hg_training: HgTraining,
  hg_training_topic_id: string,
  hg_training_topic: HgTrainingTopic,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type HgTrainingTopic = {
  __typename: "HgTrainingTopic",
  id: string,
  hg_training_topic_id?: number | null,
  project_id: string,
  project: Project,
  project_code: string,
  topic: string,
  description?: string | null,
  training?: ModelHgTrainingTrainingTopicConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelHgTrainingFacilitatorsConnection = {
  __typename: "ModelHgTrainingFacilitatorsConnection",
  items:  Array<HgTrainingFacilitators | null >,
  nextToken?: string | null,
};

export type HgTrainingFacilitators = {
  __typename: "HgTrainingFacilitators",
  id: string,
  hg_training_id: string,
  hg_training: HgTraining,
  staff_id: string,
  staff: Staff,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelNGMeetingStaffFacilitatorConnection = {
  __typename: "ModelNGMeetingStaffFacilitatorConnection",
  items:  Array<NGMeetingStaffFacilitator | null >,
  nextToken?: string | null,
};

export type NGMeetingStaffFacilitator = {
  __typename: "NGMeetingStaffFacilitator",
  id: string,
  ng_meeting_id: string,
  ng_meeting: NGMeeting,
  staff_id: string,
  staff: Staff,
  project_diocese_code: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelNeighborGroupConnection = {
  __typename: "ModelNeighborGroupConnection",
  items:  Array<NeighborGroup | null >,
  nextToken?: string | null,
};

export type ModelCGMeetingConnection = {
  __typename: "ModelCGMeetingConnection",
  items:  Array<CGMeeting | null >,
  nextToken?: string | null,
};

export type ModelHgTrainingConnection = {
  __typename: "ModelHgTrainingConnection",
  items:  Array<HgTraining | null >,
  nextToken?: string | null,
};

export type Village = {
  __typename: "Village",
  id: string,
  village_pcode?: string | null,
  villageTract_id: string,
  villageTract: VillageTract,
  name: string,
  name_mm?: string | null,
  alt_name?: string | null,
  alt_name_mm?: string | null,
  longitude?: number | null,
  latitude?: number | null,
  diocese_code?: string | null,
  active?: boolean | null,
  neighbor_groups?: ModelNeighborGroupConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type VillageTract = {
  __typename: "VillageTract",
  id: string,
  vt_pcode?: string | null,
  township_id: string,
  township: Township,
  name: string,
  name_mm?: string | null,
  diocese_code?: string | null,
  active?: boolean | null,
  villages?: ModelVillageConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelVillageConnection = {
  __typename: "ModelVillageConnection",
  items:  Array<Village | null >,
  nextToken?: string | null,
};

export type ModelOtherActivityTypeConnection = {
  __typename: "ModelOtherActivityTypeConnection",
  items:  Array<OtherActivityType | null >,
  nextToken?: string | null,
};

export type ModelHgTrainingTopicConnection = {
  __typename: "ModelHgTrainingTopicConnection",
  items:  Array<HgTrainingTopic | null >,
  nextToken?: string | null,
};

export type ModelCareGroupConnection = {
  __typename: "ModelCareGroupConnection",
  items:  Array<CareGroup | null >,
  nextToken?: string | null,
};

export type ModelTownshipConnection = {
  __typename: "ModelTownshipConnection",
  items:  Array<Township | null >,
  nextToken?: string | null,
};

export type ModelVillageTractConnection = {
  __typename: "ModelVillageTractConnection",
  items:  Array<VillageTract | null >,
  nextToken?: string | null,
};

export type ModelCareGroupFilterInput = {
  id?: ModelIDInput | null,
  care_group_id?: ModelIntInput | null,
  group_number?: ModelStringInput | null,
  project_diocese_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  township_id?: ModelIDInput | null,
  and?: Array< ModelCareGroupFilterInput | null > | null,
  or?: Array< ModelCareGroupFilterInput | null > | null,
  not?: ModelCareGroupFilterInput | null,
};

export type ModelCGMeetingFilterInput = {
  id?: ModelIDInput | null,
  cg_meeting_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  meeting_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCGMeetingFilterInput | null > | null,
  or?: Array< ModelCGMeetingFilterInput | null > | null,
  not?: ModelCGMeetingFilterInput | null,
};

export type ModelDioceseFilterInput = {
  id?: ModelIDInput | null,
  diocese_id?: ModelIntInput | null,
  diocese_code?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDioceseFilterInput | null > | null,
  or?: Array< ModelDioceseFilterInput | null > | null,
  not?: ModelDioceseFilterInput | null,
};

export type ModelDioceseConnection = {
  __typename: "ModelDioceseConnection",
  items:  Array<Diocese | null >,
  nextToken?: string | null,
};

export type ModelDistributionItemFilterInput = {
  id?: ModelIDInput | null,
  distribution_item_id?: ModelIntInput | null,
  project_id?: ModelIDInput | null,
  project_code?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  planned_distribution_start_date?: ModelStringInput | null,
  planned_distribution_end_date?: ModelStringInput | null,
  and?: Array< ModelDistributionItemFilterInput | null > | null,
  or?: Array< ModelDistributionItemFilterInput | null > | null,
  not?: ModelDistributionItemFilterInput | null,
};

export type ModelDistributionFilterInput = {
  id?: ModelIDInput | null,
  distribution_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  distribution_item_id?: ModelIDInput | null,
  neighbor_group_id?: ModelIDInput | null,
  distribution_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDistributionFilterInput | null > | null,
  or?: Array< ModelDistributionFilterInput | null > | null,
  not?: ModelDistributionFilterInput | null,
};

export type ModelNeighborGroupFilterInput = {
  id?: ModelIDInput | null,
  neighbor_group_id?: ModelIntInput | null,
  group_number?: ModelStringInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  village_id?: ModelIDInput | null,
  camp_id?: ModelIDInput | null,
  and?: Array< ModelNeighborGroupFilterInput | null > | null,
  or?: Array< ModelNeighborGroupFilterInput | null > | null,
  not?: ModelNeighborGroupFilterInput | null,
};

export type ModelNGMeetingFilterInput = {
  id?: ModelIDInput | null,
  ng_meeting_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  neighbor_group_id?: ModelIDInput | null,
  plw_facilitator_id?: ModelIDInput | null,
  meeting_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNGMeetingFilterInput | null > | null,
  or?: Array< ModelNGMeetingFilterInput | null > | null,
  not?: ModelNGMeetingFilterInput | null,
};

export type ModelCGMeetingNutritionTopicFilterInput = {
  id?: ModelIDInput | null,
  cg_meeting_id?: ModelIDInput | null,
  nutrition_topic_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelCGMeetingNutritionTopicFilterInput | null > | null,
  or?: Array< ModelCGMeetingNutritionTopicFilterInput | null > | null,
  not?: ModelCGMeetingNutritionTopicFilterInput | null,
};

export type ModelNutritionTopicFilterInput = {
  id?: ModelIDInput | null,
  nutrition_topic_id?: ModelIntInput | null,
  topic_text?: ModelStringInput | null,
  topic_text_myanmar?: ModelStringInput | null,
  description?: ModelStringInput | null,
  projects?: ModelIDInput | null,
  and?: Array< ModelNutritionTopicFilterInput | null > | null,
  or?: Array< ModelNutritionTopicFilterInput | null > | null,
  not?: ModelNutritionTopicFilterInput | null,
};

export type ModelNutritionTopicConnection = {
  __typename: "ModelNutritionTopicConnection",
  items:  Array<NutritionTopic | null >,
  nextToken?: string | null,
};

export type ModelOtherActivityTypeFilterInput = {
  id?: ModelIDInput | null,
  other_activity_type_id?: ModelIntInput | null,
  project_id?: ModelIDInput | null,
  project_code?: ModelStringInput | null,
  activity_name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelOtherActivityTypeFilterInput | null > | null,
  or?: Array< ModelOtherActivityTypeFilterInput | null > | null,
  not?: ModelOtherActivityTypeFilterInput | null,
};

export type ModelOtherActivityFilterInput = {
  id?: ModelIDInput | null,
  other_activity_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  other_activity_type_id?: ModelIDInput | null,
  activity_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelOtherActivityFilterInput | null > | null,
  or?: Array< ModelOtherActivityFilterInput | null > | null,
  not?: ModelOtherActivityFilterInput | null,
};

export type ModelPLWFilterInput = {
  id?: ModelIDInput | null,
  plw_id?: ModelIntInput | null,
  member_number?: ModelIntInput | null,
  neighbor_group_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  date_of_registry?: ModelStringInput | null,
  lead_mother?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  father_name?: ModelStringInput | null,
  date_of_birth?: ModelStringInput | null,
  education_level_id?: ModelIDInput | null,
  occupation_type_id?: ModelIDInput | null,
  disabled?: ModelBooleanInput | null,
  disability_type?: ModelIDInput | null,
  disability_type_other_description?: ModelStringInput | null,
  contact_number?: ModelStringInput | null,
  date_of_death?: ModelStringInput | null,
  lost_contact?: ModelBooleanInput | null,
  and?: Array< ModelPLWFilterInput | null > | null,
  or?: Array< ModelPLWFilterInput | null > | null,
  not?: ModelPLWFilterInput | null,
};

export type ModelCGMeetingPLWFilterInput = {
  id?: ModelIDInput | null,
  cg_meeting_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelCGMeetingPLWFilterInput | null > | null,
  or?: Array< ModelCGMeetingPLWFilterInput | null > | null,
  not?: ModelCGMeetingPLWFilterInput | null,
};

export type ModelProjectDioceseFilterInput = {
  id?: ModelIDInput | null,
  project_diocese_id?: ModelIntInput | null,
  projectID?: ModelIDInput | null,
  dioceseID?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelProjectDioceseFilterInput | null > | null,
  or?: Array< ModelProjectDioceseFilterInput | null > | null,
  not?: ModelProjectDioceseFilterInput | null,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  project_id?: ModelIntInput | null,
  project_code?: ModelStringInput | null,
  name?: ModelStringInput | null,
  sector?: ModelPROGRAM_SECTORSInput | null,
  description?: ModelStringInput | null,
  donor?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
};

export type ModelPROGRAM_SECTORSInput = {
  eq?: PROGRAM_SECTORS | null,
  ne?: PROGRAM_SECTORS | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type ModelStaffFilterInput = {
  id?: ModelIDInput | null,
  staff_id?: ModelIntInput | null,
  project_diocese_id?: ModelIDInput | null,
  position_id?: ModelIDInput | null,
  current_position?: ModelBooleanInput | null,
  from_date?: ModelStringInput | null,
  to_date?: ModelStringInput | null,
  email?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  gender?: ModelGENDERInput | null,
  is_staff?: ModelBooleanInput | null,
  is_active?: ModelBooleanInput | null,
  date_joined?: ModelStringInput | null,
  and?: Array< ModelStaffFilterInput | null > | null,
  or?: Array< ModelStaffFilterInput | null > | null,
  not?: ModelStaffFilterInput | null,
};

export type ModelGENDERInput = {
  eq?: GENDER | null,
  ne?: GENDER | null,
};

export type ModelTownshipFilterInput = {
  id?: ModelIDInput | null,
  ts_pcode?: ModelStringInput | null,
  d_pcode?: ModelStringInput | null,
  sr_pcode?: ModelStringInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  diocese_id?: ModelIDInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelTownshipFilterInput | null > | null,
  or?: Array< ModelTownshipFilterInput | null > | null,
  not?: ModelTownshipFilterInput | null,
};

export type ModelVillageFilterInput = {
  id?: ModelIDInput | null,
  village_pcode?: ModelStringInput | null,
  villageTract_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  alt_name?: ModelStringInput | null,
  alt_name_mm?: ModelStringInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelVillageFilterInput | null > | null,
  or?: Array< ModelVillageFilterInput | null > | null,
  not?: ModelVillageFilterInput | null,
};

export type ModelVillageTractFilterInput = {
  id?: ModelIDInput | null,
  vt_pcode?: ModelStringInput | null,
  township_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelVillageTractFilterInput | null > | null,
  or?: Array< ModelVillageTractFilterInput | null > | null,
  not?: ModelVillageTractFilterInput | null,
};

export type CreateProjectInput = {
  id?: string | null,
  project_id?: number | null,
  project_code: string,
  name: string,
  sector: PROGRAM_SECTORS,
  description?: string | null,
  donor?: string | null,
  start_date?: string | null,
  end_date?: string | null,
};

export type ModelProjectConditionInput = {
  project_id?: ModelIntInput | null,
  project_code?: ModelStringInput | null,
  name?: ModelStringInput | null,
  sector?: ModelPROGRAM_SECTORSInput | null,
  description?: ModelStringInput | null,
  donor?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
};

export type UpdateProjectInput = {
  id: string,
  project_id?: number | null,
  project_code?: string | null,
  name?: string | null,
  sector?: PROGRAM_SECTORS | null,
  description?: string | null,
  donor?: string | null,
  start_date?: string | null,
  end_date?: string | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateDioceseInput = {
  id?: string | null,
  diocese_id?: number | null,
  diocese_code: string,
  name: string,
  description?: string | null,
};

export type ModelDioceseConditionInput = {
  diocese_id?: ModelIntInput | null,
  diocese_code?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDioceseConditionInput | null > | null,
  or?: Array< ModelDioceseConditionInput | null > | null,
  not?: ModelDioceseConditionInput | null,
};

export type UpdateDioceseInput = {
  id: string,
  diocese_id?: number | null,
  diocese_code?: string | null,
  name?: string | null,
  description?: string | null,
};

export type DeleteDioceseInput = {
  id: string,
};

export type CreateProjectDioceseInput = {
  id?: string | null,
  project_diocese_id?: number | null,
  projectID: string,
  dioceseID: string,
  project_diocese_code: string,
};

export type ModelProjectDioceseConditionInput = {
  project_diocese_id?: ModelIntInput | null,
  projectID?: ModelIDInput | null,
  dioceseID?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelProjectDioceseConditionInput | null > | null,
  or?: Array< ModelProjectDioceseConditionInput | null > | null,
  not?: ModelProjectDioceseConditionInput | null,
};

export type UpdateProjectDioceseInput = {
  id: string,
  project_diocese_id?: number | null,
  projectID?: string | null,
  dioceseID?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteProjectDioceseInput = {
  id: string,
};

export type CreateTownshipInput = {
  id?: string | null,
  ts_pcode?: string | null,
  d_pcode?: string | null,
  sr_pcode?: string | null,
  name: string,
  name_mm?: string | null,
  diocese_id?: string | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type ModelTownshipConditionInput = {
  ts_pcode?: ModelStringInput | null,
  d_pcode?: ModelStringInput | null,
  sr_pcode?: ModelStringInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  diocese_id?: ModelIDInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelTownshipConditionInput | null > | null,
  or?: Array< ModelTownshipConditionInput | null > | null,
  not?: ModelTownshipConditionInput | null,
};

export type UpdateTownshipInput = {
  id: string,
  ts_pcode?: string | null,
  d_pcode?: string | null,
  sr_pcode?: string | null,
  name?: string | null,
  name_mm?: string | null,
  diocese_id?: string | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type DeleteTownshipInput = {
  id: string,
};

export type CreateVillageTractInput = {
  id?: string | null,
  vt_pcode?: string | null,
  township_id: string,
  name: string,
  name_mm?: string | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type ModelVillageTractConditionInput = {
  vt_pcode?: ModelStringInput | null,
  township_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelVillageTractConditionInput | null > | null,
  or?: Array< ModelVillageTractConditionInput | null > | null,
  not?: ModelVillageTractConditionInput | null,
};

export type UpdateVillageTractInput = {
  id: string,
  vt_pcode?: string | null,
  township_id?: string | null,
  name?: string | null,
  name_mm?: string | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type DeleteVillageTractInput = {
  id: string,
};

export type CreateVillageInput = {
  id?: string | null,
  village_pcode?: string | null,
  villageTract_id: string,
  name: string,
  name_mm?: string | null,
  alt_name?: string | null,
  alt_name_mm?: string | null,
  longitude?: number | null,
  latitude?: number | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type ModelVillageConditionInput = {
  village_pcode?: ModelStringInput | null,
  villageTract_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  alt_name?: ModelStringInput | null,
  alt_name_mm?: ModelStringInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelVillageConditionInput | null > | null,
  or?: Array< ModelVillageConditionInput | null > | null,
  not?: ModelVillageConditionInput | null,
};

export type UpdateVillageInput = {
  id: string,
  village_pcode?: string | null,
  villageTract_id?: string | null,
  name?: string | null,
  name_mm?: string | null,
  alt_name?: string | null,
  alt_name_mm?: string | null,
  longitude?: number | null,
  latitude?: number | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type DeleteVillageInput = {
  id: string,
};

export type CreateCampInput = {
  id?: string | null,
  camp_id?: number | null,
  township_id: string,
  name: string,
  name_mm?: string | null,
  description?: string | null,
  longitude?: number | null,
  latitude?: number | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type ModelCampConditionInput = {
  camp_id?: ModelIntInput | null,
  township_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  name_mm?: ModelStringInput | null,
  description?: ModelStringInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  diocese_code?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelCampConditionInput | null > | null,
  or?: Array< ModelCampConditionInput | null > | null,
  not?: ModelCampConditionInput | null,
};

export type UpdateCampInput = {
  id: string,
  camp_id?: number | null,
  township_id?: string | null,
  name?: string | null,
  name_mm?: string | null,
  description?: string | null,
  longitude?: number | null,
  latitude?: number | null,
  diocese_code?: string | null,
  active?: boolean | null,
};

export type DeleteCampInput = {
  id: string,
};

export type CreateCareGroupInput = {
  id?: string | null,
  care_group_id?: number | null,
  group_number: string,
  project_diocese_id: string,
  project_diocese_code: string,
  township_id: string,
};

export type ModelCareGroupConditionInput = {
  care_group_id?: ModelIntInput | null,
  group_number?: ModelStringInput | null,
  project_diocese_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  township_id?: ModelIDInput | null,
  and?: Array< ModelCareGroupConditionInput | null > | null,
  or?: Array< ModelCareGroupConditionInput | null > | null,
  not?: ModelCareGroupConditionInput | null,
};

export type UpdateCareGroupInput = {
  id: string,
  care_group_id?: number | null,
  group_number?: string | null,
  project_diocese_id?: string | null,
  project_diocese_code?: string | null,
  township_id?: string | null,
};

export type DeleteCareGroupInput = {
  id: string,
};

export type CreatePositionInput = {
  id?: string | null,
  position_id?: number | null,
  name: string,
  level?: POSITION_LEVELS | null,
  description?: string | null,
};

export type ModelPositionConditionInput = {
  position_id?: ModelIntInput | null,
  name?: ModelStringInput | null,
  level?: ModelPOSITION_LEVELSInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPositionConditionInput | null > | null,
  or?: Array< ModelPositionConditionInput | null > | null,
  not?: ModelPositionConditionInput | null,
};

export type ModelPOSITION_LEVELSInput = {
  eq?: POSITION_LEVELS | null,
  ne?: POSITION_LEVELS | null,
};

export type UpdatePositionInput = {
  id: string,
  position_id?: number | null,
  name?: string | null,
  level?: POSITION_LEVELS | null,
  description?: string | null,
};

export type DeletePositionInput = {
  id: string,
};

export type CreateStaffInput = {
  id?: string | null,
  staff_id?: number | null,
  project_diocese_id: string,
  position_id: string,
  current_position?: boolean | null,
  from_date?: string | null,
  to_date?: string | null,
  email: string,
  first_name?: string | null,
  last_name?: string | null,
  gender?: GENDER | null,
  is_staff?: boolean | null,
  is_active?: boolean | null,
  date_joined?: string | null,
};

export type ModelStaffConditionInput = {
  staff_id?: ModelIntInput | null,
  project_diocese_id?: ModelIDInput | null,
  position_id?: ModelIDInput | null,
  current_position?: ModelBooleanInput | null,
  from_date?: ModelStringInput | null,
  to_date?: ModelStringInput | null,
  email?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  gender?: ModelGENDERInput | null,
  is_staff?: ModelBooleanInput | null,
  is_active?: ModelBooleanInput | null,
  date_joined?: ModelStringInput | null,
  and?: Array< ModelStaffConditionInput | null > | null,
  or?: Array< ModelStaffConditionInput | null > | null,
  not?: ModelStaffConditionInput | null,
};

export type UpdateStaffInput = {
  id: string,
  staff_id?: number | null,
  project_diocese_id?: string | null,
  position_id?: string | null,
  current_position?: boolean | null,
  from_date?: string | null,
  to_date?: string | null,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  gender?: GENDER | null,
  is_staff?: boolean | null,
  is_active?: boolean | null,
  date_joined?: string | null,
};

export type DeleteStaffInput = {
  id: string,
};

export type CreateStaffCareGroupInput = {
  id?: string | null,
  staff_caregroup_id?: number | null,
  care_group_id: string,
  staff_id: string,
  current_assignment?: boolean | null,
  start_date?: string | null,
  end_date?: string | null,
};

export type ModelStaffCareGroupConditionInput = {
  staff_caregroup_id?: ModelIntInput | null,
  care_group_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  current_assignment?: ModelBooleanInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  and?: Array< ModelStaffCareGroupConditionInput | null > | null,
  or?: Array< ModelStaffCareGroupConditionInput | null > | null,
  not?: ModelStaffCareGroupConditionInput | null,
};

export type UpdateStaffCareGroupInput = {
  id: string,
  staff_caregroup_id?: number | null,
  care_group_id?: string | null,
  staff_id?: string | null,
  current_assignment?: boolean | null,
  start_date?: string | null,
  end_date?: string | null,
};

export type DeleteStaffCareGroupInput = {
  id: string,
};

export type CreateNeighborGroupInput = {
  id?: string | null,
  neighbor_group_id?: number | null,
  group_number: string,
  project_diocese_code: string,
  care_group_id: string,
  village_id?: string | null,
  camp_id?: string | null,
};

export type ModelNeighborGroupConditionInput = {
  neighbor_group_id?: ModelIntInput | null,
  group_number?: ModelStringInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  village_id?: ModelIDInput | null,
  camp_id?: ModelIDInput | null,
  and?: Array< ModelNeighborGroupConditionInput | null > | null,
  or?: Array< ModelNeighborGroupConditionInput | null > | null,
  not?: ModelNeighborGroupConditionInput | null,
};

export type UpdateNeighborGroupInput = {
  id: string,
  neighbor_group_id?: number | null,
  group_number?: string | null,
  project_diocese_code?: string | null,
  care_group_id?: string | null,
  village_id?: string | null,
  camp_id?: string | null,
};

export type DeleteNeighborGroupInput = {
  id: string,
};

export type CreateEducationLevelInput = {
  id?: string | null,
  education_level_id?: number | null,
  education_level: string,
  education_level_mm?: string | null,
  description?: string | null,
  order?: number | null,
};

export type ModelEducationLevelConditionInput = {
  education_level_id?: ModelIntInput | null,
  education_level?: ModelStringInput | null,
  education_level_mm?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelEducationLevelConditionInput | null > | null,
  or?: Array< ModelEducationLevelConditionInput | null > | null,
  not?: ModelEducationLevelConditionInput | null,
};

export type UpdateEducationLevelInput = {
  id: string,
  education_level_id?: number | null,
  education_level?: string | null,
  education_level_mm?: string | null,
  description?: string | null,
  order?: number | null,
};

export type DeleteEducationLevelInput = {
  id: string,
};

export type CreateOccupationTypeInput = {
  id?: string | null,
  occupation_type_id?: number | null,
  occupation_type: string,
  occupation_type_mm?: string | null,
  description?: string | null,
  order?: number | null,
};

export type ModelOccupationTypeConditionInput = {
  occupation_type_id?: ModelIntInput | null,
  occupation_type?: ModelStringInput | null,
  occupation_type_mm?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelOccupationTypeConditionInput | null > | null,
  or?: Array< ModelOccupationTypeConditionInput | null > | null,
  not?: ModelOccupationTypeConditionInput | null,
};

export type UpdateOccupationTypeInput = {
  id: string,
  occupation_type_id?: number | null,
  occupation_type?: string | null,
  occupation_type_mm?: string | null,
  description?: string | null,
  order?: number | null,
};

export type DeleteOccupationTypeInput = {
  id: string,
};

export type CreateDisabilityTypeInput = {
  id?: string | null,
  disability_type_id?: number | null,
  disability_type: string,
  description: string,
};

export type ModelDisabilityTypeConditionInput = {
  disability_type_id?: ModelIntInput | null,
  disability_type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDisabilityTypeConditionInput | null > | null,
  or?: Array< ModelDisabilityTypeConditionInput | null > | null,
  not?: ModelDisabilityTypeConditionInput | null,
};

export type DisabilityType = {
  __typename: "DisabilityType",
  id: string,
  disability_type_id?: number | null,
  disability_type: string,
  description: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDisabilityTypeInput = {
  id: string,
  disability_type_id?: number | null,
  disability_type?: string | null,
  description?: string | null,
};

export type DeleteDisabilityTypeInput = {
  id: string,
};

export type CreatePLWInput = {
  id?: string | null,
  plw_id?: number | null,
  member_number: number,
  neighbor_group_id: string,
  project_diocese_code: string,
  date_of_registry?: string | null,
  lead_mother?: boolean | null,
  name: string,
  father_name?: string | null,
  date_of_birth?: string | null,
  education_level_id?: string | null,
  occupation_type_id?: string | null,
  disabled?: boolean | null,
  disability_type?: Array< string | null > | null,
  disability_type_other_description?: string | null,
  contact_number?: string | null,
  date_of_death?: string | null,
  lost_contact?: boolean | null,
};

export type ModelPLWConditionInput = {
  plw_id?: ModelIntInput | null,
  member_number?: ModelIntInput | null,
  neighbor_group_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  date_of_registry?: ModelStringInput | null,
  lead_mother?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  father_name?: ModelStringInput | null,
  date_of_birth?: ModelStringInput | null,
  education_level_id?: ModelIDInput | null,
  occupation_type_id?: ModelIDInput | null,
  disabled?: ModelBooleanInput | null,
  disability_type?: ModelIDInput | null,
  disability_type_other_description?: ModelStringInput | null,
  contact_number?: ModelStringInput | null,
  date_of_death?: ModelStringInput | null,
  lost_contact?: ModelBooleanInput | null,
  and?: Array< ModelPLWConditionInput | null > | null,
  or?: Array< ModelPLWConditionInput | null > | null,
  not?: ModelPLWConditionInput | null,
};

export type UpdatePLWInput = {
  id: string,
  plw_id?: number | null,
  member_number?: number | null,
  neighbor_group_id?: string | null,
  project_diocese_code?: string | null,
  date_of_registry?: string | null,
  lead_mother?: boolean | null,
  name?: string | null,
  father_name?: string | null,
  date_of_birth?: string | null,
  education_level_id?: string | null,
  occupation_type_id?: string | null,
  disabled?: boolean | null,
  disability_type?: Array< string | null > | null,
  disability_type_other_description?: string | null,
  contact_number?: string | null,
  date_of_death?: string | null,
  lost_contact?: boolean | null,
};

export type DeletePLWInput = {
  id: string,
};

export type CreateBirthHistoryInput = {
  id?: string | null,
  birth_history_id?: number | null,
  plw_id: string,
  project_diocese_code: string,
  lmp?: string | null,
  miscarriage_date?: string | null,
  delivered_date?: string | null,
  child_name?: string | null,
  child_gender?: GENDER | null,
  date_of_death?: string | null,
};

export type ModelBirthHistoryConditionInput = {
  birth_history_id?: ModelIntInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  lmp?: ModelStringInput | null,
  miscarriage_date?: ModelStringInput | null,
  delivered_date?: ModelStringInput | null,
  child_name?: ModelStringInput | null,
  child_gender?: ModelGENDERInput | null,
  date_of_death?: ModelStringInput | null,
  and?: Array< ModelBirthHistoryConditionInput | null > | null,
  or?: Array< ModelBirthHistoryConditionInput | null > | null,
  not?: ModelBirthHistoryConditionInput | null,
};

export type UpdateBirthHistoryInput = {
  id: string,
  birth_history_id?: number | null,
  plw_id?: string | null,
  project_diocese_code?: string | null,
  lmp?: string | null,
  miscarriage_date?: string | null,
  delivered_date?: string | null,
  child_name?: string | null,
  child_gender?: GENDER | null,
  date_of_death?: string | null,
};

export type DeleteBirthHistoryInput = {
  id: string,
};

export type CreateNutritionTopicInput = {
  id?: string | null,
  nutrition_topic_id?: number | null,
  topic_text: string,
  topic_text_myanmar?: string | null,
  description?: string | null,
  projects?: Array< string | null > | null,
};

export type ModelNutritionTopicConditionInput = {
  nutrition_topic_id?: ModelIntInput | null,
  topic_text?: ModelStringInput | null,
  topic_text_myanmar?: ModelStringInput | null,
  description?: ModelStringInput | null,
  projects?: ModelIDInput | null,
  and?: Array< ModelNutritionTopicConditionInput | null > | null,
  or?: Array< ModelNutritionTopicConditionInput | null > | null,
  not?: ModelNutritionTopicConditionInput | null,
};

export type UpdateNutritionTopicInput = {
  id: string,
  nutrition_topic_id?: number | null,
  topic_text?: string | null,
  topic_text_myanmar?: string | null,
  description?: string | null,
  projects?: Array< string | null > | null,
};

export type DeleteNutritionTopicInput = {
  id: string,
};

export type CreateNutritionMessageInput = {
  id?: string | null,
  nutrition_message_id?: number | null,
  nutrition_topic_id: string,
  message_text: string,
  message_text_myanmar?: string | null,
  description?: string | null,
};

export type ModelNutritionMessageConditionInput = {
  nutrition_message_id?: ModelIntInput | null,
  nutrition_topic_id?: ModelIDInput | null,
  message_text?: ModelStringInput | null,
  message_text_myanmar?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNutritionMessageConditionInput | null > | null,
  or?: Array< ModelNutritionMessageConditionInput | null > | null,
  not?: ModelNutritionMessageConditionInput | null,
};

export type UpdateNutritionMessageInput = {
  id: string,
  nutrition_message_id?: number | null,
  nutrition_topic_id?: string | null,
  message_text?: string | null,
  message_text_myanmar?: string | null,
  description?: string | null,
};

export type DeleteNutritionMessageInput = {
  id: string,
};

export type CreateNutritionQuestionInput = {
  id?: string | null,
  nutrition_question_id?: number | null,
  nutrition_message_id: string,
  question_text: string,
  question_text_myanmar?: string | null,
  description?: string | null,
};

export type ModelNutritionQuestionConditionInput = {
  nutrition_question_id?: ModelIntInput | null,
  nutrition_message_id?: ModelIDInput | null,
  question_text?: ModelStringInput | null,
  question_text_myanmar?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNutritionQuestionConditionInput | null > | null,
  or?: Array< ModelNutritionQuestionConditionInput | null > | null,
  not?: ModelNutritionQuestionConditionInput | null,
};

export type UpdateNutritionQuestionInput = {
  id: string,
  nutrition_question_id?: number | null,
  nutrition_message_id?: string | null,
  question_text?: string | null,
  question_text_myanmar?: string | null,
  description?: string | null,
};

export type DeleteNutritionQuestionInput = {
  id: string,
};

export type CreateCGMeetingInput = {
  id?: string | null,
  cg_meeting_id?: number | null,
  project_diocese_code: string,
  care_group_id: string,
  meeting_date?: string | null,
  description?: string | null,
};

export type ModelCGMeetingConditionInput = {
  cg_meeting_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  meeting_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCGMeetingConditionInput | null > | null,
  or?: Array< ModelCGMeetingConditionInput | null > | null,
  not?: ModelCGMeetingConditionInput | null,
};

export type UpdateCGMeetingInput = {
  id: string,
  cg_meeting_id?: number | null,
  project_diocese_code?: string | null,
  care_group_id?: string | null,
  meeting_date?: string | null,
  description?: string | null,
};

export type DeleteCGMeetingInput = {
  id: string,
};

export type CreateCGMeetingNutritionTopicInput = {
  id?: string | null,
  cg_meeting_id: string,
  nutrition_topic_id: string,
  project_diocese_code: string,
};

export type ModelCGMeetingNutritionTopicConditionInput = {
  cg_meeting_id?: ModelIDInput | null,
  nutrition_topic_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelCGMeetingNutritionTopicConditionInput | null > | null,
  or?: Array< ModelCGMeetingNutritionTopicConditionInput | null > | null,
  not?: ModelCGMeetingNutritionTopicConditionInput | null,
};

export type UpdateCGMeetingNutritionTopicInput = {
  id: string,
  cg_meeting_id?: string | null,
  nutrition_topic_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteCGMeetingNutritionTopicInput = {
  id: string,
};

export type CreateCGMeetingStaffInput = {
  id?: string | null,
  cg_meeting_id: string,
  staff_id: string,
  project_diocese_code: string,
};

export type ModelCGMeetingStaffConditionInput = {
  cg_meeting_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelCGMeetingStaffConditionInput | null > | null,
  or?: Array< ModelCGMeetingStaffConditionInput | null > | null,
  not?: ModelCGMeetingStaffConditionInput | null,
};

export type UpdateCGMeetingStaffInput = {
  id: string,
  cg_meeting_id?: string | null,
  staff_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteCGMeetingStaffInput = {
  id: string,
};

export type CreateCGMeetingPLWInput = {
  id?: string | null,
  cg_meeting_id: string,
  plw_id: string,
  project_diocese_code: string,
};

export type ModelCGMeetingPLWConditionInput = {
  cg_meeting_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelCGMeetingPLWConditionInput | null > | null,
  or?: Array< ModelCGMeetingPLWConditionInput | null > | null,
  not?: ModelCGMeetingPLWConditionInput | null,
};

export type UpdateCGMeetingPLWInput = {
  id: string,
  cg_meeting_id?: string | null,
  plw_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteCGMeetingPLWInput = {
  id: string,
};

export type CreateNGMeetingInput = {
  id?: string | null,
  ng_meeting_id?: number | null,
  project_diocese_code: string,
  neighbor_group_id: string,
  plw_facilitator_id: string,
  meeting_date?: string | null,
  description?: string | null,
};

export type ModelNGMeetingConditionInput = {
  ng_meeting_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  neighbor_group_id?: ModelIDInput | null,
  plw_facilitator_id?: ModelIDInput | null,
  meeting_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNGMeetingConditionInput | null > | null,
  or?: Array< ModelNGMeetingConditionInput | null > | null,
  not?: ModelNGMeetingConditionInput | null,
};

export type UpdateNGMeetingInput = {
  id: string,
  ng_meeting_id?: number | null,
  project_diocese_code?: string | null,
  neighbor_group_id?: string | null,
  plw_facilitator_id?: string | null,
  meeting_date?: string | null,
  description?: string | null,
};

export type DeleteNGMeetingInput = {
  id: string,
};

export type CreateNGMeetingNutritionTopicInput = {
  id?: string | null,
  ng_meeting_id: string,
  nutrition_topic_id: string,
  project_diocese_code: string,
};

export type ModelNGMeetingNutritionTopicConditionInput = {
  ng_meeting_id?: ModelIDInput | null,
  nutrition_topic_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelNGMeetingNutritionTopicConditionInput | null > | null,
  or?: Array< ModelNGMeetingNutritionTopicConditionInput | null > | null,
  not?: ModelNGMeetingNutritionTopicConditionInput | null,
};

export type UpdateNGMeetingNutritionTopicInput = {
  id: string,
  ng_meeting_id?: string | null,
  nutrition_topic_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteNGMeetingNutritionTopicInput = {
  id: string,
};

export type CreateNGMeetingStaffFacilitatorInput = {
  id?: string | null,
  ng_meeting_id: string,
  staff_id: string,
  project_diocese_code: string,
};

export type ModelNGMeetingStaffFacilitatorConditionInput = {
  ng_meeting_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelNGMeetingStaffFacilitatorConditionInput | null > | null,
  or?: Array< ModelNGMeetingStaffFacilitatorConditionInput | null > | null,
  not?: ModelNGMeetingStaffFacilitatorConditionInput | null,
};

export type UpdateNGMeetingStaffFacilitatorInput = {
  id: string,
  ng_meeting_id?: string | null,
  staff_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteNGMeetingStaffFacilitatorInput = {
  id: string,
};

export type CreateNGMeetingPLWInput = {
  id?: string | null,
  ng_meeting_id: string,
  plw_id: string,
  project_diocese_code: string,
};

export type ModelNGMeetingPLWConditionInput = {
  ng_meeting_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelNGMeetingPLWConditionInput | null > | null,
  or?: Array< ModelNGMeetingPLWConditionInput | null > | null,
  not?: ModelNGMeetingPLWConditionInput | null,
};

export type UpdateNGMeetingPLWInput = {
  id: string,
  ng_meeting_id?: string | null,
  plw_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteNGMeetingPLWInput = {
  id: string,
};

export type CreateDistributionItemInput = {
  id?: string | null,
  distribution_item_id?: number | null,
  project_id: string,
  project_code: string,
  title: string,
  description?: string | null,
  planned_distribution_start_date?: string | null,
  planned_distribution_end_date?: string | null,
};

export type ModelDistributionItemConditionInput = {
  distribution_item_id?: ModelIntInput | null,
  project_id?: ModelIDInput | null,
  project_code?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  planned_distribution_start_date?: ModelStringInput | null,
  planned_distribution_end_date?: ModelStringInput | null,
  and?: Array< ModelDistributionItemConditionInput | null > | null,
  or?: Array< ModelDistributionItemConditionInput | null > | null,
  not?: ModelDistributionItemConditionInput | null,
};

export type UpdateDistributionItemInput = {
  id: string,
  distribution_item_id?: number | null,
  project_id?: string | null,
  project_code?: string | null,
  title?: string | null,
  description?: string | null,
  planned_distribution_start_date?: string | null,
  planned_distribution_end_date?: string | null,
};

export type DeleteDistributionItemInput = {
  id: string,
};

export type CreateDistributionInput = {
  id?: string | null,
  distribution_id?: number | null,
  project_diocese_code: string,
  distribution_item_id: string,
  neighbor_group_id: string,
  distribution_date?: string | null,
  description?: string | null,
};

export type ModelDistributionConditionInput = {
  distribution_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  distribution_item_id?: ModelIDInput | null,
  neighbor_group_id?: ModelIDInput | null,
  distribution_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDistributionConditionInput | null > | null,
  or?: Array< ModelDistributionConditionInput | null > | null,
  not?: ModelDistributionConditionInput | null,
};

export type UpdateDistributionInput = {
  id: string,
  distribution_id?: number | null,
  project_diocese_code?: string | null,
  distribution_item_id?: string | null,
  neighbor_group_id?: string | null,
  distribution_date?: string | null,
  description?: string | null,
};

export type DeleteDistributionInput = {
  id: string,
};

export type CreateDistributionRecipientsInput = {
  id?: string | null,
  distribution_id: string,
  plw_id: string,
  project_diocese_code: string,
};

export type ModelDistributionRecipientsConditionInput = {
  distribution_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelDistributionRecipientsConditionInput | null > | null,
  or?: Array< ModelDistributionRecipientsConditionInput | null > | null,
  not?: ModelDistributionRecipientsConditionInput | null,
};

export type UpdateDistributionRecipientsInput = {
  id: string,
  distribution_id?: string | null,
  plw_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteDistributionRecipientsInput = {
  id: string,
};

export type CreateOtherActivityTypeInput = {
  id?: string | null,
  other_activity_type_id?: number | null,
  project_id: string,
  project_code: string,
  activity_name: string,
  description?: string | null,
};

export type ModelOtherActivityTypeConditionInput = {
  other_activity_type_id?: ModelIntInput | null,
  project_id?: ModelIDInput | null,
  project_code?: ModelStringInput | null,
  activity_name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelOtherActivityTypeConditionInput | null > | null,
  or?: Array< ModelOtherActivityTypeConditionInput | null > | null,
  not?: ModelOtherActivityTypeConditionInput | null,
};

export type UpdateOtherActivityTypeInput = {
  id: string,
  other_activity_type_id?: number | null,
  project_id?: string | null,
  project_code?: string | null,
  activity_name?: string | null,
  description?: string | null,
};

export type DeleteOtherActivityTypeInput = {
  id: string,
};

export type CreateOtherActivityInput = {
  id?: string | null,
  other_activity_id?: number | null,
  project_diocese_code: string,
  care_group_id: string,
  other_activity_type_id: string,
  activity_date: string,
  description?: string | null,
};

export type ModelOtherActivityConditionInput = {
  other_activity_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  other_activity_type_id?: ModelIDInput | null,
  activity_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelOtherActivityConditionInput | null > | null,
  or?: Array< ModelOtherActivityConditionInput | null > | null,
  not?: ModelOtherActivityConditionInput | null,
};

export type UpdateOtherActivityInput = {
  id: string,
  other_activity_id?: number | null,
  project_diocese_code?: string | null,
  care_group_id?: string | null,
  other_activity_type_id?: string | null,
  activity_date?: string | null,
  description?: string | null,
};

export type DeleteOtherActivityInput = {
  id: string,
};

export type CreateOtherActivityFacilitatorsInput = {
  id?: string | null,
  other_activity_id: string,
  staff_id: string,
  project_diocese_code: string,
};

export type ModelOtherActivityFacilitatorsConditionInput = {
  other_activity_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelOtherActivityFacilitatorsConditionInput | null > | null,
  or?: Array< ModelOtherActivityFacilitatorsConditionInput | null > | null,
  not?: ModelOtherActivityFacilitatorsConditionInput | null,
};

export type UpdateOtherActivityFacilitatorsInput = {
  id: string,
  other_activity_id?: string | null,
  staff_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteOtherActivityFacilitatorsInput = {
  id: string,
};

export type CreateOtherActivityParticipantsInput = {
  id?: string | null,
  other_activity_id: string,
  plw_id: string,
  project_diocese_code: string,
};

export type ModelOtherActivityParticipantsConditionInput = {
  other_activity_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelOtherActivityParticipantsConditionInput | null > | null,
  or?: Array< ModelOtherActivityParticipantsConditionInput | null > | null,
  not?: ModelOtherActivityParticipantsConditionInput | null,
};

export type UpdateOtherActivityParticipantsInput = {
  id: string,
  other_activity_id?: string | null,
  plw_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteOtherActivityParticipantsInput = {
  id: string,
};

export type CreateHgTrainingTopicInput = {
  id?: string | null,
  hg_training_topic_id?: number | null,
  project_id: string,
  project_code: string,
  topic: string,
  description?: string | null,
};

export type ModelHgTrainingTopicConditionInput = {
  hg_training_topic_id?: ModelIntInput | null,
  project_id?: ModelIDInput | null,
  project_code?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelHgTrainingTopicConditionInput | null > | null,
  or?: Array< ModelHgTrainingTopicConditionInput | null > | null,
  not?: ModelHgTrainingTopicConditionInput | null,
};

export type UpdateHgTrainingTopicInput = {
  id: string,
  hg_training_topic_id?: number | null,
  project_id?: string | null,
  project_code?: string | null,
  topic?: string | null,
  description?: string | null,
};

export type DeleteHgTrainingTopicInput = {
  id: string,
};

export type CreateHgTrainingInput = {
  id?: string | null,
  hg_training_id?: number | null,
  project_diocese_code: string,
  care_group_id: string,
  training_date: string,
  description?: string | null,
};

export type ModelHgTrainingConditionInput = {
  hg_training_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  training_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelHgTrainingConditionInput | null > | null,
  or?: Array< ModelHgTrainingConditionInput | null > | null,
  not?: ModelHgTrainingConditionInput | null,
};

export type UpdateHgTrainingInput = {
  id: string,
  hg_training_id?: number | null,
  project_diocese_code?: string | null,
  care_group_id?: string | null,
  training_date?: string | null,
  description?: string | null,
};

export type DeleteHgTrainingInput = {
  id: string,
};

export type CreateHgTrainingTrainingTopicInput = {
  id?: string | null,
  hg_training_id: string,
  hg_training_topic_id: string,
  project_diocese_code: string,
};

export type ModelHgTrainingTrainingTopicConditionInput = {
  hg_training_id?: ModelIDInput | null,
  hg_training_topic_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelHgTrainingTrainingTopicConditionInput | null > | null,
  or?: Array< ModelHgTrainingTrainingTopicConditionInput | null > | null,
  not?: ModelHgTrainingTrainingTopicConditionInput | null,
};

export type UpdateHgTrainingTrainingTopicInput = {
  id: string,
  hg_training_id?: string | null,
  hg_training_topic_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteHgTrainingTrainingTopicInput = {
  id: string,
};

export type CreateHgTrainingFacilitatorsInput = {
  id?: string | null,
  hg_training_id: string,
  staff_id: string,
  project_diocese_code: string,
};

export type ModelHgTrainingFacilitatorsConditionInput = {
  hg_training_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelHgTrainingFacilitatorsConditionInput | null > | null,
  or?: Array< ModelHgTrainingFacilitatorsConditionInput | null > | null,
  not?: ModelHgTrainingFacilitatorsConditionInput | null,
};

export type UpdateHgTrainingFacilitatorsInput = {
  id: string,
  hg_training_id?: string | null,
  staff_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteHgTrainingFacilitatorsInput = {
  id: string,
};

export type CreateHgTrainingParticipantsInput = {
  id?: string | null,
  hg_training_id: string,
  plw_id: string,
  project_diocese_code: string,
};

export type ModelHgTrainingParticipantsConditionInput = {
  hg_training_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelHgTrainingParticipantsConditionInput | null > | null,
  or?: Array< ModelHgTrainingParticipantsConditionInput | null > | null,
  not?: ModelHgTrainingParticipantsConditionInput | null,
};

export type UpdateHgTrainingParticipantsInput = {
  id: string,
  hg_training_id?: string | null,
  plw_id?: string | null,
  project_diocese_code?: string | null,
};

export type DeleteHgTrainingParticipantsInput = {
  id: string,
};

export type ModelPositionFilterInput = {
  id?: ModelIDInput | null,
  position_id?: ModelIntInput | null,
  name?: ModelStringInput | null,
  level?: ModelPOSITION_LEVELSInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPositionFilterInput | null > | null,
  or?: Array< ModelPositionFilterInput | null > | null,
  not?: ModelPositionFilterInput | null,
};

export type ModelPositionConnection = {
  __typename: "ModelPositionConnection",
  items:  Array<Position | null >,
  nextToken?: string | null,
};

export type ModelStaffCareGroupFilterInput = {
  id?: ModelIDInput | null,
  staff_caregroup_id?: ModelIntInput | null,
  care_group_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  current_assignment?: ModelBooleanInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  and?: Array< ModelStaffCareGroupFilterInput | null > | null,
  or?: Array< ModelStaffCareGroupFilterInput | null > | null,
  not?: ModelStaffCareGroupFilterInput | null,
};

export type ModelEducationLevelFilterInput = {
  id?: ModelIDInput | null,
  education_level_id?: ModelIntInput | null,
  education_level?: ModelStringInput | null,
  education_level_mm?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelEducationLevelFilterInput | null > | null,
  or?: Array< ModelEducationLevelFilterInput | null > | null,
  not?: ModelEducationLevelFilterInput | null,
};

export type ModelEducationLevelConnection = {
  __typename: "ModelEducationLevelConnection",
  items:  Array<EducationLevel | null >,
  nextToken?: string | null,
};

export type ModelOccupationTypeFilterInput = {
  id?: ModelIDInput | null,
  occupation_type_id?: ModelIntInput | null,
  occupation_type?: ModelStringInput | null,
  occupation_type_mm?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelOccupationTypeFilterInput | null > | null,
  or?: Array< ModelOccupationTypeFilterInput | null > | null,
  not?: ModelOccupationTypeFilterInput | null,
};

export type ModelOccupationTypeConnection = {
  __typename: "ModelOccupationTypeConnection",
  items:  Array<OccupationType | null >,
  nextToken?: string | null,
};

export type ModelDisabilityTypeFilterInput = {
  id?: ModelIDInput | null,
  disability_type_id?: ModelIntInput | null,
  disability_type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDisabilityTypeFilterInput | null > | null,
  or?: Array< ModelDisabilityTypeFilterInput | null > | null,
  not?: ModelDisabilityTypeFilterInput | null,
};

export type ModelDisabilityTypeConnection = {
  __typename: "ModelDisabilityTypeConnection",
  items:  Array<DisabilityType | null >,
  nextToken?: string | null,
};

export type ModelBirthHistoryFilterInput = {
  id?: ModelIDInput | null,
  birth_history_id?: ModelIntInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  lmp?: ModelStringInput | null,
  miscarriage_date?: ModelStringInput | null,
  delivered_date?: ModelStringInput | null,
  child_name?: ModelStringInput | null,
  child_gender?: ModelGENDERInput | null,
  date_of_death?: ModelStringInput | null,
  and?: Array< ModelBirthHistoryFilterInput | null > | null,
  or?: Array< ModelBirthHistoryFilterInput | null > | null,
  not?: ModelBirthHistoryFilterInput | null,
};

export type ModelNutritionMessageFilterInput = {
  id?: ModelIDInput | null,
  nutrition_message_id?: ModelIntInput | null,
  nutrition_topic_id?: ModelIDInput | null,
  message_text?: ModelStringInput | null,
  message_text_myanmar?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNutritionMessageFilterInput | null > | null,
  or?: Array< ModelNutritionMessageFilterInput | null > | null,
  not?: ModelNutritionMessageFilterInput | null,
};

export type ModelNutritionQuestionFilterInput = {
  id?: ModelIDInput | null,
  nutrition_question_id?: ModelIntInput | null,
  nutrition_message_id?: ModelIDInput | null,
  question_text?: ModelStringInput | null,
  question_text_myanmar?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNutritionQuestionFilterInput | null > | null,
  or?: Array< ModelNutritionQuestionFilterInput | null > | null,
  not?: ModelNutritionQuestionFilterInput | null,
};

export type ModelCGMeetingStaffFilterInput = {
  id?: ModelIDInput | null,
  cg_meeting_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelCGMeetingStaffFilterInput | null > | null,
  or?: Array< ModelCGMeetingStaffFilterInput | null > | null,
  not?: ModelCGMeetingStaffFilterInput | null,
};

export type ModelNGMeetingNutritionTopicFilterInput = {
  id?: ModelIDInput | null,
  ng_meeting_id?: ModelIDInput | null,
  nutrition_topic_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelNGMeetingNutritionTopicFilterInput | null > | null,
  or?: Array< ModelNGMeetingNutritionTopicFilterInput | null > | null,
  not?: ModelNGMeetingNutritionTopicFilterInput | null,
};

export type ModelNGMeetingStaffFacilitatorFilterInput = {
  id?: ModelIDInput | null,
  ng_meeting_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelNGMeetingStaffFacilitatorFilterInput | null > | null,
  or?: Array< ModelNGMeetingStaffFacilitatorFilterInput | null > | null,
  not?: ModelNGMeetingStaffFacilitatorFilterInput | null,
};

export type ModelNGMeetingPLWFilterInput = {
  id?: ModelIDInput | null,
  ng_meeting_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelNGMeetingPLWFilterInput | null > | null,
  or?: Array< ModelNGMeetingPLWFilterInput | null > | null,
  not?: ModelNGMeetingPLWFilterInput | null,
};

export type ModelDistributionRecipientsFilterInput = {
  id?: ModelIDInput | null,
  distribution_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelDistributionRecipientsFilterInput | null > | null,
  or?: Array< ModelDistributionRecipientsFilterInput | null > | null,
  not?: ModelDistributionRecipientsFilterInput | null,
};

export type ModelOtherActivityFacilitatorsFilterInput = {
  id?: ModelIDInput | null,
  other_activity_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelOtherActivityFacilitatorsFilterInput | null > | null,
  or?: Array< ModelOtherActivityFacilitatorsFilterInput | null > | null,
  not?: ModelOtherActivityFacilitatorsFilterInput | null,
};

export type ModelOtherActivityParticipantsFilterInput = {
  id?: ModelIDInput | null,
  other_activity_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelOtherActivityParticipantsFilterInput | null > | null,
  or?: Array< ModelOtherActivityParticipantsFilterInput | null > | null,
  not?: ModelOtherActivityParticipantsFilterInput | null,
};

export type ModelHgTrainingTopicFilterInput = {
  id?: ModelIDInput | null,
  hg_training_topic_id?: ModelIntInput | null,
  project_id?: ModelIDInput | null,
  project_code?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelHgTrainingTopicFilterInput | null > | null,
  or?: Array< ModelHgTrainingTopicFilterInput | null > | null,
  not?: ModelHgTrainingTopicFilterInput | null,
};

export type ModelHgTrainingFilterInput = {
  id?: ModelIDInput | null,
  hg_training_id?: ModelIntInput | null,
  project_diocese_code?: ModelStringInput | null,
  care_group_id?: ModelIDInput | null,
  training_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelHgTrainingFilterInput | null > | null,
  or?: Array< ModelHgTrainingFilterInput | null > | null,
  not?: ModelHgTrainingFilterInput | null,
};

export type ModelHgTrainingTrainingTopicFilterInput = {
  id?: ModelIDInput | null,
  hg_training_id?: ModelIDInput | null,
  hg_training_topic_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelHgTrainingTrainingTopicFilterInput | null > | null,
  or?: Array< ModelHgTrainingTrainingTopicFilterInput | null > | null,
  not?: ModelHgTrainingTrainingTopicFilterInput | null,
};

export type ModelHgTrainingFacilitatorsFilterInput = {
  id?: ModelIDInput | null,
  hg_training_id?: ModelIDInput | null,
  staff_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelHgTrainingFacilitatorsFilterInput | null > | null,
  or?: Array< ModelHgTrainingFacilitatorsFilterInput | null > | null,
  not?: ModelHgTrainingFacilitatorsFilterInput | null,
};

export type ModelHgTrainingParticipantsFilterInput = {
  id?: ModelIDInput | null,
  hg_training_id?: ModelIDInput | null,
  plw_id?: ModelIDInput | null,
  project_diocese_code?: ModelStringInput | null,
  and?: Array< ModelHgTrainingParticipantsFilterInput | null > | null,
  or?: Array< ModelHgTrainingParticipantsFilterInput | null > | null,
  not?: ModelHgTrainingParticipantsFilterInput | null,
};

export type CampByTownshipCustomQueryVariables = {
  township_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CampByTownshipCustomQuery = {
  campByTownship?:  {
    __typename: "ModelCampConnection",
    items:  Array< {
      __typename: "Camp",
      id: string,
      name: string,
      name_mm?: string | null,
      active?: boolean | null,
    } | null >,
  } | null,
};

export type CareGroupByPdCodeCustomQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCareGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CareGroupByPdCodeCustomQuery = {
  careGroupByPdCode?:  {
    __typename: "ModelCareGroupConnection",
    items:  Array< {
      __typename: "CareGroup",
      id: string,
      group_number: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_code: string,
        project:  {
          __typename: "Project",
          id: string,
          project_code: string,
          name: string,
        },
        diocese:  {
          __typename: "Diocese",
          id: string,
          diocese_code: string,
          name: string,
        },
      },
      township:  {
        __typename: "Township",
        id: string,
        name: string,
        name_mm?: string | null,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        items:  Array< {
          __typename: "StaffCareGroup",
          current_assignment?: boolean | null,
          start_date?: string | null,
          end_date?: string | null,
          staff:  {
            __typename: "Staff",
            id: string,
            first_name?: string | null,
            last_name?: string | null,
            email: string,
            is_active?: boolean | null,
            position:  {
              __typename: "Position",
              id: string,
              name: string,
            },
          },
        } | null >,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        items:  Array< {
          __typename: "NeighborGroup",
          id: string,
          group_number: string,
          village?:  {
            __typename: "Village",
            id: string,
            name: string,
            name_mm?: string | null,
            villageTract:  {
              __typename: "VillageTract",
              id: string,
              name: string,
              name_mm?: string | null,
            },
          } | null,
          camp?:  {
            __typename: "Camp",
            id: string,
            name: string,
            name_mm?: string | null,
          } | null,
          plws?:  {
            __typename: "ModelPLWConnection",
            items:  Array< {
              __typename: "PLW",
              id: string,
              name: string,
              member_number: number,
              father_name?: string | null,
              education_level?:  {
                __typename: "EducationLevel",
                id: string,
                education_level: string,
                education_level_mm?: string | null,
              } | null,
              occupation_type?:  {
                __typename: "OccupationType",
                id: string,
                occupation_type: string,
                occupation_type_mm?: string | null,
              } | null,
              lead_mother?: boolean | null,
              lost_contact?: boolean | null,
              contact_number?: string | null,
              date_of_birth?: string | null,
              date_of_registry?: string | null,
              disabled?: boolean | null,
              birth_histories?:  {
                __typename: "ModelBirthHistoryConnection",
                items:  Array< {
                  __typename: "BirthHistory",
                  id: string,
                  lmp?: string | null,
                  miscarriage_date?: string | null,
                  delivered_date?: string | null,
                  child_name?: string | null,
                  child_gender?: GENDER | null,
                  date_of_death?: string | null,
                } | null >,
              } | null,
            } | null >,
          } | null,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CareGroupByPdCodeForGoogleMapQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCareGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CareGroupByPdCodeForGoogleMapQuery = {
  careGroupByPdCode?:  {
    __typename: "ModelCareGroupConnection",
    items:  Array< {
      __typename: "CareGroup",
      id: string,
      group_number: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_code: string,
        project:  {
          __typename: "Project",
          id: string,
          name: string,
        },
        diocese:  {
          __typename: "Diocese",
          id: string,
          name: string,
        },
      },
      township:  {
        __typename: "Township",
        id: string,
        name: string,
        name_mm?: string | null,
      },
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        items:  Array< {
          __typename: "NeighborGroup",
          id: string,
          group_number: string,
          village?:  {
            __typename: "Village",
            id: string,
            name: string,
            name_mm?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            villageTract:  {
              __typename: "VillageTract",
              id: string,
              name: string,
              name_mm?: string | null,
            },
          } | null,
          camp?:  {
            __typename: "Camp",
            id: string,
            name: string,
            name_mm?: string | null,
            latitude?: number | null,
            longitude?: number | null,
          } | null,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CareGroupByGroupNumberCustomQueryVariables = {
  group_number: string,
};

export type CareGroupByGroupNumberCustomQuery = {
  careGroupByGroupNumber?:  {
    __typename: "ModelCareGroupConnection",
    items:  Array< {
      __typename: "CareGroup",
      id: string,
      group_number: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        project:  {
          __typename: "Project",
          id: string,
          project_code: string,
        },
      },
    } | null >,
  } | null,
};

export type GetCareGroupCustomQueryVariables = {
  id: string,
};

export type GetCareGroupCustomQuery = {
  getCareGroup?:  {
    __typename: "CareGroup",
    id: string,
    group_number: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_code: string,
      project:  {
        __typename: "Project",
        id: string,
        name: string,
        project_code: string,
      },
      diocese:  {
        __typename: "Diocese",
        id: string,
        name: string,
        diocese_code: string,
      },
    },
    township:  {
      __typename: "Township",
      id: string,
      name: string,
    },
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        group_number: string,
        village?:  {
          __typename: "Village",
          id: string,
          name: string,
          name_mm?: string | null,
          villageTract:  {
            __typename: "VillageTract",
            id: string,
            name: string,
            name_mm?: string | null,
          },
        } | null,
        camp?:  {
          __typename: "Camp",
          id: string,
          name: string,
          name_mm?: string | null,
        } | null,
      } | null >,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingConnection",
      items:  Array< {
        __typename: "CGMeeting",
        id: string,
        meeting_date?: string | null,
        description?: string | null,
        topics?:  {
          __typename: "ModelCGMeetingNutritionTopicConnection",
          items:  Array< {
            __typename: "CGMeetingNutritionTopic",
            nutrition_topic:  {
              __typename: "NutritionTopic",
              id: string,
              topic_text: string,
            },
          } | null >,
        } | null,
        facilitators?:  {
          __typename: "ModelCGMeetingStaffConnection",
          items:  Array< {
            __typename: "CGMeetingStaff",
            staff:  {
              __typename: "Staff",
              id: string,
              first_name?: string | null,
              last_name?: string | null,
              position:  {
                __typename: "Position",
                name: string,
              },
            },
          } | null >,
        } | null,
        participants?:  {
          __typename: "ModelCGMeetingPLWConnection",
          items:  Array< {
            __typename: "CGMeetingPLW",
            plw:  {
              __typename: "PLW",
              id: string,
              member_number: number,
              name: string,
              lead_mother?: boolean | null,
              neighbor_group:  {
                __typename: "NeighborGroup",
                group_number: string,
              },
            },
          } | null >,
        } | null,
      } | null >,
    } | null,
    other_activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_type:  {
          __typename: "OtherActivityType",
          id: string,
          activity_name: string,
          description?: string | null,
        },
        activity_date: string,
        description?: string | null,
        facilitators?:  {
          __typename: "ModelOtherActivityFacilitatorsConnection",
          items:  Array< {
            __typename: "OtherActivityFacilitators",
            staff:  {
              __typename: "Staff",
              id: string,
              first_name?: string | null,
              last_name?: string | null,
              position:  {
                __typename: "Position",
                name: string,
              },
            },
          } | null >,
        } | null,
        participants?:  {
          __typename: "ModelOtherActivityParticipantsConnection",
          items:  Array< {
            __typename: "OtherActivityParticipants",
            plw:  {
              __typename: "PLW",
              id: string,
              name: string,
              member_number: number,
              lead_mother?: boolean | null,
              neighbor_group:  {
                __typename: "NeighborGroup",
                group_number: string,
              },
            },
          } | null >,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type GetCareGroupForEditQueryVariables = {
  id: string,
};

export type GetCareGroupForEditQuery = {
  getCareGroup?:  {
    __typename: "CareGroup",
    id: string,
    group_number: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_code: string,
      project:  {
        __typename: "Project",
        id: string,
        name: string,
      },
      diocese:  {
        __typename: "Diocese",
        id: string,
        name: string,
      },
    },
    township:  {
      __typename: "Township",
      id: string,
      name: string,
      name_mm?: string | null,
    },
    staff_caregroups?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        staff:  {
          __typename: "Staff",
          id: string,
          first_name?: string | null,
          last_name?: string | null,
          is_active?: boolean | null,
          is_staff?: boolean | null,
          email: string,
          position:  {
            __typename: "Position",
            id: string,
            name: string,
          },
        },
      } | null >,
    } | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
      } | null >,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingConnection",
      items:  Array< {
        __typename: "CGMeeting",
        id: string,
      } | null >,
    } | null,
    other_activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
      } | null >,
    } | null,
    hg_trainings?:  {
      __typename: "ModelHgTrainingConnection",
      items:  Array< {
        __typename: "HgTraining",
        id: string,
      } | null >,
    } | null,
  } | null,
};

export type ListCGMeetingsCustomQueryVariables = {
  id?: string | null,
  filter?: ModelCGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCGMeetingsCustomQuery = {
  listCGMeetings?:  {
    __typename: "ModelCGMeetingConnection",
    items:  Array< {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      meeting_date?: string | null,
      description?: string | null,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        group_number: string,
        project_diocese:  {
          __typename: "ProjectDiocese",
          diocese:  {
            __typename: "Diocese",
            id: string,
            name: string,
          },
          project:  {
            __typename: "Project",
            id: string,
            name: string,
          },
        },
      },
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        items:  Array< {
          __typename: "CGMeetingNutritionTopic",
          nutrition_topic:  {
            __typename: "NutritionTopic",
            id: string,
            topic_text: string,
            topic_text_myanmar?: string | null,
          },
        } | null >,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        items:  Array< {
          __typename: "CGMeetingStaff",
          staff:  {
            __typename: "Staff",
            id: string,
            first_name?: string | null,
            last_name?: string | null,
            position:  {
              __typename: "Position",
              name: string,
            },
          },
        } | null >,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        items:  Array< {
          __typename: "CGMeetingPLW",
          plw:  {
            __typename: "PLW",
            id: string,
            name: string,
            father_name?: string | null,
            date_of_birth?: string | null,
            member_number: number,
            lead_mother?: boolean | null,
            neighbor_group:  {
              __typename: "NeighborGroup",
              group_number: string,
            },
          },
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CgMeetingByPdCodeCustomQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CgMeetingByPdCodeCustomQuery = {
  cgMeetingByPdCode?:  {
    __typename: "ModelCGMeetingConnection",
    items:  Array< {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      meeting_date?: string | null,
      description?: string | null,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        group_number: string,
        project_diocese:  {
          __typename: "ProjectDiocese",
          diocese:  {
            __typename: "Diocese",
            id: string,
            name: string,
          },
          project:  {
            __typename: "Project",
            id: string,
            name: string,
          },
        },
      },
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        items:  Array< {
          __typename: "CGMeetingNutritionTopic",
          nutrition_topic:  {
            __typename: "NutritionTopic",
            id: string,
            topic_text: string,
            topic_text_myanmar?: string | null,
          },
        } | null >,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        items:  Array< {
          __typename: "CGMeetingStaff",
          staff:  {
            __typename: "Staff",
            id: string,
            first_name?: string | null,
            last_name?: string | null,
            position:  {
              __typename: "Position",
              name: string,
            },
          },
        } | null >,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        items:  Array< {
          __typename: "CGMeetingPLW",
          plw:  {
            __typename: "PLW",
            id: string,
            name: string,
            father_name?: string | null,
            date_of_birth?: string | null,
            member_number: number,
            lead_mother?: boolean | null,
            neighbor_group:  {
              __typename: "NeighborGroup",
              group_number: string,
            },
          },
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCGMeetingForEditQueryVariables = {
  id: string,
};

export type GetCGMeetingForEditQuery = {
  getCGMeeting?:  {
    __typename: "CGMeeting",
    id: string,
    project_diocese_code: string,
    care_group_id: string,
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        nutrition_topic:  {
          __typename: "NutritionTopic",
          id: string,
        },
      } | null >,
    } | null,
    facilitators?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        staff:  {
          __typename: "Staff",
          id: string,
        },
      } | null >,
    } | null,
    participants?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        plw:  {
          __typename: "PLW",
          id: string,
          member_number: number,
          neighbor_group:  {
            __typename: "NeighborGroup",
            id: string,
            group_number: string,
          },
        },
      } | null >,
    } | null,
  } | null,
};

export type ListDioceseCustomQueryVariables = {
  id?: string | null,
  filter?: ModelDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDioceseCustomQuery = {
  listDiocese?:  {
    __typename: "ModelDioceseConnection",
    items:  Array< {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
    } | null >,
  } | null,
};

export type DistributionItemByProjectCodeCustomQueryVariables = {
  project_code: string,
};

export type DistributionItemByProjectCodeCustomQuery = {
  distributionItemByProjectCode?:  {
    __typename: "ModelDistributionItemConnection",
    items:  Array< {
      __typename: "DistributionItem",
      id: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        items:  Array< {
          __typename: "Distribution",
          id: string,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type ListDistributionItemsCustomQueryVariables = {
  id?: string | null,
  filter?: ModelDistributionItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDistributionItemsCustomQuery = {
  listDistributionItems?:  {
    __typename: "ModelDistributionItemConnection",
    items:  Array< {
      __typename: "DistributionItem",
      id: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      project_code: string,
      project:  {
        __typename: "Project",
        id: string,
        name: string,
      },
    } | null >,
  } | null,
};

export type GetDistributionForEditQueryVariables = {
  id: string,
};

export type GetDistributionForEditQuery = {
  getDistribution?:  {
    __typename: "Distribution",
    id: string,
    project_diocese_code: string,
    distribution_item_id: string,
    neighbor_group_id: string,
    distribution_date?: string | null,
    description?: string | null,
    recipients?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        plw:  {
          __typename: "PLW",
          member_number: number,
          id: string,
        },
      } | null >,
    } | null,
  } | null,
};

export type DistributionByItemCustomQueryVariables = {
  distribution_item_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDistributionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DistributionByItemCustomQuery = {
  distributionByItem?:  {
    __typename: "ModelDistributionConnection",
    items:  Array< {
      __typename: "Distribution",
      id: string,
      distribution_date?: string | null,
      description?: string | null,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        title: string,
      },
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        items:  Array< {
          __typename: "DistributionRecipients",
          plw:  {
            __typename: "PLW",
            id: string,
            name: string,
            member_number: number,
            lead_mother?: boolean | null,
            father_name?: string | null,
            date_of_birth?: string | null,
          },
        } | null >,
      } | null,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        group_number: string,
        care_group:  {
          __typename: "CareGroup",
          id: string,
          group_number: string,
          project_diocese:  {
            __typename: "ProjectDiocese",
            id: string,
            project:  {
              __typename: "Project",
              id: string,
              name: string,
            },
            diocese:  {
              __typename: "Diocese",
              id: string,
              name: string,
            },
          },
        },
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DistributionByNeighborGroupCustomQueryVariables = {
  neighbor_group_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDistributionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DistributionByNeighborGroupCustomQuery = {
  distributionByNeighborGroup?:  {
    __typename: "ModelDistributionConnection",
    items:  Array< {
      __typename: "Distribution",
      id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
      },
      distribution_date?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        items:  Array< {
          __typename: "DistributionRecipients",
          plw:  {
            __typename: "PLW",
            id: string,
            member_number: number,
            name: string,
            lead_mother?: boolean | null,
          },
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type GetNeighborGroupCustomQueryVariables = {
  id: string,
};

export type GetNeighborGroupCustomQuery = {
  getNeighborGroup?:  {
    __typename: "NeighborGroup",
    id: string,
    group_number: string,
    project_diocese_code: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      group_number: string,
    },
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        neighbor_group:  {
          __typename: "NeighborGroup",
          group_number: string,
          care_group:  {
            __typename: "CareGroup",
            group_number: string,
          },
        },
        name: string,
        father_name?: string | null,
        member_number: number,
        date_of_birth?: string | null,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        contact_number?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        lost_contact?: boolean | null,
        birth_histories?:  {
          __typename: "ModelBirthHistoryConnection",
          items:  Array< {
            __typename: "BirthHistory",
            id: string,
            lmp?: string | null,
            miscarriage_date?: string | null,
            delivered_date?: string | null,
            child_name?: string | null,
            child_gender?: GENDER | null,
            date_of_death?: string | null,
          } | null >,
        } | null,
      } | null >,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        meeting_date?: string | null,
        description?: string | null,
        neighbor_group:  {
          __typename: "NeighborGroup",
          group_number: string,
          care_group:  {
            __typename: "CareGroup",
            group_number: string,
          },
        },
        plw_facilitator:  {
          __typename: "PLW",
          id: string,
        },
        topics?:  {
          __typename: "ModelNGMeetingNutritionTopicConnection",
          items:  Array< {
            __typename: "NGMeetingNutritionTopic",
            nutrition_topic:  {
              __typename: "NutritionTopic",
              id: string,
              topic_text: string,
              topic_text_myanmar?: string | null,
            },
          } | null >,
        } | null,
        staff_facilitators?:  {
          __typename: "ModelNGMeetingStaffFacilitatorConnection",
          items:  Array< {
            __typename: "NGMeetingStaffFacilitator",
            staff:  {
              __typename: "Staff",
              id: string,
              first_name?: string | null,
              last_name?: string | null,
              position:  {
                __typename: "Position",
                name: string,
              },
            },
          } | null >,
        } | null,
        participants?:  {
          __typename: "ModelNGMeetingPLWConnection",
          items:  Array< {
            __typename: "NGMeetingPLW",
            plw:  {
              __typename: "PLW",
              id: string,
            },
          } | null >,
        } | null,
      } | null >,
    } | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_date?: string | null,
        description?: string | null,
        neighbor_group:  {
          __typename: "NeighborGroup",
          group_number: string,
          care_group:  {
            __typename: "CareGroup",
            group_number: string,
          },
        },
        distribution_item:  {
          __typename: "DistributionItem",
          id: string,
          title: string,
          description?: string | null,
          planned_distribution_start_date?: string | null,
          planned_distribution_end_date?: string | null,
        },
        recipients?:  {
          __typename: "ModelDistributionRecipientsConnection",
          items:  Array< {
            __typename: "DistributionRecipients",
            plw:  {
              __typename: "PLW",
              id: string,
            },
          } | null >,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type GetNeighborGroupForEditQueryVariables = {
  id: string,
};

export type GetNeighborGroupForEditQuery = {
  getNeighborGroup?:  {
    __typename: "NeighborGroup",
    id: string,
    group_number: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      group_number: string,
    },
    village?:  {
      __typename: "Village",
      id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
      },
    } | null,
    camp?:  {
      __typename: "Camp",
      id: string,
    } | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
      } | null >,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
      } | null >,
    } | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
      } | null >,
    } | null,
  } | null,
};

export type NeighborGroupByVillageCustomQueryVariables = {
  village_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNeighborGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NeighborGroupByVillageCustomQuery = {
  neighborGroupByVillage?:  {
    __typename: "ModelNeighborGroupConnection",
    items:  Array< {
      __typename: "NeighborGroup",
      id: string,
      group_number: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        group_number: string,
        project_diocese:  {
          __typename: "ProjectDiocese",
          id: string,
          project_diocese_code: string,
          project:  {
            __typename: "Project",
            id: string,
            name: string,
          },
          diocese:  {
            __typename: "Diocese",
            id: string,
            name: string,
          },
        },
      },
      village?:  {
        __typename: "Village",
        id: string,
        name: string,
        name_mm?: string | null,
        villageTract:  {
          __typename: "VillageTract",
          id: string,
          name: string,
          name_mm?: string | null,
          township:  {
            __typename: "Township",
            id: string,
            name: string,
            name_mm?: string | null,
          },
        },
      } | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        name: string,
        name_mm?: string | null,
        township:  {
          __typename: "Township",
          id: string,
          name: string,
          name_mm?: string | null,
        },
      } | null,
    } | null >,
  } | null,
};

export type NeighborGroupByVillageForMapDrawerQueryVariables = {
  id: string,
};

export type NeighborGroupByVillageForMapDrawerQuery = {
  getVillage?:  {
    __typename: "Village",
    id: string,
    name: string,
    name_mm?: string | null,
    village_pcode?: string | null,
    villageTract:  {
      __typename: "VillageTract",
      id: string,
      name: string,
      name_mm?: string | null,
      vt_pcode?: string | null,
      township:  {
        __typename: "Township",
        id: string,
        name: string,
        name_mm?: string | null,
        ts_pcode?: string | null,
      },
    },
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        group_number: string,
        care_group:  {
          __typename: "CareGroup",
          id: string,
          group_number: string,
          project_diocese:  {
            __typename: "ProjectDiocese",
            id: string,
            project:  {
              __typename: "Project",
              id: string,
              name: string,
            },
            diocese:  {
              __typename: "Diocese",
              id: string,
              name: string,
            },
          },
        },
      } | null >,
    } | null,
  } | null,
};

export type NgMeetingByPdCodeCustomQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NgMeetingByPdCodeCustomQuery = {
  ngMeetingByPdCode?:  {
    __typename: "ModelNGMeetingConnection",
    items:  Array< {
      __typename: "NGMeeting",
      id: string,
      project_diocese_code: string,
      meeting_date?: string | null,
      description?: string | null,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        group_number: string,
        care_group:  {
          __typename: "CareGroup",
          id: string,
          group_number: string,
          project_diocese:  {
            __typename: "ProjectDiocese",
            project:  {
              __typename: "Project",
              id: string,
              name: string,
            },
            diocese:  {
              __typename: "Diocese",
              id: string,
              name: string,
            },
          },
        },
      },
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        name: string,
        member_number: number,
        lead_mother?: boolean | null,
      },
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        items:  Array< {
          __typename: "NGMeetingNutritionTopic",
          nutrition_topic:  {
            __typename: "NutritionTopic",
            id: string,
            topic_text: string,
            topic_text_myanmar?: string | null,
          },
        } | null >,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        items:  Array< {
          __typename: "NGMeetingStaffFacilitator",
          staff:  {
            __typename: "Staff",
            id: string,
            first_name?: string | null,
            last_name?: string | null,
            position:  {
              __typename: "Position",
              name: string,
            },
          },
        } | null >,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        items:  Array< {
          __typename: "NGMeetingPLW",
          plw:  {
            __typename: "PLW",
            id: string,
            name: string,
            member_number: number,
            father_name?: string | null,
            date_of_birth?: string | null,
            lead_mother?: boolean | null,
          },
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNGMeetingForEditQueryVariables = {
  id: string,
};

export type GetNGMeetingForEditQuery = {
  getNGMeeting?:  {
    __typename: "NGMeeting",
    id: string,
    neighbor_group_id: string,
    plw_facilitator_id: string,
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        nutrition_topic:  {
          __typename: "NutritionTopic",
          id: string,
        },
      } | null >,
    } | null,
    staff_facilitators?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        staff:  {
          __typename: "Staff",
          id: string,
        },
      } | null >,
    } | null,
    participants?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        plw:  {
          __typename: "PLW",
          id: string,
          member_number: number,
        },
      } | null >,
    } | null,
  } | null,
};

export type NgMeetingByNeighborGroupCustomQueryVariables = {
  neighbor_group_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NgMeetingByNeighborGroupCustomQuery = {
  ngMeetingByNeighborGroup?:  {
    __typename: "ModelNGMeetingConnection",
    items:  Array< {
      __typename: "NGMeeting",
      id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        member_number: number,
        lead_mother?: boolean | null,
        name: string,
      },
      meeting_date?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        items:  Array< {
          __typename: "NGMeetingNutritionTopic",
          nutrition_topic:  {
            __typename: "NutritionTopic",
            id: string,
            topic_text: string,
            topic_text_myanmar?: string | null,
          },
        } | null >,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        items:  Array< {
          __typename: "NGMeetingPLW",
          plw:  {
            __typename: "PLW",
            id: string,
            member_number: number,
            lead_mother?: boolean | null,
            name: string,
          },
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type TopicByCgMeetingCustomQueryVariables = {
  cg_meeting_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCGMeetingNutritionTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TopicByCgMeetingCustomQuery = {
  topicByCgMeeting?:  {
    __typename: "ModelCGMeetingNutritionTopicConnection",
    items:  Array< {
      __typename: "CGMeetingNutritionTopic",
      id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
      },
    } | null >,
  } | null,
};

export type ListNutritionTopicsCustomQueryVariables = {
  id?: string | null,
  filter?: ModelNutritionTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNutritionTopicsCustomQuery = {
  listNutritionTopics?:  {
    __typename: "ModelNutritionTopicConnection",
    items:  Array< {
      __typename: "NutritionTopic",
      id: string,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        items:  Array< {
          __typename: "NutritionMessage",
          id: string,
          message_text: string,
          message_text_myanmar?: string | null,
          description?: string | null,
          nutrition_questions?:  {
            __typename: "ModelNutritionQuestionConnection",
            items:  Array< {
              __typename: "NutritionQuestion",
              id: string,
              question_text: string,
              question_text_myanmar?: string | null,
              description?: string | null,
            } | null >,
          } | null,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type ListOtherActivityTypesCustomQueryVariables = {
  id?: string | null,
  filter?: ModelOtherActivityTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOtherActivityTypesCustomQuery = {
  listOtherActivityTypes?:  {
    __typename: "ModelOtherActivityTypeConnection",
    items:  Array< {
      __typename: "OtherActivityType",
      id: string,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        name: string,
      },
      project_code: string,
      activity_name: string,
      description?: string | null,
    } | null >,
  } | null,
};

export type OtherActivityByTypeCustomQueryVariables = {
  other_activity_type_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOtherActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OtherActivityByTypeCustomQuery = {
  otherActivityByType?:  {
    __typename: "ModelOtherActivityConnection",
    items:  Array< {
      __typename: "OtherActivity",
      id: string,
      activity_date: string,
      description?: string | null,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        activity_name: string,
        description?: string | null,
      },
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        items:  Array< {
          __typename: "OtherActivityFacilitators",
          staff:  {
            __typename: "Staff",
            id: string,
            first_name?: string | null,
            last_name?: string | null,
            position:  {
              __typename: "Position",
              name: string,
            },
          },
        } | null >,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        items:  Array< {
          __typename: "OtherActivityParticipants",
          plw:  {
            __typename: "PLW",
            id: string,
            name: string,
            member_number: number,
            lead_mother?: boolean | null,
            father_name?: string | null,
            date_of_birth?: string | null,
            neighbor_group:  {
              __typename: "NeighborGroup",
              group_number: string,
            },
          },
        } | null >,
      } | null,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        group_number: string,
        project_diocese:  {
          __typename: "ProjectDiocese",
          id: string,
          project:  {
            __typename: "Project",
            id: string,
            name: string,
          },
          diocese:  {
            __typename: "Diocese",
            id: string,
            name: string,
          },
        },
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOtherActivityForEditQueryVariables = {
  id: string,
};

export type GetOtherActivityForEditQuery = {
  getOtherActivity?:  {
    __typename: "OtherActivity",
    id: string,
    project_diocese_code: string,
    care_group_id: string,
    other_activity_type_id: string,
    activity_date: string,
    description?: string | null,
    facilitators?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        staff:  {
          __typename: "Staff",
          id: string,
        },
      } | null >,
    } | null,
    participants?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        plw:  {
          __typename: "PLW",
          id: string,
          member_number: number,
          neighbor_group:  {
            __typename: "NeighborGroup",
            id: string,
            group_number: string,
          },
        },
      } | null >,
    } | null,
  } | null,
};

export type OtherActivityTypeByProjectCodeCustomQueryVariables = {
  project_code: string,
};

export type OtherActivityTypeByProjectCodeCustomQuery = {
  otherActivityTypeByProjectCode?:  {
    __typename: "ModelOtherActivityTypeConnection",
    items:  Array< {
      __typename: "OtherActivityType",
      id: string,
      activity_name: string,
      description?: string | null,
      activities?:  {
        __typename: "ModelOtherActivityConnection",
        items:  Array< {
          __typename: "OtherActivity",
          id: string,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type GetPLWforEditQueryVariables = {
  id: string,
};

export type GetPLWforEditQuery = {
  getPLW?:  {
    __typename: "PLW",
    id: string,
    member_number: number,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      group_number: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        group_number: string,
      },
    },
    project_diocese_code: string,
    date_of_registry?: string | null,
    lead_mother?: boolean | null,
    name: string,
    father_name?: string | null,
    date_of_birth?: string | null,
    education_level_id?: string | null,
    occupation_type_id?: string | null,
    disabled?: boolean | null,
    disability_type?: Array< string | null > | null,
    disability_type_other_description?: string | null,
    contact_number?: string | null,
    date_of_death?: string | null,
    lost_contact?: boolean | null,
    birth_histories?:  {
      __typename: "ModelBirthHistoryConnection",
      items:  Array< {
        __typename: "BirthHistory",
        id: string,
        birth_history_id?: number | null,
        plw_id: string,
        project_diocese_code: string,
        lmp?: string | null,
        miscarriage_date?: string | null,
        delivered_date?: string | null,
        child_name?: string | null,
        child_gender?: GENDER | null,
        date_of_death?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings_attended?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
      } | null >,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
      } | null >,
    } | null,
    ng_meetings_attended?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
      } | null >,
    } | null,
    distributions_recieved?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
      } | null >,
    } | null,
    other_activities_attended?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
      } | null >,
    } | null,
    hg_training_attended?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
      } | null >,
    } | null,
  } | null,
};

export type ListPlwsByCareGroupIdQueryVariables = {
  id: string,
};

export type ListPlwsByCareGroupIdQuery = {
  getCareGroup?:  {
    __typename: "CareGroup",
    id: string,
    group_number: string,
    project_diocese_code: string,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        group_number: string,
        plws?:  {
          __typename: "ModelPLWConnection",
          items:  Array< {
            __typename: "PLW",
            id: string,
            member_number: number,
            name: string,
            lead_mother?: boolean | null,
          } | null >,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type PlwByNeighborGroupCustomQueryVariables = {
  neighbor_group_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPLWFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlwByNeighborGroupCustomQuery = {
  plwByNeighborGroup?:  {
    __typename: "ModelPLWConnection",
    items:  Array< {
      __typename: "PLW",
      id: string,
      member_number: number,
      lead_mother?: boolean | null,
      name: string,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        items:  Array< {
          __typename: "BirthHistory",
          id: string,
          lmp?: string | null,
          miscarriage_date?: string | null,
          delivered_date?: string | null,
          date_of_death?: string | null,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type PlwByCgMeetingCustomQueryVariables = {
  cg_meeting_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCGMeetingPLWFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlwByCgMeetingCustomQuery = {
  plwByCgMeeting?:  {
    __typename: "ModelCGMeetingPLWConnection",
    items:  Array< {
      __typename: "CGMeetingPLW",
      id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        member_number: number,
        neighbor_group:  {
          __typename: "NeighborGroup",
          group_number: string,
        },
        lead_mother?: boolean | null,
        name: string,
      },
    } | null >,
  } | null,
};

export type ListProjectDioceseCustomQueryVariables = {
  id?: string | null,
  filter?: ModelProjectDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProjectDioceseCustomQuery = {
  listProjectDiocese?:  {
    __typename: "ModelProjectDioceseConnection",
    items:  Array< {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_code: string,
      project:  {
        __typename: "Project",
        id: string,
        project_code: string,
        name: string,
      },
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_code: string,
        name: string,
      },
    } | null >,
  } | null,
};

export type GetProjectDioceseByPdCodeCustomQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProjectDioceseByPdCodeCustomQuery = {
  getProjectDioceseByPdCode?:  {
    __typename: "ModelProjectDioceseConnection",
    items:  Array< {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_code: string,
      project:  {
        __typename: "Project",
        id: string,
        project_code: string,
        name: string,
      },
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_code: string,
        name: string,
      },
    } | null >,
  } | null,
};

export type ListProjectsCustomQueryVariables = {
  id?: string | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProjectsCustomQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
    } | null >,
  } | null,
};

export type StaffByProjectDioceseCustomQueryVariables = {
  project_diocese_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StaffByProjectDioceseCustomQuery = {
  staffByProjectDiocese?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      email: string,
      position:  {
        __typename: "Position",
        name: string,
      },
    } | null >,
  } | null,
};

export type TownshipByDioceseCodeCustomQueryVariables = {
  diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTownshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TownshipByDioceseCodeCustomQuery = {
  townshipByDioceseCode?:  {
    __typename: "ModelTownshipConnection",
    items:  Array< {
      __typename: "Township",
      id: string,
      name: string,
      name_mm?: string | null,
      active?: boolean | null,
    } | null >,
  } | null,
};

export type VillageByVillageTractCustomQueryVariables = {
  villageTract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVillageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VillageByVillageTractCustomQuery = {
  villageByVillageTract?:  {
    __typename: "ModelVillageConnection",
    items:  Array< {
      __typename: "Village",
      id: string,
      name: string,
      name_mm?: string | null,
      active?: boolean | null,
    } | null >,
  } | null,
};

export type VillageTractByTownshipCustomQueryVariables = {
  township_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVillageTractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VillageTractByTownshipCustomQuery = {
  villageTractByTownship?:  {
    __typename: "ModelVillageTractConnection",
    items:  Array< {
      __typename: "VillageTract",
      id: string,
      name: string,
      name_mm?: string | null,
      active?: boolean | null,
    } | null >,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    project_id?: number | null,
    project_code: string,
    name: string,
    sector: PROGRAM_SECTORS,
    description?: string | null,
    donor?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distribution_items?:  {
      __typename: "ModelDistributionItemConnection",
      items:  Array< {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activitie_types?:  {
      __typename: "ModelOtherActivityTypeConnection",
      items:  Array< {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_topics?:  {
      __typename: "ModelHgTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTopic",
        id: string,
        hg_training_topic_id?: number | null,
        project_id: string,
        project_code: string,
        topic: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    project_id?: number | null,
    project_code: string,
    name: string,
    sector: PROGRAM_SECTORS,
    description?: string | null,
    donor?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distribution_items?:  {
      __typename: "ModelDistributionItemConnection",
      items:  Array< {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activitie_types?:  {
      __typename: "ModelOtherActivityTypeConnection",
      items:  Array< {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_topics?:  {
      __typename: "ModelHgTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTopic",
        id: string,
        hg_training_topic_id?: number | null,
        project_id: string,
        project_code: string,
        topic: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    project_id?: number | null,
    project_code: string,
    name: string,
    sector: PROGRAM_SECTORS,
    description?: string | null,
    donor?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distribution_items?:  {
      __typename: "ModelDistributionItemConnection",
      items:  Array< {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activitie_types?:  {
      __typename: "ModelOtherActivityTypeConnection",
      items:  Array< {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_topics?:  {
      __typename: "ModelHgTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTopic",
        id: string,
        hg_training_topic_id?: number | null,
        project_id: string,
        project_code: string,
        topic: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDioceseMutationVariables = {
  input: CreateDioceseInput,
  condition?: ModelDioceseConditionInput | null,
};

export type CreateDioceseMutation = {
  createDiocese?:  {
    __typename: "Diocese",
    id: string,
    diocese_id?: number | null,
    diocese_code: string,
    name: string,
    description?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    townships?:  {
      __typename: "ModelTownshipConnection",
      items:  Array< {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDioceseMutationVariables = {
  input: UpdateDioceseInput,
  condition?: ModelDioceseConditionInput | null,
};

export type UpdateDioceseMutation = {
  updateDiocese?:  {
    __typename: "Diocese",
    id: string,
    diocese_id?: number | null,
    diocese_code: string,
    name: string,
    description?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    townships?:  {
      __typename: "ModelTownshipConnection",
      items:  Array< {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDioceseMutationVariables = {
  input: DeleteDioceseInput,
  condition?: ModelDioceseConditionInput | null,
};

export type DeleteDioceseMutation = {
  deleteDiocese?:  {
    __typename: "Diocese",
    id: string,
    diocese_id?: number | null,
    diocese_code: string,
    name: string,
    description?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    townships?:  {
      __typename: "ModelTownshipConnection",
      items:  Array< {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProjectDioceseMutationVariables = {
  input: CreateProjectDioceseInput,
  condition?: ModelProjectDioceseConditionInput | null,
};

export type CreateProjectDioceseMutation = {
  createProjectDiocese?:  {
    __typename: "ProjectDiocese",
    id: string,
    project_diocese_id?: number | null,
    projectID: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    dioceseID: string,
    diocese:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProjectDioceseMutationVariables = {
  input: UpdateProjectDioceseInput,
  condition?: ModelProjectDioceseConditionInput | null,
};

export type UpdateProjectDioceseMutation = {
  updateProjectDiocese?:  {
    __typename: "ProjectDiocese",
    id: string,
    project_diocese_id?: number | null,
    projectID: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    dioceseID: string,
    diocese:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProjectDioceseMutationVariables = {
  input: DeleteProjectDioceseInput,
  condition?: ModelProjectDioceseConditionInput | null,
};

export type DeleteProjectDioceseMutation = {
  deleteProjectDiocese?:  {
    __typename: "ProjectDiocese",
    id: string,
    project_diocese_id?: number | null,
    projectID: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    dioceseID: string,
    diocese:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTownshipMutationVariables = {
  input: CreateTownshipInput,
  condition?: ModelTownshipConditionInput | null,
};

export type CreateTownshipMutation = {
  createTownship?:  {
    __typename: "Township",
    id: string,
    ts_pcode?: string | null,
    d_pcode?: string | null,
    sr_pcode?: string | null,
    name: string,
    name_mm?: string | null,
    diocese_id?: string | null,
    diocese?:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villageTracts?:  {
      __typename: "ModelVillageTractConnection",
      items:  Array< {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    camps?:  {
      __typename: "ModelCampConnection",
      items:  Array< {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTownshipMutationVariables = {
  input: UpdateTownshipInput,
  condition?: ModelTownshipConditionInput | null,
};

export type UpdateTownshipMutation = {
  updateTownship?:  {
    __typename: "Township",
    id: string,
    ts_pcode?: string | null,
    d_pcode?: string | null,
    sr_pcode?: string | null,
    name: string,
    name_mm?: string | null,
    diocese_id?: string | null,
    diocese?:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villageTracts?:  {
      __typename: "ModelVillageTractConnection",
      items:  Array< {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    camps?:  {
      __typename: "ModelCampConnection",
      items:  Array< {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTownshipMutationVariables = {
  input: DeleteTownshipInput,
  condition?: ModelTownshipConditionInput | null,
};

export type DeleteTownshipMutation = {
  deleteTownship?:  {
    __typename: "Township",
    id: string,
    ts_pcode?: string | null,
    d_pcode?: string | null,
    sr_pcode?: string | null,
    name: string,
    name_mm?: string | null,
    diocese_id?: string | null,
    diocese?:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villageTracts?:  {
      __typename: "ModelVillageTractConnection",
      items:  Array< {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    camps?:  {
      __typename: "ModelCampConnection",
      items:  Array< {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVillageTractMutationVariables = {
  input: CreateVillageTractInput,
  condition?: ModelVillageTractConditionInput | null,
};

export type CreateVillageTractMutation = {
  createVillageTract?:  {
    __typename: "VillageTract",
    id: string,
    vt_pcode?: string | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villages?:  {
      __typename: "ModelVillageConnection",
      items:  Array< {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVillageTractMutationVariables = {
  input: UpdateVillageTractInput,
  condition?: ModelVillageTractConditionInput | null,
};

export type UpdateVillageTractMutation = {
  updateVillageTract?:  {
    __typename: "VillageTract",
    id: string,
    vt_pcode?: string | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villages?:  {
      __typename: "ModelVillageConnection",
      items:  Array< {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVillageTractMutationVariables = {
  input: DeleteVillageTractInput,
  condition?: ModelVillageTractConditionInput | null,
};

export type DeleteVillageTractMutation = {
  deleteVillageTract?:  {
    __typename: "VillageTract",
    id: string,
    vt_pcode?: string | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villages?:  {
      __typename: "ModelVillageConnection",
      items:  Array< {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVillageMutationVariables = {
  input: CreateVillageInput,
  condition?: ModelVillageConditionInput | null,
};

export type CreateVillageMutation = {
  createVillage?:  {
    __typename: "Village",
    id: string,
    village_pcode?: string | null,
    villageTract_id: string,
    villageTract:  {
      __typename: "VillageTract",
      id: string,
      vt_pcode?: string | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villages?:  {
        __typename: "ModelVillageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    alt_name?: string | null,
    alt_name_mm?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVillageMutationVariables = {
  input: UpdateVillageInput,
  condition?: ModelVillageConditionInput | null,
};

export type UpdateVillageMutation = {
  updateVillage?:  {
    __typename: "Village",
    id: string,
    village_pcode?: string | null,
    villageTract_id: string,
    villageTract:  {
      __typename: "VillageTract",
      id: string,
      vt_pcode?: string | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villages?:  {
        __typename: "ModelVillageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    alt_name?: string | null,
    alt_name_mm?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVillageMutationVariables = {
  input: DeleteVillageInput,
  condition?: ModelVillageConditionInput | null,
};

export type DeleteVillageMutation = {
  deleteVillage?:  {
    __typename: "Village",
    id: string,
    village_pcode?: string | null,
    villageTract_id: string,
    villageTract:  {
      __typename: "VillageTract",
      id: string,
      vt_pcode?: string | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villages?:  {
        __typename: "ModelVillageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    alt_name?: string | null,
    alt_name_mm?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCampMutationVariables = {
  input: CreateCampInput,
  condition?: ModelCampConditionInput | null,
};

export type CreateCampMutation = {
  createCamp?:  {
    __typename: "Camp",
    id: string,
    camp_id?: number | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    description?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCampMutationVariables = {
  input: UpdateCampInput,
  condition?: ModelCampConditionInput | null,
};

export type UpdateCampMutation = {
  updateCamp?:  {
    __typename: "Camp",
    id: string,
    camp_id?: number | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    description?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCampMutationVariables = {
  input: DeleteCampInput,
  condition?: ModelCampConditionInput | null,
};

export type DeleteCampMutation = {
  deleteCamp?:  {
    __typename: "Camp",
    id: string,
    camp_id?: number | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    description?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCareGroupMutationVariables = {
  input: CreateCareGroupInput,
  condition?: ModelCareGroupConditionInput | null,
};

export type CreateCareGroupMutation = {
  createCareGroup?:  {
    __typename: "CareGroup",
    id: string,
    care_group_id?: number | null,
    group_number: string,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroups?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingConnection",
      items:  Array< {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings?:  {
      __typename: "ModelHgTrainingConnection",
      items:  Array< {
        __typename: "HgTraining",
        id: string,
        hg_training_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        training_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCareGroupMutationVariables = {
  input: UpdateCareGroupInput,
  condition?: ModelCareGroupConditionInput | null,
};

export type UpdateCareGroupMutation = {
  updateCareGroup?:  {
    __typename: "CareGroup",
    id: string,
    care_group_id?: number | null,
    group_number: string,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroups?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingConnection",
      items:  Array< {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings?:  {
      __typename: "ModelHgTrainingConnection",
      items:  Array< {
        __typename: "HgTraining",
        id: string,
        hg_training_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        training_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCareGroupMutationVariables = {
  input: DeleteCareGroupInput,
  condition?: ModelCareGroupConditionInput | null,
};

export type DeleteCareGroupMutation = {
  deleteCareGroup?:  {
    __typename: "CareGroup",
    id: string,
    care_group_id?: number | null,
    group_number: string,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroups?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingConnection",
      items:  Array< {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings?:  {
      __typename: "ModelHgTrainingConnection",
      items:  Array< {
        __typename: "HgTraining",
        id: string,
        hg_training_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        training_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePositionMutationVariables = {
  input: CreatePositionInput,
  condition?: ModelPositionConditionInput | null,
};

export type CreatePositionMutation = {
  createPosition?:  {
    __typename: "Position",
    id: string,
    position_id?: number | null,
    name: string,
    level?: POSITION_LEVELS | null,
    description?: string | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePositionMutationVariables = {
  input: UpdatePositionInput,
  condition?: ModelPositionConditionInput | null,
};

export type UpdatePositionMutation = {
  updatePosition?:  {
    __typename: "Position",
    id: string,
    position_id?: number | null,
    name: string,
    level?: POSITION_LEVELS | null,
    description?: string | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePositionMutationVariables = {
  input: DeletePositionInput,
  condition?: ModelPositionConditionInput | null,
};

export type DeletePositionMutation = {
  deletePosition?:  {
    __typename: "Position",
    id: string,
    position_id?: number | null,
    name: string,
    level?: POSITION_LEVELS | null,
    description?: string | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStaffMutationVariables = {
  input: CreateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type CreateStaffMutation = {
  createStaff?:  {
    __typename: "Staff",
    id: string,
    staff_id?: number | null,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    position_id: string,
    position:  {
      __typename: "Position",
      id: string,
      position_id?: number | null,
      name: string,
      level?: POSITION_LEVELS | null,
      description?: string | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroup?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    current_position?: boolean | null,
    from_date?: string | null,
    to_date?: string | null,
    email: string,
    first_name?: string | null,
    last_name?: string | null,
    gender?: GENDER | null,
    is_staff?: boolean | null,
    is_active?: boolean | null,
    date_joined?: string | null,
    cg_meetings_facilitated?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_facilitated?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings_facilitated?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStaffMutationVariables = {
  input: UpdateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type UpdateStaffMutation = {
  updateStaff?:  {
    __typename: "Staff",
    id: string,
    staff_id?: number | null,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    position_id: string,
    position:  {
      __typename: "Position",
      id: string,
      position_id?: number | null,
      name: string,
      level?: POSITION_LEVELS | null,
      description?: string | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroup?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    current_position?: boolean | null,
    from_date?: string | null,
    to_date?: string | null,
    email: string,
    first_name?: string | null,
    last_name?: string | null,
    gender?: GENDER | null,
    is_staff?: boolean | null,
    is_active?: boolean | null,
    date_joined?: string | null,
    cg_meetings_facilitated?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_facilitated?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings_facilitated?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStaffMutationVariables = {
  input: DeleteStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type DeleteStaffMutation = {
  deleteStaff?:  {
    __typename: "Staff",
    id: string,
    staff_id?: number | null,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    position_id: string,
    position:  {
      __typename: "Position",
      id: string,
      position_id?: number | null,
      name: string,
      level?: POSITION_LEVELS | null,
      description?: string | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroup?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    current_position?: boolean | null,
    from_date?: string | null,
    to_date?: string | null,
    email: string,
    first_name?: string | null,
    last_name?: string | null,
    gender?: GENDER | null,
    is_staff?: boolean | null,
    is_active?: boolean | null,
    date_joined?: string | null,
    cg_meetings_facilitated?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_facilitated?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings_facilitated?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStaffCareGroupMutationVariables = {
  input: CreateStaffCareGroupInput,
  condition?: ModelStaffCareGroupConditionInput | null,
};

export type CreateStaffCareGroupMutation = {
  createStaffCareGroup?:  {
    __typename: "StaffCareGroup",
    id: string,
    staff_caregroup_id?: number | null,
    care_group_id: string,
    care_group?:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    current_assignment?: boolean | null,
    start_date?: string | null,
    end_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStaffCareGroupMutationVariables = {
  input: UpdateStaffCareGroupInput,
  condition?: ModelStaffCareGroupConditionInput | null,
};

export type UpdateStaffCareGroupMutation = {
  updateStaffCareGroup?:  {
    __typename: "StaffCareGroup",
    id: string,
    staff_caregroup_id?: number | null,
    care_group_id: string,
    care_group?:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    current_assignment?: boolean | null,
    start_date?: string | null,
    end_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStaffCareGroupMutationVariables = {
  input: DeleteStaffCareGroupInput,
  condition?: ModelStaffCareGroupConditionInput | null,
};

export type DeleteStaffCareGroupMutation = {
  deleteStaffCareGroup?:  {
    __typename: "StaffCareGroup",
    id: string,
    staff_caregroup_id?: number | null,
    care_group_id: string,
    care_group?:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    current_assignment?: boolean | null,
    start_date?: string | null,
    end_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNeighborGroupMutationVariables = {
  input: CreateNeighborGroupInput,
  condition?: ModelNeighborGroupConditionInput | null,
};

export type CreateNeighborGroupMutation = {
  createNeighborGroup?:  {
    __typename: "NeighborGroup",
    id: string,
    neighbor_group_id?: number | null,
    group_number: string,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    village_id?: string | null,
    village?:  {
      __typename: "Village",
      id: string,
      village_pcode?: string | null,
      villageTract_id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      alt_name?: string | null,
      alt_name_mm?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    camp_id?: string | null,
    camp?:  {
      __typename: "Camp",
      id: string,
      camp_id?: number | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      description?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNeighborGroupMutationVariables = {
  input: UpdateNeighborGroupInput,
  condition?: ModelNeighborGroupConditionInput | null,
};

export type UpdateNeighborGroupMutation = {
  updateNeighborGroup?:  {
    __typename: "NeighborGroup",
    id: string,
    neighbor_group_id?: number | null,
    group_number: string,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    village_id?: string | null,
    village?:  {
      __typename: "Village",
      id: string,
      village_pcode?: string | null,
      villageTract_id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      alt_name?: string | null,
      alt_name_mm?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    camp_id?: string | null,
    camp?:  {
      __typename: "Camp",
      id: string,
      camp_id?: number | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      description?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNeighborGroupMutationVariables = {
  input: DeleteNeighborGroupInput,
  condition?: ModelNeighborGroupConditionInput | null,
};

export type DeleteNeighborGroupMutation = {
  deleteNeighborGroup?:  {
    __typename: "NeighborGroup",
    id: string,
    neighbor_group_id?: number | null,
    group_number: string,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    village_id?: string | null,
    village?:  {
      __typename: "Village",
      id: string,
      village_pcode?: string | null,
      villageTract_id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      alt_name?: string | null,
      alt_name_mm?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    camp_id?: string | null,
    camp?:  {
      __typename: "Camp",
      id: string,
      camp_id?: number | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      description?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEducationLevelMutationVariables = {
  input: CreateEducationLevelInput,
  condition?: ModelEducationLevelConditionInput | null,
};

export type CreateEducationLevelMutation = {
  createEducationLevel?:  {
    __typename: "EducationLevel",
    id: string,
    education_level_id?: number | null,
    education_level: string,
    education_level_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEducationLevelMutationVariables = {
  input: UpdateEducationLevelInput,
  condition?: ModelEducationLevelConditionInput | null,
};

export type UpdateEducationLevelMutation = {
  updateEducationLevel?:  {
    __typename: "EducationLevel",
    id: string,
    education_level_id?: number | null,
    education_level: string,
    education_level_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEducationLevelMutationVariables = {
  input: DeleteEducationLevelInput,
  condition?: ModelEducationLevelConditionInput | null,
};

export type DeleteEducationLevelMutation = {
  deleteEducationLevel?:  {
    __typename: "EducationLevel",
    id: string,
    education_level_id?: number | null,
    education_level: string,
    education_level_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOccupationTypeMutationVariables = {
  input: CreateOccupationTypeInput,
  condition?: ModelOccupationTypeConditionInput | null,
};

export type CreateOccupationTypeMutation = {
  createOccupationType?:  {
    __typename: "OccupationType",
    id: string,
    occupation_type_id?: number | null,
    occupation_type: string,
    occupation_type_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOccupationTypeMutationVariables = {
  input: UpdateOccupationTypeInput,
  condition?: ModelOccupationTypeConditionInput | null,
};

export type UpdateOccupationTypeMutation = {
  updateOccupationType?:  {
    __typename: "OccupationType",
    id: string,
    occupation_type_id?: number | null,
    occupation_type: string,
    occupation_type_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOccupationTypeMutationVariables = {
  input: DeleteOccupationTypeInput,
  condition?: ModelOccupationTypeConditionInput | null,
};

export type DeleteOccupationTypeMutation = {
  deleteOccupationType?:  {
    __typename: "OccupationType",
    id: string,
    occupation_type_id?: number | null,
    occupation_type: string,
    occupation_type_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDisabilityTypeMutationVariables = {
  input: CreateDisabilityTypeInput,
  condition?: ModelDisabilityTypeConditionInput | null,
};

export type CreateDisabilityTypeMutation = {
  createDisabilityType?:  {
    __typename: "DisabilityType",
    id: string,
    disability_type_id?: number | null,
    disability_type: string,
    description: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDisabilityTypeMutationVariables = {
  input: UpdateDisabilityTypeInput,
  condition?: ModelDisabilityTypeConditionInput | null,
};

export type UpdateDisabilityTypeMutation = {
  updateDisabilityType?:  {
    __typename: "DisabilityType",
    id: string,
    disability_type_id?: number | null,
    disability_type: string,
    description: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDisabilityTypeMutationVariables = {
  input: DeleteDisabilityTypeInput,
  condition?: ModelDisabilityTypeConditionInput | null,
};

export type DeleteDisabilityTypeMutation = {
  deleteDisabilityType?:  {
    __typename: "DisabilityType",
    id: string,
    disability_type_id?: number | null,
    disability_type: string,
    description: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePLWMutationVariables = {
  input: CreatePLWInput,
  condition?: ModelPLWConditionInput | null,
};

export type CreatePLWMutation = {
  createPLW?:  {
    __typename: "PLW",
    id: string,
    plw_id?: number | null,
    member_number: number,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    date_of_registry?: string | null,
    lead_mother?: boolean | null,
    name: string,
    father_name?: string | null,
    date_of_birth?: string | null,
    education_level_id?: string | null,
    education_level?:  {
      __typename: "EducationLevel",
      id: string,
      education_level_id?: number | null,
      education_level: string,
      education_level_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupation_type_id?: string | null,
    occupation_type?:  {
      __typename: "OccupationType",
      id: string,
      occupation_type_id?: number | null,
      occupation_type: string,
      occupation_type_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    disabled?: boolean | null,
    disability_type?: Array< string | null > | null,
    disability_type_other_description?: string | null,
    contact_number?: string | null,
    date_of_death?: string | null,
    lost_contact?: boolean | null,
    birth_histories?:  {
      __typename: "ModelBirthHistoryConnection",
      items:  Array< {
        __typename: "BirthHistory",
        id: string,
        birth_history_id?: number | null,
        plw_id: string,
        project_diocese_code: string,
        lmp?: string | null,
        miscarriage_date?: string | null,
        delivered_date?: string | null,
        child_name?: string | null,
        child_gender?: GENDER | null,
        date_of_death?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings_attended?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_attended?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions_recieved?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_attended?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_attended?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePLWMutationVariables = {
  input: UpdatePLWInput,
  condition?: ModelPLWConditionInput | null,
};

export type UpdatePLWMutation = {
  updatePLW?:  {
    __typename: "PLW",
    id: string,
    plw_id?: number | null,
    member_number: number,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    date_of_registry?: string | null,
    lead_mother?: boolean | null,
    name: string,
    father_name?: string | null,
    date_of_birth?: string | null,
    education_level_id?: string | null,
    education_level?:  {
      __typename: "EducationLevel",
      id: string,
      education_level_id?: number | null,
      education_level: string,
      education_level_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupation_type_id?: string | null,
    occupation_type?:  {
      __typename: "OccupationType",
      id: string,
      occupation_type_id?: number | null,
      occupation_type: string,
      occupation_type_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    disabled?: boolean | null,
    disability_type?: Array< string | null > | null,
    disability_type_other_description?: string | null,
    contact_number?: string | null,
    date_of_death?: string | null,
    lost_contact?: boolean | null,
    birth_histories?:  {
      __typename: "ModelBirthHistoryConnection",
      items:  Array< {
        __typename: "BirthHistory",
        id: string,
        birth_history_id?: number | null,
        plw_id: string,
        project_diocese_code: string,
        lmp?: string | null,
        miscarriage_date?: string | null,
        delivered_date?: string | null,
        child_name?: string | null,
        child_gender?: GENDER | null,
        date_of_death?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings_attended?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_attended?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions_recieved?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_attended?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_attended?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePLWMutationVariables = {
  input: DeletePLWInput,
  condition?: ModelPLWConditionInput | null,
};

export type DeletePLWMutation = {
  deletePLW?:  {
    __typename: "PLW",
    id: string,
    plw_id?: number | null,
    member_number: number,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    date_of_registry?: string | null,
    lead_mother?: boolean | null,
    name: string,
    father_name?: string | null,
    date_of_birth?: string | null,
    education_level_id?: string | null,
    education_level?:  {
      __typename: "EducationLevel",
      id: string,
      education_level_id?: number | null,
      education_level: string,
      education_level_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupation_type_id?: string | null,
    occupation_type?:  {
      __typename: "OccupationType",
      id: string,
      occupation_type_id?: number | null,
      occupation_type: string,
      occupation_type_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    disabled?: boolean | null,
    disability_type?: Array< string | null > | null,
    disability_type_other_description?: string | null,
    contact_number?: string | null,
    date_of_death?: string | null,
    lost_contact?: boolean | null,
    birth_histories?:  {
      __typename: "ModelBirthHistoryConnection",
      items:  Array< {
        __typename: "BirthHistory",
        id: string,
        birth_history_id?: number | null,
        plw_id: string,
        project_diocese_code: string,
        lmp?: string | null,
        miscarriage_date?: string | null,
        delivered_date?: string | null,
        child_name?: string | null,
        child_gender?: GENDER | null,
        date_of_death?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings_attended?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_attended?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions_recieved?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_attended?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_attended?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBirthHistoryMutationVariables = {
  input: CreateBirthHistoryInput,
  condition?: ModelBirthHistoryConditionInput | null,
};

export type CreateBirthHistoryMutation = {
  createBirthHistory?:  {
    __typename: "BirthHistory",
    id: string,
    birth_history_id?: number | null,
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    lmp?: string | null,
    miscarriage_date?: string | null,
    delivered_date?: string | null,
    child_name?: string | null,
    child_gender?: GENDER | null,
    date_of_death?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBirthHistoryMutationVariables = {
  input: UpdateBirthHistoryInput,
  condition?: ModelBirthHistoryConditionInput | null,
};

export type UpdateBirthHistoryMutation = {
  updateBirthHistory?:  {
    __typename: "BirthHistory",
    id: string,
    birth_history_id?: number | null,
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    lmp?: string | null,
    miscarriage_date?: string | null,
    delivered_date?: string | null,
    child_name?: string | null,
    child_gender?: GENDER | null,
    date_of_death?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBirthHistoryMutationVariables = {
  input: DeleteBirthHistoryInput,
  condition?: ModelBirthHistoryConditionInput | null,
};

export type DeleteBirthHistoryMutation = {
  deleteBirthHistory?:  {
    __typename: "BirthHistory",
    id: string,
    birth_history_id?: number | null,
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    lmp?: string | null,
    miscarriage_date?: string | null,
    delivered_date?: string | null,
    child_name?: string | null,
    child_gender?: GENDER | null,
    date_of_death?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNutritionTopicMutationVariables = {
  input: CreateNutritionTopicInput,
  condition?: ModelNutritionTopicConditionInput | null,
};

export type CreateNutritionTopicMutation = {
  createNutritionTopic?:  {
    __typename: "NutritionTopic",
    id: string,
    nutrition_topic_id?: number | null,
    topic_text: string,
    topic_text_myanmar?: string | null,
    description?: string | null,
    projects?: Array< string | null > | null,
    nutrition_messages?:  {
      __typename: "ModelNutritionMessageConnection",
      items:  Array< {
        __typename: "NutritionMessage",
        id: string,
        nutrition_message_id?: number | null,
        nutrition_topic_id: string,
        message_text: string,
        message_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNutritionTopicMutationVariables = {
  input: UpdateNutritionTopicInput,
  condition?: ModelNutritionTopicConditionInput | null,
};

export type UpdateNutritionTopicMutation = {
  updateNutritionTopic?:  {
    __typename: "NutritionTopic",
    id: string,
    nutrition_topic_id?: number | null,
    topic_text: string,
    topic_text_myanmar?: string | null,
    description?: string | null,
    projects?: Array< string | null > | null,
    nutrition_messages?:  {
      __typename: "ModelNutritionMessageConnection",
      items:  Array< {
        __typename: "NutritionMessage",
        id: string,
        nutrition_message_id?: number | null,
        nutrition_topic_id: string,
        message_text: string,
        message_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNutritionTopicMutationVariables = {
  input: DeleteNutritionTopicInput,
  condition?: ModelNutritionTopicConditionInput | null,
};

export type DeleteNutritionTopicMutation = {
  deleteNutritionTopic?:  {
    __typename: "NutritionTopic",
    id: string,
    nutrition_topic_id?: number | null,
    topic_text: string,
    topic_text_myanmar?: string | null,
    description?: string | null,
    projects?: Array< string | null > | null,
    nutrition_messages?:  {
      __typename: "ModelNutritionMessageConnection",
      items:  Array< {
        __typename: "NutritionMessage",
        id: string,
        nutrition_message_id?: number | null,
        nutrition_topic_id: string,
        message_text: string,
        message_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNutritionMessageMutationVariables = {
  input: CreateNutritionMessageInput,
  condition?: ModelNutritionMessageConditionInput | null,
};

export type CreateNutritionMessageMutation = {
  createNutritionMessage?:  {
    __typename: "NutritionMessage",
    id: string,
    nutrition_message_id?: number | null,
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    message_text: string,
    message_text_myanmar?: string | null,
    description?: string | null,
    nutrition_questions?:  {
      __typename: "ModelNutritionQuestionConnection",
      items:  Array< {
        __typename: "NutritionQuestion",
        id: string,
        nutrition_question_id?: number | null,
        nutrition_message_id: string,
        question_text: string,
        question_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNutritionMessageMutationVariables = {
  input: UpdateNutritionMessageInput,
  condition?: ModelNutritionMessageConditionInput | null,
};

export type UpdateNutritionMessageMutation = {
  updateNutritionMessage?:  {
    __typename: "NutritionMessage",
    id: string,
    nutrition_message_id?: number | null,
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    message_text: string,
    message_text_myanmar?: string | null,
    description?: string | null,
    nutrition_questions?:  {
      __typename: "ModelNutritionQuestionConnection",
      items:  Array< {
        __typename: "NutritionQuestion",
        id: string,
        nutrition_question_id?: number | null,
        nutrition_message_id: string,
        question_text: string,
        question_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNutritionMessageMutationVariables = {
  input: DeleteNutritionMessageInput,
  condition?: ModelNutritionMessageConditionInput | null,
};

export type DeleteNutritionMessageMutation = {
  deleteNutritionMessage?:  {
    __typename: "NutritionMessage",
    id: string,
    nutrition_message_id?: number | null,
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    message_text: string,
    message_text_myanmar?: string | null,
    description?: string | null,
    nutrition_questions?:  {
      __typename: "ModelNutritionQuestionConnection",
      items:  Array< {
        __typename: "NutritionQuestion",
        id: string,
        nutrition_question_id?: number | null,
        nutrition_message_id: string,
        question_text: string,
        question_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNutritionQuestionMutationVariables = {
  input: CreateNutritionQuestionInput,
  condition?: ModelNutritionQuestionConditionInput | null,
};

export type CreateNutritionQuestionMutation = {
  createNutritionQuestion?:  {
    __typename: "NutritionQuestion",
    id: string,
    nutrition_question_id?: number | null,
    nutrition_message_id: string,
    nutrition_message:  {
      __typename: "NutritionMessage",
      id: string,
      nutrition_message_id?: number | null,
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      message_text: string,
      message_text_myanmar?: string | null,
      description?: string | null,
      nutrition_questions?:  {
        __typename: "ModelNutritionQuestionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    question_text: string,
    question_text_myanmar?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNutritionQuestionMutationVariables = {
  input: UpdateNutritionQuestionInput,
  condition?: ModelNutritionQuestionConditionInput | null,
};

export type UpdateNutritionQuestionMutation = {
  updateNutritionQuestion?:  {
    __typename: "NutritionQuestion",
    id: string,
    nutrition_question_id?: number | null,
    nutrition_message_id: string,
    nutrition_message:  {
      __typename: "NutritionMessage",
      id: string,
      nutrition_message_id?: number | null,
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      message_text: string,
      message_text_myanmar?: string | null,
      description?: string | null,
      nutrition_questions?:  {
        __typename: "ModelNutritionQuestionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    question_text: string,
    question_text_myanmar?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNutritionQuestionMutationVariables = {
  input: DeleteNutritionQuestionInput,
  condition?: ModelNutritionQuestionConditionInput | null,
};

export type DeleteNutritionQuestionMutation = {
  deleteNutritionQuestion?:  {
    __typename: "NutritionQuestion",
    id: string,
    nutrition_question_id?: number | null,
    nutrition_message_id: string,
    nutrition_message:  {
      __typename: "NutritionMessage",
      id: string,
      nutrition_message_id?: number | null,
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      message_text: string,
      message_text_myanmar?: string | null,
      description?: string | null,
      nutrition_questions?:  {
        __typename: "ModelNutritionQuestionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    question_text: string,
    question_text_myanmar?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCGMeetingMutationVariables = {
  input: CreateCGMeetingInput,
  condition?: ModelCGMeetingConditionInput | null,
};

export type CreateCGMeetingMutation = {
  createCGMeeting?:  {
    __typename: "CGMeeting",
    id: string,
    cg_meeting_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCGMeetingMutationVariables = {
  input: UpdateCGMeetingInput,
  condition?: ModelCGMeetingConditionInput | null,
};

export type UpdateCGMeetingMutation = {
  updateCGMeeting?:  {
    __typename: "CGMeeting",
    id: string,
    cg_meeting_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCGMeetingMutationVariables = {
  input: DeleteCGMeetingInput,
  condition?: ModelCGMeetingConditionInput | null,
};

export type DeleteCGMeetingMutation = {
  deleteCGMeeting?:  {
    __typename: "CGMeeting",
    id: string,
    cg_meeting_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCGMeetingNutritionTopicMutationVariables = {
  input: CreateCGMeetingNutritionTopicInput,
  condition?: ModelCGMeetingNutritionTopicConditionInput | null,
};

export type CreateCGMeetingNutritionTopicMutation = {
  createCGMeetingNutritionTopic?:  {
    __typename: "CGMeetingNutritionTopic",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCGMeetingNutritionTopicMutationVariables = {
  input: UpdateCGMeetingNutritionTopicInput,
  condition?: ModelCGMeetingNutritionTopicConditionInput | null,
};

export type UpdateCGMeetingNutritionTopicMutation = {
  updateCGMeetingNutritionTopic?:  {
    __typename: "CGMeetingNutritionTopic",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCGMeetingNutritionTopicMutationVariables = {
  input: DeleteCGMeetingNutritionTopicInput,
  condition?: ModelCGMeetingNutritionTopicConditionInput | null,
};

export type DeleteCGMeetingNutritionTopicMutation = {
  deleteCGMeetingNutritionTopic?:  {
    __typename: "CGMeetingNutritionTopic",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCGMeetingStaffMutationVariables = {
  input: CreateCGMeetingStaffInput,
  condition?: ModelCGMeetingStaffConditionInput | null,
};

export type CreateCGMeetingStaffMutation = {
  createCGMeetingStaff?:  {
    __typename: "CGMeetingStaff",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCGMeetingStaffMutationVariables = {
  input: UpdateCGMeetingStaffInput,
  condition?: ModelCGMeetingStaffConditionInput | null,
};

export type UpdateCGMeetingStaffMutation = {
  updateCGMeetingStaff?:  {
    __typename: "CGMeetingStaff",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCGMeetingStaffMutationVariables = {
  input: DeleteCGMeetingStaffInput,
  condition?: ModelCGMeetingStaffConditionInput | null,
};

export type DeleteCGMeetingStaffMutation = {
  deleteCGMeetingStaff?:  {
    __typename: "CGMeetingStaff",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCGMeetingPLWMutationVariables = {
  input: CreateCGMeetingPLWInput,
  condition?: ModelCGMeetingPLWConditionInput | null,
};

export type CreateCGMeetingPLWMutation = {
  createCGMeetingPLW?:  {
    __typename: "CGMeetingPLW",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCGMeetingPLWMutationVariables = {
  input: UpdateCGMeetingPLWInput,
  condition?: ModelCGMeetingPLWConditionInput | null,
};

export type UpdateCGMeetingPLWMutation = {
  updateCGMeetingPLW?:  {
    __typename: "CGMeetingPLW",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCGMeetingPLWMutationVariables = {
  input: DeleteCGMeetingPLWInput,
  condition?: ModelCGMeetingPLWConditionInput | null,
};

export type DeleteCGMeetingPLWMutation = {
  deleteCGMeetingPLW?:  {
    __typename: "CGMeetingPLW",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNGMeetingMutationVariables = {
  input: CreateNGMeetingInput,
  condition?: ModelNGMeetingConditionInput | null,
};

export type CreateNGMeetingMutation = {
  createNGMeeting?:  {
    __typename: "NGMeeting",
    id: string,
    ng_meeting_id?: number | null,
    project_diocese_code: string,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_facilitator_id: string,
    plw_facilitator:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff_facilitators?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNGMeetingMutationVariables = {
  input: UpdateNGMeetingInput,
  condition?: ModelNGMeetingConditionInput | null,
};

export type UpdateNGMeetingMutation = {
  updateNGMeeting?:  {
    __typename: "NGMeeting",
    id: string,
    ng_meeting_id?: number | null,
    project_diocese_code: string,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_facilitator_id: string,
    plw_facilitator:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff_facilitators?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNGMeetingMutationVariables = {
  input: DeleteNGMeetingInput,
  condition?: ModelNGMeetingConditionInput | null,
};

export type DeleteNGMeetingMutation = {
  deleteNGMeeting?:  {
    __typename: "NGMeeting",
    id: string,
    ng_meeting_id?: number | null,
    project_diocese_code: string,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_facilitator_id: string,
    plw_facilitator:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff_facilitators?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNGMeetingNutritionTopicMutationVariables = {
  input: CreateNGMeetingNutritionTopicInput,
  condition?: ModelNGMeetingNutritionTopicConditionInput | null,
};

export type CreateNGMeetingNutritionTopicMutation = {
  createNGMeetingNutritionTopic?:  {
    __typename: "NGMeetingNutritionTopic",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNGMeetingNutritionTopicMutationVariables = {
  input: UpdateNGMeetingNutritionTopicInput,
  condition?: ModelNGMeetingNutritionTopicConditionInput | null,
};

export type UpdateNGMeetingNutritionTopicMutation = {
  updateNGMeetingNutritionTopic?:  {
    __typename: "NGMeetingNutritionTopic",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNGMeetingNutritionTopicMutationVariables = {
  input: DeleteNGMeetingNutritionTopicInput,
  condition?: ModelNGMeetingNutritionTopicConditionInput | null,
};

export type DeleteNGMeetingNutritionTopicMutation = {
  deleteNGMeetingNutritionTopic?:  {
    __typename: "NGMeetingNutritionTopic",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNGMeetingStaffFacilitatorMutationVariables = {
  input: CreateNGMeetingStaffFacilitatorInput,
  condition?: ModelNGMeetingStaffFacilitatorConditionInput | null,
};

export type CreateNGMeetingStaffFacilitatorMutation = {
  createNGMeetingStaffFacilitator?:  {
    __typename: "NGMeetingStaffFacilitator",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNGMeetingStaffFacilitatorMutationVariables = {
  input: UpdateNGMeetingStaffFacilitatorInput,
  condition?: ModelNGMeetingStaffFacilitatorConditionInput | null,
};

export type UpdateNGMeetingStaffFacilitatorMutation = {
  updateNGMeetingStaffFacilitator?:  {
    __typename: "NGMeetingStaffFacilitator",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNGMeetingStaffFacilitatorMutationVariables = {
  input: DeleteNGMeetingStaffFacilitatorInput,
  condition?: ModelNGMeetingStaffFacilitatorConditionInput | null,
};

export type DeleteNGMeetingStaffFacilitatorMutation = {
  deleteNGMeetingStaffFacilitator?:  {
    __typename: "NGMeetingStaffFacilitator",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNGMeetingPLWMutationVariables = {
  input: CreateNGMeetingPLWInput,
  condition?: ModelNGMeetingPLWConditionInput | null,
};

export type CreateNGMeetingPLWMutation = {
  createNGMeetingPLW?:  {
    __typename: "NGMeetingPLW",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNGMeetingPLWMutationVariables = {
  input: UpdateNGMeetingPLWInput,
  condition?: ModelNGMeetingPLWConditionInput | null,
};

export type UpdateNGMeetingPLWMutation = {
  updateNGMeetingPLW?:  {
    __typename: "NGMeetingPLW",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNGMeetingPLWMutationVariables = {
  input: DeleteNGMeetingPLWInput,
  condition?: ModelNGMeetingPLWConditionInput | null,
};

export type DeleteNGMeetingPLWMutation = {
  deleteNGMeetingPLW?:  {
    __typename: "NGMeetingPLW",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDistributionItemMutationVariables = {
  input: CreateDistributionItemInput,
  condition?: ModelDistributionItemConditionInput | null,
};

export type CreateDistributionItemMutation = {
  createDistributionItem?:  {
    __typename: "DistributionItem",
    id: string,
    distribution_item_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    title: string,
    description?: string | null,
    planned_distribution_start_date?: string | null,
    planned_distribution_end_date?: string | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDistributionItemMutationVariables = {
  input: UpdateDistributionItemInput,
  condition?: ModelDistributionItemConditionInput | null,
};

export type UpdateDistributionItemMutation = {
  updateDistributionItem?:  {
    __typename: "DistributionItem",
    id: string,
    distribution_item_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    title: string,
    description?: string | null,
    planned_distribution_start_date?: string | null,
    planned_distribution_end_date?: string | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDistributionItemMutationVariables = {
  input: DeleteDistributionItemInput,
  condition?: ModelDistributionItemConditionInput | null,
};

export type DeleteDistributionItemMutation = {
  deleteDistributionItem?:  {
    __typename: "DistributionItem",
    id: string,
    distribution_item_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    title: string,
    description?: string | null,
    planned_distribution_start_date?: string | null,
    planned_distribution_end_date?: string | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDistributionMutationVariables = {
  input: CreateDistributionInput,
  condition?: ModelDistributionConditionInput | null,
};

export type CreateDistributionMutation = {
  createDistribution?:  {
    __typename: "Distribution",
    id: string,
    distribution_id?: number | null,
    project_diocese_code: string,
    distribution_item_id: string,
    distribution_item:  {
      __typename: "DistributionItem",
      id: string,
      distribution_item_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    distribution_date?: string | null,
    description?: string | null,
    recipients?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDistributionMutationVariables = {
  input: UpdateDistributionInput,
  condition?: ModelDistributionConditionInput | null,
};

export type UpdateDistributionMutation = {
  updateDistribution?:  {
    __typename: "Distribution",
    id: string,
    distribution_id?: number | null,
    project_diocese_code: string,
    distribution_item_id: string,
    distribution_item:  {
      __typename: "DistributionItem",
      id: string,
      distribution_item_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    distribution_date?: string | null,
    description?: string | null,
    recipients?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDistributionMutationVariables = {
  input: DeleteDistributionInput,
  condition?: ModelDistributionConditionInput | null,
};

export type DeleteDistributionMutation = {
  deleteDistribution?:  {
    __typename: "Distribution",
    id: string,
    distribution_id?: number | null,
    project_diocese_code: string,
    distribution_item_id: string,
    distribution_item:  {
      __typename: "DistributionItem",
      id: string,
      distribution_item_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    distribution_date?: string | null,
    description?: string | null,
    recipients?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDistributionRecipientsMutationVariables = {
  input: CreateDistributionRecipientsInput,
  condition?: ModelDistributionRecipientsConditionInput | null,
};

export type CreateDistributionRecipientsMutation = {
  createDistributionRecipients?:  {
    __typename: "DistributionRecipients",
    id: string,
    distribution_id: string,
    distribution:  {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDistributionRecipientsMutationVariables = {
  input: UpdateDistributionRecipientsInput,
  condition?: ModelDistributionRecipientsConditionInput | null,
};

export type UpdateDistributionRecipientsMutation = {
  updateDistributionRecipients?:  {
    __typename: "DistributionRecipients",
    id: string,
    distribution_id: string,
    distribution:  {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDistributionRecipientsMutationVariables = {
  input: DeleteDistributionRecipientsInput,
  condition?: ModelDistributionRecipientsConditionInput | null,
};

export type DeleteDistributionRecipientsMutation = {
  deleteDistributionRecipients?:  {
    __typename: "DistributionRecipients",
    id: string,
    distribution_id: string,
    distribution:  {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOtherActivityTypeMutationVariables = {
  input: CreateOtherActivityTypeInput,
  condition?: ModelOtherActivityTypeConditionInput | null,
};

export type CreateOtherActivityTypeMutation = {
  createOtherActivityType?:  {
    __typename: "OtherActivityType",
    id: string,
    other_activity_type_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    activity_name: string,
    description?: string | null,
    activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOtherActivityTypeMutationVariables = {
  input: UpdateOtherActivityTypeInput,
  condition?: ModelOtherActivityTypeConditionInput | null,
};

export type UpdateOtherActivityTypeMutation = {
  updateOtherActivityType?:  {
    __typename: "OtherActivityType",
    id: string,
    other_activity_type_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    activity_name: string,
    description?: string | null,
    activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOtherActivityTypeMutationVariables = {
  input: DeleteOtherActivityTypeInput,
  condition?: ModelOtherActivityTypeConditionInput | null,
};

export type DeleteOtherActivityTypeMutation = {
  deleteOtherActivityType?:  {
    __typename: "OtherActivityType",
    id: string,
    other_activity_type_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    activity_name: string,
    description?: string | null,
    activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOtherActivityMutationVariables = {
  input: CreateOtherActivityInput,
  condition?: ModelOtherActivityConditionInput | null,
};

export type CreateOtherActivityMutation = {
  createOtherActivity?:  {
    __typename: "OtherActivity",
    id: string,
    other_activity_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    other_activity_type_id: string,
    other_activity_type:  {
      __typename: "OtherActivityType",
      id: string,
      other_activity_type_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      activity_name: string,
      description?: string | null,
      activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    activity_date: string,
    description?: string | null,
    facilitators?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOtherActivityMutationVariables = {
  input: UpdateOtherActivityInput,
  condition?: ModelOtherActivityConditionInput | null,
};

export type UpdateOtherActivityMutation = {
  updateOtherActivity?:  {
    __typename: "OtherActivity",
    id: string,
    other_activity_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    other_activity_type_id: string,
    other_activity_type:  {
      __typename: "OtherActivityType",
      id: string,
      other_activity_type_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      activity_name: string,
      description?: string | null,
      activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    activity_date: string,
    description?: string | null,
    facilitators?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOtherActivityMutationVariables = {
  input: DeleteOtherActivityInput,
  condition?: ModelOtherActivityConditionInput | null,
};

export type DeleteOtherActivityMutation = {
  deleteOtherActivity?:  {
    __typename: "OtherActivity",
    id: string,
    other_activity_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    other_activity_type_id: string,
    other_activity_type:  {
      __typename: "OtherActivityType",
      id: string,
      other_activity_type_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      activity_name: string,
      description?: string | null,
      activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    activity_date: string,
    description?: string | null,
    facilitators?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOtherActivityFacilitatorsMutationVariables = {
  input: CreateOtherActivityFacilitatorsInput,
  condition?: ModelOtherActivityFacilitatorsConditionInput | null,
};

export type CreateOtherActivityFacilitatorsMutation = {
  createOtherActivityFacilitators?:  {
    __typename: "OtherActivityFacilitators",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOtherActivityFacilitatorsMutationVariables = {
  input: UpdateOtherActivityFacilitatorsInput,
  condition?: ModelOtherActivityFacilitatorsConditionInput | null,
};

export type UpdateOtherActivityFacilitatorsMutation = {
  updateOtherActivityFacilitators?:  {
    __typename: "OtherActivityFacilitators",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOtherActivityFacilitatorsMutationVariables = {
  input: DeleteOtherActivityFacilitatorsInput,
  condition?: ModelOtherActivityFacilitatorsConditionInput | null,
};

export type DeleteOtherActivityFacilitatorsMutation = {
  deleteOtherActivityFacilitators?:  {
    __typename: "OtherActivityFacilitators",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOtherActivityParticipantsMutationVariables = {
  input: CreateOtherActivityParticipantsInput,
  condition?: ModelOtherActivityParticipantsConditionInput | null,
};

export type CreateOtherActivityParticipantsMutation = {
  createOtherActivityParticipants?:  {
    __typename: "OtherActivityParticipants",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOtherActivityParticipantsMutationVariables = {
  input: UpdateOtherActivityParticipantsInput,
  condition?: ModelOtherActivityParticipantsConditionInput | null,
};

export type UpdateOtherActivityParticipantsMutation = {
  updateOtherActivityParticipants?:  {
    __typename: "OtherActivityParticipants",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOtherActivityParticipantsMutationVariables = {
  input: DeleteOtherActivityParticipantsInput,
  condition?: ModelOtherActivityParticipantsConditionInput | null,
};

export type DeleteOtherActivityParticipantsMutation = {
  deleteOtherActivityParticipants?:  {
    __typename: "OtherActivityParticipants",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHgTrainingTopicMutationVariables = {
  input: CreateHgTrainingTopicInput,
  condition?: ModelHgTrainingTopicConditionInput | null,
};

export type CreateHgTrainingTopicMutation = {
  createHgTrainingTopic?:  {
    __typename: "HgTrainingTopic",
    id: string,
    hg_training_topic_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    topic: string,
    description?: string | null,
    training?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHgTrainingTopicMutationVariables = {
  input: UpdateHgTrainingTopicInput,
  condition?: ModelHgTrainingTopicConditionInput | null,
};

export type UpdateHgTrainingTopicMutation = {
  updateHgTrainingTopic?:  {
    __typename: "HgTrainingTopic",
    id: string,
    hg_training_topic_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    topic: string,
    description?: string | null,
    training?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHgTrainingTopicMutationVariables = {
  input: DeleteHgTrainingTopicInput,
  condition?: ModelHgTrainingTopicConditionInput | null,
};

export type DeleteHgTrainingTopicMutation = {
  deleteHgTrainingTopic?:  {
    __typename: "HgTrainingTopic",
    id: string,
    hg_training_topic_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    topic: string,
    description?: string | null,
    training?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHgTrainingMutationVariables = {
  input: CreateHgTrainingInput,
  condition?: ModelHgTrainingConditionInput | null,
};

export type CreateHgTrainingMutation = {
  createHgTraining?:  {
    __typename: "HgTraining",
    id: string,
    hg_training_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    training_date: string,
    description?: string | null,
    topics?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHgTrainingMutationVariables = {
  input: UpdateHgTrainingInput,
  condition?: ModelHgTrainingConditionInput | null,
};

export type UpdateHgTrainingMutation = {
  updateHgTraining?:  {
    __typename: "HgTraining",
    id: string,
    hg_training_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    training_date: string,
    description?: string | null,
    topics?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHgTrainingMutationVariables = {
  input: DeleteHgTrainingInput,
  condition?: ModelHgTrainingConditionInput | null,
};

export type DeleteHgTrainingMutation = {
  deleteHgTraining?:  {
    __typename: "HgTraining",
    id: string,
    hg_training_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    training_date: string,
    description?: string | null,
    topics?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHgTrainingTrainingTopicMutationVariables = {
  input: CreateHgTrainingTrainingTopicInput,
  condition?: ModelHgTrainingTrainingTopicConditionInput | null,
};

export type CreateHgTrainingTrainingTopicMutation = {
  createHgTrainingTrainingTopic?:  {
    __typename: "HgTrainingTrainingTopic",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    hg_training_topic_id: string,
    hg_training_topic:  {
      __typename: "HgTrainingTopic",
      id: string,
      hg_training_topic_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      topic: string,
      description?: string | null,
      training?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHgTrainingTrainingTopicMutationVariables = {
  input: UpdateHgTrainingTrainingTopicInput,
  condition?: ModelHgTrainingTrainingTopicConditionInput | null,
};

export type UpdateHgTrainingTrainingTopicMutation = {
  updateHgTrainingTrainingTopic?:  {
    __typename: "HgTrainingTrainingTopic",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    hg_training_topic_id: string,
    hg_training_topic:  {
      __typename: "HgTrainingTopic",
      id: string,
      hg_training_topic_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      topic: string,
      description?: string | null,
      training?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHgTrainingTrainingTopicMutationVariables = {
  input: DeleteHgTrainingTrainingTopicInput,
  condition?: ModelHgTrainingTrainingTopicConditionInput | null,
};

export type DeleteHgTrainingTrainingTopicMutation = {
  deleteHgTrainingTrainingTopic?:  {
    __typename: "HgTrainingTrainingTopic",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    hg_training_topic_id: string,
    hg_training_topic:  {
      __typename: "HgTrainingTopic",
      id: string,
      hg_training_topic_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      topic: string,
      description?: string | null,
      training?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHgTrainingFacilitatorsMutationVariables = {
  input: CreateHgTrainingFacilitatorsInput,
  condition?: ModelHgTrainingFacilitatorsConditionInput | null,
};

export type CreateHgTrainingFacilitatorsMutation = {
  createHgTrainingFacilitators?:  {
    __typename: "HgTrainingFacilitators",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHgTrainingFacilitatorsMutationVariables = {
  input: UpdateHgTrainingFacilitatorsInput,
  condition?: ModelHgTrainingFacilitatorsConditionInput | null,
};

export type UpdateHgTrainingFacilitatorsMutation = {
  updateHgTrainingFacilitators?:  {
    __typename: "HgTrainingFacilitators",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHgTrainingFacilitatorsMutationVariables = {
  input: DeleteHgTrainingFacilitatorsInput,
  condition?: ModelHgTrainingFacilitatorsConditionInput | null,
};

export type DeleteHgTrainingFacilitatorsMutation = {
  deleteHgTrainingFacilitators?:  {
    __typename: "HgTrainingFacilitators",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHgTrainingParticipantsMutationVariables = {
  input: CreateHgTrainingParticipantsInput,
  condition?: ModelHgTrainingParticipantsConditionInput | null,
};

export type CreateHgTrainingParticipantsMutation = {
  createHgTrainingParticipants?:  {
    __typename: "HgTrainingParticipants",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHgTrainingParticipantsMutationVariables = {
  input: UpdateHgTrainingParticipantsInput,
  condition?: ModelHgTrainingParticipantsConditionInput | null,
};

export type UpdateHgTrainingParticipantsMutation = {
  updateHgTrainingParticipants?:  {
    __typename: "HgTrainingParticipants",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHgTrainingParticipantsMutationVariables = {
  input: DeleteHgTrainingParticipantsInput,
  condition?: ModelHgTrainingParticipantsConditionInput | null,
};

export type DeleteHgTrainingParticipantsMutation = {
  deleteHgTrainingParticipants?:  {
    __typename: "HgTrainingParticipants",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    project_id?: number | null,
    project_code: string,
    name: string,
    sector: PROGRAM_SECTORS,
    description?: string | null,
    donor?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distribution_items?:  {
      __typename: "ModelDistributionItemConnection",
      items:  Array< {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activitie_types?:  {
      __typename: "ModelOtherActivityTypeConnection",
      items:  Array< {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_topics?:  {
      __typename: "ModelHgTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTopic",
        id: string,
        hg_training_topic_id?: number | null,
        project_id: string,
        project_code: string,
        topic: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProjectsQueryVariables = {
  id?: string | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDioceseQueryVariables = {
  id: string,
};

export type GetDioceseQuery = {
  getDiocese?:  {
    __typename: "Diocese",
    id: string,
    diocese_id?: number | null,
    diocese_code: string,
    name: string,
    description?: string | null,
    project_dioceses?:  {
      __typename: "ModelProjectDioceseConnection",
      items:  Array< {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    townships?:  {
      __typename: "ModelTownshipConnection",
      items:  Array< {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDioceseQueryVariables = {
  id?: string | null,
  filter?: ModelDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDioceseQuery = {
  listDiocese?:  {
    __typename: "ModelDioceseConnection",
    items:  Array< {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectDioceseQueryVariables = {
  id: string,
};

export type GetProjectDioceseQuery = {
  getProjectDiocese?:  {
    __typename: "ProjectDiocese",
    id: string,
    project_diocese_id?: number | null,
    projectID: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    dioceseID: string,
    diocese:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProjectDioceseQueryVariables = {
  id?: string | null,
  filter?: ModelProjectDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProjectDioceseQuery = {
  listProjectDiocese?:  {
    __typename: "ModelProjectDioceseConnection",
    items:  Array< {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTownshipQueryVariables = {
  id: string,
};

export type GetTownshipQuery = {
  getTownship?:  {
    __typename: "Township",
    id: string,
    ts_pcode?: string | null,
    d_pcode?: string | null,
    sr_pcode?: string | null,
    name: string,
    name_mm?: string | null,
    diocese_id?: string | null,
    diocese?:  {
      __typename: "Diocese",
      id: string,
      diocese_id?: number | null,
      diocese_code: string,
      name: string,
      description?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      townships?:  {
        __typename: "ModelTownshipConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villageTracts?:  {
      __typename: "ModelVillageTractConnection",
      items:  Array< {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    camps?:  {
      __typename: "ModelCampConnection",
      items:  Array< {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    care_groups?:  {
      __typename: "ModelCareGroupConnection",
      items:  Array< {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTownshipsQueryVariables = {
  id?: string | null,
  filter?: ModelTownshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTownshipsQuery = {
  listTownships?:  {
    __typename: "ModelTownshipConnection",
    items:  Array< {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVillageTractQueryVariables = {
  id: string,
};

export type GetVillageTractQuery = {
  getVillageTract?:  {
    __typename: "VillageTract",
    id: string,
    vt_pcode?: string | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    diocese_code?: string | null,
    active?: boolean | null,
    villages?:  {
      __typename: "ModelVillageConnection",
      items:  Array< {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVillageTractsQueryVariables = {
  id?: string | null,
  filter?: ModelVillageTractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVillageTractsQuery = {
  listVillageTracts?:  {
    __typename: "ModelVillageTractConnection",
    items:  Array< {
      __typename: "VillageTract",
      id: string,
      vt_pcode?: string | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villages?:  {
        __typename: "ModelVillageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVillageQueryVariables = {
  id: string,
};

export type GetVillageQuery = {
  getVillage?:  {
    __typename: "Village",
    id: string,
    village_pcode?: string | null,
    villageTract_id: string,
    villageTract:  {
      __typename: "VillageTract",
      id: string,
      vt_pcode?: string | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villages?:  {
        __typename: "ModelVillageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    alt_name?: string | null,
    alt_name_mm?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVillagesQueryVariables = {
  id?: string | null,
  filter?: ModelVillageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVillagesQuery = {
  listVillages?:  {
    __typename: "ModelVillageConnection",
    items:  Array< {
      __typename: "Village",
      id: string,
      village_pcode?: string | null,
      villageTract_id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      alt_name?: string | null,
      alt_name_mm?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampQueryVariables = {
  id: string,
};

export type GetCampQuery = {
  getCamp?:  {
    __typename: "Camp",
    id: string,
    camp_id?: number | null,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    name_mm?: string | null,
    description?: string | null,
    longitude?: number | null,
    latitude?: number | null,
    diocese_code?: string | null,
    active?: boolean | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCampsQueryVariables = {
  id?: string | null,
  filter?: ModelCampFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCampsQuery = {
  listCamps?:  {
    __typename: "ModelCampConnection",
    items:  Array< {
      __typename: "Camp",
      id: string,
      camp_id?: number | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      description?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCareGroupQueryVariables = {
  id: string,
};

export type GetCareGroupQuery = {
  getCareGroup?:  {
    __typename: "CareGroup",
    id: string,
    care_group_id?: number | null,
    group_number: string,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    township_id: string,
    township:  {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroups?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    neighbor_groups?:  {
      __typename: "ModelNeighborGroupConnection",
      items:  Array< {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingConnection",
      items:  Array< {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings?:  {
      __typename: "ModelHgTrainingConnection",
      items:  Array< {
        __typename: "HgTraining",
        id: string,
        hg_training_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        training_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCareGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelCareGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCareGroupsQuery = {
  listCareGroups?:  {
    __typename: "ModelCareGroupConnection",
    items:  Array< {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPositionQueryVariables = {
  id: string,
};

export type GetPositionQuery = {
  getPosition?:  {
    __typename: "Position",
    id: string,
    position_id?: number | null,
    name: string,
    level?: POSITION_LEVELS | null,
    description?: string | null,
    staff?:  {
      __typename: "ModelStaffConnection",
      items:  Array< {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPositionsQueryVariables = {
  id?: string | null,
  filter?: ModelPositionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPositionsQuery = {
  listPositions?:  {
    __typename: "ModelPositionConnection",
    items:  Array< {
      __typename: "Position",
      id: string,
      position_id?: number | null,
      name: string,
      level?: POSITION_LEVELS | null,
      description?: string | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStaffQueryVariables = {
  id: string,
};

export type GetStaffQuery = {
  getStaff?:  {
    __typename: "Staff",
    id: string,
    staff_id?: number | null,
    project_diocese_id: string,
    project_diocese:  {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    position_id: string,
    position:  {
      __typename: "Position",
      id: string,
      position_id?: number | null,
      name: string,
      level?: POSITION_LEVELS | null,
      description?: string | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_caregroup?:  {
      __typename: "ModelStaffCareGroupConnection",
      items:  Array< {
        __typename: "StaffCareGroup",
        id: string,
        staff_caregroup_id?: number | null,
        care_group_id: string,
        staff_id: string,
        current_assignment?: boolean | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    current_position?: boolean | null,
    from_date?: string | null,
    to_date?: string | null,
    email: string,
    first_name?: string | null,
    last_name?: string | null,
    gender?: GENDER | null,
    is_staff?: boolean | null,
    is_active?: boolean | null,
    date_joined?: string | null,
    cg_meetings_facilitated?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_facilitated?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_trainings_facilitated?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStaffQueryVariables = {
  id?: string | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStaffQuery = {
  listStaff?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStaffCareGroupQueryVariables = {
  id: string,
};

export type GetStaffCareGroupQuery = {
  getStaffCareGroup?:  {
    __typename: "StaffCareGroup",
    id: string,
    staff_caregroup_id?: number | null,
    care_group_id: string,
    care_group?:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    current_assignment?: boolean | null,
    start_date?: string | null,
    end_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStaffCareGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelStaffCareGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStaffCareGroupsQuery = {
  listStaffCareGroups?:  {
    __typename: "ModelStaffCareGroupConnection",
    items:  Array< {
      __typename: "StaffCareGroup",
      id: string,
      staff_caregroup_id?: number | null,
      care_group_id: string,
      care_group?:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      staff_id: string,
      staff:  {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      current_assignment?: boolean | null,
      start_date?: string | null,
      end_date?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNeighborGroupQueryVariables = {
  id: string,
};

export type GetNeighborGroupQuery = {
  getNeighborGroup?:  {
    __typename: "NeighborGroup",
    id: string,
    neighbor_group_id?: number | null,
    group_number: string,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    village_id?: string | null,
    village?:  {
      __typename: "Village",
      id: string,
      village_pcode?: string | null,
      villageTract_id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      alt_name?: string | null,
      alt_name_mm?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    camp_id?: string | null,
    camp?:  {
      __typename: "Camp",
      id: string,
      camp_id?: number | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      description?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNeighborGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelNeighborGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNeighborGroupsQuery = {
  listNeighborGroups?:  {
    __typename: "ModelNeighborGroupConnection",
    items:  Array< {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEducationLevelQueryVariables = {
  id: string,
};

export type GetEducationLevelQuery = {
  getEducationLevel?:  {
    __typename: "EducationLevel",
    id: string,
    education_level_id?: number | null,
    education_level: string,
    education_level_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEducationLevelsQueryVariables = {
  id?: string | null,
  filter?: ModelEducationLevelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEducationLevelsQuery = {
  listEducationLevels?:  {
    __typename: "ModelEducationLevelConnection",
    items:  Array< {
      __typename: "EducationLevel",
      id: string,
      education_level_id?: number | null,
      education_level: string,
      education_level_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOccupationTypeQueryVariables = {
  id: string,
};

export type GetOccupationTypeQuery = {
  getOccupationType?:  {
    __typename: "OccupationType",
    id: string,
    occupation_type_id?: number | null,
    occupation_type: string,
    occupation_type_mm?: string | null,
    description?: string | null,
    order?: number | null,
    plws?:  {
      __typename: "ModelPLWConnection",
      items:  Array< {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOccupationTypesQueryVariables = {
  id?: string | null,
  filter?: ModelOccupationTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOccupationTypesQuery = {
  listOccupationTypes?:  {
    __typename: "ModelOccupationTypeConnection",
    items:  Array< {
      __typename: "OccupationType",
      id: string,
      occupation_type_id?: number | null,
      occupation_type: string,
      occupation_type_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDisabilityTypeQueryVariables = {
  id: string,
};

export type GetDisabilityTypeQuery = {
  getDisabilityType?:  {
    __typename: "DisabilityType",
    id: string,
    disability_type_id?: number | null,
    disability_type: string,
    description: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDisabilityTypesQueryVariables = {
  id?: string | null,
  filter?: ModelDisabilityTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDisabilityTypesQuery = {
  listDisabilityTypes?:  {
    __typename: "ModelDisabilityTypeConnection",
    items:  Array< {
      __typename: "DisabilityType",
      id: string,
      disability_type_id?: number | null,
      disability_type: string,
      description: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPLWQueryVariables = {
  id: string,
};

export type GetPLWQuery = {
  getPLW?:  {
    __typename: "PLW",
    id: string,
    plw_id?: number | null,
    member_number: number,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    date_of_registry?: string | null,
    lead_mother?: boolean | null,
    name: string,
    father_name?: string | null,
    date_of_birth?: string | null,
    education_level_id?: string | null,
    education_level?:  {
      __typename: "EducationLevel",
      id: string,
      education_level_id?: number | null,
      education_level: string,
      education_level_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupation_type_id?: string | null,
    occupation_type?:  {
      __typename: "OccupationType",
      id: string,
      occupation_type_id?: number | null,
      occupation_type: string,
      occupation_type_mm?: string | null,
      description?: string | null,
      order?: number | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    disabled?: boolean | null,
    disability_type?: Array< string | null > | null,
    disability_type_other_description?: string | null,
    contact_number?: string | null,
    date_of_death?: string | null,
    lost_contact?: boolean | null,
    birth_histories?:  {
      __typename: "ModelBirthHistoryConnection",
      items:  Array< {
        __typename: "BirthHistory",
        id: string,
        birth_history_id?: number | null,
        plw_id: string,
        project_diocese_code: string,
        lmp?: string | null,
        miscarriage_date?: string | null,
        delivered_date?: string | null,
        child_name?: string | null,
        child_gender?: GENDER | null,
        date_of_death?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings_attended?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_facilitated?:  {
      __typename: "ModelNGMeetingConnection",
      items:  Array< {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings_attended?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    distributions_recieved?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    other_activities_attended?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    hg_training_attended?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPLWSQueryVariables = {
  id?: string | null,
  filter?: ModelPLWFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPLWSQuery = {
  listPLWS?:  {
    __typename: "ModelPLWConnection",
    items:  Array< {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBirthHistoryQueryVariables = {
  id: string,
};

export type GetBirthHistoryQuery = {
  getBirthHistory?:  {
    __typename: "BirthHistory",
    id: string,
    birth_history_id?: number | null,
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    lmp?: string | null,
    miscarriage_date?: string | null,
    delivered_date?: string | null,
    child_name?: string | null,
    child_gender?: GENDER | null,
    date_of_death?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBirthHistoriesQueryVariables = {
  id?: string | null,
  filter?: ModelBirthHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBirthHistoriesQuery = {
  listBirthHistories?:  {
    __typename: "ModelBirthHistoryConnection",
    items:  Array< {
      __typename: "BirthHistory",
      id: string,
      birth_history_id?: number | null,
      plw_id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      lmp?: string | null,
      miscarriage_date?: string | null,
      delivered_date?: string | null,
      child_name?: string | null,
      child_gender?: GENDER | null,
      date_of_death?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNutritionTopicQueryVariables = {
  id: string,
};

export type GetNutritionTopicQuery = {
  getNutritionTopic?:  {
    __typename: "NutritionTopic",
    id: string,
    nutrition_topic_id?: number | null,
    topic_text: string,
    topic_text_myanmar?: string | null,
    description?: string | null,
    projects?: Array< string | null > | null,
    nutrition_messages?:  {
      __typename: "ModelNutritionMessageConnection",
      items:  Array< {
        __typename: "NutritionMessage",
        id: string,
        nutrition_message_id?: number | null,
        nutrition_topic_id: string,
        message_text: string,
        message_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cg_meetings?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ng_meetings?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNutritionTopicsQueryVariables = {
  id?: string | null,
  filter?: ModelNutritionTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNutritionTopicsQuery = {
  listNutritionTopics?:  {
    __typename: "ModelNutritionTopicConnection",
    items:  Array< {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNutritionMessageQueryVariables = {
  id: string,
};

export type GetNutritionMessageQuery = {
  getNutritionMessage?:  {
    __typename: "NutritionMessage",
    id: string,
    nutrition_message_id?: number | null,
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    message_text: string,
    message_text_myanmar?: string | null,
    description?: string | null,
    nutrition_questions?:  {
      __typename: "ModelNutritionQuestionConnection",
      items:  Array< {
        __typename: "NutritionQuestion",
        id: string,
        nutrition_question_id?: number | null,
        nutrition_message_id: string,
        question_text: string,
        question_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNutritionMessagesQueryVariables = {
  id?: string | null,
  filter?: ModelNutritionMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNutritionMessagesQuery = {
  listNutritionMessages?:  {
    __typename: "ModelNutritionMessageConnection",
    items:  Array< {
      __typename: "NutritionMessage",
      id: string,
      nutrition_message_id?: number | null,
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      message_text: string,
      message_text_myanmar?: string | null,
      description?: string | null,
      nutrition_questions?:  {
        __typename: "ModelNutritionQuestionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNutritionQuestionQueryVariables = {
  id: string,
};

export type GetNutritionQuestionQuery = {
  getNutritionQuestion?:  {
    __typename: "NutritionQuestion",
    id: string,
    nutrition_question_id?: number | null,
    nutrition_message_id: string,
    nutrition_message:  {
      __typename: "NutritionMessage",
      id: string,
      nutrition_message_id?: number | null,
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      message_text: string,
      message_text_myanmar?: string | null,
      description?: string | null,
      nutrition_questions?:  {
        __typename: "ModelNutritionQuestionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    question_text: string,
    question_text_myanmar?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNutritionQuestionsQueryVariables = {
  id?: string | null,
  filter?: ModelNutritionQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNutritionQuestionsQuery = {
  listNutritionQuestions?:  {
    __typename: "ModelNutritionQuestionConnection",
    items:  Array< {
      __typename: "NutritionQuestion",
      id: string,
      nutrition_question_id?: number | null,
      nutrition_message_id: string,
      nutrition_message:  {
        __typename: "NutritionMessage",
        id: string,
        nutrition_message_id?: number | null,
        nutrition_topic_id: string,
        message_text: string,
        message_text_myanmar?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      question_text: string,
      question_text_myanmar?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCGMeetingQueryVariables = {
  id: string,
};

export type GetCGMeetingQuery = {
  getCGMeeting?:  {
    __typename: "CGMeeting",
    id: string,
    cg_meeting_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelCGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "CGMeetingNutritionTopic",
        id: string,
        cg_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelCGMeetingStaffConnection",
      items:  Array< {
        __typename: "CGMeetingStaff",
        id: string,
        cg_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelCGMeetingPLWConnection",
      items:  Array< {
        __typename: "CGMeetingPLW",
        id: string,
        cg_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCGMeetingsQueryVariables = {
  id?: string | null,
  filter?: ModelCGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCGMeetingsQuery = {
  listCGMeetings?:  {
    __typename: "ModelCGMeetingConnection",
    items:  Array< {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCGMeetingNutritionTopicQueryVariables = {
  id: string,
};

export type GetCGMeetingNutritionTopicQuery = {
  getCGMeetingNutritionTopic?:  {
    __typename: "CGMeetingNutritionTopic",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCGMeetingNutritionTopicsQueryVariables = {
  id?: string | null,
  filter?: ModelCGMeetingNutritionTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCGMeetingNutritionTopicsQuery = {
  listCGMeetingNutritionTopics?:  {
    __typename: "ModelCGMeetingNutritionTopicConnection",
    items:  Array< {
      __typename: "CGMeetingNutritionTopic",
      id: string,
      cg_meeting_id: string,
      cg_meeting:  {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCGMeetingStaffQueryVariables = {
  id: string,
};

export type GetCGMeetingStaffQuery = {
  getCGMeetingStaff?:  {
    __typename: "CGMeetingStaff",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCGMeetingStaffsQueryVariables = {
  id?: string | null,
  filter?: ModelCGMeetingStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCGMeetingStaffsQuery = {
  listCGMeetingStaffs?:  {
    __typename: "ModelCGMeetingStaffConnection",
    items:  Array< {
      __typename: "CGMeetingStaff",
      id: string,
      cg_meeting_id: string,
      cg_meeting:  {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_id: string,
      staff:  {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCGMeetingPLWQueryVariables = {
  id: string,
};

export type GetCGMeetingPLWQuery = {
  getCGMeetingPLW?:  {
    __typename: "CGMeetingPLW",
    id: string,
    cg_meeting_id: string,
    cg_meeting:  {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCGMeetingPLWSQueryVariables = {
  id?: string | null,
  filter?: ModelCGMeetingPLWFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCGMeetingPLWSQuery = {
  listCGMeetingPLWS?:  {
    __typename: "ModelCGMeetingPLWConnection",
    items:  Array< {
      __typename: "CGMeetingPLW",
      id: string,
      cg_meeting_id: string,
      cg_meeting:  {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNGMeetingQueryVariables = {
  id: string,
};

export type GetNGMeetingQuery = {
  getNGMeeting?:  {
    __typename: "NGMeeting",
    id: string,
    ng_meeting_id?: number | null,
    project_diocese_code: string,
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_facilitator_id: string,
    plw_facilitator:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting_date?: string | null,
    description?: string | null,
    topics?:  {
      __typename: "ModelNGMeetingNutritionTopicConnection",
      items:  Array< {
        __typename: "NGMeetingNutritionTopic",
        id: string,
        ng_meeting_id: string,
        nutrition_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff_facilitators?:  {
      __typename: "ModelNGMeetingStaffFacilitatorConnection",
      items:  Array< {
        __typename: "NGMeetingStaffFacilitator",
        id: string,
        ng_meeting_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelNGMeetingPLWConnection",
      items:  Array< {
        __typename: "NGMeetingPLW",
        id: string,
        ng_meeting_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNGMeetingsQueryVariables = {
  id?: string | null,
  filter?: ModelNGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNGMeetingsQuery = {
  listNGMeetings?:  {
    __typename: "ModelNGMeetingConnection",
    items:  Array< {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNGMeetingNutritionTopicQueryVariables = {
  id: string,
};

export type GetNGMeetingNutritionTopicQuery = {
  getNGMeetingNutritionTopic?:  {
    __typename: "NGMeetingNutritionTopic",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    nutrition_topic_id: string,
    nutrition_topic:  {
      __typename: "NutritionTopic",
      id: string,
      nutrition_topic_id?: number | null,
      topic_text: string,
      topic_text_myanmar?: string | null,
      description?: string | null,
      projects?: Array< string | null > | null,
      nutrition_messages?:  {
        __typename: "ModelNutritionMessageConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNGMeetingNutritionTopicsQueryVariables = {
  id?: string | null,
  filter?: ModelNGMeetingNutritionTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNGMeetingNutritionTopicsQuery = {
  listNGMeetingNutritionTopics?:  {
    __typename: "ModelNGMeetingNutritionTopicConnection",
    items:  Array< {
      __typename: "NGMeetingNutritionTopic",
      id: string,
      ng_meeting_id: string,
      ng_meeting:  {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNGMeetingStaffFacilitatorQueryVariables = {
  id: string,
};

export type GetNGMeetingStaffFacilitatorQuery = {
  getNGMeetingStaffFacilitator?:  {
    __typename: "NGMeetingStaffFacilitator",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNGMeetingStaffFacilitatorsQueryVariables = {
  id?: string | null,
  filter?: ModelNGMeetingStaffFacilitatorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNGMeetingStaffFacilitatorsQuery = {
  listNGMeetingStaffFacilitators?:  {
    __typename: "ModelNGMeetingStaffFacilitatorConnection",
    items:  Array< {
      __typename: "NGMeetingStaffFacilitator",
      id: string,
      ng_meeting_id: string,
      ng_meeting:  {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_id: string,
      staff:  {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNGMeetingPLWQueryVariables = {
  id: string,
};

export type GetNGMeetingPLWQuery = {
  getNGMeetingPLW?:  {
    __typename: "NGMeetingPLW",
    id: string,
    ng_meeting_id: string,
    ng_meeting:  {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNGMeetingPLWSQueryVariables = {
  id?: string | null,
  filter?: ModelNGMeetingPLWFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNGMeetingPLWSQuery = {
  listNGMeetingPLWS?:  {
    __typename: "ModelNGMeetingPLWConnection",
    items:  Array< {
      __typename: "NGMeetingPLW",
      id: string,
      ng_meeting_id: string,
      ng_meeting:  {
        __typename: "NGMeeting",
        id: string,
        ng_meeting_id?: number | null,
        project_diocese_code: string,
        neighbor_group_id: string,
        plw_facilitator_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDistributionItemQueryVariables = {
  id: string,
};

export type GetDistributionItemQuery = {
  getDistributionItem?:  {
    __typename: "DistributionItem",
    id: string,
    distribution_item_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    title: string,
    description?: string | null,
    planned_distribution_start_date?: string | null,
    planned_distribution_end_date?: string | null,
    distributions?:  {
      __typename: "ModelDistributionConnection",
      items:  Array< {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDistributionItemsQueryVariables = {
  id?: string | null,
  filter?: ModelDistributionItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDistributionItemsQuery = {
  listDistributionItems?:  {
    __typename: "ModelDistributionItemConnection",
    items:  Array< {
      __typename: "DistributionItem",
      id: string,
      distribution_item_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDistributionQueryVariables = {
  id: string,
};

export type GetDistributionQuery = {
  getDistribution?:  {
    __typename: "Distribution",
    id: string,
    distribution_id?: number | null,
    project_diocese_code: string,
    distribution_item_id: string,
    distribution_item:  {
      __typename: "DistributionItem",
      id: string,
      distribution_item_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    neighbor_group_id: string,
    neighbor_group:  {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    distribution_date?: string | null,
    description?: string | null,
    recipients?:  {
      __typename: "ModelDistributionRecipientsConnection",
      items:  Array< {
        __typename: "DistributionRecipients",
        id: string,
        distribution_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDistributionsQueryVariables = {
  id?: string | null,
  filter?: ModelDistributionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDistributionsQuery = {
  listDistributions?:  {
    __typename: "ModelDistributionConnection",
    items:  Array< {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDistributionRecipientsQueryVariables = {
  id: string,
};

export type GetDistributionRecipientsQuery = {
  getDistributionRecipients?:  {
    __typename: "DistributionRecipients",
    id: string,
    distribution_id: string,
    distribution:  {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDistributionRecipientsQueryVariables = {
  id?: string | null,
  filter?: ModelDistributionRecipientsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDistributionRecipientsQuery = {
  listDistributionRecipients?:  {
    __typename: "ModelDistributionRecipientsConnection",
    items:  Array< {
      __typename: "DistributionRecipients",
      id: string,
      distribution_id: string,
      distribution:  {
        __typename: "Distribution",
        id: string,
        distribution_id?: number | null,
        project_diocese_code: string,
        distribution_item_id: string,
        neighbor_group_id: string,
        distribution_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOtherActivityTypeQueryVariables = {
  id: string,
};

export type GetOtherActivityTypeQuery = {
  getOtherActivityType?:  {
    __typename: "OtherActivityType",
    id: string,
    other_activity_type_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    activity_name: string,
    description?: string | null,
    activities?:  {
      __typename: "ModelOtherActivityConnection",
      items:  Array< {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOtherActivityTypesQueryVariables = {
  id?: string | null,
  filter?: ModelOtherActivityTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOtherActivityTypesQuery = {
  listOtherActivityTypes?:  {
    __typename: "ModelOtherActivityTypeConnection",
    items:  Array< {
      __typename: "OtherActivityType",
      id: string,
      other_activity_type_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      activity_name: string,
      description?: string | null,
      activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOtherActivityQueryVariables = {
  id: string,
};

export type GetOtherActivityQuery = {
  getOtherActivity?:  {
    __typename: "OtherActivity",
    id: string,
    other_activity_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    other_activity_type_id: string,
    other_activity_type:  {
      __typename: "OtherActivityType",
      id: string,
      other_activity_type_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      activity_name: string,
      description?: string | null,
      activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    activity_date: string,
    description?: string | null,
    facilitators?:  {
      __typename: "ModelOtherActivityFacilitatorsConnection",
      items:  Array< {
        __typename: "OtherActivityFacilitators",
        id: string,
        other_activity_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelOtherActivityParticipantsConnection",
      items:  Array< {
        __typename: "OtherActivityParticipants",
        id: string,
        other_activity_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOtherActivitiesQueryVariables = {
  id?: string | null,
  filter?: ModelOtherActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOtherActivitiesQuery = {
  listOtherActivities?:  {
    __typename: "ModelOtherActivityConnection",
    items:  Array< {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOtherActivityFacilitatorsQueryVariables = {
  id: string,
};

export type GetOtherActivityFacilitatorsQuery = {
  getOtherActivityFacilitators?:  {
    __typename: "OtherActivityFacilitators",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOtherActivityFacilitatorsQueryVariables = {
  id?: string | null,
  filter?: ModelOtherActivityFacilitatorsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOtherActivityFacilitatorsQuery = {
  listOtherActivityFacilitators?:  {
    __typename: "ModelOtherActivityFacilitatorsConnection",
    items:  Array< {
      __typename: "OtherActivityFacilitators",
      id: string,
      other_activity_id: string,
      other_activity:  {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_id: string,
      staff:  {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOtherActivityParticipantsQueryVariables = {
  id: string,
};

export type GetOtherActivityParticipantsQuery = {
  getOtherActivityParticipants?:  {
    __typename: "OtherActivityParticipants",
    id: string,
    other_activity_id: string,
    other_activity:  {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOtherActivityParticipantsQueryVariables = {
  id?: string | null,
  filter?: ModelOtherActivityParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOtherActivityParticipantsQuery = {
  listOtherActivityParticipants?:  {
    __typename: "ModelOtherActivityParticipantsConnection",
    items:  Array< {
      __typename: "OtherActivityParticipants",
      id: string,
      other_activity_id: string,
      other_activity:  {
        __typename: "OtherActivity",
        id: string,
        other_activity_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        other_activity_type_id: string,
        activity_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHgTrainingTopicQueryVariables = {
  id: string,
};

export type GetHgTrainingTopicQuery = {
  getHgTrainingTopic?:  {
    __typename: "HgTrainingTopic",
    id: string,
    hg_training_topic_id?: number | null,
    project_id: string,
    project:  {
      __typename: "Project",
      id: string,
      project_id?: number | null,
      project_code: string,
      name: string,
      sector: PROGRAM_SECTORS,
      description?: string | null,
      donor?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      project_dioceses?:  {
        __typename: "ModelProjectDioceseConnection",
        nextToken?: string | null,
      } | null,
      distribution_items?:  {
        __typename: "ModelDistributionItemConnection",
        nextToken?: string | null,
      } | null,
      other_activitie_types?:  {
        __typename: "ModelOtherActivityTypeConnection",
        nextToken?: string | null,
      } | null,
      hg_training_topics?:  {
        __typename: "ModelHgTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_code: string,
    topic: string,
    description?: string | null,
    training?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHgTrainingTopicsQueryVariables = {
  id?: string | null,
  filter?: ModelHgTrainingTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHgTrainingTopicsQuery = {
  listHgTrainingTopics?:  {
    __typename: "ModelHgTrainingTopicConnection",
    items:  Array< {
      __typename: "HgTrainingTopic",
      id: string,
      hg_training_topic_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      topic: string,
      description?: string | null,
      training?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHgTrainingQueryVariables = {
  id: string,
};

export type GetHgTrainingQuery = {
  getHgTraining?:  {
    __typename: "HgTraining",
    id: string,
    hg_training_id?: number | null,
    project_diocese_code: string,
    care_group_id: string,
    care_group:  {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    training_date: string,
    description?: string | null,
    topics?:  {
      __typename: "ModelHgTrainingTrainingTopicConnection",
      items:  Array< {
        __typename: "HgTrainingTrainingTopic",
        id: string,
        hg_training_id: string,
        hg_training_topic_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    facilitators?:  {
      __typename: "ModelHgTrainingFacilitatorsConnection",
      items:  Array< {
        __typename: "HgTrainingFacilitators",
        id: string,
        hg_training_id: string,
        staff_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    participants?:  {
      __typename: "ModelHgTrainingParticipantsConnection",
      items:  Array< {
        __typename: "HgTrainingParticipants",
        id: string,
        hg_training_id: string,
        plw_id: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHgTrainingsQueryVariables = {
  id?: string | null,
  filter?: ModelHgTrainingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHgTrainingsQuery = {
  listHgTrainings?:  {
    __typename: "ModelHgTrainingConnection",
    items:  Array< {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHgTrainingTrainingTopicQueryVariables = {
  id: string,
};

export type GetHgTrainingTrainingTopicQuery = {
  getHgTrainingTrainingTopic?:  {
    __typename: "HgTrainingTrainingTopic",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    hg_training_topic_id: string,
    hg_training_topic:  {
      __typename: "HgTrainingTopic",
      id: string,
      hg_training_topic_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      topic: string,
      description?: string | null,
      training?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHgTrainingTrainingTopicsQueryVariables = {
  id?: string | null,
  filter?: ModelHgTrainingTrainingTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHgTrainingTrainingTopicsQuery = {
  listHgTrainingTrainingTopics?:  {
    __typename: "ModelHgTrainingTrainingTopicConnection",
    items:  Array< {
      __typename: "HgTrainingTrainingTopic",
      id: string,
      hg_training_id: string,
      hg_training:  {
        __typename: "HgTraining",
        id: string,
        hg_training_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        training_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      hg_training_topic_id: string,
      hg_training_topic:  {
        __typename: "HgTrainingTopic",
        id: string,
        hg_training_topic_id?: number | null,
        project_id: string,
        project_code: string,
        topic: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHgTrainingFacilitatorsQueryVariables = {
  id: string,
};

export type GetHgTrainingFacilitatorsQuery = {
  getHgTrainingFacilitators?:  {
    __typename: "HgTrainingFacilitators",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    staff_id: string,
    staff:  {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHgTrainingFacilitatorsQueryVariables = {
  id?: string | null,
  filter?: ModelHgTrainingFacilitatorsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHgTrainingFacilitatorsQuery = {
  listHgTrainingFacilitators?:  {
    __typename: "ModelHgTrainingFacilitatorsConnection",
    items:  Array< {
      __typename: "HgTrainingFacilitators",
      id: string,
      hg_training_id: string,
      hg_training:  {
        __typename: "HgTraining",
        id: string,
        hg_training_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        training_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_id: string,
      staff:  {
        __typename: "Staff",
        id: string,
        staff_id?: number | null,
        project_diocese_id: string,
        position_id: string,
        current_position?: boolean | null,
        from_date?: string | null,
        to_date?: string | null,
        email: string,
        first_name?: string | null,
        last_name?: string | null,
        gender?: GENDER | null,
        is_staff?: boolean | null,
        is_active?: boolean | null,
        date_joined?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHgTrainingParticipantsQueryVariables = {
  id: string,
};

export type GetHgTrainingParticipantsQuery = {
  getHgTrainingParticipants?:  {
    __typename: "HgTrainingParticipants",
    id: string,
    hg_training_id: string,
    hg_training:  {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    plw_id: string,
    plw:  {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    project_diocese_code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHgTrainingParticipantsQueryVariables = {
  id?: string | null,
  filter?: ModelHgTrainingParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHgTrainingParticipantsQuery = {
  listHgTrainingParticipants?:  {
    __typename: "ModelHgTrainingParticipantsConnection",
    items:  Array< {
      __typename: "HgTrainingParticipants",
      id: string,
      hg_training_id: string,
      hg_training:  {
        __typename: "HgTraining",
        id: string,
        hg_training_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        training_date: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectDioceseByProjectQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectDioceseByProjectQuery = {
  projectDioceseByProject?:  {
    __typename: "ModelProjectDioceseConnection",
    items:  Array< {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectDioceseByDioceseQueryVariables = {
  dioceseID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectDioceseByDioceseQuery = {
  projectDioceseByDiocese?:  {
    __typename: "ModelProjectDioceseConnection",
    items:  Array< {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectDioceseByPdCodeQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectDioceseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProjectDioceseByPdCodeQuery = {
  getProjectDioceseByPdCode?:  {
    __typename: "ModelProjectDioceseConnection",
    items:  Array< {
      __typename: "ProjectDiocese",
      id: string,
      project_diocese_id?: number | null,
      projectID: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      dioceseID: string,
      diocese:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelStaffConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TownshipByPcodeQueryVariables = {
  ts_pcode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTownshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TownshipByPcodeQuery = {
  townshipByPcode?:  {
    __typename: "ModelTownshipConnection",
    items:  Array< {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TownshipByDioceseCodeQueryVariables = {
  diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTownshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TownshipByDioceseCodeQuery = {
  townshipByDioceseCode?:  {
    __typename: "ModelTownshipConnection",
    items:  Array< {
      __typename: "Township",
      id: string,
      ts_pcode?: string | null,
      d_pcode?: string | null,
      sr_pcode?: string | null,
      name: string,
      name_mm?: string | null,
      diocese_id?: string | null,
      diocese?:  {
        __typename: "Diocese",
        id: string,
        diocese_id?: number | null,
        diocese_code: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villageTracts?:  {
        __typename: "ModelVillageTractConnection",
        nextToken?: string | null,
      } | null,
      camps?:  {
        __typename: "ModelCampConnection",
        nextToken?: string | null,
      } | null,
      care_groups?:  {
        __typename: "ModelCareGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VillageTractByPcodeQueryVariables = {
  vt_pcode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVillageTractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VillageTractByPcodeQuery = {
  villageTractByPcode?:  {
    __typename: "ModelVillageTractConnection",
    items:  Array< {
      __typename: "VillageTract",
      id: string,
      vt_pcode?: string | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villages?:  {
        __typename: "ModelVillageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VillageTractByTownshipQueryVariables = {
  township_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVillageTractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VillageTractByTownshipQuery = {
  villageTractByTownship?:  {
    __typename: "ModelVillageTractConnection",
    items:  Array< {
      __typename: "VillageTract",
      id: string,
      vt_pcode?: string | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      diocese_code?: string | null,
      active?: boolean | null,
      villages?:  {
        __typename: "ModelVillageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VillageByPcodeQueryVariables = {
  village_pcode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVillageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VillageByPcodeQuery = {
  villageByPcode?:  {
    __typename: "ModelVillageConnection",
    items:  Array< {
      __typename: "Village",
      id: string,
      village_pcode?: string | null,
      villageTract_id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      alt_name?: string | null,
      alt_name_mm?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VillageByVillageTractQueryVariables = {
  villageTract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVillageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VillageByVillageTractQuery = {
  villageByVillageTract?:  {
    __typename: "ModelVillageConnection",
    items:  Array< {
      __typename: "Village",
      id: string,
      village_pcode?: string | null,
      villageTract_id: string,
      villageTract:  {
        __typename: "VillageTract",
        id: string,
        vt_pcode?: string | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      alt_name?: string | null,
      alt_name_mm?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CampByTownshipQueryVariables = {
  township_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CampByTownshipQuery = {
  campByTownship?:  {
    __typename: "ModelCampConnection",
    items:  Array< {
      __typename: "Camp",
      id: string,
      camp_id?: number | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      description?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CampByDioceseCodeQueryVariables = {
  diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CampByDioceseCodeQuery = {
  campByDioceseCode?:  {
    __typename: "ModelCampConnection",
    items:  Array< {
      __typename: "Camp",
      id: string,
      camp_id?: number | null,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      name: string,
      name_mm?: string | null,
      description?: string | null,
      longitude?: number | null,
      latitude?: number | null,
      diocese_code?: string | null,
      active?: boolean | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CareGroupByGroupNumberQueryVariables = {
  group_number: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCareGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CareGroupByGroupNumberQuery = {
  careGroupByGroupNumber?:  {
    __typename: "ModelCareGroupConnection",
    items:  Array< {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CareGroupByPdCodeQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCareGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CareGroupByPdCodeQuery = {
  careGroupByPdCode?:  {
    __typename: "ModelCareGroupConnection",
    items:  Array< {
      __typename: "CareGroup",
      id: string,
      care_group_id?: number | null,
      group_number: string,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      township_id: string,
      township:  {
        __typename: "Township",
        id: string,
        ts_pcode?: string | null,
        d_pcode?: string | null,
        sr_pcode?: string | null,
        name: string,
        name_mm?: string | null,
        diocese_id?: string | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroups?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      neighbor_groups?:  {
        __typename: "ModelNeighborGroupConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings?:  {
        __typename: "ModelCGMeetingConnection",
        nextToken?: string | null,
      } | null,
      other_activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings?:  {
        __typename: "ModelHgTrainingConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StaffByProjectDioceseQueryVariables = {
  project_diocese_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StaffByProjectDioceseQuery = {
  staffByProjectDiocese?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      staff_id?: number | null,
      project_diocese_id: string,
      project_diocese:  {
        __typename: "ProjectDiocese",
        id: string,
        project_diocese_id?: number | null,
        projectID: string,
        dioceseID: string,
        project_diocese_code: string,
        createdAt: string,
        updatedAt: string,
      },
      position_id: string,
      position:  {
        __typename: "Position",
        id: string,
        position_id?: number | null,
        name: string,
        level?: POSITION_LEVELS | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      staff_caregroup?:  {
        __typename: "ModelStaffCareGroupConnection",
        nextToken?: string | null,
      } | null,
      current_position?: boolean | null,
      from_date?: string | null,
      to_date?: string | null,
      email: string,
      first_name?: string | null,
      last_name?: string | null,
      gender?: GENDER | null,
      is_staff?: boolean | null,
      is_active?: boolean | null,
      date_joined?: string | null,
      cg_meetings_facilitated?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      other_activities_facilitated?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      hg_trainings_facilitated?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NeighborGroupByVillageQueryVariables = {
  village_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNeighborGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NeighborGroupByVillageQuery = {
  neighborGroupByVillage?:  {
    __typename: "ModelNeighborGroupConnection",
    items:  Array< {
      __typename: "NeighborGroup",
      id: string,
      neighbor_group_id?: number | null,
      group_number: string,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      village_id?: string | null,
      village?:  {
        __typename: "Village",
        id: string,
        village_pcode?: string | null,
        villageTract_id: string,
        name: string,
        name_mm?: string | null,
        alt_name?: string | null,
        alt_name_mm?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      camp_id?: string | null,
      camp?:  {
        __typename: "Camp",
        id: string,
        camp_id?: number | null,
        township_id: string,
        name: string,
        name_mm?: string | null,
        description?: string | null,
        longitude?: number | null,
        latitude?: number | null,
        diocese_code?: string | null,
        active?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plws?:  {
        __typename: "ModelPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlwByNeighborGroupQueryVariables = {
  neighbor_group_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPLWFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlwByNeighborGroupQuery = {
  plwByNeighborGroup?:  {
    __typename: "ModelPLWConnection",
    items:  Array< {
      __typename: "PLW",
      id: string,
      plw_id?: number | null,
      member_number: number,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      date_of_registry?: string | null,
      lead_mother?: boolean | null,
      name: string,
      father_name?: string | null,
      date_of_birth?: string | null,
      education_level_id?: string | null,
      education_level?:  {
        __typename: "EducationLevel",
        id: string,
        education_level_id?: number | null,
        education_level: string,
        education_level_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      occupation_type_id?: string | null,
      occupation_type?:  {
        __typename: "OccupationType",
        id: string,
        occupation_type_id?: number | null,
        occupation_type: string,
        occupation_type_mm?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      disabled?: boolean | null,
      disability_type?: Array< string | null > | null,
      disability_type_other_description?: string | null,
      contact_number?: string | null,
      date_of_death?: string | null,
      lost_contact?: boolean | null,
      birth_histories?:  {
        __typename: "ModelBirthHistoryConnection",
        nextToken?: string | null,
      } | null,
      cg_meetings_attended?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_facilitated?:  {
        __typename: "ModelNGMeetingConnection",
        nextToken?: string | null,
      } | null,
      ng_meetings_attended?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      distributions_recieved?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      other_activities_attended?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      hg_training_attended?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CgMeetingByPdCodeQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CgMeetingByPdCodeQuery = {
  cgMeetingByPdCode?:  {
    __typename: "ModelCGMeetingConnection",
    items:  Array< {
      __typename: "CGMeeting",
      id: string,
      cg_meeting_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelCGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelCGMeetingStaffConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelCGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TopicByCgMeetingQueryVariables = {
  cg_meeting_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCGMeetingNutritionTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TopicByCgMeetingQuery = {
  topicByCgMeeting?:  {
    __typename: "ModelCGMeetingNutritionTopicConnection",
    items:  Array< {
      __typename: "CGMeetingNutritionTopic",
      id: string,
      cg_meeting_id: string,
      cg_meeting:  {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      nutrition_topic_id: string,
      nutrition_topic:  {
        __typename: "NutritionTopic",
        id: string,
        nutrition_topic_id?: number | null,
        topic_text: string,
        topic_text_myanmar?: string | null,
        description?: string | null,
        projects?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlwByCgMeetingQueryVariables = {
  cg_meeting_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCGMeetingPLWFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlwByCgMeetingQuery = {
  plwByCgMeeting?:  {
    __typename: "ModelCGMeetingPLWConnection",
    items:  Array< {
      __typename: "CGMeetingPLW",
      id: string,
      cg_meeting_id: string,
      cg_meeting:  {
        __typename: "CGMeeting",
        id: string,
        cg_meeting_id?: number | null,
        project_diocese_code: string,
        care_group_id: string,
        meeting_date?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_id: string,
      plw:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      project_diocese_code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NgMeetingByPdCodeQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NgMeetingByPdCodeQuery = {
  ngMeetingByPdCode?:  {
    __typename: "ModelNGMeetingConnection",
    items:  Array< {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NgMeetingByNeighborGroupQueryVariables = {
  neighbor_group_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNGMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NgMeetingByNeighborGroupQuery = {
  ngMeetingByNeighborGroup?:  {
    __typename: "ModelNGMeetingConnection",
    items:  Array< {
      __typename: "NGMeeting",
      id: string,
      ng_meeting_id?: number | null,
      project_diocese_code: string,
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      plw_facilitator_id: string,
      plw_facilitator:  {
        __typename: "PLW",
        id: string,
        plw_id?: number | null,
        member_number: number,
        neighbor_group_id: string,
        project_diocese_code: string,
        date_of_registry?: string | null,
        lead_mother?: boolean | null,
        name: string,
        father_name?: string | null,
        date_of_birth?: string | null,
        education_level_id?: string | null,
        occupation_type_id?: string | null,
        disabled?: boolean | null,
        disability_type?: Array< string | null > | null,
        disability_type_other_description?: string | null,
        contact_number?: string | null,
        date_of_death?: string | null,
        lost_contact?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      meeting_date?: string | null,
      description?: string | null,
      topics?:  {
        __typename: "ModelNGMeetingNutritionTopicConnection",
        nextToken?: string | null,
      } | null,
      staff_facilitators?:  {
        __typename: "ModelNGMeetingStaffFacilitatorConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelNGMeetingPLWConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DistributionItemByProjectCodeQueryVariables = {
  project_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDistributionItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DistributionItemByProjectCodeQuery = {
  distributionItemByProjectCode?:  {
    __typename: "ModelDistributionItemConnection",
    items:  Array< {
      __typename: "DistributionItem",
      id: string,
      distribution_item_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      title: string,
      description?: string | null,
      planned_distribution_start_date?: string | null,
      planned_distribution_end_date?: string | null,
      distributions?:  {
        __typename: "ModelDistributionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DistributionByPdCodeQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDistributionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DistributionByPdCodeQuery = {
  distributionByPdCode?:  {
    __typename: "ModelDistributionConnection",
    items:  Array< {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DistributionByItemQueryVariables = {
  distribution_item_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDistributionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DistributionByItemQuery = {
  distributionByItem?:  {
    __typename: "ModelDistributionConnection",
    items:  Array< {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DistributionByNeighborGroupQueryVariables = {
  neighbor_group_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDistributionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DistributionByNeighborGroupQuery = {
  distributionByNeighborGroup?:  {
    __typename: "ModelDistributionConnection",
    items:  Array< {
      __typename: "Distribution",
      id: string,
      distribution_id?: number | null,
      project_diocese_code: string,
      distribution_item_id: string,
      distribution_item:  {
        __typename: "DistributionItem",
        id: string,
        distribution_item_id?: number | null,
        project_id: string,
        project_code: string,
        title: string,
        description?: string | null,
        planned_distribution_start_date?: string | null,
        planned_distribution_end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      neighbor_group_id: string,
      neighbor_group:  {
        __typename: "NeighborGroup",
        id: string,
        neighbor_group_id?: number | null,
        group_number: string,
        project_diocese_code: string,
        care_group_id: string,
        village_id?: string | null,
        camp_id?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      distribution_date?: string | null,
      description?: string | null,
      recipients?:  {
        __typename: "ModelDistributionRecipientsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OtherActivityTypeByProjectCodeQueryVariables = {
  project_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOtherActivityTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OtherActivityTypeByProjectCodeQuery = {
  otherActivityTypeByProjectCode?:  {
    __typename: "ModelOtherActivityTypeConnection",
    items:  Array< {
      __typename: "OtherActivityType",
      id: string,
      other_activity_type_id?: number | null,
      project_id: string,
      project:  {
        __typename: "Project",
        id: string,
        project_id?: number | null,
        project_code: string,
        name: string,
        sector: PROGRAM_SECTORS,
        description?: string | null,
        donor?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      project_code: string,
      activity_name: string,
      description?: string | null,
      activities?:  {
        __typename: "ModelOtherActivityConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OtherActivityByPdCodeQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOtherActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OtherActivityByPdCodeQuery = {
  otherActivityByPdCode?:  {
    __typename: "ModelOtherActivityConnection",
    items:  Array< {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OtherActivityByTypeQueryVariables = {
  other_activity_type_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOtherActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OtherActivityByTypeQuery = {
  otherActivityByType?:  {
    __typename: "ModelOtherActivityConnection",
    items:  Array< {
      __typename: "OtherActivity",
      id: string,
      other_activity_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      other_activity_type_id: string,
      other_activity_type:  {
        __typename: "OtherActivityType",
        id: string,
        other_activity_type_id?: number | null,
        project_id: string,
        project_code: string,
        activity_name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      activity_date: string,
      description?: string | null,
      facilitators?:  {
        __typename: "ModelOtherActivityFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelOtherActivityParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HgTrainingByPdCodeQueryVariables = {
  project_diocese_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHgTrainingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HgTrainingByPdCodeQuery = {
  hgTrainingByPdCode?:  {
    __typename: "ModelHgTrainingConnection",
    items:  Array< {
      __typename: "HgTraining",
      id: string,
      hg_training_id?: number | null,
      project_diocese_code: string,
      care_group_id: string,
      care_group:  {
        __typename: "CareGroup",
        id: string,
        care_group_id?: number | null,
        group_number: string,
        project_diocese_id: string,
        project_diocese_code: string,
        township_id: string,
        createdAt: string,
        updatedAt: string,
      },
      training_date: string,
      description?: string | null,
      topics?:  {
        __typename: "ModelHgTrainingTrainingTopicConnection",
        nextToken?: string | null,
      } | null,
      facilitators?:  {
        __typename: "ModelHgTrainingFacilitatorsConnection",
        nextToken?: string | null,
      } | null,
      participants?:  {
        __typename: "ModelHgTrainingParticipantsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export const listCGMeetingsCustom = /* GraphQL */ `
  query ListCGMeetingsCustom(
    $id: ID
    $filter: ModelCGMeetingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCGMeetings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cg_meeting_id
        project_diocese_code
        meeting_date
        description
        care_group {
          id
          group_number
          project_diocese {
            diocese {
              id
              name
            }
            project {
              id
              name
            }
          }
        }
        topics {
          items {
            nutrition_topic {
              id
              topic_text
              topic_text_myanmar
            }
          }
        }
        facilitators {
          items {
            staff {
              id
              first_name
              last_name
              position {
                name
              }
            }
          }
        }
        participants {
          items {
            plw {
              id
              name
              father_name
              date_of_birth
              member_number
              lead_mother
              neighbor_group {
                group_number
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const cgMeetingByPdCodeCustom = /* GraphQL */ `
  query CgMeetingByPdCodeCustom(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCGMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cgMeetingByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cg_meeting_id
        project_diocese_code
        meeting_date
        description
        care_group {
          id
          group_number
          project_diocese {
            diocese {
              id
              name
            }
            project {
              id
              name
            }
          }
        }
        topics {
          items {
            nutrition_topic {
              id
              topic_text
              topic_text_myanmar
            }
          }
        }
        facilitators {
          items {
            staff {
              id
              first_name
              last_name
              position {
                name
              }
            }
          }
        }
        participants {
          items {
            plw {
              id
              name
              father_name
              date_of_birth
              member_number
              lead_mother
              neighbor_group {
                group_number
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getCGMeetingForEdit = /* GraphQL */ `
  query GetCGMeetingForEdit($id: ID!) {
    getCGMeeting(id: $id) {
      id
      project_diocese_code
      care_group_id
      meeting_date
      description
      topics {
        items {
          id
          nutrition_topic {
            id
          }
        }
      }
      facilitators {
        items {
          id
          staff {
            id
          }
        }
      }
      participants {
        items {
          id
          plw {
            id
            member_number
            neighbor_group {
              id
              group_number
            }
          }
        }
      }
    }
  }
`;

import { Button, Col, Collapse, Row } from 'antd';
import { FC, ReactNode, useCallback } from 'react';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { NutritionTopicType } from './NutritionMessages';

const { Panel } = Collapse;

interface TopicProps {
  topic: NutritionTopicType;
  children: ReactNode;
  setEditingTopic: (topic: NutritionTopicType | undefined) => void;
  setAddOrEditTopic: (value: 'edit') => void;
  setAddOrEditMessage: (value: 'add') => void;
  user: ReturnType<typeof useGetUserInfo>;
}

const Topic: FC<TopicProps> = ({
  topic,
  setAddOrEditTopic,
  setEditingTopic,
  setAddOrEditMessage,
  user,
  ...props
}) => {
  const editTopicButton = useCallback((topic: NutritionTopicType) => {
    return (
      <Button
        type='link'
        disabled={!(user.isAdmin && user.isEditor)}
        onClick={(e) => {
          e.stopPropagation();
          setEditingTopic(topic);
          setAddOrEditTopic('edit');
        }}
      >
        edit topic
      </Button>
    );
  }, []);

  return (
    <Panel
      key={topic.id}
      {...props}
      header={`${topic.topic_text} ${
        topic.topic_text_myanmar ? `(${topic.topic_text_myanmar})` : ''
      }`}
      extra={editTopicButton(topic)}
    >
      <Row>
        <Col span={22} offset={1}>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            <strong style={{ fontSize: '1.1rem' }}>{topic.topic_text}:</strong> {topic.description}
          </p>
          <p style={{ margin: 0, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type='link'
              style={{ justifySelf: 'flex-end' }}
              onClick={() => {
                setAddOrEditMessage('add');
                setEditingTopic(topic);
              }}
              disabled={!(user.isAdmin && user.isEditor)}
            >
              add new message
            </Button>
          </p>
          {props.children}
        </Col>
      </Row>
    </Panel>
  );
};

export default Topic;

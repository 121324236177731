import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const cgmFilterTypes = [
  'project',
  'diocese',
  'meeting_date',
  'facilitators',
  'care_group',
  'topics',
] as const;

export type CGMFilterType = typeof cgmFilterTypes[number];

interface CgmState {
  selectedMenuKeys: {
    keys: string[];
    selectAll: boolean;
    currentSelection: string | undefined;
  };
  cgMeetingFilters: Record<CGMFilterType, (boolean | string | number)[] | null>;
}

export const initialState: CgmState = {
  selectedMenuKeys: {
    keys: [],
    selectAll: false,
    currentSelection: undefined,
  },
  cgMeetingFilters: {
    project: null,
    diocese: null,
    meeting_date: null,
    facilitators: null,
    care_group: null,
    topics: null,
  },
};

export const cgmSlice = createSlice({
  name: 'cgm',
  initialState,
  reducers: {
    setSelectedMenuKeys: (state, action: PayloadAction<{ keys: string[]; selectAll: boolean }>) => {
      state.selectedMenuKeys.keys = action.payload.keys;
      state.selectedMenuKeys.selectAll = action.payload.selectAll;
      if (!action.payload.selectAll && action.payload.keys.length > 0) {
        state.selectedMenuKeys.currentSelection = action.payload.keys[0];
      } else {
        state.selectedMenuKeys.currentSelection = undefined;
      }
    },
    setCgMeetingFilters: (state, action: PayloadAction<CgmState['cgMeetingFilters']>) => {
      state.cgMeetingFilters = action.payload;
    },
  },
});

export const { setSelectedMenuKeys, setCgMeetingFilters } = cgmSlice.actions;

export default cgmSlice.reducer;

export const distributionItemByProjectCodeCustom = /* GraphQL */ `
  query DistributionItemByProjectCodeCustom($project_code: String!) {
    distributionItemByProjectCode(project_code: $project_code, limit: 100) {
      items {
        id
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        distributions(limit: 1) {
          items {
            id
          }
        }
      }
    }
  }
`;

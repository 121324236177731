import { Button, Descriptions } from 'antd';
import { FC } from 'react';
import { TableFilterType } from '../common_types';
import { DistributionType } from './HGDistributions';

interface PropsType {
  filteredDistributions: DistributionType[];
  distributionFilters: Record<string, (boolean | string | number)[] | null>;
  hide: () => void;
}

const HGDSummary: FC<PropsType> = ({ filteredDistributions, distributionFilters, hide }) => {
  const numberOfDistribution = filteredDistributions.length;

  const careGroupIDSet = new Set<string>();
  const neighborGroupIDSet = new Set<string>();
  const projectNameSet = new Set<string>();
  const dioceseNameSet = new Set<string>();
  const recipientIDSet = new Set<string>();
  const distributionPlanMap = new Map<string, string>();
  const distributionDateSet = new Set<string>();

  filteredDistributions.forEach((dist) => {
    careGroupIDSet.add(dist.neighbor_group.care_group.id);
    neighborGroupIDSet.add(dist.neighbor_group.id);
    projectNameSet.add(dist.neighbor_group.care_group.project_diocese.project.name);
    dioceseNameSet.add(dist.neighbor_group.care_group.project_diocese.diocese.name);
    if (dist.recipients) {
      dist.recipients.items.forEach((item) => {
        if (item) {
          recipientIDSet.add(item.plw.id);
        }
      });
    }
    distributionPlanMap.set(dist.distribution_item.title, dist.neighbor_group.care_group.project_diocese.project.name);
    if (dist.distribution_date) {
      distributionDateSet.add(dist.distribution_date);
    }
  });
  const distributionDateArray = [...distributionDateSet].sort();

  return (
    <Descriptions
      title='Home Garden Distribution Summary'
      bordered
      extra={<Button onClick={hide}>Close</Button>}
      column={3}
    >
      <Descriptions.Item label='Number of Distributions' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {numberOfDistribution}
      </Descriptions.Item>
      <Descriptions.Item label='Number of Care Groups' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {careGroupIDSet.size}
      </Descriptions.Item>
      <Descriptions.Item label='Number of Neighbor Groups' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {neighborGroupIDSet.size}
      </Descriptions.Item>
      <Descriptions.Item label='Total Recipients'>{recipientIDSet.size}</Descriptions.Item>
      <Descriptions.Item label='From'>{distributionDateArray[0]}</Descriptions.Item>
      <Descriptions.Item label='To'>{distributionDateArray[distributionDateArray.length - 1]}</Descriptions.Item>
      <Descriptions.Item label='Projects'>
        {[...projectNameSet].sort().map((projectName) => (
          <div key={projectName}>
            <span>{projectName}</span>
            <br />
          </div>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label='Distribution Plans' span={2}>
        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
          <ul style={{ transform: 'translate(-20px)' }}>
            {[...distributionPlanMap].sort().map((plan) => (
              <li key={plan[0]}>
                {plan[0]} | {plan[1]}
              </li>
            ))}
          </ul>
        </div>
      </Descriptions.Item>

      <Descriptions.Item label='Dioceses'>
        {[...dioceseNameSet].sort().map((dioceseName) => (
          <div key={dioceseName}>
            <span>{dioceseName}</span>
            <br />
          </div>
        ))}
      </Descriptions.Item>

      <Descriptions.Item label='Filtered by' span={2}>
        <div style={{ maxHeight: 150, overflow: 'auto' }}>
          {Object.keys(distributionFilters).map((filter) => {
            if (distributionFilters[filter] && distributionFilters[filter]!.length > 0) {
              const spaced = filter.replace('_', ' ');
              const title = spaced.charAt(0).toUpperCase() + spaced.slice(1);
              return (
                <div key={filter} style={{ padding: '4px 0px 0px 8px' }}>
                  <span>
                    {title} ({distributionFilters[filter]?.length}):{' '}
                    <span style={{ color: 'darkred' }}>
                      {distributionFilters[filter]!.map((id) => {
                        return id;
                      }).join(', ')}
                    </span>
                  </span>
                  <br></br>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default HGDSummary;

export const campByTownshipCustom = /* GraphQL */ `
  query CampByTownshipCustom(
    $township_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campByTownship(
      township_id: $township_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_mm
        active
      }
    }
  }
`;

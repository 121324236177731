export const staffByProjectDioceseCustom = /* GraphQL */ `
  query StaffByProjectDioceseCustom(
    $project_diocese_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffByProjectDiocese(
      project_diocese_id: $project_diocese_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        gender
        is_staff
        is_active
        email
        position {
          name
        }
      }
    }
  }
`;

export class CustomLatLngBounds {
  private north: number | undefined;
  private south: number | undefined;
  private east: number | undefined;
  private west: number | undefined;

  extend(latLng: CustomLatLng): void {
    if (
      this.north === undefined ||
      this.south === undefined ||
      this.east === undefined ||
      this.west === undefined
    ) {
      this.north = latLng.latitude;
      this.south = latLng.latitude;
      this.east = latLng.longitude;
      this.west = latLng.longitude;
      return;
    }
    if (latLng.latitude > this.north) {
      this.north = latLng.latitude;
    }
    if (latLng.latitude < this.south) {
      this.south = latLng.latitude;
    }
    if (latLng.longitude > this.east) {
      this.east = latLng.longitude;
    }
    if (latLng.longitude < this.west) {
      this.west = latLng.longitude;
    }
  }

  getCenter(): CustomLatLng {
    if (
      this.north === undefined ||
      this.south === undefined ||
      this.east === undefined ||
      this.west === undefined
    ) {
      return new CustomLatLng(22.17, 95.66); // Central Myanmar
    }

    return new CustomLatLng((this.north + this.south) / 2, (this.east + this.west) / 2);
  }
}

export class CustomLatLng {
  latitude: number;
  longitude: number;
  constructor(lat: number, lng: number) {
    this.latitude = lat;
    this.longitude = lng;
  }
}

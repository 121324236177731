import { API } from 'aws-amplify';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ListOtherActivityTypesCustomQuery,
  ListOtherActivityTypesQuery,
  OtherActivityByTypeCustomQuery,
} from '../../../API';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import * as queries from '../../../graphql/custom_queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { compact } from 'lodash';
import { useQueries, useQuery } from '@tanstack/react-query';
import Styles from './OtherActivities.module.scss';
import { Button, Drawer, Popover, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { SyncOutlined } from '@ant-design/icons';
import { TableFilterType } from '../common_types';
import OASummary from './OASummary';
import { exportToExcel } from './oaExcelExporter';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setSelectedMenuKeys, setActivityFilters, initialState } from '../../../redux/oaSlice';
import type { ActivityFilterType } from '../../../redux/oaSlice';
import ReactGA from 'react-ga4';

const { Link } = Typography;

export type OtherActivityTypeType = Exclude<
  Exclude<
    ListOtherActivityTypesCustomQuery['listOtherActivityTypes'],
    null | undefined
  >['items'][number],
  null
>;

export type ActivityType = Exclude<
  Exclude<OtherActivityByTypeCustomQuery['otherActivityByType'], null | undefined>['items'][number],
  null
>;

const defaultActivityFilters = initialState.activityFilters;

const OtherActivities = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/activities/otheractivities',
    });
  }, []);

  const dispatch = useAppDispatch();

  const [filteredActivities, setFilteredActivities] = useState<ActivityType[]>([]);
  // const [activityFilters, setActivityFilters] = useState<Record<string, (boolean | string | number)[] | null>>({});

  const [projectFilter, setProjectFilter] = useState<Array<TableFilterType>>([]);
  const [dioceseFilter, setDioceseFilter] = useState<Array<TableFilterType>>([]);
  const [careGroupFilter, setCareGroupFilter] = useState<Array<TableFilterType>>([]);
  const [facilitatorFilter, setFacilitatorFilter] = useState<Array<TableFilterType>>([]);
  const [dateFilter, setDateFilter] = useState<Array<TableFilterType>>([]);
  const [activityTypeFilter, setActivityTypeFilter] = useState<Array<TableFilterType>>([]);

  const [summaryDrawerVisible, setSummaryDrawerVisible] = useState(false);
  // const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);

  const { selectedMenuKeys, activityFilters } = useAppSelector((state) => state.oa);

  const user = useGetUserInfo();

  async function listOtherActivityTypes(): Promise<OtherActivityTypeType[]> {
    const result = (await API.graphql({
      query: queries.listOtherActivityTypesCustom,
      variables: {
        limit: 100,
      },
    })) as GraphQLResult<ListOtherActivityTypesCustomQuery>;
    if (result.data && result.data.listOtherActivityTypes) {
      return compact(result.data.listOtherActivityTypes.items);
    } else {
      return [];
    }
  }

  const { data: other_activity_types } = useQuery<OtherActivityTypeType[], Error>(
    ['otherActivityTypes'],
    listOtherActivityTypes,
    {
      select: (data) => data.filter((item) => user.projects.includes(item.project_code)),
    }
  );

  const listOtherActivities = async (other_activity_type_id: string) => {
    let activities: ActivityType[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const result = (await API.graphql({
        query: queries.otherActivityByTypeCustom,
        variables: {
          limit: 100,
          nextToken,
          other_activity_type_id,
        },
      })) as GraphQLResult<OtherActivityByTypeCustomQuery>;
      if (result.data && result.data.otherActivityByType) {
        activities = [...activities, ...compact(result.data.otherActivityByType.items)];
        nextToken = result.data.otherActivityByType.nextToken || undefined;
      }
    } while (nextToken);
    return activities;
  };

  const otherActivityQueryResult = useQueries<ActivityType[]>({
    queries: selectedMenuKeys.keys.map((other_activity_type_id) => {
      return {
        queryKey: ['otherActivities', other_activity_type_id],
        queryFn: () => listOtherActivities(other_activity_type_id),
      };
    }),
  });

  const { isLoading, isError, error, refetch, isFetching, data } = useMemo(() => {
    let data: ActivityType[] | undefined = undefined;
    const isLoading = otherActivityQueryResult.some((result) => result.isLoading === true);
    const isError = otherActivityQueryResult.some((result) => result.isError === true);
    const isFetching = otherActivityQueryResult.some((result) => result.isFetching === true);
    const error = compact(otherActivityQueryResult.map((result) => result.error as Error));
    const refetch = () => {
      otherActivityQueryResult.forEach((result) => result.refetch());
    };
    if (!isLoading && !isError) {
      data = otherActivityQueryResult.reduce(
        (acc, cur) => [...acc, ...compact(cur.data as ActivityType[])],
        [] as ActivityType[]
      );
    }
    return { isLoading, isError, error, refetch, isFetching, data };
  }, [otherActivityQueryResult]);

  useEffect(() => {
    // re-populate filtered records on page re-visit
    let tempActivityList = data || [];
    Object.keys(activityFilters).forEach((filteringField) => {
      let indFilterList = activityFilters[filteringField as ActivityFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempActivityList = tempActivityList.filter((activity) =>
        filterActivities(filteringField as ActivityFilterType, indFilterList!, activity)
      );
      // this effect is to create filters when a page has been returned from other pages.
      // the filters are to be used in summary and excel exporter
      drawDropDownForFilter(filteringField as ActivityFilterType);
    });
    setFilteredActivities(tempActivityList);
  }, []);

  if (isError) {
    if (error) {
      return <span>Error: {error.join('. ')}</span>;
    } else {
      return <span>Error in reqct query.</span>;
    }
  }

  const drawDropDownForFilter = (field: ActivityFilterType) => {
    let tempActivityList = data || []; // to filter the Distribution array with all filter parameters except value in field variable
    Object.keys(activityFilters).forEach((filteringField) => {
      if (filteringField === field) return; // filter except the current column / field

      let indFilterList = activityFilters[filteringField as ActivityFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempActivityList = tempActivityList.filter((activity) =>
        filterActivities(filteringField as ActivityFilterType, indFilterList!, activity)
      );
    });
    switch (field) {
      case 'project':
        const projectSet = new Set<string>();
        tempActivityList.forEach((activity) => {
          projectSet.add(activity.care_group.project_diocese.project.name);
        });
        const projectFilter: TableFilterType[] = [];
        projectSet.forEach((key) => {
          projectFilter.push({ text: key, value: key });
        });
        setProjectFilter(projectFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'diocese':
        const dioceseSet = new Set<string>();
        tempActivityList.forEach((activity) => {
          dioceseSet.add(activity.care_group.project_diocese.diocese.name);
        });
        const diocFilter: TableFilterType[] = [];
        dioceseSet.forEach((key) => {
          diocFilter.push({ text: key, value: key });
        });
        setDioceseFilter(diocFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'activity_date':
        const dateMap: { [key: string]: { [key: string]: string } } = {};
        tempActivityList.forEach((activity) => {
          if (activity.activity_date) {
            const year = activity.activity_date.split('-')[0];
            const yearMonth = activity.activity_date.split('-', 2).join('-');
            if (!dateMap[year]) dateMap[year] = {};
            dateMap[year][yearMonth] = yearMonth;
          }
        });

        const dateFilter: TableFilterType[] = [];
        for (const outer in dateMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(dateMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = { text: inner, value: inner };
              outerFilter.children!.push(innerFilter);
            });
          dateFilter.push(outerFilter);
        }
        setDateFilter(dateFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'facilitators':
        const facilitatorMap = new Map<string, string>();
        tempActivityList.forEach((activity) => {
          if (!activity.facilitators) return;
          activity.facilitators.items.forEach((fac) => {
            if (!(fac && fac.staff)) return;
            facilitatorMap.set(
              fac.staff.id,
              `${fac.staff.first_name} ${fac.staff.last_name} : ${fac.staff.position.name
                .match(/[A-Z]/g)
                ?.join('')} : ${activity.care_group.project_diocese.diocese.name}`
            );
          });
        });
        const facFilter: TableFilterType[] = [];
        for (const [key, value] of facilitatorMap) {
          facFilter.push({ text: value, value: key });
        }
        setFacilitatorFilter(facFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'care_group':
        const cgNumberMap: { [key: string]: { [key: string]: string } } = {};
        tempActivityList.forEach((activity) => {
          const cgFirstNum = activity.care_group.group_number.split('.')[0];
          const cgNum = activity.care_group.group_number;
          if (!cgNumberMap[cgFirstNum]) cgNumberMap[cgFirstNum] = {};
          cgNumberMap[cgFirstNum][cgNum] = cgNum;
        });
        const cgNumberFilter: TableFilterType[] = [];
        for (const outer in cgNumberMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(cgNumberMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = {
                text: inner,
                value: cgNumberMap[outer][inner],
              };
              outerFilter.children!.push(innerFilter);
            });

          cgNumberFilter.push(outerFilter);
        }
        setCareGroupFilter(cgNumberFilter.sort());
        break;
      case 'other_activity_type':
        const activityTypeSet = new Set<string>();
        tempActivityList.forEach((activity) => {
          activityTypeSet.add(activity.other_activity_type.activity_name);
        });

        const activityTypeFilter: TableFilterType[] = [];
        activityTypeSet.forEach((key) => {
          activityTypeFilter.push({ text: key, value: key });
        });
        setActivityTypeFilter(activityTypeFilter);
        break;
      default:
    }
  };

  const filterActivities = (
    field: ActivityFilterType,
    value: string | number | boolean | (string | number | boolean)[],
    record: ActivityType
  ): boolean => {
    switch (field) {
      case 'project':
        if (Array.isArray(value)) {
          return value.includes(record.care_group.project_diocese.project.name);
        }
        return record.care_group.project_diocese.project.name === value;
      case 'diocese':
        if (Array.isArray(value)) {
          return value.includes(record.care_group.project_diocese.diocese.name);
        }
        return record.care_group.project_diocese.diocese.name === value;
      case 'activity_date':
        if (Array.isArray(value)) {
          const yearMonth = record.activity_date.split('-', 2).join('-');
          return value.includes(yearMonth);
        }
        return record.activity_date.split('-', 2).join('-') === value;
      case 'facilitators':
        if (!record.facilitators) return false;
        const facIDSet = new Set(record.facilitators.items.map((fac) => fac?.staff.id));
        if (Array.isArray(value)) {
          return value.some((val) => facIDSet.has(val as string));
        }
        return facIDSet.has(value as string);
      case 'care_group':
        if (Array.isArray(value)) {
          return value.includes(record.care_group.group_number);
        }
        return record.care_group.group_number === value;
      case 'other_activity_type':
        if (Array.isArray(value)) {
          return value.includes(record.other_activity_type.activity_name);
        }
        return record.other_activity_type.activity_name === value;
      default:
        return false;
    }
  };

  const columns: ColumnsType<ActivityType> = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      ellipsis: true,
      render: (_, record) => {
        return record.care_group.project_diocese.project.name;
      },
      filters: projectFilter,
      filteredValue: activityFilters['project'] || null,
      onFilter: (value, record) => filterActivities('project', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('project'),
      sorter: (a, b) => {
        return a.care_group.project_diocese.project.name.localeCompare(
          b.care_group.project_diocese.project.name
        );
      },
    },
    {
      title: 'Diocese',
      dataIndex: 'diocese',
      key: 'diocese',
      render: (_, record) => record.care_group.project_diocese.diocese.name,
      filters: dioceseFilter,
      filteredValue: (activityFilters['diocese'] as any) || null,
      onFilter: (value, record) => filterActivities('diocese', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('diocese'),
      sorter: (a, b) => {
        return a.care_group.project_diocese.diocese.name.localeCompare(
          b.care_group.project_diocese.diocese.name
        );
      },
    },
    {
      title: 'Care Group',
      dataIndex: 'care_group',
      key: 'care_group',
      filters: careGroupFilter,
      filteredValue: (activityFilters['care_group'] as any) || null,
      onFilter: (value, record) => filterActivities('care_group', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('care_group'),
      render: (_, record) => {
        return record.care_group.group_number;
      },
      sorter: (a, b) => a.care_group.group_number.localeCompare(b.care_group.group_number),
    },
    {
      title: 'Activity Date',
      dataIndex: 'activity_date',
      key: 'activity_date',
      filters: dateFilter,
      filteredValue: (activityFilters['activity_date'] as any) || null,
      onFilter: (value, record) => filterActivities('activity_date', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('activity_date'),
      sorter: (a, b) => a.activity_date.localeCompare(b.activity_date),
    },
    {
      title: 'Facliltators',
      dataIndex: 'facilitators',
      key: 'facilitators',
      ellipsis: { showTitle: false },
      filters: facilitatorFilter,
      filteredValue: (activityFilters['facilitators'] as any) || null,
      onFilter: (value, record) => filterActivities('facilitators', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('facilitators'),
      render: (_, record) => {
        const facs =
          record.facilitators?.items.map((fac, index) => {
            return {
              key: index,
              name: `${fac?.staff.first_name} ${fac?.staff.last_name}`,
              position: `${fac?.staff.position.name}`,
            };
          }) || [];

        return (
          <Popover
            placement='left'
            title={`${record.care_group.group_number} @ ${record.activity_date}`}
            trigger={'click'}
            content={
              <Space direction='vertical'>
                {facs?.map((fac) => {
                  return <Space key={fac.key}>{`${fac.name} (${fac.position})`}</Space>;
                })}
              </Space>
            }
          >
            {facs.length > 0 ? (
              facs.length > 1 ? (
                <Link>{facs.length} staff facilitated</Link>
              ) : (
                facs[0].name
              )
            ) : (
              ''
            )}
          </Popover>
        );
      },
    },
    {
      title: 'Activity Type',
      dataIndex: 'other_activity_type',
      key: 'other_activity_type',
      filters: activityTypeFilter,
      filteredValue: (activityFilters['other_activity_type'] as any) || null,
      onFilter: (value, record) => filterActivities('other_activity_type', value, record),
      onFilterDropdownOpenChange: (visible) =>
        visible && drawDropDownForFilter('other_activity_type'),
      render: (_, record) => record.other_activity_type.activity_name,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
      align: 'right',
      render: (value, record) => {
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              title={`${record.care_group.group_number} @ ${record.activity_date}`}
              placement='left'
              trigger={'click'}
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                  }}
                >
                  {record.participants?.items
                    .sort((a, b) => (a?.plw.member_number || 0) - (b?.plw.member_number || 0))
                    .sort((a, b) =>
                      (a?.plw.neighbor_group.group_number || '').localeCompare(
                        b?.plw.neighbor_group.group_number || ''
                      )
                    )
                    .map((recipient) => {
                      if (recipient && recipient.plw) {
                        const completeMemberNumber = `${record.care_group.group_number}.${recipient.plw.neighbor_group.group_number}.${recipient.plw.member_number}`;
                        return (
                          <Space key={recipient.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{recipient.plw.name}</span>
                            {recipient.plw.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.participants?.items.length}</Link>
            </Popover>
          </div>
        );
      },
    },
  ];

  const showSummaryDrawer = () => {
    setSummaryDrawerVisible(true);
  };

  const hideSummaryDrawer = () => {
    setSummaryDrawerVisible(false);
  };

  const reload = () => {
    refetch();
  };
  const clearAllFilters = () => {
    dispatch(setActivityFilters(defaultActivityFilters));
    setFilteredActivities(data || []);
  };
  const excelExportHandler = () => {
    exportToExcel(
      filteredActivities.length > 0 ? filteredActivities : data || [],
      activityFilters,
      facilitatorFilter
    );
  };

  const onMenuSelect = ({ target: { value } }: RadioChangeEvent) => {
    if (value === 'all') {
      dispatch(
        setSelectedMenuKeys({
          keys: other_activity_types?.map((item) => item.id) || [],
          selectAll: true,
        })
      );
    } else {
      dispatch(setSelectedMenuKeys({ keys: [value], selectAll: false }));
    }
    dispatch(setActivityFilters(defaultActivityFilters));
    setFilteredActivities([]);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>OTHER TYPES OF ACTIVITIES</div>
      <div className={Styles.contents}>
        <div className={Styles.selectionPanel} style={{ paddingRight: 7 }}>
          <Radio.Group
            onChange={onMenuSelect}
            optionType='button'
            style={{ width: '100%' }}
            value={selectedMenuKeys.selectAll ? 'all' : selectedMenuKeys.keys[0] || undefined}
          >
            <Space direction='vertical' style={{ width: '100%' }}>
              {other_activity_types
                ? [
                    ...other_activity_types
                      .sort((a, b) => a.activity_name.localeCompare(b.activity_name))
                      .map((item) => {
                        return (
                          <Radio
                            value={item.id}
                            key={item.id}
                            style={{ height: 'fit-content', width: '100%' }}
                          >
                            {item.activity_name} | {item.project.name}
                          </Radio>
                        );
                      }),
                    <Radio
                      value={'all'}
                      key={'all'}
                      style={{ height: 'fit-content', width: '100%' }}
                    >
                      All Other Activity Types
                    </Radio>,
                  ]
                : null}
            </Space>
          </Radio.Group>
        </div>
        <div className={Styles.displayPanel}>
          <div
            className={Styles.buttonContainer}
            style={{ display: !data || data.length === 0 ? 'none' : 'inherit' }}
          >
            <Space className={Styles.innerButtonContainer}>
              <Button
                className={Styles.syncButton}
                onClick={reload}
                loading={isLoading || isFetching}
                icon={<SyncOutlined />}
              ></Button>
              <Button
                className={Styles.clearFilterButton}
                onClick={clearAllFilters}
                disabled={isLoading || isFetching}
              >
                Clear All Filters
              </Button>
              <Button
                className={Styles.exportButton}
                onClick={excelExportHandler}
                disabled={isLoading || isFetching}
              >
                Export
              </Button>
              <Button
                className={Styles.summaryButton}
                onClick={showSummaryDrawer}
                disabled={isLoading || isFetching}
              >
                Show Summary
              </Button>
            </Space>
          </div>
          <div className={Styles.dataTable}>
            <Table
              dataSource={data}
              columns={columns}
              loading={isLoading}
              rowKey='id'
              size='small'
              showSorterTooltip={false}
              pagination={{
                position: ['topRight'],
                defaultPageSize: 50,
                style: { marginRight: 25 },
                showTotal: (total) => `Total ${total}`,
              }}
              onChange={(_pagination, filters, _sorter, extra) => {
                if (extra.action === 'filter') {
                  dispatch(setActivityFilters(filters));
                  setFilteredActivities(extra.currentDataSource);
                }
              }}
              scroll={{ y: 'calc(100vh - 300px)' }}
            />
          </div>
        </div>
        <Drawer
          placement='right'
          closable={false}
          onClose={hideSummaryDrawer}
          open={summaryDrawerVisible}
          getContainer={false}
          width={1000}
          style={{ position: 'absolute' }}
        >
          <OASummary
            filteredActivities={filteredActivities.length > 0 ? filteredActivities : data || []}
            activityFilters={activityFilters}
            facilitatorFilter={facilitatorFilter}
            hide={hideSummaryDrawer}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default OtherActivities;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { TownshipByDioceseCodeCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';
import { compact } from 'lodash';

export const useListTownshipByDioceseCode = (diocese_code: string | undefined) => {
  return useQuery(
    ['listTownshipByDioceseCode', diocese_code || 'never'],
    () =>
      API.graphql({
        query: queries.townshipByDioceseCodeCustom,
        variables: {
          limit: 100,
          diocese_code: diocese_code || 'never',
        },
      }) as Promise<GraphQLResult<TownshipByDioceseCodeCustomQuery>>,
    {
      enabled: !!diocese_code,
      select: (data) => {
        if (data.data && data.data.townshipByDioceseCode) {
          return compact(data.data.townshipByDioceseCode.items);
        }
        return undefined;
      },
    }
  );
};

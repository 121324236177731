import { useEffect, useMemo, useState, useTransition } from 'react';
import { Button, DatePicker, Drawer, Menu, Popover, Space, Table, Tag, Tooltip } from 'antd';
import { API, Analytics } from 'aws-amplify';
import { CareGroupByPdCodeCustomQuery } from '../../API';
import { useGetUserInfo } from '../../hooks/auth_hooks';
import { useListDioceses } from '../../hooks/useListDioceses';
import { useListProjects } from '../../hooks/useListProjects';
import Styles from './CareGroups.module.scss';
import * as queries from '../../graphql/custom_queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { compact } from 'lodash';
import { useQueries } from '@tanstack/react-query';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import { TableFilterType } from '../Activities/common_types';
import { Link, useNavigate } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  setBaseDate,
  setSelectedMenuKeys,
  setCareGroupFilters,
  initialState,
  setOpenedCareGroupID,
} from '../../redux/caregroupsSlice';
import type { CareGroupFilterType } from '../../redux/caregroupsSlice';
import CareGroupSummary from './CareGroupSummary';
import { exportToExcel } from './careGroupExcelExporter';
import CareGroupModalForm from './CareGroupModalForm';

import ReactGA from 'react-ga4';

export type CareGroupType = NonNullable<
  NonNullable<CareGroupByPdCodeCustomQuery['careGroupByPdCode']>['items'][number]
>;

export type ExtendedCareGroupType = CareGroupType & {
  ng_count: number;
  plw_count: number;
  lost_contact_plw_count: number;
  pregnancy_count: number;
  lost_contact_pregnancy_count: number;
  overdue_pregnancy_count: number;
  lost_contact_overdue_pregnancy_count: number;
  u6m_count: number;
  lost_contact_u6m_count: number;
  u2y_count: number;
  lost_contact_u2y_count: number;
};

export type ProjectDioceseType = CareGroupType['project_diocese'];

export type StaffType = NonNullable<
  NonNullable<CareGroupType['staff_caregroups']>['items'][number]
>;

export type NeighborGroupType = NonNullable<
  NonNullable<CareGroupType['neighbor_groups']>['items'][number]
>;

export type PLWType = NonNullable<NonNullable<NeighborGroupType['plws']>['items'][number]>;

export type ExtendedPLWType = PLWType & {
  isPregnant: boolean;
  has_u6m_child: boolean;
  has_u2y_child: boolean;
  has_overdue_pregnancy: boolean;
};

const defaultCareGroupFilter = initialState.careGroupFilters;

const CareGroups = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/caregroups',
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isPending, startTransition] = useTransition();

  const [isCareGroupModalVisible, setIsCareGroupModalVisible] = useState<
    'adding' | 'editing' | false
  >(false);
  const [editingCareGroupId, setEditingCareGroupId] = useState<string | undefined>(undefined);

  const [filteredCareGroups, setFilteredCareGroups] = useState<ExtendedCareGroupType[]>([]);

  const [cgFilter, setCgFilter] = useState<Array<TableFilterType>>([]);
  const [projectFilter, setProjectFilter] = useState<Array<TableFilterType>>([]);
  const [dioceseFilter, setDioceseFilter] = useState<Array<TableFilterType>>([]);
  const [townshipFilter, setTownshipFilter] = useState<Array<TableFilterType>>([]);
  const [staffFilter, setStaffFilter] = useState<Array<TableFilterType>>([]);

  const [summaryDrawerVisible, setSummaryDrawerVisible] = useState(false);

  const baseDate = useAppSelector((state) => state.caregroups.baseDate);
  const selectedMenuKeys = useAppSelector((state) => state.caregroups.selectedMenuKeys);
  const careGroupFilters = useAppSelector((state) => state.caregroups.careGroupFilters);

  const user = useGetUserInfo();

  const { data: projects } = useListProjects();
  const { data: dioceses } = useListDioceses();

  const listCareGroups = async (project_diocese_code: string) => {
    let care_groups: CareGroupType[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const result = (await API.graphql({
        query: queries.careGroupByPdCodeCustom,
        variables: {
          limit: 100,
          nextToken,
          project_diocese_code,
        },
      })) as GraphQLResult<CareGroupByPdCodeCustomQuery>;
      if (result.data && result.data.careGroupByPdCode) {
        care_groups = [...care_groups, ...compact(result.data.careGroupByPdCode.items)];
        nextToken = result.data.careGroupByPdCode.nextToken || undefined;
      }
    } while (nextToken);
    return care_groups;
  };

  const listCareGroupsQueryResult = useQueries<CareGroupType[]>({
    queries: selectedMenuKeys.keys.map((proj_dioc_code) => {
      return {
        queryKey: ['listCareGroups', proj_dioc_code],
        queryFn: () => listCareGroups(proj_dioc_code),
      };
    }),
  });

  const { isLoading, isError, data, error, refetch, isFetching, updateStatusFlag } = useMemo(() => {
    let data: CareGroupType[] | undefined = undefined;
    const isLoading = listCareGroupsQueryResult.some((result) => result.isLoading === true);
    const isError = listCareGroupsQueryResult.some((result) => result.isError === true);
    const isFetching = listCareGroupsQueryResult.some((result) => result.isFetching === true);
    const error = compact(listCareGroupsQueryResult.map((result) => result.error as Error));
    const updateStatusFlag = listCareGroupsQueryResult.reduce(
      (acc, cur) => acc + cur.dataUpdatedAt,
      0
    );

    const refetch = () => {
      listCareGroupsQueryResult.forEach((result) => result.refetch());
    };
    if (!isLoading && !isError) {
      data = listCareGroupsQueryResult.reduce(
        (acc, cur) => [...acc, ...(cur.data as CareGroupType[])],
        [] as CareGroupType[]
      );
    }
    return {
      isLoading,
      isError,
      error,
      refetch,
      isFetching,
      data,
      updateStatusFlag,
    };
  }, [listCareGroupsQueryResult]);

  const careGroups = useMemo(() => {
    let base_date: Moment;
    if (baseDate) {
      base_date = moment(baseDate);
    } else {
      base_date = moment();
    }
    if (data) {
      let extended_cgs: ExtendedCareGroupType[] = [];
      data.forEach((cg) => {
        let ng_count = 0;
        let plw_count = 0;
        let lost_contact_plw_count = 0;
        let pregnancy_count = 0;
        let lost_contact_pregnancy_count = 0;
        let overdue_pregnancy_count = 0;
        let lost_contact_overdue_pregnancy_count = 0;
        let u6m_count = 0;
        let lost_contact_u6m_count = 0;
        let u2y_count = 0;
        let lost_contact_u2y_count = 0;
        let extendedPlws: ExtendedPLWType[] = [];
        if (cg.neighbor_groups) {
          cg.neighbor_groups.items.forEach((ng) => {
            ng_count += 1;

            if (ng && ng.plws) {
              ng.plws.items.forEach((plw) => {
                if (plw) {
                  let extendedPLW: ExtendedPLWType & { ng_id: string } = {
                    ...plw,
                    ng_id: ng.id,
                    isPregnant: false,
                    has_u6m_child: false,
                    has_u2y_child: false,
                    has_overdue_pregnancy: false,
                  };
                  if (plw.birth_histories) {
                    plw.birth_histories.items.forEach((bh) => {
                      if (bh) {
                        if (
                          !!bh.lmp &&
                          !bh.miscarriage_date &&
                          !bh.delivered_date &&
                          !bh.date_of_death
                        ) {
                          if (base_date.diff(moment(bh.lmp)) > 0) {
                            extendedPLW.isPregnant = true;
                            if (base_date.diff(moment(bh.lmp), 'days') > 294) {
                              extendedPLW.has_overdue_pregnancy = true;
                            }
                          }
                        }
                        if (!!bh.delivered_date && !bh.date_of_death) {
                          if (base_date.diff(moment(bh.delivered_date)) > 0) {
                            if (base_date.diff(moment(bh.delivered_date), 'days') <= 180) {
                              extendedPLW.has_u6m_child = true;
                            } else if (base_date.diff(moment(bh.delivered_date), 'days') <= 730) {
                              extendedPLW.has_u2y_child = true;
                            }
                          }
                        }
                      }
                    });
                  }
                  extendedPlws.push(extendedPLW);
                }
              });
            }
          });
        }

        extendedPlws.forEach((plw) => {
          plw_count += 1;
          if (plw.lost_contact) {
            lost_contact_plw_count += 1;
          }
          if (plw.isPregnant) {
            pregnancy_count += 1;
            if (plw.lost_contact) {
              lost_contact_pregnancy_count += 1;
            }
          }
          if (plw.has_overdue_pregnancy) {
            overdue_pregnancy_count += 1;
            if (plw.lost_contact) {
              lost_contact_overdue_pregnancy_count += 1;
            }
          }
          if (plw.has_u6m_child) {
            u6m_count += 1;
            if (plw.lost_contact) {
              lost_contact_u6m_count += 1;
            }
          }
          if (plw.has_u2y_child) {
            u2y_count += 1;
            if (plw.lost_contact) {
              lost_contact_u2y_count += 1;
            }
          }
        });
        extended_cgs.push({
          ...cg,
          ng_count,
          plw_count,
          lost_contact_plw_count,
          pregnancy_count,
          lost_contact_pregnancy_count,
          overdue_pregnancy_count,
          lost_contact_overdue_pregnancy_count,
          u6m_count,
          lost_contact_u6m_count,
          u2y_count,
          lost_contact_u2y_count,
        });
      });
      return extended_cgs.sort((a, b) => {
        const aValue = a.group_number.split('.').map((val) => parseInt(val));
        const bValue = b.group_number.split('.').map((val) => parseInt(val));
        if (aValue[0] !== bValue[0]) {
          return aValue[0] - bValue[0];
        } else {
          return aValue[1] - bValue[1];
        }
      });
    }
  }, [updateStatusFlag, baseDate]);

  useEffect(() => {
    // re-populate filtered records on page re-visit
    let tempCareGroupList = careGroups || [];
    Object.keys(careGroupFilters).forEach((filteringField) => {
      let indFilterList = careGroupFilters[filteringField as CareGroupFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempCareGroupList = tempCareGroupList.filter((meeting) =>
        filterCareGroup(filteringField as CareGroupFilterType, indFilterList!, meeting)
      );
      // this effect is to create filters when a page has been returned from other pages.
      // the filters are to be used in summary and excel exporter
      drawDropDownForFilter(filteringField as CareGroupFilterType);
    });
    setFilteredCareGroups(tempCareGroupList);
    // the filtered records also needs to update after baseDate chnaged
  }, [baseDate]);

  if (isError) {
    if (error.length > 0) {
      return <span>Error: {error.join('. ')} </span>;
    } else {
      return <span>Error in react query.</span>;
    }
  }

  const drawDropDownForFilter = (field: CareGroupFilterType) => {
    let tempCareGroupList = careGroups || []; // to filter the careGroups array with all filter parameters except value in field variable

    Object.keys(careGroupFilters).forEach((filteringField) => {
      if (filteringField === field) return; // filter except the current column / field

      let indFilterList = careGroupFilters[filteringField as CareGroupFilterType];
      if (indFilterList === null) return; // skip field with no filter
      tempCareGroupList = tempCareGroupList.filter((cg) => {
        return filterCareGroup(filteringField as CareGroupFilterType, indFilterList!, cg);
      });
    });

    switch (field) {
      case 'care_group':
        const cgNumberMap: { [key: string]: { [key: string]: string } } = {};
        tempCareGroupList.forEach((cg) => {
          const cgFirstNum = cg.group_number.split('.')[0];
          if (!cgNumberMap[cgFirstNum]) cgNumberMap[cgFirstNum] = {};
          cgNumberMap[cgFirstNum][cg.group_number] = cg.group_number;
        });
        const cgNumberFilter: TableFilterType[] = [];
        for (const outer in cgNumberMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(cgNumberMap[outer])
            .sort((a, b) => parseInt(a.split('.')[1]) - parseInt(b.split('.')[1]))
            .forEach((inner) => {
              const innerFilter = {
                text: inner,
                value: cgNumberMap[outer][inner],
              };
              outerFilter.children!.push(innerFilter);
            });
          cgNumberFilter.push(outerFilter);
        }
        setCgFilter(cgNumberFilter);
        break;

      case 'project':
        const projectSet = new Set<string>();
        tempCareGroupList.forEach((cg) => {
          projectSet.add(cg.project_diocese.project.name);
        });
        const projectFilter: TableFilterType[] = [];
        projectSet.forEach((key) => {
          projectFilter.push({ text: key, value: key });
        });
        setProjectFilter(projectFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'diocese':
        const dioceseSet = new Set<string>();
        tempCareGroupList.forEach((cg) => {
          dioceseSet.add(cg.project_diocese.diocese.name);
        });
        const diocFilter: TableFilterType[] = [];
        dioceseSet.forEach((key) => {
          diocFilter.push({ text: key, value: key });
        });
        setDioceseFilter(diocFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'township':
        const townshipSet = new Set<string>();
        tempCareGroupList.forEach((cg) => {
          townshipSet.add(cg.township.name);
        });
        const townshipFilter: TableFilterType[] = [];
        townshipSet.forEach((key) => {
          townshipFilter.push({ text: key, value: key });
        });
        setTownshipFilter(townshipFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'staff':
        const staffMap = new Map<string, string>();
        tempCareGroupList.forEach((cg) => {
          if (cg.staff_caregroups) {
            cg.staff_caregroups.items.map((staffcg) => {
              if (staffcg && staffcg.current_assignment === true) {
                staffMap.set(
                  staffcg.staff.id,
                  `${staffcg.staff.first_name} ${
                    staffcg.staff.last_name
                  } | ${staffcg.staff.position.name
                    .split(' ')
                    .map((item) => item[0].toUpperCase())
                    .join('')} | ${cg.project_diocese.diocese.name} | ${
                    cg.project_diocese.project.name
                  }`
                );
              }
            });
          }
        });
        const staffFilter: TableFilterType[] = [];
        staffMap.forEach((value, key) => {
          staffFilter.push({ text: value, value: key });
        });
        setStaffFilter(staffFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      default:
    }
  };

  const filterCareGroup = (
    field: CareGroupFilterType,
    value: string | number | boolean | (string | number | boolean)[],
    record: ExtendedCareGroupType
  ): boolean => {
    switch (field) {
      case 'care_group':
        if (Array.isArray(value)) {
          return value.includes(record.group_number);
        }
        return value === record.group_number;
      case 'project':
        if (Array.isArray(value)) {
          return value.includes(record.project_diocese.project.name);
        }
        return value === record.project_diocese.project.name;
      case 'diocese':
        if (Array.isArray(value)) {
          return value.includes(record.project_diocese.diocese.name);
        }
        return value === record.project_diocese.diocese.name;
      case 'township':
        if (Array.isArray(value)) {
          return value.includes(record.township.name);
        }
        return value === record.township.name;
      case 'staff':
        const current_staff_id = record.staff_caregroups?.items.find(
          (item) => item?.current_assignment === true
        )?.staff.id;
        if (current_staff_id) {
          if (Array.isArray(value)) {
            return value.includes(current_staff_id);
          }
          return value === current_staff_id;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  // console.log('repeat');

  let columns: ColumnsType<ExtendedCareGroupType> = [
    {
      title: 'CG #',
      dataIndex: 'care_group',
      key: 'care_group',
      fixed: 'left',
      width: 100,
      align: 'center',
      filters: cgFilter,
      filteredValue: (careGroupFilters['care_group'] as any) || null,
      onFilter: (value, record) => filterCareGroup('care_group', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('care_group'),
      render: (_, record) => record.group_number,
      sorter: (a, b) => {
        const aValue = a.group_number.split('.').map((val) => parseInt(val));
        const bValue = b.group_number.split('.').map((val) => parseInt(val));
        if (aValue[0] !== bValue[0]) {
          return aValue[0] - bValue[0];
        } else {
          return aValue[1] - bValue[1];
        }
      },
    },
    {
      title: 'Diocese',
      dataIndex: 'diocese',
      key: 'diocese',
      width: 130,
      fixed: 'left',
      render: (_, record) => record.project_diocese.diocese.name,
      filters: dioceseFilter,
      filteredValue: (careGroupFilters['diocese'] as any) || null,
      onFilter: (value, record) => filterCareGroup('diocese', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('diocese'),
      sorter: (a, b) => {
        return a.project_diocese.diocese.name.localeCompare(b.project_diocese.diocese.name);
      },
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      ellipsis: true,
      width: 130,
      render: (_, record) => {
        return record.project_diocese.project.name;
      },
      filters: projectFilter,
      filteredValue: careGroupFilters['project'] || null,
      onFilter: (value, record) => filterCareGroup('project', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('project'),
      sorter: (a, b) => {
        return a.project_diocese.project.name.localeCompare(b.project_diocese.project.name);
      },
    },
    {
      title: 'Township',
      dataIndex: 'township',
      key: 'township',
      width: 130,
      render: (_, record) => record.township.name,
      filters: townshipFilter,
      filteredValue: (careGroupFilters['township'] as any) || null,
      onFilter: (value, record) => filterCareGroup('township', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('township'),
      sorter: (a, b) => {
        return a.township.name.localeCompare(b.township.name);
      },
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      key: 'staff',
      width: 150,
      ellipsis: { showTitle: false },
      filters: staffFilter,
      filteredValue: (careGroupFilters['staff'] as any) || null,
      onFilter: (value, record) => filterCareGroup('staff', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('staff'),
      render: (_, record) => {
        const staffOfCg: StaffType[] = [];
        if (record.staff_caregroups) {
          record.staff_caregroups.items.forEach((scg) => {
            if (scg) {
              staffOfCg.push(scg);
            }
          });
        }
        const currentAssignedStaff = staffOfCg.find((scg) => scg.current_assignment === true);
        const displayCurrentStaff = currentAssignedStaff
          ? `${currentAssignedStaff.staff.first_name} ${
              currentAssignedStaff.staff.last_name
            } (${currentAssignedStaff.staff.position.name
              .split(' ')
              .map((item) => item[0].toUpperCase())
              .join('')})`
          : '';
        return (
          <Popover
            placement='leftBottom'
            title={`All assigned staff of group ${record.group_number}`}
            content={
              <Space direction='vertical'>
                {staffOfCg.map((staff) => {
                  const displayStaff = `${staff.staff.first_name} ${staff.staff.last_name} (${staff.staff.position.name})`;
                  return <Space key={staff.staff.id}>{displayStaff}</Space>;
                })}
              </Space>
            }
          >
            {displayCurrentStaff}
          </Popover>
        );
      },
      sorter: (a, b) => {
        return a.township.name.localeCompare(b.township.name);
      },
    },
    {
      title: 'Total NGs',
      dataIndex: 'ng_count',
      key: 'ng_count',
      width: 125,
      align: 'center',
      render: (_, record) => record.ng_count,
      sorter: (a, b) => a.ng_count - b.ng_count,
    },
    {
      title: 'Total PLWs',
      dataIndex: 'plw_count',
      key: 'plw_count',
      width: 125,
      align: 'center',
      render: (_, record) => {
        return (
          <div
            style={{
              width: '100%',
              paddingRight: 20,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {record.lost_contact_plw_count > 0 ? (
              <Tooltip title='lost contact'>
                <Tag color='cyan'>{record.lost_contact_plw_count}</Tag>
              </Tooltip>
            ) : null}
            {record.plw_count}
          </div>
        );
      },
      sorter: (a, b) => {
        return a.plw_count - b.plw_count;
      },
    },
    {
      title: 'Pregnancy',
      dataIndex: 'pregnancy',
      key: 'pregnancy',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Popover
            title={`Pregnancy data of group: ${record.group_number}, ${record.project_diocese.project.name}`}
            placement='leftTop'
            content={
              <table className={Styles.popoverTable}>
                <tbody>
                  <tr>
                    <td></td>
                    <th>active</th>
                    <th>lost contact</th>
                    <th>total</th>
                  </tr>
                  <tr>
                    <th>pregnancy</th>
                    <td>{record.pregnancy_count - record.lost_contact_pregnancy_count}</td>
                    <td>{record.lost_contact_pregnancy_count}</td>
                    <td>{record.pregnancy_count}</td>
                  </tr>
                  <tr>
                    <th>overdue</th>
                    <td>
                      {record.overdue_pregnancy_count - record.lost_contact_overdue_pregnancy_count}
                    </td>
                    <td>{record.lost_contact_overdue_pregnancy_count}</td>
                    <td>{record.overdue_pregnancy_count}</td>
                  </tr>
                </tbody>
              </table>
            }
          >
            <div
              style={{
                width: '100%',
                paddingRight: 20,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {record.overdue_pregnancy_count > 0 ? (
                <Tag color='pink'>{record.overdue_pregnancy_count}</Tag>
              ) : null}
              {record.pregnancy_count}
            </div>
          </Popover>
        );
      },
      sorter: (a, b) => a.pregnancy_count - b.pregnancy_count,
    },
    {
      title: 'U6M',
      dataIndex: 'u6m_count',
      key: 'u6m_count',
      width: 80,
      align: 'center',
      render: (_, record) => record.u6m_count,
      sorter: (a, b) => a.u6m_count - b.u6m_count,
    },
    {
      title: 'U2Y',
      dataIndex: 'u2y_count',
      key: 'u2y_count',
      width: 80,
      align: 'center',
      render: (_, record) => record.u2y_count,
      sorter: (a, b) => a.u2y_count - b.u2y_count,
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Space size={15}>
          <Link
            to={record.id}
            onClick={() => {
              dispatch(setOpenedCareGroupID(record.id));
            }}
          >
            Open
          </Link>
          <Link
            to='#'
            onClick={() => {
              showEditCareGroupModal(record.id);
            }}
            style={!user.isEditor ? { pointerEvents: 'none', opacity: 0.3 } : {}} // disable edit if not editor
          >
            Edit
          </Link>
        </Space>
      ),
    },
  ];

  const proj_dioc_menu_items = [
    ...user.groups.map((group) => {
      const project = projects?.find((proj) => proj?.project_code === group.split('_')[0])?.name;
      const diocese = dioceses?.find((dioc) => dioc?.diocese_code === group.split('_')[1])?.name;
      if (project && diocese) {
        return {
          label: `${diocese} (${project})`,
          key: group,
        };
      } else {
        return {
          label: '...loading',
          key: group,
        };
      }
    }),
    {
      label: 'All Dioceses',
      key: 'all',
    },
  ];

  const showSummaryDrawer = () => {
    setSummaryDrawerVisible(true);
  };

  const hideSummaryDrawer = () => {
    setSummaryDrawerVisible(false);
  };

  const reload = () => {
    refetch();
  };
  const clearAllFilters = () => {
    dispatch(setCareGroupFilters(defaultCareGroupFilter));
    setFilteredCareGroups(careGroups || []);
  };
  const excelExportHandler = () => {
    exportToExcel(
      filteredCareGroups.length > 0 ? filteredCareGroups : careGroups || [],
      careGroupFilters,
      staffFilter,
      baseDate
    );
  };

  const dateChangeHandler = (value: moment.Moment | null) => {
    startTransition(() => {
      dispatch(setBaseDate(value?.format('YYYY-MM-DD') || moment().format('YYYY-MM-DD')));
    });
  };

  const showAddCareGroupModal = () => {
    setEditingCareGroupId(undefined);
    setIsCareGroupModalVisible('adding');
  };

  const showEditCareGroupModal = (editingCareGroupId: string) => {
    setEditingCareGroupId(editingCareGroupId);
    setIsCareGroupModalVisible('editing');
  };

  const onMenuSelect = ({ key }: { key: string }) => {
    ReactGA.event({
      category: 'CareGroups',
      action: 'select Project Diocese',
      label: key,
    });
    startTransition(() => {
      if (key === 'all') {
        dispatch(setSelectedMenuKeys({ keys: user.groups, selectAll: true }));
      } else {
        dispatch(setSelectedMenuKeys({ keys: [key], selectAll: false }));
      }
      dispatch(setCareGroupFilters(defaultCareGroupFilter));
      setFilteredCareGroups([]);
    });
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>CARE GROUPS</div>
      <div className={Styles.contents}>
        <div className={Styles.selectionPanel}>
          <Menu
            items={proj_dioc_menu_items}
            onSelect={onMenuSelect}
            selectedKeys={selectedMenuKeys.selectAll ? ['all'] : selectedMenuKeys.keys}
          />
        </div>
        <div className={Styles.displayPanel}>
          <div className={Styles.tableHeaderRow}>
            <div
              className={Styles.buttonContainer}
              // style={{
              //   display: !careGroups || careGroups.length === 0 ? 'none' : 'inherit',
              // }}
            >
              <Space className={Styles.innerButtonContainer}>
                <Button
                  className={Styles.syncButton}
                  onClick={reload}
                  loading={isLoading || isFetching}
                  icon={<SyncOutlined />}
                ></Button>
                <Button
                  className={Styles.clearFilterButton}
                  onClick={clearAllFilters}
                  disabled={isLoading || isFetching}
                >
                  Clear All Filters
                </Button>
                <Button
                  className={Styles.exportButton}
                  onClick={excelExportHandler}
                  disabled={isLoading || isFetching}
                >
                  Export
                </Button>
                <Button
                  className={Styles.summaryButton}
                  onClick={showSummaryDrawer}
                  disabled={isLoading || isFetching}
                >
                  Show Summary
                </Button>
                <Button
                  className={Styles.summaryButton}
                  onClick={showAddCareGroupModal}
                  disabled={
                    isLoading ||
                    isFetching ||
                    selectedMenuKeys.selectAll ||
                    selectedMenuKeys.keys.length === 0
                  }
                >
                  Add new Care Group
                </Button>
              </Space>
            </div>
            <div className={Styles.datePickContainer}>
              <span>Birth History based date: </span>
              <DatePicker value={moment(baseDate)} onChange={(value) => dateChangeHandler(value)} />
            </div>
          </div>
          <div className={Styles.dataTable}>
            <Table
              dataSource={careGroups}
              columns={columns}
              loading={isLoading}
              rowKey='id'
              size='small'
              showSorterTooltip={false}
              pagination={{
                position: ['bottomRight'],
                defaultPageSize: 50,
                style: { marginRight: 25 },
                showTotal: (total, range) => `Total ${total}`,
              }}
              onChange={(_pagination, filters, _sorter, extra) => {
                if (extra.action === 'filter') {
                  dispatch(setCareGroupFilters(filters));
                  setFilteredCareGroups(extra.currentDataSource);
                }
              }}
              scroll={{ x: 'calc(100vw - 500px)', y: 'calc(100vh - 300px)' }}
            />
          </div>
        </div>
        <Drawer
          placement='right'
          closable={false}
          onClose={hideSummaryDrawer}
          open={summaryDrawerVisible}
          getContainer={false}
          width={1050}
          style={{ position: 'absolute' }}
        >
          <CareGroupSummary
            filteredCareGroups={
              filteredCareGroups.length > 0 ? filteredCareGroups : careGroups || []
            }
            careGroupFilters={careGroupFilters}
            birth_history_base_date={baseDate}
            staffFilter={staffFilter}
            hide={hideSummaryDrawer}
          />
        </Drawer>
      </div>
      {selectedMenuKeys.keys.length > 0 && (
        <CareGroupModalForm
          modalVisible={isCareGroupModalVisible}
          setModalVisible={setIsCareGroupModalVisible}
          editingCareGroupId={editingCareGroupId}
          project_diocese_code={selectedMenuKeys.keys[0]}
        />
      )}
    </div>
  );
};

export default CareGroups;

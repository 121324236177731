import { useEffect, useMemo, useState } from 'react';
import Styles from './NeighborGroupMeetings.module.scss';

import { NgMeetingByPdCodeCustomQuery } from '../../../API';
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/custom_queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { compact } from 'lodash';
import { useQueries } from '@tanstack/react-query';
import { Button, Drawer, Menu, Popover, Space, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { CopyFilled, CopyOutlined, CopyTwoTone, SyncOutlined } from '@ant-design/icons';
import { TableFilterType } from '../common_types';
import NGMSummary from './NGMSummary';
import { exportToExcel } from './ngmExcelExporter';
import { useListProjects } from '../../../hooks/useListProjects';
import { useListDioceses } from '../../../hooks/useListDioceses';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setSelectedMenuKeys, setNgMeetingFilters, initialState } from '../../../redux/ngmSlice';
import type { NgmFilterType } from '../../../redux/ngmSlice';
import ReactGA from 'react-ga4';

const { Link } = Typography;

export type NGMeetingType = Exclude<
  Exclude<NgMeetingByPdCodeCustomQuery['ngMeetingByPdCode'], null | undefined>['items'][number],
  null
>;

const defaultNgmFilters = initialState.ngMeetingFilters;

const NeighborGroupMeetings = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/activities/neighborgroupmeetings',
    });
  }, []);

  const dispatch = useAppDispatch();

  const [filteredNgMeetings, setFilteredNgMeetings] = useState<NGMeetingType[]>([]);
  // const [ngMeetingFilters, setNgMeetingFilters] = useState<Record<string, (boolean | string | number)[] | null>>({});

  const [neighborGroupFilter, setNeighborGroupFilter] = useState<Array<TableFilterType>>([]);
  const [projectFilter, setProjectFilter] = useState<Array<TableFilterType>>([]);
  const [dioceseFilter, setDioceseFilter] = useState<Array<TableFilterType>>([]);
  const [careGroupFilter, setCareGroupFilter] = useState<Array<TableFilterType>>([]);
  const [topicFilter, setTopicFilter] = useState<Array<TableFilterType>>([]);
  const [dateFilter, setDateFilter] = useState<Array<TableFilterType>>([]);

  const [summaryDrawerVisible, setSummaryDrawerVisible] = useState(false);
  // const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);

  const user = useGetUserInfo();

  const ngMeetingFilters = useAppSelector((state) => state.ngm.ngMeetingFilters);
  const selectedMenuKeys = useAppSelector((state) => state.ngm.selectedMenuKeys);

  const { data: projects } = useListProjects();
  const { data: dioceses } = useListDioceses();

  const getNgMeetings = async (proj_dioc_code: string) => {
    let meetings: NGMeetingType[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const result = (await API.graphql({
        query: queries.ngMeetingByPdCodeCustom,
        variables: {
          limit: 100,
          nextToken,
          project_diocese_code: proj_dioc_code,
        },
      })) as GraphQLResult<NgMeetingByPdCodeCustomQuery>;
      if (result.data && result.data.ngMeetingByPdCode) {
        meetings = [...meetings, ...compact(result.data.ngMeetingByPdCode.items)];
        nextToken = result.data.ngMeetingByPdCode.nextToken || undefined;
      }
    } while (nextToken);
    return meetings;
  };

  const queryResults = useQueries<NGMeetingType[]>({
    queries: selectedMenuKeys.keys.map((proj_dioc_code) => {
      return {
        queryKey: ['ngMeetings', proj_dioc_code],
        queryFn: () => getNgMeetings(proj_dioc_code),
      };
    }),
  });

  const { isLoading, isError, data, error, refetch, isFetching } = useMemo(() => {
    let data: NGMeetingType[] | undefined = undefined;
    const isLoading = queryResults.some((result) => result.isLoading === true);
    const isError = queryResults.some((result) => result.isError === true);
    const isFetching = queryResults.some((result) => result.isFetching === true);
    const error = compact(queryResults.map((result) => result.error as Error));
    const refetch = () => {
      queryResults.forEach((result) => result.refetch());
    };
    if (!isLoading && !isError) {
      data = queryResults.reduce(
        (acc, cur) => [...acc, ...(cur.data as NGMeetingType[])],
        [] as NGMeetingType[]
      );
    }
    return { isLoading, isError, error, refetch, isFetching, data };
  }, [queryResults]);

  useEffect(() => {
    // re-populate filtered records on page re-visit
    let tempNgMeetingList = data || [];
    Object.keys(ngMeetingFilters).forEach((filteringField) => {
      let indFilterList = ngMeetingFilters[filteringField as NgmFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempNgMeetingList = tempNgMeetingList.filter((meeting) =>
        filterMeeting(filteringField as NgmFilterType, indFilterList!, meeting)
      );
      // this effect is to create filters when a page has been returned from other pages.
      // the filters are to be used in summary and excel exporter
      drawDropDownForFilter(filteringField as NgmFilterType);
    });
    setFilteredNgMeetings(tempNgMeetingList);
  }, []);

  if (isError) {
    if (error) {
      return <span>Error: {error.join('. ')}</span>;
    } else {
      return <span>Error in reqct query.</span>;
    }
  }

  const drawDropDownForFilter = (field: NgmFilterType) => {
    let tempNgMeetingList = data || []; // to filter the ngMeeting array with all filter parameters except value in field variable
    Object.keys(ngMeetingFilters).forEach((filteringField) => {
      if (filteringField === field) return; // filter except the current column / field

      let indFilterList = ngMeetingFilters[filteringField as NgmFilterType];
      if (!indFilterList) return; // skip if field with no filter
      tempNgMeetingList = tempNgMeetingList.filter((meeting) =>
        filterMeeting(filteringField as NgmFilterType, indFilterList!, meeting)
      );
    });
    switch (field) {
      case 'project':
        const projectSet = new Set<string>();
        tempNgMeetingList.forEach((cgm) => {
          projectSet.add(cgm.neighbor_group.care_group.project_diocese.project.name);
        });
        const projectFilter: TableFilterType[] = [];
        projectSet.forEach((key) => {
          projectFilter.push({ text: key, value: key });
        });
        setProjectFilter(projectFilter.sort());
        break;
      case 'diocese':
        const dioceseSet = new Set<string>();
        tempNgMeetingList.forEach((ngm) => {
          dioceseSet.add(ngm.neighbor_group.care_group.project_diocese.diocese.name);
        });
        const diocFilter: TableFilterType[] = [];
        dioceseSet.forEach((dioceseName) => {
          diocFilter.push({ text: dioceseName, value: dioceseName });
        });
        setDioceseFilter(diocFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'meeting_date':
        const dateMap: { [key: string]: { [key: string]: string } } = {};
        tempNgMeetingList.forEach((ngm) => {
          const year = ngm.meeting_date!.split('-')[0];
          const yearMonth = ngm.meeting_date!.split('-', 2).join('-');
          if (!dateMap[year]) dateMap[year] = {};
          dateMap[year][yearMonth] = yearMonth;
        });

        const dateFilter: TableFilterType[] = [];
        for (const outer in dateMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(dateMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = { text: inner, value: inner };
              outerFilter.children!.push(innerFilter);
            });
          dateFilter.push(outerFilter);
        }
        setDateFilter(dateFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'care_group':
        const cgNumberMap: { [key: string]: { [key: string]: string } } = {};
        tempNgMeetingList.forEach((ngm) => {
          const cgFirstNum = ngm.neighbor_group.care_group.group_number.split('.')[0];
          if (!cgNumberMap[cgFirstNum]) cgNumberMap[cgFirstNum] = {};
          cgNumberMap[cgFirstNum][ngm.neighbor_group.care_group.group_number] =
            ngm.neighbor_group.care_group.group_number;
        });
        const cgNumberFilter: TableFilterType[] = [];
        for (const outer in cgNumberMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(cgNumberMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = {
                text: inner,
                value: cgNumberMap[outer][inner],
              };
              outerFilter.children!.push(innerFilter);
            });
          cgNumberFilter.push(outerFilter);
        }
        setCareGroupFilter(cgNumberFilter.sort());
        break;
      case 'neighbor_group':
        const ngNumberMap: { [key: string]: { [key: string]: string } } = {};
        tempNgMeetingList.forEach((ngm) => {
          const completeNgNumber = `${ngm.neighbor_group.care_group.group_number}.${ngm.neighbor_group.group_number}`;
          const cgNum = ngm.neighbor_group.care_group.group_number;
          if (!ngNumberMap[cgNum]) ngNumberMap[cgNum] = {};
          ngNumberMap[cgNum][completeNgNumber] = completeNgNumber;
        });

        const ngNumberFilter: TableFilterType[] = [];
        Object.keys(ngNumberMap)
          .sort()
          .forEach((firstLevel) => {
            let outerFilter: TableFilterType = {
              text: firstLevel,
              value: firstLevel,
              children: [],
            };
            Object.keys(ngNumberMap[firstLevel])
              .sort()
              .forEach((secondLevel) => {
                let innerFilter = {
                  text: secondLevel,
                  value: secondLevel,
                };
                outerFilter.children!.push(innerFilter);
              });
            ngNumberFilter.push(outerFilter);
          });
        setNeighborGroupFilter(ngNumberFilter);
        break;
      case 'topics':
        const topicMap: { [key: string]: string } = {};
        tempNgMeetingList.forEach((cgm) => {
          if (!cgm.topics) return;
          cgm.topics.items.forEach((topic) => {
            if (!(topic && topic.nutrition_topic)) return;
            topicMap[topic.nutrition_topic.id] = topic.nutrition_topic.topic_text;
          });
        });

        const topFilter: TableFilterType[] = [];
        for (const [key, value] of Object.entries(topicMap)) {
          topFilter.push({ text: value, value: key });
        }
        setTopicFilter(
          topFilter.sort((a, b) => {
            let A = parseInt(a.text.split('.')[0]);
            let B = parseInt(b.text.split('.')[0]);
            if (isNaN(A) || isNaN(B)) return 0;
            return A - B;
          })
        );
        break;
      default:
    }
  };

  const filterMeeting = (
    field: NgmFilterType,
    value: string | number | boolean | (string | number | boolean)[],
    record: NGMeetingType
  ): boolean => {
    switch (field) {
      case 'project':
        if (Array.isArray(value)) {
          return value.includes(record.neighbor_group.care_group.project_diocese.project.name);
        }
        return record.neighbor_group.care_group.project_diocese.project.name === value;
      case 'diocese':
        if (Array.isArray(value)) {
          return value.includes(record.neighbor_group.care_group.project_diocese.diocese.name);
        }
        return record.neighbor_group.care_group.project_diocese.diocese.name === value;
      case 'meeting_date':
        if (Array.isArray(value)) {
          const yearMonth = record.meeting_date!.split('-', 2).join('-');
          return value.includes(yearMonth);
        }
        return record.meeting_date!.split('-', 2).join('-') === value;
      case 'care_group':
        if (Array.isArray(value)) {
          return value.includes(record.neighbor_group.care_group.group_number);
        }
        return record.neighbor_group.care_group.group_number === value;
      case 'neighbor_group':
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;

        if (Array.isArray(value)) {
          return value.includes(completeNgNumber);
        }
        return completeNgNumber === value;
      case 'topics':
        if (!record.topics) return false;
        const topicIDSet = new Set(
          record.topics.items.map((item) => {
            if (item && item.nutrition_topic) {
              return item.nutrition_topic.id;
            } else return null;
          })
        );
        if (Array.isArray(value)) {
          return value.some((val) => topicIDSet.has(val as string));
        }
        return topicIDSet.has(value as string);
      default:
        return false;
    }
  };

  let columns: ColumnsType<NGMeetingType> = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      ellipsis: true,
      render: (_, record) => {
        return record.neighbor_group.care_group.project_diocese.project.name;
      },
      filters: projectFilter,
      filteredValue: ngMeetingFilters['project'] || null,
      onFilter: (value, record) => filterMeeting('project', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('project'),
      sorter: (a, b) => {
        return a.neighbor_group.care_group.project_diocese.project.name.localeCompare(
          b.neighbor_group.care_group.project_diocese.project.name
        );
      },
    },
    {
      title: 'Diocese',
      dataIndex: 'diocese',
      key: 'diocese',
      render: (_, record) => record.neighbor_group.care_group.project_diocese.diocese.name,
      filters: dioceseFilter,
      filteredValue: (ngMeetingFilters['diocese'] as any) || null,
      onFilter: (value, record) => filterMeeting('diocese', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('diocese'),
      sorter: (a, b) => {
        return a.neighbor_group.care_group.project_diocese.diocese.name.localeCompare(
          b.neighbor_group.care_group.project_diocese.diocese.name
        );
      },
    },
    {
      title: 'Meeting Date',
      dataIndex: 'meeting_date',
      key: 'meeting_date',
      filters: dateFilter,
      filteredValue: (ngMeetingFilters['meeting_date'] as any) || null,
      onFilter: (value, record) => filterMeeting('meeting_date', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('meeting_date'),
      sorter: (a, b) => a.meeting_date!.localeCompare(b.meeting_date!),
    },
    {
      title: 'Facilitator',
      dataIndex: 'facilitator',
      key: 'facilitator',
      ellipsis: true,
      render: (_, record) => {
        const completeMemberNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}.${record.plw_facilitator.member_number}`;
        return `${completeMemberNumber} - ${record.plw_facilitator.name} ${
          record.plw_facilitator.lead_mother ? ' *' : ''
        }`;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Care Group',
      dataIndex: 'care_group',
      key: 'care_group',
      filters: careGroupFilter,
      filteredValue: (ngMeetingFilters['care_group'] as any) || null,
      onFilter: (value, record) => filterMeeting('care_group', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('care_group'),
      render: (_, record) => {
        return record.neighbor_group.care_group.group_number;
      },
      sorter: (a, b) =>
        a.neighbor_group.care_group.group_number.localeCompare(
          b.neighbor_group.care_group.group_number
        ),
    },
    {
      title: 'Neighbor Group',
      dataIndex: 'neighbor_group',
      key: 'neighbor_group',
      filters: neighborGroupFilter,
      filteredValue: (ngMeetingFilters['neighbor_group'] as any) || null,
      onFilter: (value, record) => filterMeeting('neighbor_group', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('neighbor_group'),
      render: (_, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return completeNgNumber;
      },
      sorter: (a, b) => {
        const aCompleteNgNumber = `${a.neighbor_group.care_group.group_number}.${a.neighbor_group.group_number}`;
        const bCompleteNgNumber = `${b.neighbor_group.care_group.group_number}.${b.neighbor_group.group_number}`;
        return aCompleteNgNumber.localeCompare(bCompleteNgNumber);
      },
    },
    {
      title: 'Topics',
      dataIndex: 'topics',
      key: 'topics',
      ellipsis: { showTitle: false },
      filters: topicFilter,
      filteredValue: (ngMeetingFilters['topics'] as any) || null,
      onFilter: (value, record) => filterMeeting('topics', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('topics'),
      render: (_, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return (
          <Popover
            placement='left'
            title={`${completeNgNumber} @ ${record.meeting_date}`}
            trigger={'click'}
            content={
              <Space
                direction='vertical'
                style={{
                  maxWidth: 500,
                  maxHeight: 300,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {record.topics?.items
                  .sort((a, b) => {
                    const firstNum = parseInt(a!.nutrition_topic.topic_text.split('.')[0]) || false;
                    const secondNum =
                      parseInt(b!.nutrition_topic.topic_text.split('.')[0]) || false;
                    if (firstNum && secondNum) {
                      return firstNum - secondNum;
                    }
                    return a!.nutrition_topic.topic_text.localeCompare(
                      b!.nutrition_topic.topic_text
                    );
                  })
                  .map((item) => {
                    return (
                      <Space key={item?.nutrition_topic?.id}>
                        {item?.nutrition_topic?.topic_text}
                      </Space>
                    );
                  })}
              </Space>
            }
          >
            <Link>
              {record.topics?.items.length} {record.topics?.items.length === 1 ? 'topic' : 'topics'}{' '}
              covered
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Participants #',
      dataIndex: 'participants',
      key: 'participants',
      align: 'right',
      render: (value, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              title={`${completeNgNumber} @ ${record.meeting_date}`}
              placement='left'
              trigger={'click'}
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                  }}
                >
                  {record.participants?.items
                    .sort((a, b) => (a?.plw.member_number || 0) - (b?.plw.member_number || 0))
                    .map((ppt) => {
                      if (ppt && ppt.plw) {
                        const completeMemberNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}.${ppt.plw.member_number}`;
                        return (
                          <Space key={ppt.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{ppt.plw.name}</span>
                            {ppt.plw.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.participants?.items.length || 0}</Link>
            </Popover>
          </div>
        );
      },
    },
  ];

  // if (user.projectCount < 2) {
  //   columns = columns.filter((col: ColumnType<NGMeetingType>) => col.dataIndex !== 'project');
  // }

  const showSummaryDrawer = () => {
    setSummaryDrawerVisible(true);
  };

  const hideSummaryDrawer = () => {
    setSummaryDrawerVisible(false);
  };

  const reload = () => {
    refetch();
  };
  const clearAllFilters = () => {
    dispatch(setNgMeetingFilters(defaultNgmFilters));
    setFilteredNgMeetings(data || []);
  };
  const excelExportHandler = () => {
    exportToExcel(
      filteredNgMeetings.length > 0 ? filteredNgMeetings : data || [],
      ngMeetingFilters,
      topicFilter
    );
  };

  const proj_dioc_menu_items = [
    ...user.groups.map((group) => {
      const project = projects?.find((proj) => proj?.project_code === group.split('_')[0])?.name;
      const diocese = dioceses?.find((dioc) => dioc?.diocese_code === group.split('_')[1])?.name;
      if (project && diocese) {
        return {
          label: `${diocese} (${project})`,
          key: group,
        };
      } else {
        return {
          label: group,
          key: group,
        };
      }
    }),
    {
      label: 'All Dioceses',
      key: 'all',
    },
  ];

  const onMenuSelect = ({ key }: { key: string }) => {
    if (key === 'all') {
      dispatch(setSelectedMenuKeys({ keys: user.groups, selectAll: true }));
    } else {
      dispatch(setSelectedMenuKeys({ keys: [key], selectAll: false }));
    }
    dispatch(setNgMeetingFilters(defaultNgmFilters));
    setFilteredNgMeetings([]);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>NEIGHBOR GROUP MEETINGS</div>
      <div className={Styles.contents}>
        <div className={Styles.selectionPanel}>
          <Menu
            items={proj_dioc_menu_items}
            onSelect={onMenuSelect}
            selectedKeys={selectedMenuKeys.selectAll ? ['all'] : selectedMenuKeys.keys}
          />
        </div>
        <div className={Styles.displayPanel}>
          <div
            className={Styles.buttonContainer}
            style={{ display: !data || data.length === 0 ? 'none' : 'inherit' }}
          >
            <Space className={Styles.innerButtonContainer}>
              <Button
                className={Styles.syncButton}
                onClick={reload}
                loading={isLoading || isFetching}
                icon={<SyncOutlined />}
              ></Button>
              <Button
                className={Styles.clearFilterButton}
                onClick={clearAllFilters}
                disabled={isLoading || isFetching}
              >
                Clear All Filters
              </Button>
              <Button
                className={Styles.exportButton}
                onClick={excelExportHandler}
                disabled={isLoading || isFetching}
              >
                Export
              </Button>
              <Button
                className={Styles.summaryButton}
                onClick={showSummaryDrawer}
                disabled={isLoading || isFetching}
              >
                Show Summary
              </Button>
            </Space>
          </div>
          <div className={Styles.dataTable}>
            <Table
              dataSource={data}
              columns={columns}
              loading={isLoading}
              rowKey='id'
              size='small'
              showSorterTooltip={false}
              pagination={{
                position: ['topRight'],
                defaultPageSize: 50,
                style: { marginRight: 25 },
                showTotal: (total, range) => `Total ${total}`,
              }}
              onChange={(_pagination, filters, _sorter, extra) => {
                if (extra.action === 'filter') {
                  dispatch(setNgMeetingFilters(filters));
                  setFilteredNgMeetings(extra.currentDataSource);
                }
              }}
              scroll={{ y: 'calc(100vh - 300px)' }}
            />
          </div>
        </div>
        <Drawer
          placement='right'
          closable={false}
          onClose={hideSummaryDrawer}
          open={summaryDrawerVisible}
          getContainer={false}
          width={1000}
          style={{ position: 'absolute' }}
        >
          <NGMSummary
            filteredNgMeetings={filteredNgMeetings.length > 0 ? filteredNgMeetings : data || []}
            ngMeetingFilters={ngMeetingFilters}
            topicFilter={topicFilter}
            hide={hideSummaryDrawer}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default NeighborGroupMeetings;

import React, { useEffect, useState } from 'react';
// import Uploader from './uploaders/uploaders';

// import { useAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import store from './redux/store';
import { Provider as ReduxProvider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import MainMenu from './components/Navigation/MainMenu';
import Login from './components/Authenticator/Login';
import RequireAuth from './components/Authenticator/RequireAuth';
import CareGroups from './pages/CareGroups/CareGroups';
import NeighborGroups from './pages/NeighborGroups/NeighborGroups';
import CareGroupMeetings from './pages/Activities/CareGroupMeetings/CareGroupMeetings';
import NeighborGroupMeetings from './pages/Activities/NeighborGroupMeetings/NeighborGroupMeetings';
import Activities from './pages/Activities/Activities';
import HGDistributions from './pages/Activities/HGDistributions/HGDistributions';
import OtherActivities from './pages/Activities/OtherActivities/OtherActivities';
import Staff from './pages/Setup/Staff/Staff';
import Map from './pages/Map/Map';

import { Analytics, Auth, Storage } from 'aws-amplify';

import ReactGA from 'react-ga4';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUserAmplify } from '@aws-amplify/ui';
import HomePagePhotoAlbum from './pages/Setup/HomePagePhotoAlbum/HomePagePhotoAlbum';
import NutritionMessages from './pages/Setup/NutritionMessages/NutritionMessages';
import OtherActivityTypes from './pages/Setup/OtherActivityTypes/OtherActivityTypes';
import DistributionPlans from './pages/Setup/DistributionPlans/DistributionPlans';
const TRACKING_ID = 'G-VD08HXK3YW';
ReactGA.initialize(
  TRACKING_ID
  //   {
  //   gaOptions: {
  //     siteSpeedSampleRate: 100,
  //   },
  // }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 1, // 1 hr
      cacheTime: 1000 * 60 * 60 * 8, // 8 hrs
    },
  },
});

export type AlbumPhotoType = {
  name: string;
  url: string;
};

function App() {
  const { user } = useAuthenticator((context) => [context.user]);
  const [photoList, setPhotoList] = useState<AlbumPhotoType[]>([]);

  useEffect(() => {
    const getPhotoList = async () => {
      try {
        const result = await Storage.list('');
        let uploadedFiles: AlbumPhotoType[] = [];
        for (let file of result) {
          if (file.key && file.eTag) {
            const signedUrl = await Storage.get(file.key, {
              expires: 60 * 60 * 8, // 8 hrs
            });
            uploadedFiles.push({ url: signedUrl, name: file.key });
          }
        }
        setPhotoList(uploadedFiles);
      } catch {
        console.error('Error in fetching photos.');
      }
    };
    getPhotoList();
  }, []);

  const trackUserId = async (user: CognitoUserAmplify) => {
    try {
      let userEmail: string | undefined = undefined;
      if (user.attributes) {
        userEmail = user.attributes.email;
      }
      const username = user.username;
      await Analytics.updateEndpoint({
        address: userEmail || username,
        channelType: 'APNS',
        optOut: 'ALL',
        userId: username,
        userAttribites: {
          email: userEmail,
          username: username,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user) {
      trackUserId(user);
    }
  }, [user]);

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<MainMenu />}>
              <Route index element={<Home photoList={photoList} />} />
              {/* <Route index element={<Home photoList={[]} />} /> */}
              <Route
                path='/caregroups'
                element={
                  <RequireAuth>
                    <CareGroups />
                  </RequireAuth>
                }
              />
              <Route
                path='/caregroups/:cg_id'
                element={
                  <RequireAuth>
                    <NeighborGroups />
                  </RequireAuth>
                }
              />
              <Route path='activities'>
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Activities />
                    </RequireAuth>
                  }
                />
                <Route
                  path='caregroupmeetings'
                  element={
                    <RequireAuth>
                      <CareGroupMeetings />
                    </RequireAuth>
                  }
                />
                <Route
                  path='neighborgroupmeetings'
                  element={
                    <RequireAuth>
                      <NeighborGroupMeetings />
                    </RequireAuth>
                  }
                />
                <Route
                  path='homegardendistributions'
                  element={
                    <RequireAuth>
                      <HGDistributions />
                    </RequireAuth>
                  }
                />
                <Route
                  path='otheractivities'
                  element={
                    <RequireAuth>
                      <OtherActivities />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path='setup'>
                <Route
                  path='nutritionmessages'
                  element={
                    <RequireAuth>
                      <NutritionMessages />
                    </RequireAuth>
                  }
                />
                <Route
                  path='distributionplans'
                  element={
                    <RequireAuth>
                      <DistributionPlans />
                    </RequireAuth>
                  }
                />
                <Route
                  path='otheractivitytypes'
                  element={
                    <RequireAuth>
                      <OtherActivityTypes />
                    </RequireAuth>
                  }
                />
                <Route
                  path='photos'
                  element={
                    <RequireAuth>
                      <HomePagePhotoAlbum />
                    </RequireAuth>
                  }
                />
                {/* <Route
                  path='staff'
                  element={
                    <RequireAuth>
                      <Staff />
                    </RequireAuth>
                  }
                /> */}
              </Route>
              <Route path='map' element={<Map />} />
              {/* <Route
                path='/uploader'
                element={
                  <RequireAuth>
                    <Uploader />
                  </RequireAuth>
                }
              /> */}
              <Route path='/login' element={<Login />} />
            </Route>
          </Routes>
        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-right' /> */}
      </QueryClientProvider>
    </ReduxProvider>
  );
}

export default App;

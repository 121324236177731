export const ngMeetingByPdCodeCustom = /* GraphQL */ `
  query NgMeetingByPdCodeCustom(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNGMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ngMeetingByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_diocese_code
        meeting_date
        description
        neighbor_group {
          id
          group_number
          care_group {
            id
            group_number
            project_diocese {
              project {
                id
                name
              }
              diocese {
                id
                name
              }
            }
          }
        }
        plw_facilitator {
          id
          name
          member_number
          lead_mother
        }
        topics {
          items {
            nutrition_topic {
              id
              topic_text
              topic_text_myanmar
            }
          }
        }
        staff_facilitators {
          items {
            staff {
              id
              first_name
              last_name
              position {
                name
              }
            }
          }
        }
        participants {
          items {
            plw {
              id
              name
              member_number
              father_name
              date_of_birth
              lead_mother
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getNGMeetingForEdit = /* GraphQL */ `
  query GetNGMeetingForEdit($id: ID!) {
    getNGMeeting(id: $id) {
      id
      neighbor_group_id
      plw_facilitator_id
      meeting_date
      description
      topics {
        items {
          id
          nutrition_topic {
            id
          }
        }
      }
      staff_facilitators {
        items {
          id
          staff {
            id
          }
        }
      }
      participants {
        items {
          id
          plw {
            id
            member_number
          }
        }
      }
    }
  }
`;

export const ngMeetingByNeighborGroupCustom = /* GraphQL */ `
  query NgMeetingByNeighborGroupCustom(
    $neighbor_group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNGMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ngMeetingByNeighborGroup(
      neighbor_group_id: $neighbor_group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        plw_facilitator {
          id
          member_number
          lead_mother
          name
        }
        meeting_date
        topics {
          items {
            nutrition_topic {
              id
              topic_text
              topic_text_myanmar
            }
          }
        }
        participants {
          items {
            plw {
              id
              member_number
              lead_mother
              name
            }
          }
        }
      }
    }
  }
`;

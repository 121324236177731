export const listProjectsCustom = /* GraphQL */ `
  query ListProjectsCustom(
    $id: ID
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjects(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
      }
    }
  }
`;

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { CGMeetingType } from './CareGroupMeetings';
import { TableFilterType } from '../common_types';
import moment from 'moment';

export const exportToExcel = async (
  filteredCgMeetings: CGMeetingType[],
  cgMeetingFilters: Record<string, (boolean | string | number)[] | null>,
  facilitatorFilter: Array<TableFilterType>,
  topicFilter: Array<TableFilterType>
) => {
  const workbook = new ExcelJS.Workbook();

  const dioceseNameSet = new Set<string>();
  const careGroupNumberMap = new Map<string, { careGroupNumber: string; dioceseName: string; projectName: string }>();
  const facilitatorMap = new Map<
    string,
    {
      facilitatorName: string;
      position: string;
      diocese: string;
      project: string;
    }
  >();
  const topicMap = new Map<
    string,
    {
      topicInEnglish: string;
      topicInMyanmar: string;
    }
  >();
  const participantMap = new Map<
    string,
    {
      memberNumber: string;
      name: string;
      fatherName: string;
      dob: string;
      leadMother: boolean;
      careGroup: string;
      diocese: string;
      project: string;
    }
  >();

  const meetingSheet = workbook.addWorksheet('Care Group Meetings');
  meetingSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Meeting Date', key: 'date', width: 15 },
    { header: 'Facilitators', key: 'facilitators', width: 25 },
    { header: 'Description', key: 'description', width: 20 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Topics', key: 'topics', width: 25 },
    { header: 'Participants #', key: 'participants', width: 15 },
  ];
  meetingSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  const meetingDetailSheet = workbook.addWorksheet('Meeting Details');
  meetingDetailSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Meeting Date', key: 'date', width: 15 },
    { header: 'Facilitators', key: 'facilitators', width: 25 },
    { header: 'Description', key: 'description', width: 20 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Topics', key: 'topics', width: 25 },
    { header: 'Participant #', key: 'participantNumber', width: 15 },
    { header: 'Participant Name', key: 'participantName', width: 20 },
    { header: 'Lead Mother', key: 'leadMother', width: 15 },
  ];
  meetingDetailSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));
  filteredCgMeetings.forEach((fcgm, index) => {
    ///////////////////////////////////////
    if (fcgm.care_group.project_diocese.diocese) dioceseNameSet.add(fcgm.care_group.project_diocese.diocese.name);

    careGroupNumberMap.set(fcgm.care_group.id, {
      careGroupNumber: fcgm.care_group.group_number,
      dioceseName: fcgm.care_group.project_diocese.diocese.name,
      projectName: fcgm.care_group.project_diocese.project.name,
    });

    if (fcgm.facilitators) {
      fcgm.facilitators.items.forEach((facilitator) => {
        if (facilitator && facilitator.staff) {
          facilitatorMap.set(facilitator.staff.id, {
            facilitatorName: facilitator.staff.first_name + ' ' + facilitator.staff.last_name,
            position: facilitator.staff.position.name,
            diocese: fcgm.care_group.project_diocese.diocese.name,
            project: fcgm.care_group.project_diocese.project.name,
          });
        }
      });
    }

    if (fcgm.topics) {
      fcgm.topics.items.forEach((topic) => {
        if (topic && topic.nutrition_topic) {
          topicMap.set(topic.nutrition_topic.id, {
            topicInEnglish: topic.nutrition_topic.topic_text,
            topicInMyanmar: topic.nutrition_topic.topic_text_myanmar || '',
          });
        }
      });
    }

    if (fcgm.participants) {
      fcgm.participants.items.forEach((participant) => {
        if (participant && participant.plw) {
          const complete_member_number = `${fcgm.care_group.group_number}.${participant.plw.neighbor_group.group_number}.${participant.plw.member_number}`;
          participantMap.set(participant.plw.id, {
            memberNumber: complete_member_number,
            name: participant.plw.name,
            fatherName: participant.plw.father_name || '',
            dob: participant.plw.date_of_birth || '',
            leadMother: participant.plw.lead_mother || false,
            careGroup: fcgm.care_group.group_number,
            diocese: fcgm.care_group.project_diocese.diocese.name,
            project: fcgm.care_group.project_diocese.project.name,
          });

          ///////////////////////////////////////////////
          meetingDetailSheet.addRow({
            project: fcgm.care_group.project_diocese.project.name,
            diocese: fcgm.care_group.project_diocese.diocese?.name,
            date: fcgm.meeting_date,
            facilitators: fcgm.facilitators?.items
              .map((fac) => {
                if (fac && fac.staff) {
                  return fac.staff.first_name + ' ' + fac.staff.last_name;
                }
              })
              .join(', '),
            description: fcgm.description,
            careGroup: fcgm.care_group.group_number,
            topics: fcgm.topics?.items
              .map((topic) => {
                if (topic && topic.nutrition_topic) {
                  return topic.nutrition_topic.topic_text;
                }
              })
              .join(', '),
            participantNumber: complete_member_number,
            participantName: participant.plw.name,
            leadMother: participant.plw.lead_mother ? 'TRUE' : '',
          });
          const lastRow = meetingDetailSheet.lastRow!;
          lastRow.eachCell({ includeEmpty: true }, (cell) => {
            if (index % 2 === 0) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D1D1D1' },
                // bgColor: { argb: 'DDDDDD' },
              };
            }
            cell.border = {
              top: { style: 'dotted' },
              right: { style: 'dotted' },
              bottom: { style: 'dotted' },
              left: { style: 'dotted' },
            };
          });
        }
      });
    }

    ///////////////////////////////////////
    meetingSheet.addRow({
      project: fcgm.care_group.project_diocese.project.name,
      diocese: fcgm.care_group.project_diocese.diocese?.name,
      date: fcgm.meeting_date,
      facilitators: fcgm.facilitators?.items
        .map((fac) => {
          if (fac && fac.staff) {
            return fac.staff.first_name + ' ' + fac.staff.last_name;
          }
        })
        .join(', '),
      description: fcgm.description,
      careGroup: fcgm.care_group.group_number,
      topics: fcgm.topics?.items
        .map((topic) => {
          if (topic && topic.nutrition_topic) {
            return topic.nutrition_topic.topic_text;
          }
        })
        .join(', '),
      participants: fcgm.participants?.items.length,
    });
  });

  const filterSheet = workbook.addWorksheet('Filters');

  filterSheet.getColumn(1).width = 22;
  filterSheet.getColumn(2).width = 50;
  filterSheet.addRow(['Filter Name', 'Filter Value']);
  const lastRow = filterSheet.lastRow!;
  lastRow.eachCell((cell) => {
    cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true };
  });

  Object.keys(cgMeetingFilters).forEach((filter) => {
    if (cgMeetingFilters[filter] && cgMeetingFilters[filter]!.length > 0) {
      const spaced = filter.replace(/([A-Z])/g, ' $1');
      const filterTitle = spaced.charAt(0).toUpperCase() + spaced.slice(1);
      if (cgMeetingFilters[filter]) {
        cgMeetingFilters[filter]!.forEach((id, index) => {
          let filterValue: string | number | boolean | undefined;
          switch (filter) {
            case 'facilitators':
              filterValue = facilitatorFilter.find((ff) => ff.value === id)?.text;
              break;
            case 'topics':
              filterValue = topicFilter.find((tf) => tf.value === id)?.text;
              break;
            default:
              filterValue = id;
              break;
          }
          if (index === 0) {
            filterSheet.addRow([filterTitle, filterValue]);
          } else {
            filterSheet.addRow(['', filterValue]);
          }
        });
      }
    }
  });

  const dioceseSheet = workbook.addWorksheet('Dioceses');
  dioceseSheet.columns = [{ header: 'Participating Dioceses', key: 'diocese', width: 25 }];
  dioceseSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));
  [...dioceseNameSet].sort().forEach((name) => {
    dioceseSheet.addRow([name]);
  });

  const facilitatorSheet = workbook.addWorksheet('Facilitators');
  facilitatorSheet.columns = [
    { header: 'Name', key: 'name', width: 18 },
    { header: 'Position', key: 'position', width: 25 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  facilitatorSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...facilitatorMap]
    .sort((a, b) => a[1].facilitatorName.localeCompare(b[1].facilitatorName))
    .forEach((facilitator) => {
      facilitatorSheet.addRow({
        name: facilitator[1].facilitatorName,
        position: facilitator[1].position,
        diocese: facilitator[1].diocese,
        project: facilitator[1].project,
      });
    });

  const careGroupSheet = workbook.addWorksheet('Care Groups');
  careGroupSheet.columns = [
    { header: 'Group Number', key: 'groupNumber', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  careGroupSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...careGroupNumberMap]
    .sort((a, b) => {
      return a[1].careGroupNumber.localeCompare(b[1].careGroupNumber);
    })
    .forEach((careGroup) => {
      careGroupSheet.addRow({
        groupNumber: careGroup[1].careGroupNumber,
        diocese: careGroup[1].dioceseName,
        project: careGroup[1].projectName,
      });
    });

  const topicSheet = workbook.addWorksheet('Topics');
  topicSheet.columns = [
    { header: 'Topic', key: 'topic', width: 25 },
    { header: 'Topic in Myanmar', key: 'topicMyanmar', width: 50 },
  ];
  topicSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...topicMap]
    .sort((a, b) => a[1].topicInEnglish.localeCompare(b[1].topicInEnglish))
    .forEach((topic) => {
      topicSheet.addRow({
        topic: topic[1].topicInEnglish,
        topicMyanmar: topic[1].topicInMyanmar,
      });
    });

  const participantSheet = workbook.addWorksheet('Participants');
  participantSheet.columns = [
    { header: 'Member Number', key: 'memberNumber', width: 12 },
    { header: 'Name', key: 'name', width: 25 },
    { header: 'Father Name', key: 'fatherName', width: 25 },
    { header: 'DOB', key: 'dob', width: 15 },
    { header: 'Lead Mother', key: 'leadMother', width: 15 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  participantSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...participantMap]
    .sort((a, b) => a[1].memberNumber.localeCompare(b[1].memberNumber))
    .forEach((participant) => {
      participantSheet.addRow({
        memberNumber: participant[1].memberNumber,
        name: participant[1].name,
        fatherName: participant[1].fatherName,
        dob: participant[1].dob,
        leadMother: participant[1].leadMother ? 'TRUE' : '',
        careGroup: participant[1].careGroup,
        diocese: participant[1].diocese,
        project: participant[1].project,
      });
    });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `Care Group Meeting Data ${moment().format('YYYY-MM-DD h-mm a')}.xlsx`);
};

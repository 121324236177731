export const listDistributionItemsCustom = /* GraphQL */ `
  query ListDistributionItemsCustom(
    $id: ID
    $filter: ModelDistributionItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistributionItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        project_code
        project {
          id
          name
        }
      }
    }
  }
`;

export const getDistributionForEdit = /* GraphQL */ `
  query GetDistributionForEdit($id: ID!) {
    getDistribution(id: $id) {
      id
      project_diocese_code
      distribution_item_id
      neighbor_group_id
      distribution_date
      description
      recipients {
        items {
          id
          plw {
            member_number
            id
          }
        }
      }
    }
  }
`;

export const distributionByItemCustom = /* GraphQL */ `
  query DistributionByItemCustom(
    $distribution_item_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distributionByItem(
      distribution_item_id: $distribution_item_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distribution_date
        description
        distribution_item {
          id
          title
        }
        recipients {
          items {
            plw {
              id
              name
              member_number
              lead_mother
              father_name
              date_of_birth
            }
          }
        }
        neighbor_group {
          id
          group_number
          care_group {
            id
            group_number
            project_diocese {
              id
              project {
                id
                name
              }
              diocese {
                id
                name
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const distributionByNeighborGroupCustom = /* GraphQL */ `
  query DistributionByNeighborGroupCustom(
    $neighbor_group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distributionByNeighborGroup(
      neighbor_group_id: $neighbor_group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distribution_item {
          id
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
        }
        distribution_date
        recipients {
          items {
            plw {
              id
              member_number
              name
              lead_mother
            }
          }
        }
      }
    }
  }
`;

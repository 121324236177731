/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      project_id
      project_code
      name
      sector
      description
      donor
      start_date
      end_date
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distribution_items {
        items {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activitie_types {
        items {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_topics {
        items {
          id
          hg_training_topic_id
          project_id
          project_code
          topic
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $id: ID
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjects(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDiocese = /* GraphQL */ `
  query GetDiocese($id: ID!) {
    getDiocese(id: $id) {
      id
      diocese_id
      diocese_code
      name
      description
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      townships {
        items {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDiocese = /* GraphQL */ `
  query ListDiocese(
    $id: ID
    $filter: ModelDioceseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDiocese(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectDiocese = /* GraphQL */ `
  query GetProjectDiocese($id: ID!) {
    getProjectDiocese(id: $id) {
      id
      project_diocese_id
      projectID
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      dioceseID
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjectDiocese = /* GraphQL */ `
  query ListProjectDiocese(
    $id: ID
    $filter: ModelProjectDioceseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjectDiocese(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTownship = /* GraphQL */ `
  query GetTownship($id: ID!) {
    getTownship(id: $id) {
      id
      ts_pcode
      d_pcode
      sr_pcode
      name
      name_mm
      diocese_id
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      diocese_code
      active
      villageTracts {
        items {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      camps {
        items {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTownships = /* GraphQL */ `
  query ListTownships(
    $id: ID
    $filter: ModelTownshipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTownships(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVillageTract = /* GraphQL */ `
  query GetVillageTract($id: ID!) {
    getVillageTract(id: $id) {
      id
      vt_pcode
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      diocese_code
      active
      villages {
        items {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVillageTracts = /* GraphQL */ `
  query ListVillageTracts(
    $id: ID
    $filter: ModelVillageTractFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVillageTracts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vt_pcode
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        diocese_code
        active
        villages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVillage = /* GraphQL */ `
  query GetVillage($id: ID!) {
    getVillage(id: $id) {
      id
      village_pcode
      villageTract_id
      villageTract {
        id
        vt_pcode
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        diocese_code
        active
        villages {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      alt_name
      alt_name_mm
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVillages = /* GraphQL */ `
  query ListVillages(
    $id: ID
    $filter: ModelVillageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVillages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        village_pcode
        villageTract_id
        villageTract {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        alt_name
        alt_name_mm
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCamp = /* GraphQL */ `
  query GetCamp($id: ID!) {
    getCamp(id: $id) {
      id
      camp_id
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      description
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCamps = /* GraphQL */ `
  query ListCamps(
    $id: ID
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCamps(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        camp_id
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        description
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareGroup = /* GraphQL */ `
  query GetCareGroup($id: ID!) {
    getCareGroup(id: $id) {
      id
      care_group_id
      group_number
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroups {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings {
        items {
          id
          hg_training_id
          project_diocese_code
          care_group_id
          training_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCareGroups = /* GraphQL */ `
  query ListCareGroups(
    $id: ID
    $filter: ModelCareGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCareGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPosition = /* GraphQL */ `
  query GetPosition($id: ID!) {
    getPosition(id: $id) {
      id
      position_id
      name
      level
      description
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPositions = /* GraphQL */ `
  query ListPositions(
    $id: ID
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPositions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        position_id
        name
        level
        description
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      staff_id
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      position_id
      position {
        id
        position_id
        name
        level
        description
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroup {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_position
      from_date
      to_date
      email
      first_name
      last_name
      gender
      is_staff
      is_active
      date_joined
      cg_meetings_facilitated {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_facilitated {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings_facilitated {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStaff = /* GraphQL */ `
  query ListStaff(
    $id: ID
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStaff(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStaffCareGroup = /* GraphQL */ `
  query GetStaffCareGroup($id: ID!) {
    getStaffCareGroup(id: $id) {
      id
      staff_caregroup_id
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      current_assignment
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`;
export const listStaffCareGroups = /* GraphQL */ `
  query ListStaffCareGroups(
    $id: ID
    $filter: ModelStaffCareGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStaffCareGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        staff_caregroup_id
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        staff_id
        staff {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        current_assignment
        start_date
        end_date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNeighborGroup = /* GraphQL */ `
  query GetNeighborGroup($id: ID!) {
    getNeighborGroup(id: $id) {
      id
      neighbor_group_id
      group_number
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      village_id
      village {
        id
        village_pcode
        villageTract_id
        villageTract {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        alt_name
        alt_name_mm
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      camp_id
      camp {
        id
        camp_id
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        description
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNeighborGroups = /* GraphQL */ `
  query ListNeighborGroups(
    $id: ID
    $filter: ModelNeighborGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNeighborGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducationLevel = /* GraphQL */ `
  query GetEducationLevel($id: ID!) {
    getEducationLevel(id: $id) {
      id
      education_level_id
      education_level
      education_level_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEducationLevels = /* GraphQL */ `
  query ListEducationLevels(
    $id: ID
    $filter: ModelEducationLevelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEducationLevels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        education_level_id
        education_level
        education_level_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOccupationType = /* GraphQL */ `
  query GetOccupationType($id: ID!) {
    getOccupationType(id: $id) {
      id
      occupation_type_id
      occupation_type
      occupation_type_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOccupationTypes = /* GraphQL */ `
  query ListOccupationTypes(
    $id: ID
    $filter: ModelOccupationTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOccupationTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        occupation_type_id
        occupation_type
        occupation_type_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDisabilityType = /* GraphQL */ `
  query GetDisabilityType($id: ID!) {
    getDisabilityType(id: $id) {
      id
      disability_type_id
      disability_type
      description
      createdAt
      updatedAt
    }
  }
`;
export const listDisabilityTypes = /* GraphQL */ `
  query ListDisabilityTypes(
    $id: ID
    $filter: ModelDisabilityTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDisabilityTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        disability_type_id
        disability_type
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPLW = /* GraphQL */ `
  query GetPLW($id: ID!) {
    getPLW(id: $id) {
      id
      plw_id
      member_number
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      date_of_registry
      lead_mother
      name
      father_name
      date_of_birth
      education_level_id
      education_level {
        id
        education_level_id
        education_level
        education_level_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      occupation_type_id
      occupation_type {
        id
        occupation_type_id
        occupation_type
        occupation_type_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      disabled
      disability_type
      disability_type_other_description
      contact_number
      date_of_death
      lost_contact
      birth_histories {
        items {
          id
          birth_history_id
          plw_id
          project_diocese_code
          lmp
          miscarriage_date
          delivered_date
          child_name
          child_gender
          date_of_death
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings_attended {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_attended {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions_recieved {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_attended {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_attended {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPLWS = /* GraphQL */ `
  query ListPLWS(
    $id: ID
    $filter: ModelPLWFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPLWS(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBirthHistory = /* GraphQL */ `
  query GetBirthHistory($id: ID!) {
    getBirthHistory(id: $id) {
      id
      birth_history_id
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      lmp
      miscarriage_date
      delivered_date
      child_name
      child_gender
      date_of_death
      createdAt
      updatedAt
    }
  }
`;
export const listBirthHistories = /* GraphQL */ `
  query ListBirthHistories(
    $id: ID
    $filter: ModelBirthHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBirthHistories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        birth_history_id
        plw_id
        plw {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        project_diocese_code
        lmp
        miscarriage_date
        delivered_date
        child_name
        child_gender
        date_of_death
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNutritionTopic = /* GraphQL */ `
  query GetNutritionTopic($id: ID!) {
    getNutritionTopic(id: $id) {
      id
      nutrition_topic_id
      topic_text
      topic_text_myanmar
      description
      projects
      nutrition_messages {
        items {
          id
          nutrition_message_id
          nutrition_topic_id
          message_text
          message_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNutritionTopics = /* GraphQL */ `
  query ListNutritionTopics(
    $id: ID
    $filter: ModelNutritionTopicFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNutritionTopics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNutritionMessage = /* GraphQL */ `
  query GetNutritionMessage($id: ID!) {
    getNutritionMessage(id: $id) {
      id
      nutrition_message_id
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      message_text
      message_text_myanmar
      description
      nutrition_questions {
        items {
          id
          nutrition_question_id
          nutrition_message_id
          question_text
          question_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNutritionMessages = /* GraphQL */ `
  query ListNutritionMessages(
    $id: ID
    $filter: ModelNutritionMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNutritionMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nutrition_message_id
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        message_text
        message_text_myanmar
        description
        nutrition_questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNutritionQuestion = /* GraphQL */ `
  query GetNutritionQuestion($id: ID!) {
    getNutritionQuestion(id: $id) {
      id
      nutrition_question_id
      nutrition_message_id
      nutrition_message {
        id
        nutrition_message_id
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        message_text
        message_text_myanmar
        description
        nutrition_questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question_text
      question_text_myanmar
      description
      createdAt
      updatedAt
    }
  }
`;
export const listNutritionQuestions = /* GraphQL */ `
  query ListNutritionQuestions(
    $id: ID
    $filter: ModelNutritionQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNutritionQuestions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nutrition_question_id
        nutrition_message_id
        nutrition_message {
          id
          nutrition_message_id
          nutrition_topic_id
          message_text
          message_text_myanmar
          description
          createdAt
          updatedAt
        }
        question_text
        question_text_myanmar
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCGMeeting = /* GraphQL */ `
  query GetCGMeeting($id: ID!) {
    getCGMeeting(id: $id) {
      id
      cg_meeting_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCGMeetings = /* GraphQL */ `
  query ListCGMeetings(
    $id: ID
    $filter: ModelCGMeetingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCGMeetings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCGMeetingNutritionTopic = /* GraphQL */ `
  query GetCGMeetingNutritionTopic($id: ID!) {
    getCGMeetingNutritionTopic(id: $id) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listCGMeetingNutritionTopics = /* GraphQL */ `
  query ListCGMeetingNutritionTopics(
    $id: ID
    $filter: ModelCGMeetingNutritionTopicFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCGMeetingNutritionTopics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cg_meeting_id
        cg_meeting {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCGMeetingStaff = /* GraphQL */ `
  query GetCGMeetingStaff($id: ID!) {
    getCGMeetingStaff(id: $id) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listCGMeetingStaffs = /* GraphQL */ `
  query ListCGMeetingStaffs(
    $id: ID
    $filter: ModelCGMeetingStaffFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCGMeetingStaffs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cg_meeting_id
        cg_meeting {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        staff_id
        staff {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCGMeetingPLW = /* GraphQL */ `
  query GetCGMeetingPLW($id: ID!) {
    getCGMeetingPLW(id: $id) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listCGMeetingPLWS = /* GraphQL */ `
  query ListCGMeetingPLWS(
    $id: ID
    $filter: ModelCGMeetingPLWFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCGMeetingPLWS(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cg_meeting_id
        cg_meeting {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        plw_id
        plw {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNGMeeting = /* GraphQL */ `
  query GetNGMeeting($id: ID!) {
    getNGMeeting(id: $id) {
      id
      ng_meeting_id
      project_diocese_code
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_facilitator_id
      plw_facilitator {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      staff_facilitators {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNGMeetings = /* GraphQL */ `
  query ListNGMeetings(
    $id: ID
    $filter: ModelNGMeetingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNGMeetings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNGMeetingNutritionTopic = /* GraphQL */ `
  query GetNGMeetingNutritionTopic($id: ID!) {
    getNGMeetingNutritionTopic(id: $id) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listNGMeetingNutritionTopics = /* GraphQL */ `
  query ListNGMeetingNutritionTopics(
    $id: ID
    $filter: ModelNGMeetingNutritionTopicFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNGMeetingNutritionTopics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ng_meeting_id
        ng_meeting {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNGMeetingStaffFacilitator = /* GraphQL */ `
  query GetNGMeetingStaffFacilitator($id: ID!) {
    getNGMeetingStaffFacilitator(id: $id) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listNGMeetingStaffFacilitators = /* GraphQL */ `
  query ListNGMeetingStaffFacilitators(
    $id: ID
    $filter: ModelNGMeetingStaffFacilitatorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNGMeetingStaffFacilitators(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ng_meeting_id
        ng_meeting {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        staff_id
        staff {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNGMeetingPLW = /* GraphQL */ `
  query GetNGMeetingPLW($id: ID!) {
    getNGMeetingPLW(id: $id) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listNGMeetingPLWS = /* GraphQL */ `
  query ListNGMeetingPLWS(
    $id: ID
    $filter: ModelNGMeetingPLWFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNGMeetingPLWS(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ng_meeting_id
        ng_meeting {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        plw_id
        plw {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistributionItem = /* GraphQL */ `
  query GetDistributionItem($id: ID!) {
    getDistributionItem(id: $id) {
      id
      distribution_item_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      title
      description
      planned_distribution_start_date
      planned_distribution_end_date
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDistributionItems = /* GraphQL */ `
  query ListDistributionItems(
    $id: ID
    $filter: ModelDistributionItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistributionItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        distribution_item_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistribution = /* GraphQL */ `
  query GetDistribution($id: ID!) {
    getDistribution(id: $id) {
      id
      distribution_id
      project_diocese_code
      distribution_item_id
      distribution_item {
        id
        distribution_item_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      distribution_date
      description
      recipients {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDistributions = /* GraphQL */ `
  query ListDistributions(
    $id: ID
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistributions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistributionRecipients = /* GraphQL */ `
  query GetDistributionRecipients($id: ID!) {
    getDistributionRecipients(id: $id) {
      id
      distribution_id
      distribution {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listDistributionRecipients = /* GraphQL */ `
  query ListDistributionRecipients(
    $id: ID
    $filter: ModelDistributionRecipientsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistributionRecipients(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        distribution_id
        distribution {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        plw_id
        plw {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOtherActivityType = /* GraphQL */ `
  query GetOtherActivityType($id: ID!) {
    getOtherActivityType(id: $id) {
      id
      other_activity_type_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      activity_name
      description
      activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOtherActivityTypes = /* GraphQL */ `
  query ListOtherActivityTypes(
    $id: ID
    $filter: ModelOtherActivityTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOtherActivityTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        other_activity_type_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        activity_name
        description
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOtherActivity = /* GraphQL */ `
  query GetOtherActivity($id: ID!) {
    getOtherActivity(id: $id) {
      id
      other_activity_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      other_activity_type_id
      other_activity_type {
        id
        other_activity_type_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        activity_name
        description
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      activity_date
      description
      facilitators {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOtherActivities = /* GraphQL */ `
  query ListOtherActivities(
    $id: ID
    $filter: ModelOtherActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOtherActivities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOtherActivityFacilitators = /* GraphQL */ `
  query GetOtherActivityFacilitators($id: ID!) {
    getOtherActivityFacilitators(id: $id) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listOtherActivityFacilitators = /* GraphQL */ `
  query ListOtherActivityFacilitators(
    $id: ID
    $filter: ModelOtherActivityFacilitatorsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOtherActivityFacilitators(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        other_activity_id
        other_activity {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        staff_id
        staff {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOtherActivityParticipants = /* GraphQL */ `
  query GetOtherActivityParticipants($id: ID!) {
    getOtherActivityParticipants(id: $id) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listOtherActivityParticipants = /* GraphQL */ `
  query ListOtherActivityParticipants(
    $id: ID
    $filter: ModelOtherActivityParticipantsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOtherActivityParticipants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        other_activity_id
        other_activity {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        plw_id
        plw {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHgTrainingTopic = /* GraphQL */ `
  query GetHgTrainingTopic($id: ID!) {
    getHgTrainingTopic(id: $id) {
      id
      hg_training_topic_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      topic
      description
      training {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHgTrainingTopics = /* GraphQL */ `
  query ListHgTrainingTopics(
    $id: ID
    $filter: ModelHgTrainingTopicFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHgTrainingTopics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hg_training_topic_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        topic
        description
        training {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHgTraining = /* GraphQL */ `
  query GetHgTraining($id: ID!) {
    getHgTraining(id: $id) {
      id
      hg_training_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      training_date
      description
      topics {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHgTrainings = /* GraphQL */ `
  query ListHgTrainings(
    $id: ID
    $filter: ModelHgTrainingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHgTrainings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHgTrainingTrainingTopic = /* GraphQL */ `
  query GetHgTrainingTrainingTopic($id: ID!) {
    getHgTrainingTrainingTopic(id: $id) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      hg_training_topic_id
      hg_training_topic {
        id
        hg_training_topic_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        topic
        description
        training {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listHgTrainingTrainingTopics = /* GraphQL */ `
  query ListHgTrainingTrainingTopics(
    $id: ID
    $filter: ModelHgTrainingTrainingTopicFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHgTrainingTrainingTopics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hg_training_id
        hg_training {
          id
          hg_training_id
          project_diocese_code
          care_group_id
          training_date
          description
          createdAt
          updatedAt
        }
        hg_training_topic_id
        hg_training_topic {
          id
          hg_training_topic_id
          project_id
          project_code
          topic
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHgTrainingFacilitators = /* GraphQL */ `
  query GetHgTrainingFacilitators($id: ID!) {
    getHgTrainingFacilitators(id: $id) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listHgTrainingFacilitators = /* GraphQL */ `
  query ListHgTrainingFacilitators(
    $id: ID
    $filter: ModelHgTrainingFacilitatorsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHgTrainingFacilitators(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hg_training_id
        hg_training {
          id
          hg_training_id
          project_diocese_code
          care_group_id
          training_date
          description
          createdAt
          updatedAt
        }
        staff_id
        staff {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHgTrainingParticipants = /* GraphQL */ `
  query GetHgTrainingParticipants($id: ID!) {
    getHgTrainingParticipants(id: $id) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const listHgTrainingParticipants = /* GraphQL */ `
  query ListHgTrainingParticipants(
    $id: ID
    $filter: ModelHgTrainingParticipantsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHgTrainingParticipants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hg_training_id
        hg_training {
          id
          hg_training_id
          project_diocese_code
          care_group_id
          training_date
          description
          createdAt
          updatedAt
        }
        plw_id
        plw {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectDioceseByProject = /* GraphQL */ `
  query ProjectDioceseByProject(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDioceseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectDioceseByProject(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectDioceseByDiocese = /* GraphQL */ `
  query ProjectDioceseByDiocese(
    $dioceseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDioceseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectDioceseByDiocese(
      dioceseID: $dioceseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectDioceseByPdCode = /* GraphQL */ `
  query GetProjectDioceseByPdCode(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDioceseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProjectDioceseByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const townshipByPcode = /* GraphQL */ `
  query TownshipByPcode(
    $ts_pcode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTownshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    townshipByPcode(
      ts_pcode: $ts_pcode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const townshipByDioceseCode = /* GraphQL */ `
  query TownshipByDioceseCode(
    $diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTownshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    townshipByDioceseCode(
      diocese_code: $diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const villageTractByPcode = /* GraphQL */ `
  query VillageTractByPcode(
    $vt_pcode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVillageTractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    villageTractByPcode(
      vt_pcode: $vt_pcode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vt_pcode
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        diocese_code
        active
        villages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const villageTractByTownship = /* GraphQL */ `
  query VillageTractByTownship(
    $township_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVillageTractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    villageTractByTownship(
      township_id: $township_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vt_pcode
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        diocese_code
        active
        villages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const villageByPcode = /* GraphQL */ `
  query VillageByPcode(
    $village_pcode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVillageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    villageByPcode(
      village_pcode: $village_pcode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        village_pcode
        villageTract_id
        villageTract {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        alt_name
        alt_name_mm
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const villageByVillageTract = /* GraphQL */ `
  query VillageByVillageTract(
    $villageTract_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVillageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    villageByVillageTract(
      villageTract_id: $villageTract_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        village_pcode
        villageTract_id
        villageTract {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        alt_name
        alt_name_mm
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campByTownship = /* GraphQL */ `
  query CampByTownship(
    $township_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campByTownship(
      township_id: $township_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        camp_id
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        description
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campByDioceseCode = /* GraphQL */ `
  query CampByDioceseCode(
    $diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campByDioceseCode(
      diocese_code: $diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        camp_id
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        description
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const careGroupByGroupNumber = /* GraphQL */ `
  query CareGroupByGroupNumber(
    $group_number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCareGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careGroupByGroupNumber(
      group_number: $group_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const careGroupByPdCode = /* GraphQL */ `
  query CareGroupByPdCode(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCareGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careGroupByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const staffByProjectDiocese = /* GraphQL */ `
  query StaffByProjectDiocese(
    $project_diocese_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffByProjectDiocese(
      project_diocese_id: $project_diocese_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const neighborGroupByVillage = /* GraphQL */ `
  query NeighborGroupByVillage(
    $village_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNeighborGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    neighborGroupByVillage(
      village_id: $village_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const plwByNeighborGroup = /* GraphQL */ `
  query PlwByNeighborGroup(
    $neighbor_group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPLWFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plwByNeighborGroup(
      neighbor_group_id: $neighbor_group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cgMeetingByPdCode = /* GraphQL */ `
  query CgMeetingByPdCode(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCGMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cgMeetingByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicByCgMeeting = /* GraphQL */ `
  query TopicByCgMeeting(
    $cg_meeting_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCGMeetingNutritionTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicByCgMeeting(
      cg_meeting_id: $cg_meeting_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cg_meeting_id
        cg_meeting {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const plwByCgMeeting = /* GraphQL */ `
  query PlwByCgMeeting(
    $cg_meeting_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCGMeetingPLWFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plwByCgMeeting(
      cg_meeting_id: $cg_meeting_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cg_meeting_id
        cg_meeting {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        plw_id
        plw {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        project_diocese_code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ngMeetingByPdCode = /* GraphQL */ `
  query NgMeetingByPdCode(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNGMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ngMeetingByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ngMeetingByNeighborGroup = /* GraphQL */ `
  query NgMeetingByNeighborGroup(
    $neighbor_group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNGMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ngMeetingByNeighborGroup(
      neighbor_group_id: $neighbor_group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const distributionItemByProjectCode = /* GraphQL */ `
  query DistributionItemByProjectCode(
    $project_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDistributionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distributionItemByProjectCode(
      project_code: $project_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distribution_item_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const distributionByPdCode = /* GraphQL */ `
  query DistributionByPdCode(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distributionByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const distributionByItem = /* GraphQL */ `
  query DistributionByItem(
    $distribution_item_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distributionByItem(
      distribution_item_id: $distribution_item_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const distributionByNeighborGroup = /* GraphQL */ `
  query DistributionByNeighborGroup(
    $neighbor_group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distributionByNeighborGroup(
      neighbor_group_id: $neighbor_group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const otherActivityTypeByProjectCode = /* GraphQL */ `
  query OtherActivityTypeByProjectCode(
    $project_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOtherActivityTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otherActivityTypeByProjectCode(
      project_code: $project_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        other_activity_type_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        activity_name
        description
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const otherActivityByPdCode = /* GraphQL */ `
  query OtherActivityByPdCode(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOtherActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otherActivityByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const otherActivityByType = /* GraphQL */ `
  query OtherActivityByType(
    $other_activity_type_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOtherActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otherActivityByType(
      other_activity_type_id: $other_activity_type_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const hgTrainingByPdCode = /* GraphQL */ `
  query HgTrainingByPdCode(
    $project_diocese_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelHgTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hgTrainingByPdCode(
      project_diocese_code: $project_diocese_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const villageByVillageTractCustom = /* GraphQL */ `
  query VillageByVillageTractCustom(
    $villageTract_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVillageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    villageByVillageTract(
      villageTract_id: $villageTract_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_mm
        active
      }
    }
  }
`;

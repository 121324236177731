import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React, { FC, useState } from 'react';
import {
  CreateNeighborGroupInput,
  CreateNeighborGroupMutation,
  DeleteNeighborGroupInput,
  DeleteNeighborGroupMutation,
  GetNeighborGroupForEditQuery,
  UpdateNeighborGroupInput,
  UpdateNeighborGroupMutation,
} from '../../../API';
import { Button, Form, Input, message, Modal, Select, Space } from 'antd';
import * as queries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import { CareGroupType } from '../NeighborGroups';
import { compact } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useListCampByTownshipId } from '../../../hooks/useListCampByTownshipId';
import { useListVillageByVillageTractId, useListVillageTractByTownshipId } from '../../../hooks';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { warning } = Modal;

interface NeighborGroupModalFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingNeighborGroupId: string | undefined;
  careGroup: CareGroupType;
}

const NeighborGroupModalForm: FC<NeighborGroupModalFormProps> = ({
  modalVisible,
  setModalVisible,
  editingNeighborGroupId,
  careGroup,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  const [selectedVillageTractId, setSelectedVillageTractId] = useState<string>();

  const {
    data: neighborGroup,
    isLoading: isNeighborGroupLoading,
    isError: isNeighborGroupError,
  } = useQuery(
    ['getNeighborGroupForEdit', editingNeighborGroupId],
    () => {
      return API.graphql({
        query: queries.getNeighborGroupForEdit,
        variables: { id: editingNeighborGroupId },
      }) as Promise<GraphQLResult<GetNeighborGroupForEditQuery>>;
    },
    {
      staleTime: 0,
      enabled: modalVisible === 'editing' && !!editingNeighborGroupId,
      select: (data) => data.data?.getNeighborGroup,
      onSuccess: (ng) => {
        if (ng) {
          console.log(ng);
          form.setFieldsValue({
            group_number: ng.group_number,
            villageTract_id: ng.village?.villageTract.id,
            village_id: ng.village?.id,
            camp_id: ng.camp?.id,
          });
          setSelectedVillageTractId(ng.village?.villageTract.id || undefined);
        }
      },
    }
  );

  const { data: villageTracts } = useListVillageTractByTownshipId(careGroup.township.id);
  const { data: villages } = useListVillageByVillageTractId(selectedVillageTractId);
  const { data: camps } = useListCampByTownshipId(careGroup.township.id);

  ////////////////// Mutations
  const invalidateQueries = () => {
    queryClient.invalidateQueries(['getCareGroup', careGroup.id]);
    queryClient.invalidateQueries([
      'listCareGroups',
      careGroup.project_diocese.project_diocese_code,
    ]);
  };

  const addNgMutation = useMutation(
    (input: CreateNeighborGroupInput) =>
      API.graphql({
        query: mutations.createNeighborGroup,
        variables: {
          input,
        },
      }) as Promise<GraphQLResult<CreateNeighborGroupMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );

  const updateNgMutation = useMutation(
    (input: UpdateNeighborGroupInput) =>
      API.graphql({
        query: mutations.updateNeighborGroup,
        variables: {
          input,
        },
      }) as Promise<GraphQLResult<UpdateNeighborGroupMutation>>,
    {
      onSuccess: () => invalidateQueries,
    }
  );

  const deleteNgMutation = useMutation(
    (input: DeleteNeighborGroupInput) =>
      API.graphql({
        query: mutations.deleteNeighborGroup,
        variables: {
          input,
        },
      }) as Promise<GraphQLResult<DeleteNeighborGroupMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );

  /// Component specific functions

  const validateNeighborGroupNumber = async (_: any, value: string | undefined) => {
    if (!value) return Promise.resolve();

    if (value === neighborGroup?.group_number) {
      return Promise.resolve();
    }

    if (careGroup.neighbor_groups) {
      const ngUnderCg = compact(careGroup.neighbor_groups.items);
      if (ngUnderCg.some((ng) => ng.group_number === value)) {
        return Promise.reject(`Group number (${value}) is already used under this Care Group.`);
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.reject('Connection Error');
    }
  };

  const addNeighborGroup = async () => {
    try {
      const values = await form.validateFields().catch((reason) => {
        throw new Error('Validation Error');
      });
      await addNgMutation.mutateAsync({
        group_number: values.group_number,
        project_diocese_code: careGroup.project_diocese.project_diocese_code,
        care_group_id: careGroup.id,
        village_id: values.village_id || null,
        camp_id: values.camp_id || null,
      });
      closeModal();
    } catch (err: any) {
      console.error(err.message || err);
    }
  };

  const saveEditedNeighborGroup = async () => {
    if (!editingNeighborGroupId) {
      message.error('No neighbor group id provided.');
      return;
    }
    try {
      const values = await form.validateFields().catch(() => {
        throw new Error('Validation Error');
      });
      // const values = await form.validateFields();;
      await updateNgMutation.mutateAsync({
        id: editingNeighborGroupId,
        group_number: values.group_number,
        village_id: values.village_id || null,
        camp_id: values.camp_id || null,
      });
      closeModal();
    } catch (error: any) {
      console.log(error || error);
    }
  };

  const deleteNeighborGroup = async () => {
    if (!editingNeighborGroupId || !neighborGroup) {
      message.error('No neighbor group id provided.');
      return;
    }
    const plw_count = neighborGroup.plws?.items.length || 0;
    const ng_meeting_count = neighborGroup.ng_meetings?.items.length || 0;
    const distribution_count = neighborGroup.distributions?.items.length || 0;
    if ([plw_count, ng_meeting_count, distribution_count].some((item) => item > 0)) {
      warning({
        title: 'This Neighbor Group cannot be deleted.',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <div>Follwoing tables have associated records to this Neighbor Group.</div>
            <ul>
              {plw_count > 0 && <li>PLW</li>}
              {ng_meeting_count > 0 && <li>Neighbor Group Meeting</li>}
              {distribution_count > 0 && <li>Distribution</li>}
            </ul>
            <div>You have to delete those records first.</div>
          </div>
        ),
      });
      return;
    }
    try {
      deleteNgMutation.mutateAsync({
        id: editingNeighborGroupId,
      });
      closeModal();
    } catch (err: any) {
      console.error(err.message || 'Error.');
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addNeighborGroup();
            } else if (modalVisible === 'editing') {
              saveEditedNeighborGroup();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Button danger onClick={deleteNeighborGroup} disabled={!user.isEditor}>
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding'
          ? `Adding a new Neighbor Group to ${careGroup.group_number}, 
        ${careGroup.project_diocese.diocese.name} Diocese,
        ${careGroup.project_diocese.project.name} Project`
          : modalVisible === 'editing'
          ? `Editing Neighbor Group ${careGroup.group_number}.${neighborGroup?.group_number},
          ${careGroup.project_diocese.diocese.name} Diocese,
          ${careGroup.project_diocese.project.name} Project`
          : 'Neighbor Group Form'
      }
      open={modalVisible !== false}
      footer={customModalFooter}
      closable={false}
      width={600}
    >
      <div style={{ height: 220 }}>
        <Form
          form={form}
          name='neighbor_group_form'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label={
              <span>
                <span style={{ color: 'red' }}>*</span> Group Number
              </span>
            }
          >
            <span
              style={{
                display: 'inline-block',
                width: 70,
                textAlign: 'right',
                padding: '4px 15px',
                border: '1px solid lightgray',
                borderRight: 'none',
              }}
            >
              {careGroup.group_number}
            </span>
            <Form.Item
              name='group_number'
              rules={[
                { required: true, message: 'Group number is required.' },
                {
                  validator: validateNeighborGroupNumber,
                  validateTrigger: ['onChange'],
                },
              ]}
              noStyle
            >
              <Select style={{ width: 100 }} allowClear>
                {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((alpha) => (
                  <Select.Option key={alpha} value={alpha}>
                    {alpha}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <Form.Item name='villageTract_id' label='Village Tract'>
                <Select
                  allowClear
                  onChange={(value) => {
                    setSelectedVillageTractId(value);
                    setFieldValue('camp_id', null);
                    setFieldValue('village_id', null);
                  }}
                >
                  {villageTracts?.map((vt) => (
                    <Select.Option key={vt.id} value={vt.id}>
                      {vt.name} {vt.name_mm && `(${vt.name_mm})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, validateFields }) => (
              <Form.Item
                name='village_id'
                label='Village'
                rules={[
                  {
                    required: !getFieldValue('camp_id'),
                    message: 'Either Village or Camp is required.',
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={() => {
                    validateFields(['camp_id']);
                  }}
                >
                  {villages?.map((village) => (
                    <Select.Option key={village.id} value={village.id}>
                      {village.name} {village.name_mm && `(${village.name_mm})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ setFieldValue, getFieldValue, validateFields }) => (
              <Form.Item
                name='camp_id'
                label='Camp'
                rules={[
                  {
                    required: !getFieldValue('village_id'),
                    message: 'Either Village or Camp is required.',
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={() => {
                    setSelectedVillageTractId(undefined);
                    setFieldValue('villageTract_id', null);
                    setFieldValue('village_id', null);
                    validateFields(['village_id']);
                  }}
                >
                  {camps?.map((camp) => (
                    <Select.Option key={camp.id} value={camp.id}>
                      {camp.name} {camp.name_mm && `(${camp.name_mm})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NeighborGroupModalForm;

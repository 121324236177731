export const listOtherActivityTypesCustom = /* GraphQL */ `
  query ListOtherActivityTypesCustom(
    $id: ID
    $filter: ModelOtherActivityTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOtherActivityTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        project_id
        project {
          id
          name
        }
        project_code
        activity_name
        description
      }
    }
  }
`;

export const otherActivityByTypeCustom = /* GraphQL */ `
  query OtherActivityByTypeCustom(
    $other_activity_type_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOtherActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otherActivityByType(
      other_activity_type_id: $other_activity_type_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activity_date
        description
        other_activity_type {
          id
          activity_name
          description
        }
        facilitators {
          items {
            staff {
              id
              first_name
              last_name
              position {
                name
              }
            }
          }
        }
        participants {
          items {
            plw {
              id
              name
              member_number
              lead_mother
              father_name
              date_of_birth
              neighbor_group {
                group_number
              }
            }
          }
        }
        care_group {
          id
          group_number
          project_diocese {
            id
            project {
              id
              name
            }
            diocese {
              id
              name
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getOtherActivityForEdit = /* GraphQL */ `
  query GetOtherActivityForEdit($id: ID!) {
    getOtherActivity(id: $id) {
      id
      project_diocese_code
      care_group_id
      other_activity_type_id
      activity_date
      description
      facilitators {
        items {
          id
          staff {
            id
          }
        }
      }
      participants {
        items {
          id
          plw {
            id
            member_number
            neighbor_group {
              id
              group_number
            }
          }
        }
      }
    }
  }
`;

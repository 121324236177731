import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React, { useCallback, useMemo, useState } from 'react';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { listNutritionTopicsCustom } from '../../../graphql/custom_queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { ListNutritionTopicsCustomQuery, NutritionTopic } from '../../../API';
import { compact } from 'lodash';
import { Button, Col, Collapse, Row, Spin } from 'antd';
import Topic from './Topic';
import TopicForm from './TopicForm';
import Message from './Message';
import Question from './Question';
import MessageForm from './MessageForm';
import QuestionForm from './QuestionForm';

export type NutritionTopicType = NonNullable<
  NonNullable<NonNullable<ListNutritionTopicsCustomQuery>['listNutritionTopics']>['items'][number]
>;

export type NutritionMessageType = NonNullable<
  NonNullable<NutritionTopicType['nutrition_messages']>['items'][number]
>;

export type NutritionQuestionType = NonNullable<
  NonNullable<NutritionMessageType['nutrition_questions']>['items'][number]
>;

const NutritionMessages = () => {
  const user = useGetUserInfo();
  const editable = useMemo(() => {
    //Administrators who also have editor role can edit
    return user.isAdmin && user.isEditor;
  }, [user]);
  const [addOrEditTopic, setAddOrEditTopic] = useState<'add' | 'edit' | undefined>(undefined);
  const [editingTopic, setEditingTopic] = useState<NutritionTopicType | undefined>(undefined);
  const [addOrEditMessage, setAddOrEditMessage] = useState<'add' | 'edit' | undefined>(undefined);
  const [editingMessage, setEditingMessage] = useState<NutritionMessageType | undefined>(undefined);
  const [addOrEditQuestion, setAddOrEditQuestion] = useState<'add' | 'edit' | undefined>(undefined);
  const [editingQuestion, setEditingQuestion] = useState<NutritionQuestionType | undefined>(
    undefined
  );

  const {
    data: topics,
    isError,
    error,
    isLoading,
  } = useQuery(
    ['listNutritionTopics'],
    () => {
      return API.graphql({
        query: listNutritionTopicsCustom,
      }) as Promise<GraphQLResult<ListNutritionTopicsCustomQuery>>;
    },
    {
      select: (data) => {
        if (data.data?.listNutritionTopics?.items) {
          return compact(data.data.listNutritionTopics.items);
        } else {
          return [];
        }
      },
    }
  );

  type InputTextType = {
    topic_text?: string;
    message_text?: string;
  };
  const textSorter = (a: InputTextType, b: InputTextType) => {
    let A: number, B: number;
    if (a.topic_text && b.topic_text) {
      A = parseInt(a.topic_text.split('.')[0]);
      B = parseInt(b.topic_text.split('.')[0]);
    } else if (a.message_text && b.message_text) {
      A = parseInt(a.message_text.split('.')[0]);
      B = parseInt(b.message_text.split('.')[0]);
    } else {
      A = B = 0;
    }
    if (isNaN(A) || isNaN(B)) return 0;
    return A - B;
  };

  if (isError) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Error loading nutrition topics.
      </div>
    );
  }
  if (isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Spin size='large' />
      </div>
    );
  }

  return (
    <Row style={{ marginTop: 20 }}>
      <Col xs={24} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }}>
        <h2>
          <span>Standard Nutrition Messages</span>
          <Button
            type='link'
            onClick={() => setAddOrEditTopic('add')}
            disabled={!(user.isAdmin && user.isEditor)}
          >
            add new topic
          </Button>
        </h2>
        <Collapse>
          {topics.sort(textSorter).map((topic) => (
            <Topic
              topic={topic}
              key={topic.id}
              setAddOrEditTopic={setAddOrEditTopic}
              setEditingTopic={setEditingTopic}
              setAddOrEditMessage={setAddOrEditMessage}
              user={user}
            >
              {compact(topic.nutrition_messages?.items || [])
                .sort(textSorter)
                .map((message) => {
                  return (
                    <Message
                      message={message}
                      key={message.id}
                      setAddOrEditMessage={setAddOrEditMessage}
                      setEditingMessage={setEditingMessage}
                      setAddOrEditQuestion={setAddOrEditQuestion}
                      user={user}
                    >
                      {compact(message.nutrition_questions?.items || []).map((question, index) => {
                        return (
                          <Question
                            question={question}
                            key={question.id}
                            serial={index + 1}
                            setEditingQuestion={setEditingQuestion}
                            setAddOrEditQuestion={setAddOrEditQuestion}
                            user={user}
                          />
                        );
                      })}
                    </Message>
                  );
                })}
            </Topic>
          ))}
        </Collapse>
      </Col>
      <TopicForm
        addOrEdit={addOrEditTopic}
        setAddOrEdit={setAddOrEditTopic}
        editingTopic={editingTopic}
      />
      <MessageForm
        addOrEdit={addOrEditMessage}
        setAddOrEdit={setAddOrEditMessage}
        editingMessage={editingMessage}
        nutritionTopicId={editingTopic?.id}
      />
      <QuestionForm
        addOrEdit={addOrEditQuestion}
        setAddOrEdit={setAddOrEditQuestion}
        editingQuestion={editingQuestion}
        nutritionMessageId={editingMessage?.id}
      />
    </Row>
  );
};

export default NutritionMessages;

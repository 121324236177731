import React, { FC, useMemo } from 'react';
import {
  Form,
  Select,
  Modal,
  message,
  Space,
  Button,
  Popconfirm,
  DatePicker,
  Typography,
  Input,
  Transfer,
} from 'antd';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreateDistributionInput,
  CreateDistributionMutation,
  DeleteDistributionInput,
  DeleteDistributionMutation,
  GetDistributionForEditQuery,
  GetNeighborGroupCustomQuery,
  UpdateDistributionInput,
  UpdateDistributionMutation,
} from '../../../API';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useAppSelector } from '../../../redux/hooks';
import { compact, difference } from 'lodash';
import { useGetProjectDioceseByPdCode, useListDistributionItemByProjectCode } from '../../../hooks';
import { API } from 'aws-amplify';
import { getDistributionForEdit } from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Text } = Typography;

interface DistributionModalFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingDistributionId: string | undefined;
}

const DistributionModalForm: FC<DistributionModalFormProps> = ({
  modalVisible,
  setModalVisible,
  editingDistributionId,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  const [targetKeys, setTargetKeys] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  // QUERIES
  const currentlySelectedNeighborGroupId = useAppSelector(
    (state) => state.neighborgroups.selectedMenuKeys.currentSelection
  );

  const ng = queryClient.getQueryData(['getNeighborGroup', currentlySelectedNeighborGroupId]) as
    | GraphQLResult<GetNeighborGroupCustomQuery>
    | undefined;

  const neighborGroup = ng?.data?.getNeighborGroup;
  const plws = useMemo(
    () =>
      neighborGroup?.plws
        ? compact(neighborGroup.plws.items).sort((a, b) => a.member_number - b.member_number)
        : [],
    [neighborGroup]
  );

  const {
    data: projectDiocese,
    isLoading: isProjectDioceseLoading,
    isError: isProjectDioceseError,
    error: projectDioceseError,
  } = useGetProjectDioceseByPdCode(neighborGroup?.project_diocese_code);

  const {
    data: distributionItems,
    isLoading: isDistributionItemLoading,
    isError: isDistributionItemError,
    error: distributionItemError,
  } = useListDistributionItemByProjectCode(neighborGroup?.project_diocese_code.split('_')[0]);

  const { data: editingDistribution } = useQuery(
    ['getDistributionForEdit', editingDistributionId],
    () =>
      API.graphql({
        query: getDistributionForEdit,
        variables: {
          id: editingDistributionId || 'never',
        },
      }) as Promise<GraphQLResult<GetDistributionForEditQuery>>,
    {
      staleTime: 0,
      enabled: modalVisible === 'editing' && !!editingDistributionId,
      select: (data) => data.data?.getDistribution,
      onSuccess: (dist) => {
        let recipients: string[] = [];
        if (dist && dist.recipients) {
          const temp_plws = compact(dist.recipients.items).sort(
            (a, b) => a.plw.member_number - b.plw.member_number
          );
          recipients = temp_plws.map((plw) => plw.plw.id);
        }

        if (dist) {
          form.setFieldsValue({
            distribution_item_id: dist.distribution_item_id,
            distribution_date: dist.distribution_date ? moment(dist.distribution_date) : null,
            description: dist.description,
            recipients: recipients,
          });
          setTargetKeys(recipients);
        }
      },
    }
  );

  // MUTATIONS
  const invalidateQueries = () => {
    if (neighborGroup) {
      queryClient.invalidateQueries(['getNeighborGroup', neighborGroup.id]);
      queryClient.invalidateQueries(['getCareGroup', neighborGroup.care_group.id]);
      // queryClient.invalidateQueries(['listCareGroups', neighborGroup.project_diocese_code]);
    }
  };
  // INSERT
  const addDistributionMutation = useMutation(
    (input: CreateDistributionInput) =>
      API.graphql({
        query: mutations.createDistribution,
        variables: { input },
      }) as Promise<GraphQLResult<CreateDistributionMutation>>
  );
  // UPDATE
  const updateDistributionMutation = useMutation(
    (input: UpdateDistributionInput) =>
      API.graphql({
        query: mutations.updateDistribution,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateDistributionMutation>>
  );
  // DELETE
  const deleteDistributionMutation = useMutation(
    (input: DeleteDistributionInput) =>
      API.graphql({
        query: mutations.deleteDistribution,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteDistributionMutation>>
  );

  // COMPONENT SPECIFIC FUNCTIONS
  const addDistribution = async () => {
    if (!neighborGroup) {
      message.error('No Neighbor Group Loaded.');
      return;
    }
    try {
      const values = await form.validateFields();
      const new_dist_result = await addDistributionMutation.mutateAsync({
        project_diocese_code: neighborGroup.project_diocese_code,
        distribution_item_id: values.distribution_item_id,
        neighbor_group_id: neighborGroup.id,
        distribution_date: values.distribution_date
          ? values.distribution_date.format('YYYY-MM-DD')
          : null,
        description: values.description,
      });
      if (new_dist_result.data?.createDistribution) {
        const new_distribution = new_dist_result.data.createDistribution;
        if (values.recipients) {
          await Promise.all(
            values.recipients.map((plw_id: string) =>
              API.graphql({
                query: mutations.createDistributionRecipients,
                variables: {
                  input: {
                    distribution_id: new_distribution.id,
                    plw_id,
                    project_diocese_code: neighborGroup.project_diocese_code,
                  },
                },
              })
            )
          );
        }
      }
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const saveEditedDistribution = async () => {
    if (!editingDistributionId && !editingDistribution) {
      message.error('No distribution id provided.');
      return;
    }
    if (!neighborGroup) {
      message.error('No Neighbor Group Loaded.', 5);
      return;
    }
    try {
      const values = await form.validateFields();
      await updateDistributionMutation.mutateAsync({
        id: editingDistribution!.id,
        distribution_item_id: values.distribution_item_id,
        distribution_date: values.distribution_date
          ? values.distribution_date.format('YYYY-MM-DD')
          : null,
        description: values.description,
      });

      const original_plw_ids = editingDistribution?.recipients
        ? compact(editingDistribution.recipients.items).map((item) => item.plw.id)
        : [];
      const updated_plw_ids = values.recipients || [];
      const plws_to_add = difference(updated_plw_ids, original_plw_ids);
      let plws_to_delete = difference(original_plw_ids, updated_plw_ids);
      plws_to_delete = compact(editingDistribution?.recipients?.items || [])
        .filter((pptplw) => plws_to_delete.includes(pptplw.plw.id))
        .map((pptplw) => pptplw.id);

      const promise_array = [
        ...plws_to_add.map(
          (plw_id) =>
            API.graphql({
              query: mutations.createDistributionRecipients,
              variables: {
                input: {
                  distribution_id: editingDistributionId,
                  plw_id: plw_id,
                  project_diocese_code: neighborGroup.project_diocese_code,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
        ...plws_to_delete.map(
          (rec_plw_id) =>
            API.graphql({
              query: mutations.deleteDistributionRecipients,
              variables: {
                input: {
                  id: rec_plw_id,
                },
              },
            }) as Promise<GraphQLResult<any>>
        ),
      ];
      await Promise.all(promise_array);
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const deleteDistribution = async () => {
    if (!editingDistributionId && !editingDistribution) {
      message.error('No distribution id provided.');
      return;
    }
    try {
      await Promise.all(
        (editingDistribution?.recipients?.items || []).map((rec_plw) =>
          API.graphql({
            query: mutations.deleteDistributionRecipients,
            variables: {
              input: {
                id: rec_plw?.id,
              },
            },
          })
        )
      );
      await deleteDistributionMutation.mutateAsync({
        id: editingDistributionId!,
      });
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
    setTargetKeys([]);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addDistribution();
            } else if (modalVisible === 'editing') {
              saveEditedDistribution();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Popconfirm
          title={
            <div>
              <div>Are you sure you want to delete this Distribution?</div>
              <div>All information related to this distribution will be deleted.</div>
              <div>This action is not recoverable.</div>
            </div>
          }
          icon={<ExclamationCircleFilled style={{ color: 'orangered', fontSize: 16 }} />}
          onConfirm={deleteDistribution}
          okButtonProps={{ danger: true }}
          disabled={!user.isEditor}
        >
          <Button danger disabled={!user.isEditor}>
            Delete
          </Button>
        </Popconfirm>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding'
          ? `Adding a new Distribution Record to 
          ${neighborGroup?.care_group.group_number}.${neighborGroup?.group_number}`
          : modalVisible === 'editing'
          ? `Editing Distribution (${editingDistribution?.distribution_date} @ ${neighborGroup?.care_group.group_number}.${neighborGroup?.group_number}), 
          ${projectDiocese?.diocese.name} Diocese, 
          ${projectDiocese?.project.name} Project`
          : 'Distribution Form'
      }
      open={modalVisible === 'adding' || modalVisible === 'editing'}
      footer={customModalFooter}
      closable={false}
      width={650}
    >
      {isProjectDioceseError || isDistributionItemError || !neighborGroup ? (
        <div>
          <div>
            Error in loading Project Diocese or Distribution PLans. Possibly connection error.
          </div>
          <>
            {!!projectDioceseError && <div>{`${projectDioceseError}`}</div>}
            {!!distributionItemError && <div>{`${distributionItemError}`}</div>}
          </>
        </div>
      ) : isProjectDioceseLoading || isDistributionItemLoading ? (
        <div></div>
      ) : (
        <div style={{ maxHeight: 500, overflow: 'auto', paddingRight: 10 }}>
          <Form form={form} name='ng_meeting_form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              name='distribution_date'
              label='Distribution date'
              rules={[{ required: true, message: 'Required.' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name='distribution_item_id'
              label='Distribution Plan'
              rules={[{ required: true, message: 'Distribution Plan is required.' }]}
            >
              <Select allowClear>
                {distributionItems?.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      <Text style={{ width: 300 }} ellipsis={{ tooltip: item.title }}>
                        {item.title}
                      </Text>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name='description' label='Description'>
              <Input.TextArea />
            </Form.Item>
            <Form.Item name='recipients' wrapperCol={{ span: 24 }}>
              <Transfer
                dataSource={plws.map((plw) => {
                  return {
                    key: plw.id,
                    name: plw.name,
                    member_number: plw.member_number,
                    lead_mother: plw.lead_mother,
                    complete_member_number: `${neighborGroup.care_group.group_number}.${neighborGroup.group_number}.${plw.member_number}`,
                  };
                })}
                titles={['PLWs', 'Recipients']}
                listStyle={{ width: 300, height: 350 }}
                oneWay
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                render={(item) =>
                  `${item.complete_member_number} - ${item.name} ${item.lead_mother ? ' *' : ''}`
                }
                onChange={(nextTargetKey: string[]) => {
                  setTargetKeys(nextTargetKey);
                }}
                onSelectChange={(sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
                  setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
                }}
              />
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default DistributionModalForm;

import { ColumnsType } from 'antd/lib/table';
import React, { FC, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { CGMeetingType, PLWType } from '../NeighborGroups';
import { Typography, Table, Space, Button, Popover } from 'antd';
import Styles from '../NeighborGroups.module.scss';
import CGMeetingModalForm from './CGMeetingModalForm';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Link } = Typography;

interface PropType {
  isLoading: boolean;
  cgNumber: string;
  cgMeetings: CGMeetingType[];
  plws: PLWType[];
}

const CGMeetingTable: FC<PropType> = ({ isLoading, cgNumber, cgMeetings, plws }) => {
  const [isCgMeetingModalVisible, setIsCgMeetingModalVisible] = useState<
    'adding' | 'editing' | false
  >(false);
  const [editingCgMeetingId, setEditingCgMeetingId] = useState<string | undefined>();
  const selectedMenuKeys = useAppSelector((state) => state.neighborgroups.selectedMenuKeys);
  const user = useGetUserInfo();

  const plwMap = useMemo(() => {
    const plwMap = new Map<string, PLWType>();
    plws.forEach((plw) => plwMap.set(plw.id, plw));
    return plwMap;
  }, [plws]);

  const columns: ColumnsType<CGMeetingType> = [
    {
      title: 'Date',
      dataIndex: 'meeting_date',
      key: 'meeting_date',
      sorter: (a, b) => b.meeting_date!.localeCompare(a.meeting_date!),
    },
    {
      title: 'Facilitators',
      dataIndex: 'facilitators',
      key: 'facilitators',
      render: (value, record) => {
        const facilitators = record.facilitators?.items.map((item) => {
          return {
            key: item?.staff.id,
            name: `${item?.staff.first_name} ${item?.staff.last_name}`,
            position: `${item?.staff.position.name}`,
          };
        });
        if (facilitators) {
          return (
            <Popover
              placement='rightTop'
              title={`${cgNumber} @ ${record.meeting_date}`}
              trigger='click'
              content={
                <Space direction='vertical'>
                  {facilitators?.map((item) => {
                    return <Space key={item.key}>{`${item.name} (${item.position})`}</Space>;
                  })}
                </Space>
              }
            >
              <Link>
                {' '}
                {facilitators.length > 0
                  ? facilitators.length > 1
                    ? `${facilitators.length} staff facilitated`
                    : facilitators[0].name
                  : ''}
              </Link>
            </Popover>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Topics',
      dataIndex: 'topics',
      key: 'topics',
      render: (value, record) => {
        return (
          <Popover
            placement='left'
            title={`${cgNumber} @ ${record.meeting_date}`}
            trigger={'onclick'}
            content={
              <Space
                direction='vertical'
                style={{
                  maxWidth: 500,
                  maxHeight: 300,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {record.topics?.items
                  .sort((a, b) => {
                    const firstNum = parseInt(a!.nutrition_topic.topic_text.split('.')[0]) || false;
                    const secondNum =
                      parseInt(b!.nutrition_topic.topic_text.split('.')[0]) || false;
                    if (firstNum && secondNum) {
                      return firstNum - secondNum;
                    }
                    return a!.nutrition_topic.topic_text.localeCompare(
                      b!.nutrition_topic.topic_text
                    );
                  })
                  .map((item) => {
                    if (item && item.nutrition_topic) {
                      return (
                        <Space key={item?.nutrition_topic?.id}>
                          {item?.nutrition_topic?.topic_text}
                        </Space>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Space>
            }
          >
            <Link>
              {record.topics
                ? `${record.topics.items.length} ${
                    record.topics.items.length === 1 ? 'topic' : 'topics'
                  } covered`
                : ''}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
      align: 'right',
      render: (value, record) => {
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              title={`${cgNumber} @ ${record.meeting_date}`}
              placement='left'
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                  }}
                >
                  {record.participants?.items
                    .sort(
                      (a, b) =>
                        (plwMap.get(a?.plw.id || '')?.member_number || 0) -
                        (plwMap.get(b?.plw.id || '')?.member_number || 0)
                    )
                    .map((ppt) => {
                      if (ppt && ppt.plw) {
                        const plw = plwMap.get(ppt.plw.id);
                        const completeMemberNumber = `${cgNumber}.${
                          ppt.plw.neighbor_group.group_number
                        }.${plw?.member_number == undefined ? '-' : plw.member_number}`;
                        return (
                          <Space key={ppt.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{plw?.name}</span>
                            {plw?.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.participants?.items.length}</Link>
            </Popover>
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Link
            disabled={!user.isEditor || !selectedMenuKeys.selectAll}
            onClick={() => showEditCgMeetingModal(record.id)}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  const showAddCgMeetingModal = () => {
    setEditingCgMeetingId(undefined);
    setIsCgMeetingModalVisible('adding');
  };

  const showEditCgMeetingModal = (editingCgMeetingId: string) => {
    setEditingCgMeetingId(editingCgMeetingId);
    setIsCgMeetingModalVisible('editing');
  };

  return (
    <div className={Styles.dataTable}>
      <div className={Styles.tableHeaderRow}>
        <div className={Styles.buttonContainer}>
          <Space>
            <Button
              className={Styles.exportButton}
              onClick={showAddCgMeetingModal}
              disabled={!user.isEditor || isLoading || selectedMenuKeys.selectAll === false}
            >
              Add New Care Group Meeting
            </Button>
          </Space>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={cgMeetings}
        showSorterTooltip={false}
        size='small'
        rowKey={'id'}
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 50,
          style: { marginRight: 25 },
          showTotal: (total, range) => `Total ${total}`,
        }}
        scroll={{ x: 'calc(100vw - 400px)', y: 'calc(100vh - 350px)' }}
      />
      {selectedMenuKeys.selectAll && (
        <CGMeetingModalForm
          modalVisible={isCgMeetingModalVisible}
          setModalVisible={setIsCgMeetingModalVisible}
          editingCgMeetingId={editingCgMeetingId}
        />
      )}
    </div>
  );
};

export default CGMeetingTable;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { Popover, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { API } from 'aws-amplify';
import { compact } from 'lodash';
import React, { FC } from 'react';
import { NgMeetingByNeighborGroupCustomQuery } from '../../API';
import { ngMeetingByNeighborGroupCustom } from '../../graphql/custom_queries';

const { Link } = Typography;

type ngMeetingMiniType = NonNullable<
  NonNullable<
    NonNullable<NgMeetingByNeighborGroupCustomQuery>['ngMeetingByNeighborGroup']
  >['items'][number]
>;

interface NgMeetingMiniTableProps {
  neighborGroupId: string;
  completeNgNumber: string;
}

const NgMeetingMiniTable: FC<NgMeetingMiniTableProps> = ({ neighborGroupId, completeNgNumber }) => {
  const {
    data: ngMeetings,
    isLoading: isNgMeetingLoading,
    // isError: isNgMeetingError,
    // error: ngMeetingError,
  } = useQuery(
    ['ngMeetingByNgIdMini', neighborGroupId],
    () => {
      return API.graphql({
        query: ngMeetingByNeighborGroupCustom,
        variables: {
          neighbor_group_id: neighborGroupId,
          limit: 100,
        },
      }) as Promise<GraphQLResult<NgMeetingByNeighborGroupCustomQuery>>;
    },
    {
      select: (data) => {
        if (data.data?.ngMeetingByNeighborGroup) {
          return compact(data.data.ngMeetingByNeighborGroup.items).sort((a, b) =>
            (b.meeting_date || '').localeCompare(a.meeting_date || '')
          );
        }
      },
    }
  );

  const columns: ColumnsType<ngMeetingMiniType> = [
    {
      title: 'Date',
      dataIndex: 'meeting_date',
      key: 'meeting_date',
      width: 110,
    },
    {
      title: 'Facilitator',
      dataIndex: 'plw_facilitator',
      key: 'plw_facilitator',
      ellipsis: true,
      render: (value, record) => {
        return `${completeNgNumber}.${record.plw_facilitator.member_number} - ${
          record.plw_facilitator.name
        } ${record.plw_facilitator.lead_mother ? '*' : ''}`;
      },
    },

    {
      title: 'Topics',
      dataIndex: 'topics',
      key: 'topics',
      width: 140,
      render: (value, record) => {
        return (
          <Popover
            placement='left'
            title={`${completeNgNumber} @ ${record.meeting_date}`}
            trigger='click'
            content={
              <Space
                direction='vertical'
                style={{
                  maxWidth: 500,
                  maxHeight: 300,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {record.topics?.items
                  .sort((a, b) => {
                    const firstNum = parseInt(a!.nutrition_topic.topic_text.split('.')[0]) || false;
                    const secondNum =
                      parseInt(b!.nutrition_topic.topic_text.split('.')[0]) || false;
                    if (firstNum && secondNum) {
                      return firstNum - secondNum;
                    }
                    return a!.nutrition_topic.topic_text.localeCompare(
                      b!.nutrition_topic.topic_text
                    );
                  })
                  .map((item) => {
                    if (item && item.nutrition_topic) {
                      return (
                        <Space key={item?.nutrition_topic?.id}>
                          {item?.nutrition_topic?.topic_text}
                        </Space>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Space>
            }
          >
            <Link>
              {' '}
              {record.topics
                ? `${record.topics.items.length} ${
                    record.topics.items.length === 1 ? 'topic' : 'topics'
                  } covered`
                : ''}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
      width: 110,
      align: 'right',
      render: (value, record) => {
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              title={`${completeNgNumber} @ ${record.meeting_date}`}
              trigger='click'
              placement='left'
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                  }}
                >
                  {record.participants?.items
                    .sort((a, b) => (a?.plw.member_number || 0) - (b?.plw.member_number || 0))
                    .map((ppt) => {
                      if (ppt && ppt.plw) {
                        const plw = ppt.plw;
                        const completeMemberNumber = `${completeNgNumber}.${
                          plw?.member_number === undefined ? '-' : plw.member_number
                        }`;
                        return (
                          <Space key={ppt.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{plw?.name}</span>
                            {plw?.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.participants?.items.length}</Link>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      size='small'
      dataSource={ngMeetings}
      columns={columns}
      rowKey={'id'}
      loading={isNgMeetingLoading}
      scroll={{ y: 250 }}
      bordered={true}
      pagination={{ pageSize: 50, hideOnSinglePage: true }}
      style={{ marginBottom: 12 }}
    />
  );
};

export default NgMeetingMiniTable;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { ColumnsType } from 'antd/lib/table';
import { Popover, Space, Typography, Table } from 'antd';
import { API } from 'aws-amplify';
import { compact } from 'lodash';
import React, { FC } from 'react';
import { DistributionByNeighborGroupCustomQuery } from '../../API';
import { distributionByNeighborGroupCustom } from '../../graphql/custom_queries';

const { Link } = Typography;

type DistributionMiniType = NonNullable<
  NonNullable<
    NonNullable<DistributionByNeighborGroupCustomQuery>['distributionByNeighborGroup']
  >['items'][number]
>;

interface DistributionMiniTableProps {
  neighborGroupId: string;
  completeNgNumber: string;
}

const DistributionMiniTable: FC<DistributionMiniTableProps> = ({
  neighborGroupId,
  completeNgNumber,
}) => {
  const { data: distributions, isLoading: isDistributionLoading } = useQuery(
    ['distributionByNgIdMini', neighborGroupId],
    () => {
      return API.graphql({
        query: distributionByNeighborGroupCustom,
        variables: {
          neighbor_group_id: neighborGroupId,
          limit: 100,
        },
      }) as Promise<GraphQLResult<DistributionByNeighborGroupCustomQuery>>;
    },
    {
      select: (data) => {
        if (data.data?.distributionByNeighborGroup) {
          return compact(data.data.distributionByNeighborGroup.items).sort((a, b) =>
            (b.distribution_date || '').localeCompare(a.distribution_date || '')
          );
        }
      },
    }
  );

  const columns: ColumnsType<DistributionMiniType> = [
    {
      title: 'Date',
      dataIndex: 'distribution_date',
      key: 'distribution_date',
      width: 110,
    },
    {
      title: 'Distribution Plan',
      dataIndex: 'distribution_item',
      key: 'distribution_item',
      ellipsis: { showTitle: false },
      render: (_, record) => {
        return (
          <Popover
            title={record.distribution_item.title}
            placement='left'
            trigger={'click'}
            content={
              <div
                style={{
                  width: 500,
                  maxHeight: 500,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {record.distribution_item.planned_distribution_start_date && (
                  <p>
                    Planned start date : {record.distribution_item.planned_distribution_start_date}
                  </p>
                )}
                {record.distribution_item.planned_distribution_end_date && (
                  <p>Planned end date : {record.distribution_item.planned_distribution_end_date}</p>
                )}
                <p style={{ whiteSpace: 'pre-wrap', maxWidth: '400px' }}>
                  {record.distribution_item.description}
                </p>
              </div>
            }
          >
            <Link>{record.distribution_item.title}</Link>
          </Popover>
        );
      },
    },

    {
      title: 'Receivers',
      dataIndex: 'recipients',
      key: 'recipients',
      align: 'right',
      width: 90,
      render: (_, record) => {
        return (
          <div style={{ paddingRight: 20 }}>
            <Popover
              placement='left'
              title={`${completeNgNumber} @ ${record.distribution_date}`}
              trigger={'click'}
              content={
                <Space
                  direction='vertical'
                  style={{ maxHeight: 400, overflow: 'auto', padding: '10px' }}
                >
                  {record.recipients?.items

                    .sort((a, b) => (a?.plw.member_number || 0) - (b?.plw.member_number || 0))
                    .map((recipient) => {
                      if (recipient && recipient.plw) {
                        const plw = recipient.plw;
                        const completeMemberNumber = `${completeNgNumber}.${plw.member_number}`;
                        return (
                          <Space key={recipient.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{plw?.name}</span>
                            {plw?.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.recipients?.items.length || 0}</Link>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      size='small'
      dataSource={distributions}
      columns={columns}
      rowKey={'id'}
      loading={isDistributionLoading}
      scroll={{ y: 250 }}
      bordered={true}
      pagination={{ pageSize: 50, hideOnSinglePage: true }}
      style={{ marginBottom: 12 }}
    />
  );
};

export default DistributionMiniTable;

export const villageTractByTownshipCustom = /* GraphQL */ `
  query VillageTractByTownshipCustom(
    $township_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVillageTractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    villageTractByTownship(
      township_id: $township_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_mm
        active
      }
    }
  }
`;

import { Button, Descriptions } from 'antd';
import { FC } from 'react';
import { TableFilterType } from '../common_types';
import { ActivityType } from './OtherActivities';

interface PropsType {
  filteredActivities: ActivityType[];
  activityFilters: Record<string, (boolean | string | number)[] | null>;
  facilitatorFilter: Array<TableFilterType>;
  hide: () => void;
}

const OASummary: FC<PropsType> = ({ filteredActivities, activityFilters, facilitatorFilter, hide }) => {
  const numberOfActivities = filteredActivities.length;

  const careGroupIDSet = new Set<string>();
  const projectNameSet = new Set<string>();
  const dioceseNameSet = new Set<string>();
  const participantIDSet = new Set<string>();
  const facilitatorMap = new Map<string, string>();
  const activityTypeMap = new Map<string, string>();
  const activityDateSet = new Set<string>();

  filteredActivities.forEach((activity) => {
    careGroupIDSet.add(activity.care_group.id);
    projectNameSet.add(activity.care_group.project_diocese.project.name);
    dioceseNameSet.add(activity.care_group.project_diocese.diocese.name);
    if (activity.participants) {
      activity.participants.items.forEach((item) => {
        if (item) {
          participantIDSet.add(item.plw.id);
        }
      });
    }
    if (activity.facilitators) {
      activity.facilitators.items.forEach((facilitator) => {
        if (facilitator && facilitator.staff) {
          facilitatorMap.set(
            facilitator.staff.id,
            `${facilitator.staff.first_name} ${facilitator.staff.last_name} (${facilitator.staff.position.name
              .match(/[A-Z]/g)
              ?.join('')})`
          );
        }
      });
    }
    activityTypeMap.set(activity.other_activity_type.activity_name, activity.care_group.project_diocese.project.name);
    if (activity.activity_date) {
      activityDateSet.add(activity.activity_date);
    }
  });
  const distributionDateArray = [...activityDateSet].sort();

  return (
    <Descriptions title='Other Activities Summary' bordered extra={<Button onClick={hide}>Close</Button>} column={3}>
      <Descriptions.Item label='Number of Activities' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {numberOfActivities}
      </Descriptions.Item>
      <Descriptions.Item label='Number of Care Groups' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {careGroupIDSet.size}
      </Descriptions.Item>
      <Descriptions.Item label='Total Participants' labelStyle={{ width: 120 }} contentStyle={{ width: 200 }}>
        {participantIDSet.size}
      </Descriptions.Item>
      <Descriptions.Item label='Projects'>
        {[...projectNameSet].sort().map((projectName) => (
          <div key={projectName}>
            <span>{projectName}</span>
            <br />
          </div>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label='From'>{distributionDateArray[0]}</Descriptions.Item>
      <Descriptions.Item label='To'>{distributionDateArray[distributionDateArray.length - 1]}</Descriptions.Item>
      <Descriptions.Item label='Dioceses'>
        {[...dioceseNameSet].sort().map((dioceseName) => (
          <div key={dioceseName}>
            <span>{dioceseName}</span>
            <br />
          </div>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label='Activity Type' span={2}>
        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
          <ul style={{ transform: 'translate(-20px)' }}>
            {[...activityTypeMap].sort().map((plan) => (
              <li key={plan[0]}>
                {plan[0]} | {plan[1]}
              </li>
            ))}
          </ul>
        </div>
      </Descriptions.Item>
      <Descriptions.Item label='Facilitators'>
        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
          {[...facilitatorMap]
            .sort((a, b) => a[1].localeCompare(b[1]))
            .map((staff) => (
              <div key={staff[0]}>
                <span>{staff[1]}</span>
                <br />
              </div>
            ))}
        </div>
      </Descriptions.Item>

      <Descriptions.Item label='Filtered by' span={2}>
        <div style={{ maxHeight: 150, overflow: 'auto' }}>
          {Object.keys(activityFilters).map((filter) => {
            if (activityFilters[filter] && activityFilters[filter]!.length > 0) {
              const spaced = filter.replace('_', ' ');
              const title = spaced.charAt(0).toUpperCase() + spaced.slice(1);
              return (
                <div key={filter} style={{ padding: '4px 0px 0px 8px' }}>
                  <span>
                    {title} ({activityFilters[filter]?.length}):{' '}
                    <span style={{ color: 'darkred' }}>
                      {activityFilters[filter]!.map((id) => {
                        if (filter === 'facilitators') {
                          return facilitatorFilter.find((ff) => ff.value === id)?.text;
                        } else {
                          return id;
                        }
                      }).join(', ')}
                    </span>
                  </span>
                  <br></br>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default OASummary;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { ListProjectsCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';

const listProjects = async () => {
  const result = (await API.graphql({
    query: queries.listProjectsCustom,
  })) as GraphQLResult<ListProjectsCustomQuery>;
  return result;
};

// const onSuccess = () => {
//   console.log('Successful in fetching projects');
// };

// const onError = () => {
//   console.log('Error in fetching projects');
// };

export const useListProjects = () => {
  return useQuery(['listProjects'], listProjects, {
    select: (data) => {
      if (data.data && data.data.listProjects) {
        return data.data.listProjects.items;
      } else {
        return [];
      }
    },
    // staleTime: 1000 * 60 * 60 * 24, // 24 hours
    // cacheTime: 1000 * 60 * 60 * 8, // 8 hrs
  });
};

import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  Spin,
  message,
} from 'antd';
import React, { FC, useState } from 'react';
import { useListEducationLevels } from '../../../hooks/useListEducationLevels';
import { useListOccupationTypes } from '../../../hooks/useListOccupationTypes';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from '../../../redux/hooks';
import {
  CreatePLWInput,
  CreatePLWMutation,
  DeletePLWInput,
  DeletePLWMutation,
  GetNeighborGroupCustomQuery,
  GetPLWforEditQuery,
  UpdatePLWInput,
  UpdatePLWMutation,
} from '../../../API';
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useGetProjectDioceseByPdCode } from '../../../hooks/useGetProjectDioceseByPdCode';
import moment from 'moment';
import { compact } from 'lodash';
import BirthHistoryTable from './BirthHistoryTable';
import BirthHistoryDrawerForm from './BirthHistoryDrawerForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { warning } = Modal;

export type PLWType = NonNullable<GetPLWforEditQuery['getPLW']>;

export type BirthHistoryType = NonNullable<
  NonNullable<PLWType['birth_histories']>['items'][number]
>;

interface PLWModalFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingPlwId: string | undefined;
}

const PLWModalForm: FC<PLWModalFormProps> = ({ modalVisible, setModalVisible, editingPlwId }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  const [addOrEditBirthHistory, setAddOrEditBirthHistory] = useState<'adding' | 'editing' | false>(
    false
  );
  const [editingBirthHistory, setEditingBirthHistory] = useState<BirthHistoryType | undefined>(
    undefined
  );

  // GETTING REQUIRED DATA

  const { data: educationLevels } = useListEducationLevels();
  const { data: occupationTypes } = useListOccupationTypes();

  // GETTING PLW
  const { data: editingPLW, isLoading: isPlwLoading } = useQuery(
    ['getPlwForEdit', editingPlwId],
    () =>
      API.graphql({
        query: queries.getPLWforEdit,
        variables: {
          id: editingPlwId,
        },
      }) as Promise<GraphQLResult<GetPLWforEditQuery>>,
    {
      staleTime: 0,
      enabled: modalVisible === 'editing' && !!editingPlwId,
      select: (data) => data.data?.getPLW,
      onSuccess: (plw) => {
        if (plw) {
          const { date_of_birth, date_of_death, date_of_registry, ...restPlw } = plw;
          form.setFieldsValue({
            ...restPlw,
            date_of_birth: date_of_birth ? moment(date_of_birth) : null,
            date_of_death: date_of_death ? moment(date_of_death) : null,
            date_of_registry: date_of_registry ? moment(date_of_registry) : null,
          });
        }
      },
    }
  );

  // GETTING NEIGHBOR GROUP
  const currentlySelectedNeighborGroupId = useAppSelector(
    (state) => state.neighborgroups.selectedMenuKeys.currentSelection
  );

  const id_of_ng_to_fetch =
    editingPlwId && editingPLW ? editingPLW.neighbor_group.id : currentlySelectedNeighborGroupId;

  const ng = queryClient.getQueryData(['getNeighborGroup', id_of_ng_to_fetch]) as
    | GraphQLResult<GetNeighborGroupCustomQuery>
    | undefined;
  const neighborGroup = ng?.data?.getNeighborGroup;

  // PROJECT_DIOCESE
  const { data: projectDiocese } = useGetProjectDioceseByPdCode(
    neighborGroup?.project_diocese_code || '-'
  );

  // MUTATIONS
  const invalidateQueries = () => {
    if (neighborGroup) {
      queryClient.invalidateQueries(['getNeighborGroup', neighborGroup.id]);
      queryClient.invalidateQueries(['getCareGroup', neighborGroup.care_group.id]);
      queryClient.invalidateQueries(['listCareGroups', neighborGroup.project_diocese_code]);
    }
  };
  // INSERT
  const addPlwMutation = useMutation((input: CreatePLWInput) => {
    return API.graphql({
      query: mutations.createPLW,
      variables: {
        input,
      },
    }) as Promise<GraphQLResult<CreatePLWMutation>>;
  });
  // UPDATE
  const updatePlwMutation = useMutation((input: UpdatePLWInput) => {
    return API.graphql({
      query: mutations.updatePLW,
      variables: { input },
    }) as Promise<GraphQLResult<UpdatePLWMutation>>;
  });
  // DELETE
  const deletePlwMuation = useMutation(
    (input: DeletePLWInput) => {
      return API.graphql({
        query: mutations.deletePLW,
        variables: { input },
      }) as Promise<GraphQLResult<DeletePLWMutation>>;
    },
    {
      onSuccess: invalidateQueries,
    }
  );

  // COMPONENT SPECIFIC FUNCTIONS

  const validateMemberNumber = async (_: any, value: number | undefined) => {
    if (value === undefined) return Promise.resolve();

    if (value === editingPLW?.member_number) {
      return Promise.resolve();
    }

    if (neighborGroup?.plws) {
      const plwsUnderNg = compact(neighborGroup.plws.items);
      if (plwsUnderNg.some((plw) => plw.member_number === value)) {
        return Promise.reject(
          `Member number (${value}) is already used under this Neighbor Group.`
        );
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.reject('Connection Error');
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
    setAddOrEditBirthHistory(false);
  };

  const addPLW = async () => {
    if (!neighborGroup) {
      message.error('No Neighbor Group Loaded.', 5);
      return;
    }
    try {
      const { date_of_birth, date_of_death, date_of_registry, ...restValue } = await form
        .validateFields()
        .catch(() => {
          throw new Error('Validation Error');
        });
      await addPlwMutation.mutateAsync({
        neighbor_group_id: neighborGroup.id,
        project_diocese_code: neighborGroup.project_diocese_code,
        ...restValue,
        date_of_birth: date_of_birth ? date_of_birth.format('YYYY-MM-DD') : null,
        date_of_death: date_of_death ? date_of_death.format('YYYY-MM-DD') : null,
        date_of_registry: date_of_registry ? date_of_registry.format('YYYY-MM-DD') : null,
      });
      if (restValue.lead_mother === true) {
        if (neighborGroup?.plws) {
          const current_lead_mohters = compact(neighborGroup.plws.items).filter(
            (p) => p.lead_mother === true
          );
          for (const lm of current_lead_mohters) {
            await updatePlwMutation.mutateAsync({
              id: lm.id,
              lead_mother: false,
            });
          }
        }
      }
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error?.message || error);
    }
  };

  const saveEditedPLW = async () => {
    if (!editingPlwId) {
      message.error('No plw id provided.');
      return;
    }
    try {
      const {
        date_of_birth,
        date_of_death,
        date_of_registry,
        education_level_id,
        occupation_type_id,
        ...restValue
      } = await form.validateFields().catch(() => {
        throw new Error('Validation Error');
      });
      await updatePlwMutation.mutateAsync({
        id: editingPlwId,
        ...restValue,
        education_level_id: education_level_id || null,
        occupation_type_id: occupation_type_id || null,
        date_of_birth: date_of_birth ? date_of_birth.format('YYYY-MM-DD') : null,
        date_of_death: date_of_death ? date_of_death.format('YYYY-MM-DD') : null,
        date_of_registry: date_of_registry ? date_of_registry.format('YYYY-MM-DD') : null,
      });
      if (restValue.lead_mother === true) {
        if (neighborGroup?.plws) {
          const other_current_lead_mohters = compact(neighborGroup.plws.items).filter(
            (p) => p.id !== editingPlwId && p.lead_mother === true
          );
          for (const lm of other_current_lead_mohters) {
            await updatePlwMutation.mutateAsync({
              id: lm.id,
              lead_mother: false,
            });
          }
        }
      }
      invalidateQueries();
      closeModal();
    } catch (error: any) {
      console.error(error?.message || error);
    }
  };

  const deletePLW = async () => {
    if (!editingPlwId || !editingPLW) {
      message.error('No plw id provided.');
      return;
    }
    const birth_history_count = editingPLW.birth_histories?.items.length || 0;
    const cg_meeting_count = editingPLW.cg_meetings_attended?.items.length || 0;
    const ng_meeting_count = editingPLW.ng_meetings_attended?.items.length || 0;
    const ng_meeting_facilitated_count = editingPLW.ng_meetings_facilitated?.items.length || 0;
    const distribution_count = editingPLW.distributions_recieved?.items.length || 0;
    const other_activity_count = editingPLW.other_activities_attended?.items.length || 0;
    const hg_training_count = editingPLW.hg_training_attended?.items.length || 0;

    if (
      [
        birth_history_count,
        cg_meeting_count,
        ng_meeting_count,
        ng_meeting_facilitated_count,
        distribution_count,
        other_activity_count,
        hg_training_count,
      ].some((item) => item > 0)
    ) {
      warning({
        title: 'This PLW cannot be deleted.',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <div>Follwoing tables have associated records to this PLW.</div>
            <ul>
              {birth_history_count > 0 && <li>Birth History</li>}
              {cg_meeting_count > 0 && <li>Care Group Meeting</li>}
              {ng_meeting_count > 0 && <li>Neighbor Group Meeting - Attended</li>}
              {ng_meeting_facilitated_count > 0 && <li>Neighbor Group Meeting - Facilitated</li>}
              {distribution_count > 0 && <li>Distribution</li>}
              {other_activity_count > 0 && <li>Other Type of Activity</li>}
              {hg_training_count > 0 && <li>Home Garden Training</li>}
            </ul>
            <div>You have to delete those records first.</div>
          </div>
        ),
      });
      return;
    }

    try {
      await deletePlwMuation.mutateAsync({
        id: editingPlwId,
      });
      closeModal();
    } catch (error: any) {
      console.error(error?.message || error);
    }
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addPLW();
            } else if (modalVisible === 'editing') {
              saveEditedPLW();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Button danger onClick={deletePLW} disabled={!user.isEditor}>
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding'
          ? `Adding a new PLW to ${projectDiocese?.diocese.name || '-'} Diocese, ${
              projectDiocese?.project.name || '-'
            } Project`
          : modalVisible === 'editing'
          ? `Editing PLW ${neighborGroup?.care_group.group_number || '-'}.${
              neighborGroup?.group_number || '-'
            }.${editingPLW?.member_number}`
          : 'PLW Form'
      }
      open={modalVisible !== false}
      footer={customModalFooter}
      closable={false}
      width={920}
    >
      {modalVisible === 'editing' && isPlwLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </div>
      ) : (
        <>
          <Form form={form} name='plw_form' labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name='name'
                  label='Name'
                  rules={[{ required: true, message: 'Required.' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='father_name' label='Father name'>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='education_level_id' label='Education'>
                  <Select dropdownMatchSelectWidth={380} allowClear>
                    {educationLevels?.map((edu) => (
                      <Select.Option key={edu.id} value={edu.id}>
                        {edu.education_level} ({edu.education_level_mm})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='occupation_type_id' label='Occupation'>
                  <Select dropdownMatchSelectWidth={380} allowClear>
                    {occupationTypes?.map((occu) => (
                      <Select.Option key={occu.id} value={occu.id}>
                        {occu.occupation_type} ({occu.occupation_type_mm})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label={
                    <span>
                      <span style={{ color: 'red' }}>*</span> Member Number
                    </span>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      width: 100,
                      textAlign: 'right',
                      padding: '4px 10px',
                      border: '1px solid lightgray',
                      borderRight: 'none',
                    }}
                  >
                    {neighborGroup?.care_group.group_number || ''}.
                    {neighborGroup?.group_number || ''}
                  </span>
                  <Form.Item
                    noStyle
                    name='member_number'
                    rules={[
                      { required: true, message: 'Member number is required.' },
                      {
                        validator: validateMemberNumber,
                        validateTrigger: ['onChange'],
                      },
                    ]}
                  >
                    <Select style={{ width: 78 }} allowClear showSearch>
                      {[...Array(51).keys()].map((number) => (
                        <Select.Option key={number} value={number}>
                          {number}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='date_of_registry'
                  label='Date of Registry'
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='lead_mother'
                  label='Lead Mother'
                  valuePropName='checked'
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name='date_of_birth'
                  label='Date of Birth'
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='date_of_death'
                  label='Date of Death'
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='lost_contact'
                  label='Lost Contact'
                  valuePropName='checked'
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name='contact_number'
                  label='Contact Number'
                  labelCol={{ offset: 4, span: 6 }}
                  wrapperCol={{ span: 5 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {!!editingPLW && (
            <>
              <BirthHistoryTable
                addOrEditBirthHistory={addOrEditBirthHistory}
                setAddOrEditBirthHistory={setAddOrEditBirthHistory}
                birthHistories={
                  editingPLW.birth_histories
                    ? compact(editingPLW.birth_histories.items).sort((a, b) => {
                        const sortDateA = a.delivered_date || a.lmp || '';
                        const sortDateB = b.delivered_date || b.lmp || '';
                        return sortDateB.localeCompare(sortDateA);
                      })
                    : []
                }
                setEditingBirthHistory={setEditingBirthHistory}
              />
              <BirthHistoryDrawerForm
                addingOrEditing={addOrEditBirthHistory}
                setAddingOrEditing={setAddOrEditBirthHistory}
                editingBirthHistory={editingBirthHistory}
                parentPLW={editingPLW}
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default PLWModalForm;

import React, { FC, useEffect } from 'react';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { Button, Modal, Popconfirm, Space, Form, Input, message } from 'antd';
import * as mutations from '../../../graphql/mutations';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  CreateOtherActivityTypeInput,
  CreateOtherActivityTypeMutation,
  DeleteOtherActivityTypeInput,
  DeleteOtherActivityTypeMutation,
  UpdateOtherActivityTypeInput,
  UpdateOtherActivityTypeMutation,
} from '../../../API';
import { API } from 'aws-amplify';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { warning } = Modal;

type ProjectType = {
  __typename: 'Project';
  id: string;
  project_code: string;
};

type OtherActivityTypeType = {
  __typename: 'OtherActivityType';
  id: string;
  activity_name: string;
  description?: string | null | undefined;
  activities?:
    | {
        __typename: 'ModelOtherActivityConnection';
        items: ({
          __typename: 'OtherActivity';
          id: string;
        } | null)[];
      }
    | null
    | undefined;
};

interface OtherActivityTypeFormProps {
  modalVisible: 'adding' | 'editing' | false;
  setModalVisible: (visible: 'adding' | 'editing' | false) => void;
  editingOtherActivityType: OtherActivityTypeType | undefined;
  project: ProjectType;
}

const OtherActivityTypeForm: FC<OtherActivityTypeFormProps> = ({
  modalVisible,
  setModalVisible,
  editingOtherActivityType,
  project,
}) => {
  const [form] = Form.useForm();
  const user = useGetUserInfo();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (modalVisible === 'editing' && editingOtherActivityType) {
      form.setFieldsValue(editingOtherActivityType);
    } else {
      form.resetFields();
    }
  }, [modalVisible, editingOtherActivityType]);

  // MUTATIONS
  // INSERT
  const addOtherActivityTypeMutation = useMutation(
    (input: CreateOtherActivityTypeInput) =>
      API.graphql({
        query: mutations.createOtherActivityType,
        variables: { input },
      }) as Promise<GraphQLResult<CreateOtherActivityTypeMutation>>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'listOtherActivityTypesByProjectCode',
          project.project_code,
        ]);
      },
    }
  );
  // UPDATE
  const updateOtherActivityTypeMutation = useMutation(
    (input: UpdateOtherActivityTypeInput) =>
      API.graphql({
        query: mutations.updateOtherActivityType,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateOtherActivityTypeMutation>>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'listOtherActivityTypesByProjectCode',
          project.project_code,
        ]);
      },
    }
  );
  // DELETE
  const deleteOtherActivityTypeMutation = useMutation(
    (input: DeleteOtherActivityTypeInput) =>
      API.graphql({
        query: mutations.deleteOtherActivityType,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteOtherActivityTypeMutation>>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'listOtherActivityTypesByProjectCode',
          project.project_code,
        ]);
      },
    }
  );

  // HANDLERS
  const addOtherActivityType = async () => {
    try {
      const values = await form.validateFields();
      await addOtherActivityTypeMutation.mutateAsync({
        project_id: project.id,
        project_code: project.project_code,
        activity_name: values.activity_name,
        description: values.description,
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  const saveEditedOtherActivityType = async () => {
    if (!editingOtherActivityType) {
      message.error('No other activity type selected');
      return;
    }
    try {
      const values = await form.validateFields();
      await updateOtherActivityTypeMutation.mutateAsync({
        id: editingOtherActivityType.id,
        activity_name: values.activity_name,
        description: values.description,
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOtherActivityType = async () => {
    if (!editingOtherActivityType) {
      message.error('No other activity type selected');
      return;
    }
    const other_activity_count = editingOtherActivityType.activities?.items.length || 0;
    if (other_activity_count > 0) {
      warning({
        title: 'Cannot delete activity type',
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            <div>Following table has associated records to this activity type.</div>
            <ul>
              <li>Other Activities</li>
            </ul>
          </div>
        ),
      });
      return;
    }
    try {
      await deleteOtherActivityTypeMutation.mutateAsync({
        id: editingOtherActivityType.id,
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const customModalFooter = (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (modalVisible === 'adding') {
              addOtherActivityType();
            } else if (modalVisible === 'editing') {
              saveEditedOtherActivityType();
            }
          }}
          disabled={!user.isEditor}
        >
          Save
        </Button>
      </Space>
      {modalVisible === 'editing' ? (
        <Button
          danger
          onClick={deleteOtherActivityType}
          disabled={!(user.isAdmin && user.isEditor)}
        >
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      title={
        modalVisible === 'adding' ? 'Adding Other Activity Type' : 'Editing Other Activity Type'
      }
      open={modalVisible !== false}
      footer={customModalFooter}
      closable={false}
      width={650}
    >
      <Form
        form={form}
        name='otherActivityTypeForm'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          name='activity_name'
          label='Activity Name'
          rules={[{ required: true, message: 'Please input activity name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name='description' label='Description'>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OtherActivityTypeForm;

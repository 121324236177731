import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { CampByTownshipCustomQuery, StaffByProjectDioceseCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';
import { compact } from 'lodash';

export const useListCampByTownshipId = (township_id: string | undefined) => {
  return useQuery(
    ['campByTownship', township_id || 'never'],
    () =>
      API.graphql({
        query: queries.campByTownshipCustom,
        variables: {
          township_id: township_id || 'never',
        },
      }) as Promise<GraphQLResult<CampByTownshipCustomQuery>>,
    {
      enabled: !!township_id,
      select: (data) => data.data?.campByTownship && compact(data.data.campByTownship.items),
    }
  );
};

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://enl7px45qbgjdl5iqjgmckbpfy.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mic2fbfa6j.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:ed57e34e-ade9-465c-af73-6c0874ee7935",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_dfMl465Ge",
    "aws_user_pools_web_client_id": "3m84fst9it523qbmh70ii13opi",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "c16f555d94a5413ca55ebbe06150c31e",
    "aws_mobile_analytics_app_region": "ap-southeast-1",
    "aws_user_files_s3_bucket": "cgmamplified-homepage-photos111727-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;

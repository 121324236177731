import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapState {
  selectedMenuKeys: {
    keys: string[];
    selectAll: boolean;
    currentSelection: string | undefined;
  };
  selectedVillageId: string | undefined;
}

export const initialState: MapState = {
  selectedMenuKeys: {
    keys: [],
    selectAll: false,
    currentSelection: undefined,
  },
  selectedVillageId: undefined,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setSelectedMenuKeys: (state, action: PayloadAction<{ keys: string[]; selectAll: boolean }>) => {
      state.selectedMenuKeys.keys = action.payload.keys;
      state.selectedMenuKeys.selectAll = action.payload.selectAll;
      if (!action.payload.selectAll && action.payload.keys.length > 0) {
        state.selectedMenuKeys.currentSelection = action.payload.keys[0];
      } else {
        state.selectedMenuKeys.currentSelection = undefined;
      }
    },
    setSelectedVillageId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedVillageId = action.payload;
    },
  },
});

export const { setSelectedMenuKeys, setSelectedVillageId } = mapSlice.actions;

export default mapSlice.reducer;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { compact } from 'lodash';
import { ListPlwsByCareGroupIdQuery } from '../API';
import * as queries from '../graphql/custom_queries';

type PLWType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<ListPlwsByCareGroupIdQuery['getCareGroup']>['neighbor_groups']
      >['items'][number]
    >['plws']
  >['items'][number] & { cg_number: string; ng_number: string; complete_member_number: string }
>;

export const useListPlwByCareGroupId = (care_group_id: string | undefined) => {
  return useQuery(
    ['listPlwsByCareGroupId', care_group_id || 'never'],
    () =>
      API.graphql({
        query: queries.listPlwsByCareGroupId,
        variables: {
          id: care_group_id || 'never',
        },
      }) as Promise<GraphQLResult<ListPlwsByCareGroupIdQuery>>,
    {
      enabled: !!care_group_id,
      select: (data) => {
        const plws: PLWType[] = [];
        if (data.data?.getCareGroup?.neighbor_groups) {
          const cg_number = data.data.getCareGroup.group_number;
          const ng = data.data.getCareGroup.neighbor_groups.items.forEach((ng) => {
            if (ng) {
              const ng_number = ng?.group_number;
              if (ng?.plws?.items) {
                plws.push(
                  ...compact(ng.plws.items).map((plw) => {
                    return {
                      ...plw,
                      cg_number,
                      ng_number,
                      complete_member_number: `${cg_number}.${ng_number}.${plw.member_number}`,
                    };
                  })
                );
              }
            }
          });
        }
        return plws.sort((a, b) => {
          if (a.ng_number === b.ng_number) {
            return a.member_number - b.member_number;
          } else {
            return a.ng_number.localeCompare(b.ng_number);
          }
        });
      },
    }
  );
};

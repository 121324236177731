import { ColumnsType } from 'antd/lib/table';
import React, { FC, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { PLWType, DistributionType } from '../NeighborGroups';
import { Typography, Table, Space, Button, Popover } from 'antd';
import Styles from '../NeighborGroups.module.scss';
import DistributionModalForm from './DistributionModalForm';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Link } = Typography;

interface PropsType {
  isLoading: boolean;
  distributions: DistributionType[];
  plws: PLWType[];
}

const DistributionTable: FC<PropsType> = ({ isLoading, distributions, plws }) => {
  const [isDistributionModalVisible, setIsDistributionModalVisible] = useState<
    'adding' | 'editing' | false
  >(false);
  const [editingDistributionId, setEditingDistributionId] = useState<string | undefined>();
  const selectedMenuKeys = useAppSelector((state) => state.neighborgroups.selectedMenuKeys);
  const user = useGetUserInfo();

  const plwMap = useMemo(() => {
    const plwMap = new Map<string, PLWType>();
    plws.forEach((plw) => plwMap.set(plw.id, plw));
    return plwMap;
  }, [plws]);

  const columns: ColumnsType<DistributionType> = [
    {
      title: 'Neighbor Group',
      key: 'neighbor_group',
      width: 150,
      render: (_, record) => (
        <div
          style={{ paddingLeft: 30 }}
        >{`${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`}</div>
      ),
      sorter: (a, b) => a.neighbor_group.group_number.localeCompare(b.neighbor_group.group_number),
    },
    {
      title: 'Distribution Plan',
      dataIndex: 'distribution_item',
      key: 'distribution_item',
      // ellipsis: { showTitle: false },
      render: (_, record) => {
        return (
          <Popover
            title={record.distribution_item.title}
            placement='right'
            trigger={'click'}
            content={
              <div
                style={{
                  width: 500,
                  maxHeight: 500,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {record.distribution_item.planned_distribution_start_date && (
                  <p>
                    Planned start date : {record.distribution_item.planned_distribution_start_date}
                  </p>
                )}
                {record.distribution_item.planned_distribution_end_date && (
                  <p>Planned end date : {record.distribution_item.planned_distribution_end_date}</p>
                )}
                <p style={{ whiteSpace: 'pre-wrap', maxWidth: '400px' }}>
                  {record.distribution_item.description}
                </p>
              </div>
            }
          >
            <Link>{record.distribution_item.title}</Link>
          </Popover>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Distribution Date',
      dataIndex: 'distribution_date',
      key: 'distribution_date',
      width: 145,
      sorter: (a, b) => a.distribution_date!.localeCompare(b.distribution_date!),
    },
    {
      title: 'Receivers',
      dataIndex: 'recipients',
      key: 'recipients',
      align: 'right',
      width: 120,
      render: (_, record) => {
        const completeNgNumber = `${record.neighbor_group.care_group.group_number}.${record.neighbor_group.group_number}`;
        return (
          <div style={{ paddingRight: 20 }}>
            <Popover
              placement='left'
              title={`${completeNgNumber} @ ${record.distribution_date}`}
              trigger={'click'}
              content={
                <Space
                  direction='vertical'
                  style={{ maxHeight: 400, overflow: 'auto', padding: '10px' }}
                >
                  {record.recipients?.items

                    .sort(
                      (a, b) =>
                        (plwMap.get(a?.plw.id || '')?.member_number || 0) -
                        (plwMap.get(b?.plw.id || '')?.member_number || 0)
                    )
                    .map((recipient) => {
                      if (recipient && recipient.plw) {
                        const plw = plwMap.get(recipient.plw.id);
                        const completeMemberNumber = `${
                          record.neighbor_group.care_group.group_number
                        }.${record.neighbor_group.group_number}.${
                          plw?.member_number === undefined ? '-' : plw.member_number
                        }`;
                        return (
                          <Space key={recipient.plw.id}>
                            <span>{completeMemberNumber}</span>
                            <span>{plw?.name}</span>
                            {plw?.lead_mother ? <span> *</span> : null}
                          </Space>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Space>
              }
            >
              <Link>{record.recipients?.items.length || 0}</Link>
            </Popover>
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Link
            disabled={!user.isEditor || selectedMenuKeys.selectAll}
            onClick={() => showEditDistributionModal(record.id)}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  const showAddDistributionModal = () => {
    setEditingDistributionId(undefined);
    setIsDistributionModalVisible('adding');
  };

  const showEditDistributionModal = (editingDistributionId: string) => {
    setEditingDistributionId(editingDistributionId);
    setIsDistributionModalVisible('editing');
  };

  return (
    <div className={Styles.dataTable}>
      <div className={Styles.tableHeaderRow}>
        <div className={Styles.buttonContainer}>
          <Space>
            <Button
              className={Styles.exportButton}
              onClick={showAddDistributionModal}
              disabled={
                !user.isEditor ||
                isLoading ||
                selectedMenuKeys.selectAll ||
                selectedMenuKeys.keys.length < 1
              }
            >
              Add New Distribution
            </Button>
            {/* <Button
              className={Styles.exportButton}
              // onClick={excelExportHandler}
              disabled={isLoading}
            >
              Export
            </Button> */}
          </Space>
        </div>
      </div>
      <Table
        showSorterTooltip={false}
        columns={columns}
        dataSource={distributions}
        size='small'
        rowKey={'id'}
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 50,
          style: { marginRight: 25 },
          showTotal: (total, range) => `Total ${total}`,
        }}
        scroll={{ x: 'calc(100vw - 400px)', y: 'calc(100vh - 350px)' }}
      />
      {!!selectedMenuKeys.currentSelection && (
        <DistributionModalForm
          modalVisible={isDistributionModalVisible}
          setModalVisible={setIsDistributionModalVisible}
          editingDistributionId={editingDistributionId}
        />
      )}
    </div>
  );
};

export default DistributionTable;

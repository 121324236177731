import saveAs from 'file-saver';
import moment from 'moment';
import { TableFilterType } from '../common_types';
import { NGMeetingType } from './NeighborGroupMeetings';
import ExcelJS from 'exceljs';

export const exportToExcel = async (
  filteredNgMeetings: NGMeetingType[],
  ngMeetingFilters: Record<string, (boolean | string | number)[] | null>,
  topicFilter: Array<TableFilterType>
) => {
  const workbook = new ExcelJS.Workbook();

  const dioceseNameSet = new Set<string>();
  const careGroupNumberMap = new Map<string, { careGroupNumber: string; dioceseName: string; projectName: string }>();
  const neighborGroupNumberMap = new Map<
    string,
    { neighborGroupNumber: string; careGroupNumber: string; dioceseName: string; projectName: string }
  >();
  const facilitatorMap = new Map<
    string,
    {
      memberNumber: string;
      facilitatorName: string;
      leadMother: boolean;
      careGroup: string;
      diocese: string;
      project: string;
    }
  >();
  const topicMap = new Map<
    string,
    {
      topicInEnglish: string;
      topicInMyanmar: string;
    }
  >();
  const participantMap = new Map<
    string,
    {
      memberNumber: string;
      name: string;
      fatherName: string;
      dob: string;
      leadMother: boolean;
      careGroup: string;
      diocese: string;
      project: string;
    }
  >();

  const meetingSheet = workbook.addWorksheet('Neighbor Group Meetings');
  meetingSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Meeting Date', key: 'date', width: 15 },
    { header: 'Facilitator', key: 'facilitator', width: 25 },
    { header: 'Description', key: 'description', width: 20 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Neighbor Group', key: 'neighborGroup', width: 20 },
    { header: 'Topics', key: 'topics', width: 25 },
    { header: 'Participants #', key: 'participants', width: 15 },
  ];
  meetingSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  const meetingDetailSheet = workbook.addWorksheet('Meeting Details');
  meetingDetailSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Meeting Date', key: 'date', width: 15 },
    { header: 'Facilitator', key: 'facilitator', width: 25 },
    { header: 'Description', key: 'description', width: 20 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Neighbor Group', key: 'neighborGroup', width: 20 },
    { header: 'Topics', key: 'topics', width: 25 },
    { header: 'Participant #', key: 'participantNumber', width: 15 },
    { header: 'Participant Name', key: 'participantName', width: 20 },
    { header: 'Lead Mother', key: 'leadMother', width: 15 },
  ];
  meetingDetailSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  filteredNgMeetings.forEach((fngm, index) => {
    ///////////////////////////
    if (fngm.neighbor_group.care_group.project_diocese.diocese)
      dioceseNameSet.add(fngm.neighbor_group.care_group.project_diocese.diocese.name);

    careGroupNumberMap.set(fngm.neighbor_group.care_group.id, {
      careGroupNumber: fngm.neighbor_group.care_group.group_number,
      dioceseName: fngm.neighbor_group.care_group.project_diocese.diocese.name,
      projectName: fngm.neighbor_group.care_group.project_diocese.project.name,
    });

    const completeNgNumber = `${fngm.neighbor_group.care_group.group_number}.${fngm.neighbor_group.group_number}`;
    neighborGroupNumberMap.set(fngm.neighbor_group.id, {
      neighborGroupNumber: completeNgNumber,
      careGroupNumber: fngm.neighbor_group.care_group.group_number,
      dioceseName: fngm.neighbor_group.care_group.project_diocese.diocese.name,
      projectName: fngm.neighbor_group.care_group.project_diocese.project.name,
    });

    const completePLWFacilitatorNumber = `${fngm.neighbor_group.care_group.group_number}.${fngm.neighbor_group.group_number}.${fngm.plw_facilitator.member_number}`;
    facilitatorMap.set(fngm.plw_facilitator.id, {
      facilitatorName: fngm.plw_facilitator.name,
      memberNumber: completePLWFacilitatorNumber,
      leadMother: fngm.plw_facilitator.lead_mother || false,
      careGroup: fngm.neighbor_group.care_group.group_number,
      diocese: fngm.neighbor_group.care_group.project_diocese.diocese.name,
      project: fngm.neighbor_group.care_group.project_diocese.project.name,
    });

    if (fngm.topics) {
      fngm.topics.items.forEach((topic) => {
        if (topic && topic.nutrition_topic) {
          topicMap.set(topic.nutrition_topic.id, {
            topicInEnglish: topic.nutrition_topic.topic_text,
            topicInMyanmar: topic.nutrition_topic.topic_text_myanmar || '',
          });
        }
      });
    }

    if (fngm.participants) {
      fngm.participants.items.forEach((participant) => {
        if (participant && participant.plw) {
          const completeMemberNumber = `${fngm.neighbor_group.care_group.group_number}.${fngm.neighbor_group.group_number}.${participant.plw.member_number}`;

          participantMap.set(participant.plw.id, {
            memberNumber: completeMemberNumber,
            name: participant.plw.name,
            fatherName: participant.plw.father_name || '',
            dob: participant.plw.date_of_birth || '',
            leadMother: participant.plw.lead_mother || false,
            careGroup: fngm.neighbor_group.care_group.group_number,
            diocese: fngm.neighbor_group.care_group.project_diocese.diocese.name,
            project: fngm.neighbor_group.care_group.project_diocese.project.name,
          });

          ////////////////////////////////////
          meetingDetailSheet.addRow({
            project: fngm.neighbor_group.care_group.project_diocese.project.name,
            diocese: fngm.neighbor_group.care_group.project_diocese.diocese.name,
            date: fngm.meeting_date,
            facilitator:
              completePLWFacilitatorNumber +
              ' - ' +
              fngm.plw_facilitator.name +
              (fngm.plw_facilitator.lead_mother ? ' *' : ''),
            description: fngm.description,
            careGroup: fngm.neighbor_group.care_group.group_number,
            neighborGroup: completeNgNumber,
            topics: fngm.topics?.items
              .map((topic) => {
                if (topic && topic.nutrition_topic) {
                  return topic.nutrition_topic.topic_text;
                }
              })
              .join(', '),
            participantNumber: completeMemberNumber,
            participantName: participant.plw.name,
            leadMother: participant.plw.lead_mother ? 'TRUE' : '',
          });
          const lastRow = meetingDetailSheet.lastRow!;
          lastRow.eachCell({ includeEmpty: true }, (cell) => {
            if (index % 2 === 0) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D1D1D1' },
                // bgColor: { argb: 'DDDDDD' },
              };
            }
            cell.border = {
              top: { style: 'dotted' },
              right: { style: 'dotted' },
              bottom: { style: 'dotted' },
              left: { style: 'dotted' },
            };
          });
        }
      });
    }

    ///////////////////////////

    meetingSheet.addRow({
      project: fngm.neighbor_group.care_group.project_diocese.project.name,
      diocese: fngm.neighbor_group.care_group.project_diocese.diocese.name,
      date: fngm.meeting_date,
      facilitator:
        completePLWFacilitatorNumber +
        ' - ' +
        fngm.plw_facilitator.name +
        (fngm.plw_facilitator.lead_mother ? ' *' : ''),
      description: fngm.description,
      careGroup: fngm.neighbor_group.care_group.group_number,
      neighborGroup: completeNgNumber,
      topics: fngm.topics?.items
        .map((topic) => {
          if (topic && topic.nutrition_topic) {
            return topic.nutrition_topic.topic_text;
          }
        })
        .join(', '),
      participants: fngm.participants?.items.length,
    });
  });

  const filterSheet = workbook.addWorksheet('Filters');

  filterSheet.getColumn(1).width = 22;
  filterSheet.getColumn(2).width = 50;
  filterSheet.addRow(['Filter Name', 'Filer Value']);
  const lastRow = filterSheet.lastRow!;
  lastRow.eachCell((cell) => {
    cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true };
  });

  Object.keys(ngMeetingFilters).forEach((filter) => {
    if (ngMeetingFilters[filter] && ngMeetingFilters[filter]!.length) {
      const spaced = filter.replace(/([A-Z])/g, ' $1');
      const filterTitle = spaced.charAt(0).toUpperCase() + spaced.slice(1);
      ngMeetingFilters[filter]!.forEach((id, index) => {
        let filterValue: string | number | boolean | undefined;
        if (filter === 'topics') filterValue = topicFilter.find((tf) => tf.value === id)?.text;
        else filterValue = id;
        if (index === 0) {
          filterSheet.addRow([filterTitle, filterValue]);
        } else {
          filterSheet.addRow(['', filterValue]);
        }
      });
    }
  });

  const dioceseSheet = workbook.addWorksheet('Dioceses');
  dioceseSheet.columns = [{ header: 'Participating Dioceses', key: 'diocese', width: 25 }];
  dioceseSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...dioceseNameSet].sort().forEach((name) => {
    dioceseSheet.addRow([name]);
  });

  const careGroupSheet = workbook.addWorksheet('Care Groups');
  careGroupSheet.columns = [
    { header: 'CG Number', key: 'cgNumber', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  careGroupSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));
  [...careGroupNumberMap]
    .sort((a, b) => a[1].careGroupNumber.localeCompare(b[1].careGroupNumber))
    .forEach((cg) => {
      careGroupSheet.addRow({
        cgNumber: cg[1].careGroupNumber,
        diocese: cg[1].dioceseName,
        project: cg[1].projectName,
      });
    });

  const neighborGroupSheet = workbook.addWorksheet('Neighbor Groups');
  neighborGroupSheet.columns = [
    { header: 'NG Number', key: 'ngNumber', width: 15 },
    { header: 'Care group', key: 'cgNumber', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  neighborGroupSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...neighborGroupNumberMap]
    .sort((a, b) => a[1].neighborGroupNumber.localeCompare(b[1].neighborGroupNumber))
    .forEach((ng) => {
      neighborGroupSheet.addRow({
        ngNumber: ng[1].neighborGroupNumber,
        cgNumber: ng[1].careGroupNumber,
        diocese: ng[1].dioceseName,
        project: ng[1].projectName,
      });
    });

  const facilitatorSheet = workbook.addWorksheet('Facilitators');
  facilitatorSheet.columns = [
    { header: 'Member Number', key: 'memberNumber', width: 15 },
    { header: 'Facilitator name', key: 'name', width: 15 },
    { header: 'Lead Mother', key: 'leadMother', width: 15 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  facilitatorSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...facilitatorMap]
    .sort((a, b) => a[1].memberNumber.localeCompare(b[1].memberNumber))
    .forEach((fac) => {
      let facilitator = fac[1];
      facilitatorSheet.addRow({
        memberNumber: facilitator.memberNumber,
        name: facilitator.facilitatorName,
        leadMother: facilitator.leadMother ? 'TRUE' : '',
        careGroup: facilitator.careGroup,
        diocese: facilitator.diocese,
        project: facilitator.project,
      });
    });

  const topicSheet = workbook.addWorksheet('Topics');
  topicSheet.columns = [
    { header: 'Topic', key: 'topicEnglish', width: 25 },
    { header: 'Topic in Myanmar', key: 'topicMyanmar', width: 50 },
  ];
  topicSheet.getRow(1).eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...topicMap]
    .sort((a, b) => a[1].topicInEnglish.localeCompare(b[1].topicInEnglish))
    .forEach((topic) => {
      topicSheet.addRow({
        topicEnglish: topic[1].topicInEnglish,
        topicMyanmar: topic[1].topicInMyanmar,
      });
    });

  const participantSheet = workbook.addWorksheet('Participants');
  participantSheet.columns = [
    { header: 'Member Number', key: 'memberNumber', width: 15 },
    { header: 'Name', key: 'name', width: 15 },
    { header: 'Father Name', key: 'fatherName', width: 15 },
    { header: 'DOB', key: 'dob', width: 15 },
    { header: 'Lead Mother', key: 'leadMother', width: 10 },
    { header: 'Care Group', key: 'careGroup', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  participantSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...participantMap]
    .sort((a, b) => a[1].memberNumber.localeCompare(b[1].memberNumber))
    .forEach((par) => {
      const participant = par[1];
      participantSheet.addRow({
        memberNumber: participant.memberNumber,
        name: participant.name,
        fatherName: participant.fatherName,
        dob: participant.dob,
        leadMother: participant.leadMother ? 'TRUE' : '',
        careGroup: participant.careGroup,
        diocese: participant.diocese,
        project: participant.project,
      });
    });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `Neighbor Group Meeting Data ${moment().format('YYYY-MM-DD h-mm a')}.xlsx`);
};

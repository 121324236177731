/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      project_id
      project_code
      name
      sector
      description
      donor
      start_date
      end_date
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distribution_items {
        items {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activitie_types {
        items {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_topics {
        items {
          id
          hg_training_topic_id
          project_id
          project_code
          topic
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      project_id
      project_code
      name
      sector
      description
      donor
      start_date
      end_date
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distribution_items {
        items {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activitie_types {
        items {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_topics {
        items {
          id
          hg_training_topic_id
          project_id
          project_code
          topic
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      project_id
      project_code
      name
      sector
      description
      donor
      start_date
      end_date
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distribution_items {
        items {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activitie_types {
        items {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_topics {
        items {
          id
          hg_training_topic_id
          project_id
          project_code
          topic
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDiocese = /* GraphQL */ `
  mutation CreateDiocese(
    $input: CreateDioceseInput!
    $condition: ModelDioceseConditionInput
  ) {
    createDiocese(input: $input, condition: $condition) {
      id
      diocese_id
      diocese_code
      name
      description
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      townships {
        items {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDiocese = /* GraphQL */ `
  mutation UpdateDiocese(
    $input: UpdateDioceseInput!
    $condition: ModelDioceseConditionInput
  ) {
    updateDiocese(input: $input, condition: $condition) {
      id
      diocese_id
      diocese_code
      name
      description
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      townships {
        items {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiocese = /* GraphQL */ `
  mutation DeleteDiocese(
    $input: DeleteDioceseInput!
    $condition: ModelDioceseConditionInput
  ) {
    deleteDiocese(input: $input, condition: $condition) {
      id
      diocese_id
      diocese_code
      name
      description
      project_dioceses {
        items {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      townships {
        items {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProjectDiocese = /* GraphQL */ `
  mutation CreateProjectDiocese(
    $input: CreateProjectDioceseInput!
    $condition: ModelProjectDioceseConditionInput
  ) {
    createProjectDiocese(input: $input, condition: $condition) {
      id
      project_diocese_id
      projectID
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      dioceseID
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectDiocese = /* GraphQL */ `
  mutation UpdateProjectDiocese(
    $input: UpdateProjectDioceseInput!
    $condition: ModelProjectDioceseConditionInput
  ) {
    updateProjectDiocese(input: $input, condition: $condition) {
      id
      project_diocese_id
      projectID
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      dioceseID
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectDiocese = /* GraphQL */ `
  mutation DeleteProjectDiocese(
    $input: DeleteProjectDioceseInput!
    $condition: ModelProjectDioceseConditionInput
  ) {
    deleteProjectDiocese(input: $input, condition: $condition) {
      id
      project_diocese_id
      projectID
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      dioceseID
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTownship = /* GraphQL */ `
  mutation CreateTownship(
    $input: CreateTownshipInput!
    $condition: ModelTownshipConditionInput
  ) {
    createTownship(input: $input, condition: $condition) {
      id
      ts_pcode
      d_pcode
      sr_pcode
      name
      name_mm
      diocese_id
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      diocese_code
      active
      villageTracts {
        items {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      camps {
        items {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTownship = /* GraphQL */ `
  mutation UpdateTownship(
    $input: UpdateTownshipInput!
    $condition: ModelTownshipConditionInput
  ) {
    updateTownship(input: $input, condition: $condition) {
      id
      ts_pcode
      d_pcode
      sr_pcode
      name
      name_mm
      diocese_id
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      diocese_code
      active
      villageTracts {
        items {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      camps {
        items {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTownship = /* GraphQL */ `
  mutation DeleteTownship(
    $input: DeleteTownshipInput!
    $condition: ModelTownshipConditionInput
  ) {
    deleteTownship(input: $input, condition: $condition) {
      id
      ts_pcode
      d_pcode
      sr_pcode
      name
      name_mm
      diocese_id
      diocese {
        id
        diocese_id
        diocese_code
        name
        description
        project_dioceses {
          nextToken
        }
        townships {
          nextToken
        }
        createdAt
        updatedAt
      }
      diocese_code
      active
      villageTracts {
        items {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      camps {
        items {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      care_groups {
        items {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVillageTract = /* GraphQL */ `
  mutation CreateVillageTract(
    $input: CreateVillageTractInput!
    $condition: ModelVillageTractConditionInput
  ) {
    createVillageTract(input: $input, condition: $condition) {
      id
      vt_pcode
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      diocese_code
      active
      villages {
        items {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVillageTract = /* GraphQL */ `
  mutation UpdateVillageTract(
    $input: UpdateVillageTractInput!
    $condition: ModelVillageTractConditionInput
  ) {
    updateVillageTract(input: $input, condition: $condition) {
      id
      vt_pcode
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      diocese_code
      active
      villages {
        items {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVillageTract = /* GraphQL */ `
  mutation DeleteVillageTract(
    $input: DeleteVillageTractInput!
    $condition: ModelVillageTractConditionInput
  ) {
    deleteVillageTract(input: $input, condition: $condition) {
      id
      vt_pcode
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      diocese_code
      active
      villages {
        items {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVillage = /* GraphQL */ `
  mutation CreateVillage(
    $input: CreateVillageInput!
    $condition: ModelVillageConditionInput
  ) {
    createVillage(input: $input, condition: $condition) {
      id
      village_pcode
      villageTract_id
      villageTract {
        id
        vt_pcode
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        diocese_code
        active
        villages {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      alt_name
      alt_name_mm
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVillage = /* GraphQL */ `
  mutation UpdateVillage(
    $input: UpdateVillageInput!
    $condition: ModelVillageConditionInput
  ) {
    updateVillage(input: $input, condition: $condition) {
      id
      village_pcode
      villageTract_id
      villageTract {
        id
        vt_pcode
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        diocese_code
        active
        villages {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      alt_name
      alt_name_mm
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVillage = /* GraphQL */ `
  mutation DeleteVillage(
    $input: DeleteVillageInput!
    $condition: ModelVillageConditionInput
  ) {
    deleteVillage(input: $input, condition: $condition) {
      id
      village_pcode
      villageTract_id
      villageTract {
        id
        vt_pcode
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        diocese_code
        active
        villages {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      alt_name
      alt_name_mm
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCamp = /* GraphQL */ `
  mutation CreateCamp(
    $input: CreateCampInput!
    $condition: ModelCampConditionInput
  ) {
    createCamp(input: $input, condition: $condition) {
      id
      camp_id
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      description
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCamp = /* GraphQL */ `
  mutation UpdateCamp(
    $input: UpdateCampInput!
    $condition: ModelCampConditionInput
  ) {
    updateCamp(input: $input, condition: $condition) {
      id
      camp_id
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      description
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCamp = /* GraphQL */ `
  mutation DeleteCamp(
    $input: DeleteCampInput!
    $condition: ModelCampConditionInput
  ) {
    deleteCamp(input: $input, condition: $condition) {
      id
      camp_id
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      name_mm
      description
      longitude
      latitude
      diocese_code
      active
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCareGroup = /* GraphQL */ `
  mutation CreateCareGroup(
    $input: CreateCareGroupInput!
    $condition: ModelCareGroupConditionInput
  ) {
    createCareGroup(input: $input, condition: $condition) {
      id
      care_group_id
      group_number
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroups {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings {
        items {
          id
          hg_training_id
          project_diocese_code
          care_group_id
          training_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCareGroup = /* GraphQL */ `
  mutation UpdateCareGroup(
    $input: UpdateCareGroupInput!
    $condition: ModelCareGroupConditionInput
  ) {
    updateCareGroup(input: $input, condition: $condition) {
      id
      care_group_id
      group_number
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroups {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings {
        items {
          id
          hg_training_id
          project_diocese_code
          care_group_id
          training_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareGroup = /* GraphQL */ `
  mutation DeleteCareGroup(
    $input: DeleteCareGroupInput!
    $condition: ModelCareGroupConditionInput
  ) {
    deleteCareGroup(input: $input, condition: $condition) {
      id
      care_group_id
      group_number
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      township_id
      township {
        id
        ts_pcode
        d_pcode
        sr_pcode
        name
        name_mm
        diocese_id
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        diocese_code
        active
        villageTracts {
          nextToken
        }
        camps {
          nextToken
        }
        care_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroups {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      neighbor_groups {
        items {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          project_diocese_code
          care_group_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings {
        items {
          id
          hg_training_id
          project_diocese_code
          care_group_id
          training_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPosition = /* GraphQL */ `
  mutation CreatePosition(
    $input: CreatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    createPosition(input: $input, condition: $condition) {
      id
      position_id
      name
      level
      description
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePosition = /* GraphQL */ `
  mutation UpdatePosition(
    $input: UpdatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    updatePosition(input: $input, condition: $condition) {
      id
      position_id
      name
      level
      description
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePosition = /* GraphQL */ `
  mutation DeletePosition(
    $input: DeletePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    deletePosition(input: $input, condition: $condition) {
      id
      position_id
      name
      level
      description
      staff {
        items {
          id
          staff_id
          project_diocese_id
          position_id
          current_position
          from_date
          to_date
          email
          first_name
          last_name
          gender
          is_staff
          is_active
          date_joined
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
      id
      staff_id
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      position_id
      position {
        id
        position_id
        name
        level
        description
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroup {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_position
      from_date
      to_date
      email
      first_name
      last_name
      gender
      is_staff
      is_active
      date_joined
      cg_meetings_facilitated {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_facilitated {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings_facilitated {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      staff_id
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      position_id
      position {
        id
        position_id
        name
        level
        description
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroup {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_position
      from_date
      to_date
      email
      first_name
      last_name
      gender
      is_staff
      is_active
      date_joined
      cg_meetings_facilitated {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_facilitated {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings_facilitated {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
      id
      staff_id
      project_diocese_id
      project_diocese {
        id
        project_diocese_id
        projectID
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        dioceseID
        diocese {
          id
          diocese_id
          diocese_code
          name
          description
          createdAt
          updatedAt
        }
        project_diocese_code
        care_groups {
          nextToken
        }
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      position_id
      position {
        id
        position_id
        name
        level
        description
        staff {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_caregroup {
        items {
          id
          staff_caregroup_id
          care_group_id
          staff_id
          current_assignment
          start_date
          end_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_position
      from_date
      to_date
      email
      first_name
      last_name
      gender
      is_staff
      is_active
      date_joined
      cg_meetings_facilitated {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_facilitated {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_trainings_facilitated {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStaffCareGroup = /* GraphQL */ `
  mutation CreateStaffCareGroup(
    $input: CreateStaffCareGroupInput!
    $condition: ModelStaffCareGroupConditionInput
  ) {
    createStaffCareGroup(input: $input, condition: $condition) {
      id
      staff_caregroup_id
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      current_assignment
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`;
export const updateStaffCareGroup = /* GraphQL */ `
  mutation UpdateStaffCareGroup(
    $input: UpdateStaffCareGroupInput!
    $condition: ModelStaffCareGroupConditionInput
  ) {
    updateStaffCareGroup(input: $input, condition: $condition) {
      id
      staff_caregroup_id
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      current_assignment
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`;
export const deleteStaffCareGroup = /* GraphQL */ `
  mutation DeleteStaffCareGroup(
    $input: DeleteStaffCareGroupInput!
    $condition: ModelStaffCareGroupConditionInput
  ) {
    deleteStaffCareGroup(input: $input, condition: $condition) {
      id
      staff_caregroup_id
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      current_assignment
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`;
export const createNeighborGroup = /* GraphQL */ `
  mutation CreateNeighborGroup(
    $input: CreateNeighborGroupInput!
    $condition: ModelNeighborGroupConditionInput
  ) {
    createNeighborGroup(input: $input, condition: $condition) {
      id
      neighbor_group_id
      group_number
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      village_id
      village {
        id
        village_pcode
        villageTract_id
        villageTract {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        alt_name
        alt_name_mm
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      camp_id
      camp {
        id
        camp_id
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        description
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNeighborGroup = /* GraphQL */ `
  mutation UpdateNeighborGroup(
    $input: UpdateNeighborGroupInput!
    $condition: ModelNeighborGroupConditionInput
  ) {
    updateNeighborGroup(input: $input, condition: $condition) {
      id
      neighbor_group_id
      group_number
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      village_id
      village {
        id
        village_pcode
        villageTract_id
        villageTract {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        alt_name
        alt_name_mm
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      camp_id
      camp {
        id
        camp_id
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        description
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNeighborGroup = /* GraphQL */ `
  mutation DeleteNeighborGroup(
    $input: DeleteNeighborGroupInput!
    $condition: ModelNeighborGroupConditionInput
  ) {
    deleteNeighborGroup(input: $input, condition: $condition) {
      id
      neighbor_group_id
      group_number
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      village_id
      village {
        id
        village_pcode
        villageTract_id
        villageTract {
          id
          vt_pcode
          township_id
          name
          name_mm
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        alt_name
        alt_name_mm
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      camp_id
      camp {
        id
        camp_id
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        name
        name_mm
        description
        longitude
        latitude
        diocese_code
        active
        neighbor_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEducationLevel = /* GraphQL */ `
  mutation CreateEducationLevel(
    $input: CreateEducationLevelInput!
    $condition: ModelEducationLevelConditionInput
  ) {
    createEducationLevel(input: $input, condition: $condition) {
      id
      education_level_id
      education_level
      education_level_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEducationLevel = /* GraphQL */ `
  mutation UpdateEducationLevel(
    $input: UpdateEducationLevelInput!
    $condition: ModelEducationLevelConditionInput
  ) {
    updateEducationLevel(input: $input, condition: $condition) {
      id
      education_level_id
      education_level
      education_level_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEducationLevel = /* GraphQL */ `
  mutation DeleteEducationLevel(
    $input: DeleteEducationLevelInput!
    $condition: ModelEducationLevelConditionInput
  ) {
    deleteEducationLevel(input: $input, condition: $condition) {
      id
      education_level_id
      education_level
      education_level_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOccupationType = /* GraphQL */ `
  mutation CreateOccupationType(
    $input: CreateOccupationTypeInput!
    $condition: ModelOccupationTypeConditionInput
  ) {
    createOccupationType(input: $input, condition: $condition) {
      id
      occupation_type_id
      occupation_type
      occupation_type_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOccupationType = /* GraphQL */ `
  mutation UpdateOccupationType(
    $input: UpdateOccupationTypeInput!
    $condition: ModelOccupationTypeConditionInput
  ) {
    updateOccupationType(input: $input, condition: $condition) {
      id
      occupation_type_id
      occupation_type
      occupation_type_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOccupationType = /* GraphQL */ `
  mutation DeleteOccupationType(
    $input: DeleteOccupationTypeInput!
    $condition: ModelOccupationTypeConditionInput
  ) {
    deleteOccupationType(input: $input, condition: $condition) {
      id
      occupation_type_id
      occupation_type
      occupation_type_mm
      description
      order
      plws {
        items {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDisabilityType = /* GraphQL */ `
  mutation CreateDisabilityType(
    $input: CreateDisabilityTypeInput!
    $condition: ModelDisabilityTypeConditionInput
  ) {
    createDisabilityType(input: $input, condition: $condition) {
      id
      disability_type_id
      disability_type
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateDisabilityType = /* GraphQL */ `
  mutation UpdateDisabilityType(
    $input: UpdateDisabilityTypeInput!
    $condition: ModelDisabilityTypeConditionInput
  ) {
    updateDisabilityType(input: $input, condition: $condition) {
      id
      disability_type_id
      disability_type
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteDisabilityType = /* GraphQL */ `
  mutation DeleteDisabilityType(
    $input: DeleteDisabilityTypeInput!
    $condition: ModelDisabilityTypeConditionInput
  ) {
    deleteDisabilityType(input: $input, condition: $condition) {
      id
      disability_type_id
      disability_type
      description
      createdAt
      updatedAt
    }
  }
`;
export const createPLW = /* GraphQL */ `
  mutation CreatePLW(
    $input: CreatePLWInput!
    $condition: ModelPLWConditionInput
  ) {
    createPLW(input: $input, condition: $condition) {
      id
      plw_id
      member_number
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      date_of_registry
      lead_mother
      name
      father_name
      date_of_birth
      education_level_id
      education_level {
        id
        education_level_id
        education_level
        education_level_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      occupation_type_id
      occupation_type {
        id
        occupation_type_id
        occupation_type
        occupation_type_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      disabled
      disability_type
      disability_type_other_description
      contact_number
      date_of_death
      lost_contact
      birth_histories {
        items {
          id
          birth_history_id
          plw_id
          project_diocese_code
          lmp
          miscarriage_date
          delivered_date
          child_name
          child_gender
          date_of_death
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings_attended {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_attended {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions_recieved {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_attended {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_attended {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePLW = /* GraphQL */ `
  mutation UpdatePLW(
    $input: UpdatePLWInput!
    $condition: ModelPLWConditionInput
  ) {
    updatePLW(input: $input, condition: $condition) {
      id
      plw_id
      member_number
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      date_of_registry
      lead_mother
      name
      father_name
      date_of_birth
      education_level_id
      education_level {
        id
        education_level_id
        education_level
        education_level_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      occupation_type_id
      occupation_type {
        id
        occupation_type_id
        occupation_type
        occupation_type_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      disabled
      disability_type
      disability_type_other_description
      contact_number
      date_of_death
      lost_contact
      birth_histories {
        items {
          id
          birth_history_id
          plw_id
          project_diocese_code
          lmp
          miscarriage_date
          delivered_date
          child_name
          child_gender
          date_of_death
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings_attended {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_attended {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions_recieved {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_attended {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_attended {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePLW = /* GraphQL */ `
  mutation DeletePLW(
    $input: DeletePLWInput!
    $condition: ModelPLWConditionInput
  ) {
    deletePLW(input: $input, condition: $condition) {
      id
      plw_id
      member_number
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      date_of_registry
      lead_mother
      name
      father_name
      date_of_birth
      education_level_id
      education_level {
        id
        education_level_id
        education_level
        education_level_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      occupation_type_id
      occupation_type {
        id
        occupation_type_id
        occupation_type
        occupation_type_mm
        description
        order
        plws {
          nextToken
        }
        createdAt
        updatedAt
      }
      disabled
      disability_type
      disability_type_other_description
      contact_number
      date_of_death
      lost_contact
      birth_histories {
        items {
          id
          birth_history_id
          plw_id
          project_diocese_code
          lmp
          miscarriage_date
          delivered_date
          child_name
          child_gender
          date_of_death
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings_attended {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_facilitated {
        items {
          id
          ng_meeting_id
          project_diocese_code
          neighbor_group_id
          plw_facilitator_id
          meeting_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings_attended {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      distributions_recieved {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      other_activities_attended {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      hg_training_attended {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBirthHistory = /* GraphQL */ `
  mutation CreateBirthHistory(
    $input: CreateBirthHistoryInput!
    $condition: ModelBirthHistoryConditionInput
  ) {
    createBirthHistory(input: $input, condition: $condition) {
      id
      birth_history_id
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      lmp
      miscarriage_date
      delivered_date
      child_name
      child_gender
      date_of_death
      createdAt
      updatedAt
    }
  }
`;
export const updateBirthHistory = /* GraphQL */ `
  mutation UpdateBirthHistory(
    $input: UpdateBirthHistoryInput!
    $condition: ModelBirthHistoryConditionInput
  ) {
    updateBirthHistory(input: $input, condition: $condition) {
      id
      birth_history_id
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      lmp
      miscarriage_date
      delivered_date
      child_name
      child_gender
      date_of_death
      createdAt
      updatedAt
    }
  }
`;
export const deleteBirthHistory = /* GraphQL */ `
  mutation DeleteBirthHistory(
    $input: DeleteBirthHistoryInput!
    $condition: ModelBirthHistoryConditionInput
  ) {
    deleteBirthHistory(input: $input, condition: $condition) {
      id
      birth_history_id
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      lmp
      miscarriage_date
      delivered_date
      child_name
      child_gender
      date_of_death
      createdAt
      updatedAt
    }
  }
`;
export const createNutritionTopic = /* GraphQL */ `
  mutation CreateNutritionTopic(
    $input: CreateNutritionTopicInput!
    $condition: ModelNutritionTopicConditionInput
  ) {
    createNutritionTopic(input: $input, condition: $condition) {
      id
      nutrition_topic_id
      topic_text
      topic_text_myanmar
      description
      projects
      nutrition_messages {
        items {
          id
          nutrition_message_id
          nutrition_topic_id
          message_text
          message_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNutritionTopic = /* GraphQL */ `
  mutation UpdateNutritionTopic(
    $input: UpdateNutritionTopicInput!
    $condition: ModelNutritionTopicConditionInput
  ) {
    updateNutritionTopic(input: $input, condition: $condition) {
      id
      nutrition_topic_id
      topic_text
      topic_text_myanmar
      description
      projects
      nutrition_messages {
        items {
          id
          nutrition_message_id
          nutrition_topic_id
          message_text
          message_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNutritionTopic = /* GraphQL */ `
  mutation DeleteNutritionTopic(
    $input: DeleteNutritionTopicInput!
    $condition: ModelNutritionTopicConditionInput
  ) {
    deleteNutritionTopic(input: $input, condition: $condition) {
      id
      nutrition_topic_id
      topic_text
      topic_text_myanmar
      description
      projects
      nutrition_messages {
        items {
          id
          nutrition_message_id
          nutrition_topic_id
          message_text
          message_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      cg_meetings {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      ng_meetings {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNutritionMessage = /* GraphQL */ `
  mutation CreateNutritionMessage(
    $input: CreateNutritionMessageInput!
    $condition: ModelNutritionMessageConditionInput
  ) {
    createNutritionMessage(input: $input, condition: $condition) {
      id
      nutrition_message_id
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      message_text
      message_text_myanmar
      description
      nutrition_questions {
        items {
          id
          nutrition_question_id
          nutrition_message_id
          question_text
          question_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNutritionMessage = /* GraphQL */ `
  mutation UpdateNutritionMessage(
    $input: UpdateNutritionMessageInput!
    $condition: ModelNutritionMessageConditionInput
  ) {
    updateNutritionMessage(input: $input, condition: $condition) {
      id
      nutrition_message_id
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      message_text
      message_text_myanmar
      description
      nutrition_questions {
        items {
          id
          nutrition_question_id
          nutrition_message_id
          question_text
          question_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNutritionMessage = /* GraphQL */ `
  mutation DeleteNutritionMessage(
    $input: DeleteNutritionMessageInput!
    $condition: ModelNutritionMessageConditionInput
  ) {
    deleteNutritionMessage(input: $input, condition: $condition) {
      id
      nutrition_message_id
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      message_text
      message_text_myanmar
      description
      nutrition_questions {
        items {
          id
          nutrition_question_id
          nutrition_message_id
          question_text
          question_text_myanmar
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNutritionQuestion = /* GraphQL */ `
  mutation CreateNutritionQuestion(
    $input: CreateNutritionQuestionInput!
    $condition: ModelNutritionQuestionConditionInput
  ) {
    createNutritionQuestion(input: $input, condition: $condition) {
      id
      nutrition_question_id
      nutrition_message_id
      nutrition_message {
        id
        nutrition_message_id
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        message_text
        message_text_myanmar
        description
        nutrition_questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question_text
      question_text_myanmar
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateNutritionQuestion = /* GraphQL */ `
  mutation UpdateNutritionQuestion(
    $input: UpdateNutritionQuestionInput!
    $condition: ModelNutritionQuestionConditionInput
  ) {
    updateNutritionQuestion(input: $input, condition: $condition) {
      id
      nutrition_question_id
      nutrition_message_id
      nutrition_message {
        id
        nutrition_message_id
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        message_text
        message_text_myanmar
        description
        nutrition_questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question_text
      question_text_myanmar
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteNutritionQuestion = /* GraphQL */ `
  mutation DeleteNutritionQuestion(
    $input: DeleteNutritionQuestionInput!
    $condition: ModelNutritionQuestionConditionInput
  ) {
    deleteNutritionQuestion(input: $input, condition: $condition) {
      id
      nutrition_question_id
      nutrition_message_id
      nutrition_message {
        id
        nutrition_message_id
        nutrition_topic_id
        nutrition_topic {
          id
          nutrition_topic_id
          topic_text
          topic_text_myanmar
          description
          projects
          createdAt
          updatedAt
        }
        message_text
        message_text_myanmar
        description
        nutrition_questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question_text
      question_text_myanmar
      description
      createdAt
      updatedAt
    }
  }
`;
export const createCGMeeting = /* GraphQL */ `
  mutation CreateCGMeeting(
    $input: CreateCGMeetingInput!
    $condition: ModelCGMeetingConditionInput
  ) {
    createCGMeeting(input: $input, condition: $condition) {
      id
      cg_meeting_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCGMeeting = /* GraphQL */ `
  mutation UpdateCGMeeting(
    $input: UpdateCGMeetingInput!
    $condition: ModelCGMeetingConditionInput
  ) {
    updateCGMeeting(input: $input, condition: $condition) {
      id
      cg_meeting_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCGMeeting = /* GraphQL */ `
  mutation DeleteCGMeeting(
    $input: DeleteCGMeetingInput!
    $condition: ModelCGMeetingConditionInput
  ) {
    deleteCGMeeting(input: $input, condition: $condition) {
      id
      cg_meeting_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          cg_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          cg_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          cg_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCGMeetingNutritionTopic = /* GraphQL */ `
  mutation CreateCGMeetingNutritionTopic(
    $input: CreateCGMeetingNutritionTopicInput!
    $condition: ModelCGMeetingNutritionTopicConditionInput
  ) {
    createCGMeetingNutritionTopic(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateCGMeetingNutritionTopic = /* GraphQL */ `
  mutation UpdateCGMeetingNutritionTopic(
    $input: UpdateCGMeetingNutritionTopicInput!
    $condition: ModelCGMeetingNutritionTopicConditionInput
  ) {
    updateCGMeetingNutritionTopic(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteCGMeetingNutritionTopic = /* GraphQL */ `
  mutation DeleteCGMeetingNutritionTopic(
    $input: DeleteCGMeetingNutritionTopicInput!
    $condition: ModelCGMeetingNutritionTopicConditionInput
  ) {
    deleteCGMeetingNutritionTopic(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createCGMeetingStaff = /* GraphQL */ `
  mutation CreateCGMeetingStaff(
    $input: CreateCGMeetingStaffInput!
    $condition: ModelCGMeetingStaffConditionInput
  ) {
    createCGMeetingStaff(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateCGMeetingStaff = /* GraphQL */ `
  mutation UpdateCGMeetingStaff(
    $input: UpdateCGMeetingStaffInput!
    $condition: ModelCGMeetingStaffConditionInput
  ) {
    updateCGMeetingStaff(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteCGMeetingStaff = /* GraphQL */ `
  mutation DeleteCGMeetingStaff(
    $input: DeleteCGMeetingStaffInput!
    $condition: ModelCGMeetingStaffConditionInput
  ) {
    deleteCGMeetingStaff(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createCGMeetingPLW = /* GraphQL */ `
  mutation CreateCGMeetingPLW(
    $input: CreateCGMeetingPLWInput!
    $condition: ModelCGMeetingPLWConditionInput
  ) {
    createCGMeetingPLW(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateCGMeetingPLW = /* GraphQL */ `
  mutation UpdateCGMeetingPLW(
    $input: UpdateCGMeetingPLWInput!
    $condition: ModelCGMeetingPLWConditionInput
  ) {
    updateCGMeetingPLW(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteCGMeetingPLW = /* GraphQL */ `
  mutation DeleteCGMeetingPLW(
    $input: DeleteCGMeetingPLWInput!
    $condition: ModelCGMeetingPLWConditionInput
  ) {
    deleteCGMeetingPLW(input: $input, condition: $condition) {
      id
      cg_meeting_id
      cg_meeting {
        id
        cg_meeting_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createNGMeeting = /* GraphQL */ `
  mutation CreateNGMeeting(
    $input: CreateNGMeetingInput!
    $condition: ModelNGMeetingConditionInput
  ) {
    createNGMeeting(input: $input, condition: $condition) {
      id
      ng_meeting_id
      project_diocese_code
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_facilitator_id
      plw_facilitator {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      staff_facilitators {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNGMeeting = /* GraphQL */ `
  mutation UpdateNGMeeting(
    $input: UpdateNGMeetingInput!
    $condition: ModelNGMeetingConditionInput
  ) {
    updateNGMeeting(input: $input, condition: $condition) {
      id
      ng_meeting_id
      project_diocese_code
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_facilitator_id
      plw_facilitator {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      staff_facilitators {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNGMeeting = /* GraphQL */ `
  mutation DeleteNGMeeting(
    $input: DeleteNGMeetingInput!
    $condition: ModelNGMeetingConditionInput
  ) {
    deleteNGMeeting(input: $input, condition: $condition) {
      id
      ng_meeting_id
      project_diocese_code
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_facilitator_id
      plw_facilitator {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      meeting_date
      description
      topics {
        items {
          id
          ng_meeting_id
          nutrition_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      staff_facilitators {
        items {
          id
          ng_meeting_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          ng_meeting_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNGMeetingNutritionTopic = /* GraphQL */ `
  mutation CreateNGMeetingNutritionTopic(
    $input: CreateNGMeetingNutritionTopicInput!
    $condition: ModelNGMeetingNutritionTopicConditionInput
  ) {
    createNGMeetingNutritionTopic(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateNGMeetingNutritionTopic = /* GraphQL */ `
  mutation UpdateNGMeetingNutritionTopic(
    $input: UpdateNGMeetingNutritionTopicInput!
    $condition: ModelNGMeetingNutritionTopicConditionInput
  ) {
    updateNGMeetingNutritionTopic(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteNGMeetingNutritionTopic = /* GraphQL */ `
  mutation DeleteNGMeetingNutritionTopic(
    $input: DeleteNGMeetingNutritionTopicInput!
    $condition: ModelNGMeetingNutritionTopicConditionInput
  ) {
    deleteNGMeetingNutritionTopic(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      nutrition_topic_id
      nutrition_topic {
        id
        nutrition_topic_id
        topic_text
        topic_text_myanmar
        description
        projects
        nutrition_messages {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createNGMeetingStaffFacilitator = /* GraphQL */ `
  mutation CreateNGMeetingStaffFacilitator(
    $input: CreateNGMeetingStaffFacilitatorInput!
    $condition: ModelNGMeetingStaffFacilitatorConditionInput
  ) {
    createNGMeetingStaffFacilitator(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateNGMeetingStaffFacilitator = /* GraphQL */ `
  mutation UpdateNGMeetingStaffFacilitator(
    $input: UpdateNGMeetingStaffFacilitatorInput!
    $condition: ModelNGMeetingStaffFacilitatorConditionInput
  ) {
    updateNGMeetingStaffFacilitator(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteNGMeetingStaffFacilitator = /* GraphQL */ `
  mutation DeleteNGMeetingStaffFacilitator(
    $input: DeleteNGMeetingStaffFacilitatorInput!
    $condition: ModelNGMeetingStaffFacilitatorConditionInput
  ) {
    deleteNGMeetingStaffFacilitator(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createNGMeetingPLW = /* GraphQL */ `
  mutation CreateNGMeetingPLW(
    $input: CreateNGMeetingPLWInput!
    $condition: ModelNGMeetingPLWConditionInput
  ) {
    createNGMeetingPLW(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateNGMeetingPLW = /* GraphQL */ `
  mutation UpdateNGMeetingPLW(
    $input: UpdateNGMeetingPLWInput!
    $condition: ModelNGMeetingPLWConditionInput
  ) {
    updateNGMeetingPLW(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteNGMeetingPLW = /* GraphQL */ `
  mutation DeleteNGMeetingPLW(
    $input: DeleteNGMeetingPLWInput!
    $condition: ModelNGMeetingPLWConditionInput
  ) {
    deleteNGMeetingPLW(input: $input, condition: $condition) {
      id
      ng_meeting_id
      ng_meeting {
        id
        ng_meeting_id
        project_diocese_code
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        plw_facilitator_id
        plw_facilitator {
          id
          plw_id
          member_number
          neighbor_group_id
          project_diocese_code
          date_of_registry
          lead_mother
          name
          father_name
          date_of_birth
          education_level_id
          occupation_type_id
          disabled
          disability_type
          disability_type_other_description
          contact_number
          date_of_death
          lost_contact
          createdAt
          updatedAt
        }
        meeting_date
        description
        topics {
          nextToken
        }
        staff_facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createDistributionItem = /* GraphQL */ `
  mutation CreateDistributionItem(
    $input: CreateDistributionItemInput!
    $condition: ModelDistributionItemConditionInput
  ) {
    createDistributionItem(input: $input, condition: $condition) {
      id
      distribution_item_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      title
      description
      planned_distribution_start_date
      planned_distribution_end_date
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDistributionItem = /* GraphQL */ `
  mutation UpdateDistributionItem(
    $input: UpdateDistributionItemInput!
    $condition: ModelDistributionItemConditionInput
  ) {
    updateDistributionItem(input: $input, condition: $condition) {
      id
      distribution_item_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      title
      description
      planned_distribution_start_date
      planned_distribution_end_date
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDistributionItem = /* GraphQL */ `
  mutation DeleteDistributionItem(
    $input: DeleteDistributionItemInput!
    $condition: ModelDistributionItemConditionInput
  ) {
    deleteDistributionItem(input: $input, condition: $condition) {
      id
      distribution_item_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      title
      description
      planned_distribution_start_date
      planned_distribution_end_date
      distributions {
        items {
          id
          distribution_id
          project_diocese_code
          distribution_item_id
          neighbor_group_id
          distribution_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDistribution = /* GraphQL */ `
  mutation CreateDistribution(
    $input: CreateDistributionInput!
    $condition: ModelDistributionConditionInput
  ) {
    createDistribution(input: $input, condition: $condition) {
      id
      distribution_id
      project_diocese_code
      distribution_item_id
      distribution_item {
        id
        distribution_item_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      distribution_date
      description
      recipients {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDistribution = /* GraphQL */ `
  mutation UpdateDistribution(
    $input: UpdateDistributionInput!
    $condition: ModelDistributionConditionInput
  ) {
    updateDistribution(input: $input, condition: $condition) {
      id
      distribution_id
      project_diocese_code
      distribution_item_id
      distribution_item {
        id
        distribution_item_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      distribution_date
      description
      recipients {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDistribution = /* GraphQL */ `
  mutation DeleteDistribution(
    $input: DeleteDistributionInput!
    $condition: ModelDistributionConditionInput
  ) {
    deleteDistribution(input: $input, condition: $condition) {
      id
      distribution_id
      project_diocese_code
      distribution_item_id
      distribution_item {
        id
        distribution_item_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        title
        description
        planned_distribution_start_date
        planned_distribution_end_date
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      neighbor_group_id
      neighbor_group {
        id
        neighbor_group_id
        group_number
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        village_id
        village {
          id
          village_pcode
          villageTract_id
          name
          name_mm
          alt_name
          alt_name_mm
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        camp_id
        camp {
          id
          camp_id
          township_id
          name
          name_mm
          description
          longitude
          latitude
          diocese_code
          active
          createdAt
          updatedAt
        }
        plws {
          nextToken
        }
        ng_meetings {
          nextToken
        }
        distributions {
          nextToken
        }
        createdAt
        updatedAt
      }
      distribution_date
      description
      recipients {
        items {
          id
          distribution_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDistributionRecipients = /* GraphQL */ `
  mutation CreateDistributionRecipients(
    $input: CreateDistributionRecipientsInput!
    $condition: ModelDistributionRecipientsConditionInput
  ) {
    createDistributionRecipients(input: $input, condition: $condition) {
      id
      distribution_id
      distribution {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateDistributionRecipients = /* GraphQL */ `
  mutation UpdateDistributionRecipients(
    $input: UpdateDistributionRecipientsInput!
    $condition: ModelDistributionRecipientsConditionInput
  ) {
    updateDistributionRecipients(input: $input, condition: $condition) {
      id
      distribution_id
      distribution {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteDistributionRecipients = /* GraphQL */ `
  mutation DeleteDistributionRecipients(
    $input: DeleteDistributionRecipientsInput!
    $condition: ModelDistributionRecipientsConditionInput
  ) {
    deleteDistributionRecipients(input: $input, condition: $condition) {
      id
      distribution_id
      distribution {
        id
        distribution_id
        project_diocese_code
        distribution_item_id
        distribution_item {
          id
          distribution_item_id
          project_id
          project_code
          title
          description
          planned_distribution_start_date
          planned_distribution_end_date
          createdAt
          updatedAt
        }
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        distribution_date
        description
        recipients {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createOtherActivityType = /* GraphQL */ `
  mutation CreateOtherActivityType(
    $input: CreateOtherActivityTypeInput!
    $condition: ModelOtherActivityTypeConditionInput
  ) {
    createOtherActivityType(input: $input, condition: $condition) {
      id
      other_activity_type_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      activity_name
      description
      activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOtherActivityType = /* GraphQL */ `
  mutation UpdateOtherActivityType(
    $input: UpdateOtherActivityTypeInput!
    $condition: ModelOtherActivityTypeConditionInput
  ) {
    updateOtherActivityType(input: $input, condition: $condition) {
      id
      other_activity_type_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      activity_name
      description
      activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOtherActivityType = /* GraphQL */ `
  mutation DeleteOtherActivityType(
    $input: DeleteOtherActivityTypeInput!
    $condition: ModelOtherActivityTypeConditionInput
  ) {
    deleteOtherActivityType(input: $input, condition: $condition) {
      id
      other_activity_type_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      activity_name
      description
      activities {
        items {
          id
          other_activity_id
          project_diocese_code
          care_group_id
          other_activity_type_id
          activity_date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOtherActivity = /* GraphQL */ `
  mutation CreateOtherActivity(
    $input: CreateOtherActivityInput!
    $condition: ModelOtherActivityConditionInput
  ) {
    createOtherActivity(input: $input, condition: $condition) {
      id
      other_activity_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      other_activity_type_id
      other_activity_type {
        id
        other_activity_type_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        activity_name
        description
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      activity_date
      description
      facilitators {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOtherActivity = /* GraphQL */ `
  mutation UpdateOtherActivity(
    $input: UpdateOtherActivityInput!
    $condition: ModelOtherActivityConditionInput
  ) {
    updateOtherActivity(input: $input, condition: $condition) {
      id
      other_activity_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      other_activity_type_id
      other_activity_type {
        id
        other_activity_type_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        activity_name
        description
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      activity_date
      description
      facilitators {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOtherActivity = /* GraphQL */ `
  mutation DeleteOtherActivity(
    $input: DeleteOtherActivityInput!
    $condition: ModelOtherActivityConditionInput
  ) {
    deleteOtherActivity(input: $input, condition: $condition) {
      id
      other_activity_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      other_activity_type_id
      other_activity_type {
        id
        other_activity_type_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        activity_name
        description
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      activity_date
      description
      facilitators {
        items {
          id
          other_activity_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          other_activity_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOtherActivityFacilitators = /* GraphQL */ `
  mutation CreateOtherActivityFacilitators(
    $input: CreateOtherActivityFacilitatorsInput!
    $condition: ModelOtherActivityFacilitatorsConditionInput
  ) {
    createOtherActivityFacilitators(input: $input, condition: $condition) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateOtherActivityFacilitators = /* GraphQL */ `
  mutation UpdateOtherActivityFacilitators(
    $input: UpdateOtherActivityFacilitatorsInput!
    $condition: ModelOtherActivityFacilitatorsConditionInput
  ) {
    updateOtherActivityFacilitators(input: $input, condition: $condition) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteOtherActivityFacilitators = /* GraphQL */ `
  mutation DeleteOtherActivityFacilitators(
    $input: DeleteOtherActivityFacilitatorsInput!
    $condition: ModelOtherActivityFacilitatorsConditionInput
  ) {
    deleteOtherActivityFacilitators(input: $input, condition: $condition) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createOtherActivityParticipants = /* GraphQL */ `
  mutation CreateOtherActivityParticipants(
    $input: CreateOtherActivityParticipantsInput!
    $condition: ModelOtherActivityParticipantsConditionInput
  ) {
    createOtherActivityParticipants(input: $input, condition: $condition) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateOtherActivityParticipants = /* GraphQL */ `
  mutation UpdateOtherActivityParticipants(
    $input: UpdateOtherActivityParticipantsInput!
    $condition: ModelOtherActivityParticipantsConditionInput
  ) {
    updateOtherActivityParticipants(input: $input, condition: $condition) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteOtherActivityParticipants = /* GraphQL */ `
  mutation DeleteOtherActivityParticipants(
    $input: DeleteOtherActivityParticipantsInput!
    $condition: ModelOtherActivityParticipantsConditionInput
  ) {
    deleteOtherActivityParticipants(input: $input, condition: $condition) {
      id
      other_activity_id
      other_activity {
        id
        other_activity_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        other_activity_type_id
        other_activity_type {
          id
          other_activity_type_id
          project_id
          project_code
          activity_name
          description
          createdAt
          updatedAt
        }
        activity_date
        description
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createHgTrainingTopic = /* GraphQL */ `
  mutation CreateHgTrainingTopic(
    $input: CreateHgTrainingTopicInput!
    $condition: ModelHgTrainingTopicConditionInput
  ) {
    createHgTrainingTopic(input: $input, condition: $condition) {
      id
      hg_training_topic_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      topic
      description
      training {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHgTrainingTopic = /* GraphQL */ `
  mutation UpdateHgTrainingTopic(
    $input: UpdateHgTrainingTopicInput!
    $condition: ModelHgTrainingTopicConditionInput
  ) {
    updateHgTrainingTopic(input: $input, condition: $condition) {
      id
      hg_training_topic_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      topic
      description
      training {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHgTrainingTopic = /* GraphQL */ `
  mutation DeleteHgTrainingTopic(
    $input: DeleteHgTrainingTopicInput!
    $condition: ModelHgTrainingTopicConditionInput
  ) {
    deleteHgTrainingTopic(input: $input, condition: $condition) {
      id
      hg_training_topic_id
      project_id
      project {
        id
        project_id
        project_code
        name
        sector
        description
        donor
        start_date
        end_date
        project_dioceses {
          nextToken
        }
        distribution_items {
          nextToken
        }
        other_activitie_types {
          nextToken
        }
        hg_training_topics {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_code
      topic
      description
      training {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHgTraining = /* GraphQL */ `
  mutation CreateHgTraining(
    $input: CreateHgTrainingInput!
    $condition: ModelHgTrainingConditionInput
  ) {
    createHgTraining(input: $input, condition: $condition) {
      id
      hg_training_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      training_date
      description
      topics {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHgTraining = /* GraphQL */ `
  mutation UpdateHgTraining(
    $input: UpdateHgTrainingInput!
    $condition: ModelHgTrainingConditionInput
  ) {
    updateHgTraining(input: $input, condition: $condition) {
      id
      hg_training_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      training_date
      description
      topics {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHgTraining = /* GraphQL */ `
  mutation DeleteHgTraining(
    $input: DeleteHgTrainingInput!
    $condition: ModelHgTrainingConditionInput
  ) {
    deleteHgTraining(input: $input, condition: $condition) {
      id
      hg_training_id
      project_diocese_code
      care_group_id
      care_group {
        id
        care_group_id
        group_number
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        project_diocese_code
        township_id
        township {
          id
          ts_pcode
          d_pcode
          sr_pcode
          name
          name_mm
          diocese_id
          diocese_code
          active
          createdAt
          updatedAt
        }
        staff_caregroups {
          nextToken
        }
        neighbor_groups {
          nextToken
        }
        cg_meetings {
          nextToken
        }
        other_activities {
          nextToken
        }
        hg_trainings {
          nextToken
        }
        createdAt
        updatedAt
      }
      training_date
      description
      topics {
        items {
          id
          hg_training_id
          hg_training_topic_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      facilitators {
        items {
          id
          hg_training_id
          staff_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      participants {
        items {
          id
          hg_training_id
          plw_id
          project_diocese_code
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHgTrainingTrainingTopic = /* GraphQL */ `
  mutation CreateHgTrainingTrainingTopic(
    $input: CreateHgTrainingTrainingTopicInput!
    $condition: ModelHgTrainingTrainingTopicConditionInput
  ) {
    createHgTrainingTrainingTopic(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      hg_training_topic_id
      hg_training_topic {
        id
        hg_training_topic_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        topic
        description
        training {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateHgTrainingTrainingTopic = /* GraphQL */ `
  mutation UpdateHgTrainingTrainingTopic(
    $input: UpdateHgTrainingTrainingTopicInput!
    $condition: ModelHgTrainingTrainingTopicConditionInput
  ) {
    updateHgTrainingTrainingTopic(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      hg_training_topic_id
      hg_training_topic {
        id
        hg_training_topic_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        topic
        description
        training {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteHgTrainingTrainingTopic = /* GraphQL */ `
  mutation DeleteHgTrainingTrainingTopic(
    $input: DeleteHgTrainingTrainingTopicInput!
    $condition: ModelHgTrainingTrainingTopicConditionInput
  ) {
    deleteHgTrainingTrainingTopic(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      hg_training_topic_id
      hg_training_topic {
        id
        hg_training_topic_id
        project_id
        project {
          id
          project_id
          project_code
          name
          sector
          description
          donor
          start_date
          end_date
          createdAt
          updatedAt
        }
        project_code
        topic
        description
        training {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createHgTrainingFacilitators = /* GraphQL */ `
  mutation CreateHgTrainingFacilitators(
    $input: CreateHgTrainingFacilitatorsInput!
    $condition: ModelHgTrainingFacilitatorsConditionInput
  ) {
    createHgTrainingFacilitators(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateHgTrainingFacilitators = /* GraphQL */ `
  mutation UpdateHgTrainingFacilitators(
    $input: UpdateHgTrainingFacilitatorsInput!
    $condition: ModelHgTrainingFacilitatorsConditionInput
  ) {
    updateHgTrainingFacilitators(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteHgTrainingFacilitators = /* GraphQL */ `
  mutation DeleteHgTrainingFacilitators(
    $input: DeleteHgTrainingFacilitatorsInput!
    $condition: ModelHgTrainingFacilitatorsConditionInput
  ) {
    deleteHgTrainingFacilitators(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff_id
      staff {
        id
        staff_id
        project_diocese_id
        project_diocese {
          id
          project_diocese_id
          projectID
          dioceseID
          project_diocese_code
          createdAt
          updatedAt
        }
        position_id
        position {
          id
          position_id
          name
          level
          description
          createdAt
          updatedAt
        }
        staff_caregroup {
          nextToken
        }
        current_position
        from_date
        to_date
        email
        first_name
        last_name
        gender
        is_staff
        is_active
        date_joined
        cg_meetings_facilitated {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        other_activities_facilitated {
          nextToken
        }
        hg_trainings_facilitated {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const createHgTrainingParticipants = /* GraphQL */ `
  mutation CreateHgTrainingParticipants(
    $input: CreateHgTrainingParticipantsInput!
    $condition: ModelHgTrainingParticipantsConditionInput
  ) {
    createHgTrainingParticipants(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const updateHgTrainingParticipants = /* GraphQL */ `
  mutation UpdateHgTrainingParticipants(
    $input: UpdateHgTrainingParticipantsInput!
    $condition: ModelHgTrainingParticipantsConditionInput
  ) {
    updateHgTrainingParticipants(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteHgTrainingParticipants = /* GraphQL */ `
  mutation DeleteHgTrainingParticipants(
    $input: DeleteHgTrainingParticipantsInput!
    $condition: ModelHgTrainingParticipantsConditionInput
  ) {
    deleteHgTrainingParticipants(input: $input, condition: $condition) {
      id
      hg_training_id
      hg_training {
        id
        hg_training_id
        project_diocese_code
        care_group_id
        care_group {
          id
          care_group_id
          group_number
          project_diocese_id
          project_diocese_code
          township_id
          createdAt
          updatedAt
        }
        training_date
        description
        topics {
          nextToken
        }
        facilitators {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      plw_id
      plw {
        id
        plw_id
        member_number
        neighbor_group_id
        neighbor_group {
          id
          neighbor_group_id
          group_number
          project_diocese_code
          care_group_id
          village_id
          camp_id
          createdAt
          updatedAt
        }
        project_diocese_code
        date_of_registry
        lead_mother
        name
        father_name
        date_of_birth
        education_level_id
        education_level {
          id
          education_level_id
          education_level
          education_level_mm
          description
          order
          createdAt
          updatedAt
        }
        occupation_type_id
        occupation_type {
          id
          occupation_type_id
          occupation_type
          occupation_type_mm
          description
          order
          createdAt
          updatedAt
        }
        disabled
        disability_type
        disability_type_other_description
        contact_number
        date_of_death
        lost_contact
        birth_histories {
          nextToken
        }
        cg_meetings_attended {
          nextToken
        }
        ng_meetings_facilitated {
          nextToken
        }
        ng_meetings_attended {
          nextToken
        }
        distributions_recieved {
          nextToken
        }
        other_activities_attended {
          nextToken
        }
        hg_training_attended {
          nextToken
        }
        createdAt
        updatedAt
      }
      project_diocese_code
      createdAt
      updatedAt
    }
  }
`;

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { ListDioceseCustomQuery } from '../API';
import * as queries from '../graphql/custom_queries';

const listDioceses = async () => {
  const result = (await API.graphql({
    query: queries.listDioceseCustom,
  })) as GraphQLResult<ListDioceseCustomQuery>;
  return result;
};

// const onSuccess = () => {
//   console.log('Successful in fetching dioceses');
// };

// const onError = () => {
//   console.log('Error in fetching dioceses');
// };

export const useListDioceses = () => {
  return useQuery(['listDioceses'], listDioceses, {
    select: (data) => {
      if (data.data && data.data.listDiocese) {
        return data.data.listDiocese.items;
      } else {
        return [];
      }
    },
    // staleTime: 1000 * 60 * 60 * 24, // 24 hours
    // cacheTime: 1000 * 60 * 60 * 8, // 8 hrs
  });
};

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { ListNutritionTopicsQuery } from '../API';
import * as queries from '../graphql/queries';
import { compact } from 'lodash';

export const useListNutritionTopics = () => {
  return useQuery(
    ['listNutritionTopics'],
    () =>
      API.graphql({
        query: queries.listNutritionTopics,
      }) as Promise<GraphQLResult<ListNutritionTopicsQuery>>,
    {
      select: (data) => {
        let nutrition_topics: NonNullable<
          NonNullable<ListNutritionTopicsQuery['listNutritionTopics']>['items'][number]
        >[] = [];
        if (data.data?.listNutritionTopics) {
          nutrition_topics = compact(data.data.listNutritionTopics.items).sort((a, b) => {
            const topic_text_a = a.topic_text.split('.')[0];
            const topic_text_b = b.topic_text.split('.')[0];
            const a_position = parseInt(topic_text_a);
            const b_position = parseInt(topic_text_b);
            if (isNaN(a_position) || isNaN(b_position)) {
              return topic_text_a.localeCompare(topic_text_b);
            } else {
              return a_position - b_position;
            }
          });
        }
        return nutrition_topics;
      },
    }
  );
};

import saveAs from 'file-saver';
import moment from 'moment';
import { TableFilterType } from '../Activities/common_types';
import { ExtendedCareGroupType, ExtendedPLWType, NeighborGroupType, StaffType } from './CareGroups';
import ExcelJS from 'exceljs';
import { CareGroupFilterType } from '../../redux/caregroupsSlice';

export const exportToExcel = async (
  filteredCareGroups: ExtendedCareGroupType[],
  careGroupFilters: Record<CareGroupFilterType, (boolean | string | number)[] | null>,
  staffFilter: Array<TableFilterType>,
  baseDate: string
) => {
  const workbook = new ExcelJS.Workbook();

  const projectNameSet = new Set<string>();
  const dioceseNameSet = new Set<string>();
  const villageMap = new Map<string, { village: string; villageTract: string; township: string }>();
  const campMap = new Map<string, { camp: string; township: string }>();
  const staffMap = new Map<string, StaffType & { diocese: string; project: string }>();

  const base_date = moment(baseDate);

  const careGroupSheet = workbook.addWorksheet('Care Groups');
  careGroupSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Township', key: 'township', width: 15 },
    { header: 'Assigned Staff', key: 'assigned_staff', width: 20 },
    { header: 'Group Number', key: 'group_number', width: 20 },
    { header: 'Neighbor Groups', key: 'neighbor_groups', width: 20 },
    { header: 'PLWs', key: 'plw_count', width: 15 },
    { header: 'LC PLWs', key: 'lost_contact_plw_count', width: 15 },
    { header: 'Pregnancy', key: 'pregnancy_count', width: 15 },
    { header: 'LC Pregnancy', key: 'lost_contact_pregnancy_count', width: 15 },
    { header: 'Overdue', key: 'overdue_pregnancy_count', width: 15 },
    { header: 'LC Overdue', key: 'lost_contact_overdue_pregnancy_count', width: 15 },
    { header: 'U6M Mothers', key: 'u6m_count', width: 15 },
    { header: 'LC U6M Mohters', key: 'lost_contact_u6m_count', width: 15 },
    { header: 'U2Y Mothers', key: 'u2y_count', width: 15 },
    { header: 'LC U2Y Mohters', key: 'lost_contact_u2y_count', width: 15 },
  ];
  careGroupSheet.getColumn(5).alignment = { horizontal: 'center' };
  careGroupSheet.getCell(1, 5).alignment = { horizontal: 'left' };
  careGroupSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  ///////////////////////////////////////////////////////
  const neighborGroupSheet = workbook.addWorksheet('Neighbor Groups');
  neighborGroupSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Township', key: 'township', width: 15 },
    { header: 'Village Tract', key: 'village_tract', width: 20 },
    { header: 'Village', key: 'village', width: 20 },
    { header: 'Camp', key: 'camp', width: 15 },
    { header: 'Assigned Staff', key: 'assigned_staff', width: 22 },
    { header: 'Care Group', key: 'care_group_number', width: 15 },
    { header: 'NG Number', key: 'neighbor_group_number', width: 15 },
    { header: 'Lead Mother', key: 'lead_mother_name', width: 20 },
    { header: 'LM Number', key: 'lead_mother_number', width: 15 },
    { header: 'PLWs', key: 'plw_count', width: 15 },
    { header: 'LC PLWs', key: 'lost_contact_plw_count', width: 15 },
    { header: 'Pregnancy', key: 'pregnancy_count', width: 15 },
    { header: 'LC Pregnancy', key: 'lost_contact_pregnancy_count', width: 15 },
    { header: 'Overdue', key: 'overdue_pregnancy_count', width: 15 },
    { header: 'LC Overdue', key: 'lost_contact_overdue_pregnancy_count', width: 15 },
    { header: 'U6M Mothers', key: 'u6m_count', width: 15 },
    { header: 'LC U6M Mohters', key: 'lost_contact_u6m_count', width: 15 },
    { header: 'U2Y Mothers', key: 'u2y_count', width: 15 },
    { header: 'LC U2Y Mohters', key: 'lost_contact_u2y_count', width: 15 },
  ];
  neighborGroupSheet.getColumn(8).alignment = { horizontal: 'center' };
  neighborGroupSheet.getColumn(9).alignment = { horizontal: 'center' };
  neighborGroupSheet.getColumn(11).alignment = { horizontal: 'center' };
  neighborGroupSheet.getCell(1, 8).alignment = { horizontal: 'left' };
  neighborGroupSheet.getCell(1, 9).alignment = { horizontal: 'left' };
  neighborGroupSheet.getCell(1, 11).alignment = { horizontal: 'left' };
  neighborGroupSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  /////////////////////////////////////////////////

  const plwSheet = workbook.addWorksheet('PLWs');
  plwSheet.columns = [
    { header: 'Project', key: 'project', width: 15 },
    { header: 'Diocese', key: 'diocese', width: 15 },
    { header: 'Township', key: 'township', width: 15 },
    { header: 'Village Tract', key: 'village_tract', width: 20 },
    { header: 'Village', key: 'village', width: 20 },
    { header: 'Camp', key: 'camp', width: 15 },
    { header: 'Assigned Staff', key: 'assigned_staff', width: 22 },
    { header: 'Care Group', key: 'care_group_number', width: 15 },
    { header: 'Neighbor Group', key: 'neighbor_group_number', width: 15 },
    { header: 'PLW Number', key: 'plw_number', width: 15 },
    { header: 'Lead Mother', key: 'lead_mother', width: 15 },
    { header: 'Lost Contact', key: 'lost_contact', width: 15 },
    { header: 'Name', key: 'name', width: 20 },
    { header: 'Father Name', key: 'father_name', width: 20 },
    { header: 'Contact Number', key: 'contact_number', width: 20 },
    { header: 'Date of Registry', key: 'date_of_registry', width: 20 },
    { header: 'Date of Birth', key: 'date_of_birth', width: 20 },
    { header: 'Education', key: 'education', width: 25 },
    { header: 'Occupation', key: 'occupation', width: 25 },
    { header: 'Disabled', key: 'disabled', width: 15 },
    { header: 'Pregnant', key: 'pregnant', width: 15 },
    { header: 'Overdue', key: 'overdue', width: 15 },
    { header: 'U6M Mother', key: 'u6m', width: 15 },
    { header: 'U2Y Mother', key: 'u2y', width: 15 },
  ];
  plwSheet.getColumn(8).alignment = { horizontal: 'center' };
  plwSheet.getColumn(9).alignment = { horizontal: 'center' };
  plwSheet.getColumn(10).alignment = { horizontal: 'center' };
  plwSheet.getCell(1, 8).alignment = { horizontal: 'left' };
  plwSheet.getCell(1, 9).alignment = { horizontal: 'left' };
  plwSheet.getCell(1, 10).alignment = { horizontal: 'left' };
  plwSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  /////////////////////////////////////////////////
  filteredCareGroups.forEach((cg, index) => {
    projectNameSet.add(cg.project_diocese.project.name);
    dioceseNameSet.add(cg.project_diocese.diocese.name);

    const assignedStaff = cg.staff_caregroups?.items.find(
      (scg) => scg?.current_assignment === true
    )?.staff;
    const assignedStaffName = assignedStaff
      ? `${assignedStaff.first_name} ${assignedStaff.last_name} (${assignedStaff.position.name
          .split(' ')
          .map((item) => item[0].toUpperCase())
          .join('')})`
      : '';

    careGroupSheet.addRow({
      project: cg.project_diocese.project.name,
      diocese: cg.project_diocese.diocese.name,
      township: cg.township.name,
      group_number: cg.group_number,
      assigned_staff: assignedStaffName,
      neighbor_groups: cg.ng_count,
      plw_count: cg.plw_count,
      lost_contact_plw_count: cg.lost_contact_plw_count,
      pregnancy_count: cg.pregnancy_count,
      lost_contact_pregnancy_count: cg.lost_contact_pregnancy_count,
      overdue_pregnancy_count: cg.overdue_pregnancy_count,
      lost_contact_overdue_pregnancy_count: cg.lost_contact_overdue_pregnancy_count,
      u6m_count: cg.u6m_count,
      lost_contact_u6m_count: cg.lost_contact_u6m_count,
      u2y_count: cg.u2y_count,
      lost_contact_u2y_count: cg.lost_contact_u2y_count,
    });

    if (cg.neighbor_groups) {
      cg.neighbor_groups.items.forEach((ng) => {
        if (ng) {
          const leadMother = ng.plws?.items.find((plw) => plw?.lead_mother === true);

          if (ng.village) {
            villageMap.set(ng.village.id, {
              village: ng.village.name,
              villageTract: ng.village.villageTract.name,
              township: cg.township.name,
            });
          }
          if (ng.camp) {
            campMap.set(ng.camp.id, {
              camp: ng.camp.name,
              township: cg.township.name,
            });
          }

          let plw_count = 0;
          let lost_contact_plw_count = 0;
          let pregnancy_count = 0;
          let lost_contact_pregnancy_count = 0;
          let overdue_pregnancy_count = 0;
          let lost_contact_overdue_pregnancy_count = 0;
          let u6m_count = 0;
          let lost_contact_u6m_count = 0;
          let u2y_count = 0;
          let lost_contact_u2y_count = 0;
          const plwsOfNg: ExtendedPLWType[] = [];

          if (ng.plws) {
            ng.plws.items.forEach((plw) => {
              if (plw) {
                let plwOfNg: ExtendedPLWType = {
                  ...plw,
                  isPregnant: false,
                  has_overdue_pregnancy: false,
                  has_u6m_child: false,
                  has_u2y_child: false,
                };
                if (plw.birth_histories) {
                  plw.birth_histories.items.forEach((bh) => {
                    if (bh) {
                      if (
                        !!bh.lmp &&
                        !bh.miscarriage_date &&
                        !bh.delivered_date &&
                        !bh.date_of_death
                      ) {
                        if (base_date.diff(moment(bh.lmp)) > 0) {
                          plwOfNg.isPregnant = true;
                          if (base_date.diff(moment(bh.lmp), 'days') > 294) {
                            plwOfNg.has_overdue_pregnancy = true;
                          }
                        }
                      }
                      if (!!bh.delivered_date && !bh.date_of_death) {
                        if (base_date.diff(moment(bh.delivered_date)) > 0) {
                          if (base_date.diff(moment(bh.delivered_date), 'days') <= 180) {
                            plwOfNg.has_u6m_child = true;
                          } else if (base_date.diff(moment(bh.delivered_date), 'days') <= 730) {
                            plwOfNg.has_u2y_child = true;
                          }
                        }
                      }
                    }
                  });
                }
                plwsOfNg.push(plwOfNg);

                plwSheet.addRow({
                  project: cg.project_diocese.project.name,
                  diocese: cg.project_diocese.diocese.name,
                  township: cg.township.name,
                  village_tract: ng.village?.villageTract.name || '',
                  village: ng.village?.name || '',
                  camp: ng.camp?.name || '',
                  assigned_staff: assignedStaffName,
                  care_group_number: cg.group_number,
                  neighbor_group_number: `${cg.group_number}.${ng.group_number}`,
                  plw_number: `${cg.group_number}.${ng.group_number}.${plwOfNg.member_number}`,
                  lead_mother: plw.lead_mother === true ? 'TRUE' : '',
                  lost_contact: plw.lost_contact === true ? 'TRUE' : '',
                  name: plw.name,
                  father_name: plw.father_name || '',
                  contact_number: plw.contact_number || '',
                  date_of_registry: plw.date_of_registry || '',
                  date_of_birth: plw.date_of_birth || '',
                  education: plw.education_level?.education_level || '',
                  occupation: plw.occupation_type?.occupation_type || '',
                  disabled: plw.disabled === true ? 'TRUE' : '',
                  pregnant: plwOfNg.isPregnant === true ? 'TRUE' : '',
                  overdue: plwOfNg.has_overdue_pregnancy === true ? 'TRUE' : '',
                  u6m: plwOfNg.has_u6m_child === true ? 'TRUE' : '',
                  u2y: plwOfNg.has_u2y_child === true ? 'TRUE' : '',
                });
              }
            });
          }

          plwsOfNg.forEach((plw) => {
            plw_count += 1;
            if (plw.lost_contact) {
              lost_contact_plw_count += 1;
            }
            if (plw.isPregnant) {
              pregnancy_count += 1;
              if (plw.lost_contact) {
                lost_contact_pregnancy_count += 1;
              }
              if (plw.has_overdue_pregnancy) {
                overdue_pregnancy_count += 1;
                if (plw.lost_contact) {
                  lost_contact_overdue_pregnancy_count += 1;
                }
              }
            }
            if (plw.has_u6m_child) {
              u6m_count += 1;
              if (plw.lost_contact) {
                lost_contact_u6m_count += 1;
              }
            }
            if (plw.has_u2y_child) {
              u2y_count += 1;
              if (plw.lost_contact) {
                lost_contact_u2y_count += 1;
              }
            }
          });

          neighborGroupSheet.addRow({
            project: cg.project_diocese.project.name,
            diocese: cg.project_diocese.diocese.name,
            township: cg.township.name,
            village_tract: ng.village?.villageTract.name || '',
            village: ng.village?.name || '',
            camp: ng.camp?.name || '',
            care_group_number: cg.group_number,
            neighbor_group_number: cg.group_number + '.' + ng.group_number,
            assigned_staff: assignedStaffName,
            lead_mother_name: leadMother?.name || '',
            lead_mother_number: leadMother
              ? `${cg.group_number}.${ng.group_number}.${leadMother.member_number}`
              : '',
            plw_count,
            lost_contact_plw_count,
            pregnancy_count,
            lost_contact_pregnancy_count,
            overdue_pregnancy_count,
            lost_contact_overdue_pregnancy_count,
            u6m_count,
            lost_contact_u6m_count,
            u2y_count,
            lost_contact_u2y_count,
          });
        }
      });
    }

    if (cg.staff_caregroups) {
      cg.staff_caregroups.items.forEach((scg) => {
        if (scg && scg.current_assignment === true) {
          staffMap.set(scg.staff.id, {
            ...scg,
            project: cg.project_diocese.project.name,
            diocese: cg.project_diocese.diocese.name,
          });
        }
      });
    }
  });
  /////////////////////////////////////////////////////////////
  const filterSheet = workbook.addWorksheet('Filters');

  filterSheet.getColumn(1).width = 22;
  filterSheet.getColumn(2).width = 50;
  filterSheet.addRow(['Filter Name', 'Filer Value']);
  const lastRow = filterSheet.lastRow!;
  lastRow.eachCell((cell) => {
    cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true };
  });

  Object.keys(careGroupFilters).forEach((filter) => {
    if (
      careGroupFilters[filter as CareGroupFilterType] &&
      careGroupFilters[filter as CareGroupFilterType]!.length
    ) {
      const spaced = filter.replace(/([A-Z])/g, ' $1');
      const filterTitle = spaced.charAt(0).toUpperCase() + spaced.slice(1);
      careGroupFilters[filter as CareGroupFilterType]!.forEach((id, index) => {
        let filterValue: string | number | boolean | undefined;
        if (filter === 'staff') filterValue = staffFilter.find((sf) => sf.value === id)?.text;
        else filterValue = id;
        if (index === 0) {
          filterSheet.addRow([filterTitle, filterValue]);
        } else {
          filterSheet.addRow(['', filterValue]);
        }
      });
    }
  });
  ///////////////////////////////////////////////////////////////
  const staffSheet = workbook.addWorksheet('Staff');
  staffSheet.columns = [
    { header: 'Name', key: 'name', width: 20 },
    { header: 'Position', key: 'position', width: 25 },
    { header: 'Diocese', key: 'diocese', width: 20 },
    { header: 'Project', key: 'project', width: 15 },
  ];
  staffSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...staffMap]
    .sort((a, b) => a[1].staff.first_name!.localeCompare(b[1].staff.first_name!))
    .forEach((v) => {
      staffSheet.addRow({
        name: v[1].staff.first_name + ' ' + v[1].staff.last_name,
        position: v[1].staff.position.name,
        diocese: v[1].diocese,
        project: v[1].project,
      });
    });

  ///////////////////////////////////////////////////////////////
  const villageSheet = workbook.addWorksheet('Villages');
  villageSheet.columns = [
    { header: 'Township', key: 'township', width: 15 },
    { header: 'Village Tract', key: 'village_tract', width: 15 },
    { header: 'Village', key: 'village', width: 15 },
  ];
  villageSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));

  [...villageMap]
    .sort((a, b) => a[1].village.localeCompare(b[1].village))
    .sort((a, b) => a[1].villageTract.localeCompare(b[1].villageTract))
    .sort((a, b) => a[1].township.localeCompare(b[1].township))
    .forEach((v) => {
      villageSheet.addRow({
        township: v[1].township,
        village_tract: v[1].villageTract,
        village: v[1].village,
      });
    });

  ///////////////////////////////////////////////////////////////
  const campSheet = workbook.addWorksheet('Camps');
  campSheet.columns = [
    { header: 'Township', key: 'township', width: 15 },
    { header: 'Camp', key: 'camp', width: 15 },
  ];
  campSheet
    .getRow(1)
    .eachCell((cell) => (cell.font = { name: 'Cambria', color: { argb: '0033CC' }, bold: true }));
  [...campMap]
    .sort((a, b) => a[1].camp.localeCompare(b[1].camp))
    .sort((a, b) => a[1].township.localeCompare(b[1].township))
    .forEach((c) => {
      campSheet.addRow({
        township: c[1].township,
        camp: c[1].camp,
      });
    });

  const baseDateSheet = workbook.addWorksheet('Base Date');
  baseDateSheet.getColumn(1).width = 100;
  baseDateSheet.addRow(['Birth history data is calculated based this date of ' + baseDate + '.']);

  careGroupSheet.autoFilter = {
    from: 'A1',
    to: {
      row: careGroupSheet.rowCount,
      column: careGroupSheet.columnCount,
    },
  };

  neighborGroupSheet.autoFilter = {
    from: 'A1',
    to: {
      row: neighborGroupSheet.rowCount,
      column: neighborGroupSheet.columnCount,
    },
  };

  plwSheet.autoFilter = {
    from: 'A1',
    to: {
      row: plwSheet.rowCount,
      column: plwSheet.columnCount,
    },
  };

  villageSheet.autoFilter = {
    from: 'A1',
    to: {
      row: villageSheet.rowCount,
      column: villageSheet.columnCount,
    },
  };

  campSheet.autoFilter = {
    from: 'A1',
    to: {
      row: campSheet.rowCount,
      column: campSheet.columnCount,
    },
  };

  staffSheet.autoFilter = {
    from: 'A1',
    to: {
      row: staffSheet.rowCount,
      column: staffSheet.columnCount,
    },
  };

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `Care Group Data ${moment().format('YYYY-MM-DD h-mm a')}.xlsx`);
};

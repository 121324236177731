import { Button } from 'antd';
import React, { FC } from 'react';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { NutritionQuestionType } from './NutritionMessages';

import Styles from './NutritionMessages.module.scss';

interface QuestionProps {
  serial: number;
  question: NutritionQuestionType;
  setEditingQuestion: (question: NutritionQuestionType | undefined) => void;
  setAddOrEditQuestion: (value: 'edit') => void;
  user: ReturnType<typeof useGetUserInfo>;
}
const Question: FC<QuestionProps> = ({
  question,
  serial,
  user,
  setEditingQuestion,
  setAddOrEditQuestion,
}) => {
  return (
    <>
      <tr className={Styles.SubQuestionTitle}>
        <td colSpan={2}>
          <strong>Question {serial}</strong>
          <Button
            type='link'
            disabled={!(user.isAdmin && user.isEditor)}
            onClick={() => {
              setEditingQuestion(question);
              setAddOrEditQuestion('edit');
            }}
          >
            edit question
          </Button>
        </td>
      </tr>
      <tr>
        <td className={Styles.SubQuestionTitleColumn}>Question text:</td>
        <td style={{ whiteSpace: 'pre-wrap' }}>{question.question_text}</td>
      </tr>
      <tr>
        <td className={Styles.SubQuestionTitleColumn}>Question text in Myanmar:</td>
        <td style={{ whiteSpace: 'pre-wrap' }}>{question.question_text_myanmar}</td>
      </tr>
      <tr>
        <td className={Styles.SubQuestionTitleColumn}>Description: </td>
        <td style={{ whiteSpace: 'pre-wrap' }}>{question.description}</td>
      </tr>
    </>
  );
};

export default Question;

import { ColumnsType } from 'antd/lib/table';
import React, { FC, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { CareGroupType, NeighborGroupType } from '../NeighborGroups';
import { Typography, Table, Space, Button } from 'antd';
import Styles from '../NeighborGroups.module.scss';
import { compact } from 'lodash';
import NeighborGroupModalForm from './NeighborGroupModalForm';
import { useGetUserInfo } from '../../../hooks/auth_hooks';

const { Link } = Typography;

interface NeighborGroupTableProps {
  isLoading: boolean;
  careGroup: CareGroupType;
  neighborGroups: NeighborGroupType[];
}

const NeighborGroupTable: FC<NeighborGroupTableProps> = ({
  isLoading,
  careGroup,
  neighborGroups,
}) => {
  const selectedMenuKeys = useAppSelector((state) => state.neighborgroups.selectedMenuKeys);

  const [isNeighborGroupModalVisible, setIsNeighborGroupModalVisible] = useState<
    'adding' | 'editing' | false
  >(false);
  const [editingNeighborGroupId, setEditingNeighborGroupId] = useState<string | undefined>();

  const user = useGetUserInfo();

  const columns: ColumnsType<NeighborGroupType> = [
    {
      title: 'Group Number',
      key: 'group_number',
      width: 150,
      align: 'center',
      render: (_, record) => `${careGroup.group_number}.${record.group_number}`,
    },
    {
      title: 'Village Tract',
      key: 'village_tract',
      render: (_, record) => {
        let displayName: string = '';
        if (record.village) {
          displayName = record.village.villageTract.name;
          if (record.village.villageTract.name_mm) {
            displayName = displayName + ` (${record.village.villageTract.name_mm})`;
          }
        }
        return displayName;
      },
    },
    {
      title: 'Village',
      key: 'village',
      render: (_, record) => {
        let displayName: string = '';
        if (record.village) {
          displayName = record.village.name;
          if (record.village.name_mm) {
            displayName = displayName + ` (${record.village.name_mm})`;
          }
        }
        return displayName;
      },
    },
    {
      title: 'Camp',
      key: 'camp',
      render: (_, record) => {
        let displayName: string = '';
        if (record.camp) {
          displayName = record.camp.name;
          if (record.camp.name_mm) {
            displayName = displayName + ` (${record.camp.name_mm})`;
          }
        }
        return displayName;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Link onClick={() => showEditNeighborGroupModal(record.id)} disabled={!user.isEditor}>
            Edit
          </Link>
        );
      },
    },
  ];

  const showAddNeighborGroupModal = () => {
    setEditingNeighborGroupId(undefined);
    setIsNeighborGroupModalVisible('adding');
  };

  const showEditNeighborGroupModal = (editingNeighborGroupId: string) => {
    setEditingNeighborGroupId(editingNeighborGroupId);
    setIsNeighborGroupModalVisible('editing');
  };
  return (
    <div className={Styles.dataTable}>
      <div className={Styles.tableHeaderRow}>
        <div className={Styles.buttonContainer}>
          <Space>
            <Button
              className={Styles.exportButton}
              onClick={showAddNeighborGroupModal}
              disabled={!user.isEditor || isLoading || selectedMenuKeys.selectAll === false}
            >
              Add New Neighbor Group
            </Button>
            {/* <Button
              className={Styles.exportButton}
              // onClick={excelExportHandler}
              disabled={isLoading}
            >
              Export
            </Button> */}
          </Space>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={neighborGroups}
        size='small'
        rowKey={'id'}
        loading={isLoading}
        showSorterTooltip={false}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 50,
          style: { marginRight: 25 },
          showTotal: (total, range) => `Total ${total}`,
        }}
        scroll={{ x: 'calc(100vw - 400px)', y: 'calc(100vh - 350px)' }}
      />
      <NeighborGroupModalForm
        modalVisible={isNeighborGroupModalVisible}
        setModalVisible={setIsNeighborGroupModalVisible}
        editingNeighborGroupId={editingNeighborGroupId}
        careGroup={careGroup}
      />
    </div>
  );
};

export default NeighborGroupTable;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Input, Modal, Space } from 'antd';
import React, { FC, useEffect } from 'react';
import {
  CreateNutritionQuestionInput,
  CreateNutritionQuestionMutation,
  DeleteNutritionQuestionMutation,
  UpdateNutritionQuestionInput,
  UpdateNutritionQuestionMutation,
} from '../../../API';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { NutritionQuestionType } from './NutritionMessages';
import * as mutations from '../../../graphql/mutations';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';

interface QuestionFormProps {
  addOrEdit: 'add' | 'edit' | undefined;
  setAddOrEdit: React.Dispatch<React.SetStateAction<'add' | 'edit' | undefined>>;
  editingQuestion?: NutritionQuestionType | undefined;
  nutritionMessageId?: string;
}

const QuestionForm: FC<QuestionFormProps> = (props) => {
  const { addOrEdit, setAddOrEdit, editingQuestion, nutritionMessageId } = props;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useGetUserInfo();

  useEffect(() => {
    if (addOrEdit === 'edit' && !!editingQuestion) {
      form.setFieldsValue(editingQuestion);
    } else {
      form.resetFields();
    }
  }, [addOrEdit, editingQuestion]);

  // MUTATIONS
  const invalidateQueries = () => {
    queryClient.invalidateQueries(['listNutritionTopics']);
  };
  // CREATE
  const addQuestionMutation = useMutation(
    (input: CreateNutritionQuestionInput) =>
      API.graphql({
        query: mutations.createNutritionQuestion,
        variables: { input: { ...input, nutrition_message_id: nutritionMessageId } },
      }) as Promise<GraphQLResult<CreateNutritionQuestionMutation>>,
    {
      onSuccess: invalidateQueries,
    }
  );
  // UPDATE
  const editQuestionMutation = useMutation(
    (input: UpdateNutritionQuestionInput) => {
      return API.graphql({
        query: mutations.updateNutritionQuestion,
        variables: { input },
      }) as Promise<GraphQLResult<UpdateNutritionQuestionMutation>>;
    },
    {
      onSuccess: invalidateQueries,
    }
  );
  // DELETE
  const deleteQuestionMutation = useMutation(
    (input: { id: string }) => {
      return API.graphql({
        query: mutations.deleteNutritionQuestion,
        variables: { input },
      }) as Promise<GraphQLResult<DeleteNutritionQuestionMutation>>;
    },
    {
      onSuccess: invalidateQueries,
    }
  );

  const addNutritionQuestion = async () => {
    if (!nutritionMessageId) return;
    try {
      const values = await form.validateFields();
      await addQuestionMutation.mutateAsync(values);
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const saveEditedNutritionQuestion = async () => {
    if (!editingQuestion) return;
    try {
      const values = await form.validateFields();
      await editQuestionMutation.mutateAsync({ ...values, id: editingQuestion.id });
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteNutritionQuestion = async () => {
    if (!editingQuestion) return;
    try {
      await deleteQuestionMutation.mutateAsync({ id: editingQuestion.id });
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setAddOrEdit(undefined);
  };

  const customModalFooter = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '2.5rem',
      }}
    >
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type='primary'
          onClick={() => {
            if (addOrEdit === 'add') {
              addNutritionQuestion();
            } else if (addOrEdit === 'edit') {
              saveEditedNutritionQuestion();
            }
          }}
          disabled={!(user.isAdmin && user.isEditor)}
        >
          Save
        </Button>
      </Space>
      {addOrEdit === 'edit' ? (
        <Button
          danger
          onClick={deleteNutritionQuestion}
          disabled={!(user.isAdmin && user.isEditor)}
        >
          Delete
        </Button>
      ) : null}
    </div>
  );

  return (
    <Modal
      open={addOrEdit === 'add' || addOrEdit === 'edit'}
      title={addOrEdit === 'add' ? 'Add a new Nutrition Question' : 'Edit Nutrition Question'}
      footer={customModalFooter}
      closable={false}
      width={800}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} name='questionForm'>
        <Form.Item
          label='Question Text'
          name='question_text'
          rules={[{ required: true, message: 'Question text is required.' }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label='Question Text in Myanmar' name='question_text_myanmar'>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label='Description' name='description'>
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QuestionForm;

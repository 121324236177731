import { useEffect, useMemo, useState } from 'react';
import * as queries from '../../../graphql/custom_queries';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import Styles from './CareGroupMeetings.module.scss';
import {
  CgMeetingByPdCodeCustomQuery,
  PlwByCgMeetingCustomQuery,
  TopicByCgMeetingCustomQuery,
} from '../../../API';
import { Button, Drawer, Menu, Popover, Space, Spin, Table, Typography } from 'antd';
import { compact } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { SyncOutlined } from '@ant-design/icons';
import { exportToExcel } from './excelExporter';
import CGMSummary from './CGMSummary';
import { useGetUserInfo } from '../../../hooks/auth_hooks';
import { TableFilterType } from '../common_types';
import { useListProjects } from '../../../hooks/useListProjects';
import { useListDioceses } from '../../../hooks/useListDioceses';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setSelectedMenuKeys, setCgMeetingFilters, initialState } from '../../../redux/cgmSlice';
import type { CGMFilterType } from '../../../redux/cgmSlice';

import ReactGA from 'react-ga4';

const { Link } = Typography;

export type CGMeetingType = Exclude<
  Exclude<CgMeetingByPdCodeCustomQuery['cgMeetingByPdCode'], null | undefined>['items'][number],
  null
>;

type PlwByCgMeetingType = NonNullable<
  NonNullable<NonNullable<PlwByCgMeetingCustomQuery>['plwByCgMeeting']>['items'][number]
>;

type TopicByCgMeetingType = NonNullable<
  NonNullable<NonNullable<TopicByCgMeetingCustomQuery>['topicByCgMeeting']>['items'][number]
>;

const defaultCgmFilters = initialState.cgMeetingFilters;

const CareGroupMeetings = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/activities/caregroups',
    });
  }, []);
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();

  const [filteredCgMeetings, setFilteredCgMeetings] = useState<CGMeetingType[]>([]);
  // const [cgMeetingFilters, setCgMeetingFilters] = useState<Record<CGMFilterType, (boolean | string | number)[] | null>>({});

  const [cgFilter, setCgFilter] = useState<Array<TableFilterType>>([]);
  const [projectFilter, setProjectFilter] = useState<Array<TableFilterType>>([]);
  const [dioceseFilter, setDioceseFilter] = useState<Array<TableFilterType>>([]);
  const [facilitatorFilter, setFacilitatorFilter] = useState<Array<TableFilterType>>([]);
  const [topicFilter, setTopicFilter] = useState<Array<TableFilterType>>([]);
  const [dateFilter, setDateFilter] = useState<Array<TableFilterType>>([]);

  const [summaryDrawerVisible, setSummaryDrawerVisible] = useState(false);
  const [plwPopover, setPlwPopover] = useState<PlwByCgMeetingType[] | undefined>();
  const [topicPopover, setTopicPopover] = useState<TopicByCgMeetingType[] | undefined>();

  // const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);

  const user = useGetUserInfo();

  const selectedMenuKeys = useAppSelector((state) => state.cgm.selectedMenuKeys);
  const cgMeetingFilters = useAppSelector((state) => state.cgm.cgMeetingFilters);

  const { data: projects } = useListProjects();
  const { data: dioceses } = useListDioceses();

  const getCgMeetings = async (proj_dioc_code: string) => {
    let meetings: CGMeetingType[] = [];
    let nextToken: string | undefined = undefined;
    // setCgMeetings(() => []);
    do {
      const result = (await API.graphql({
        query: queries.cgMeetingByPdCodeCustom,
        variables: {
          limit: 100,
          nextToken,
          project_diocese_code: proj_dioc_code,
        },
      })) as GraphQLResult<CgMeetingByPdCodeCustomQuery>;
      if (result.data && result.data.cgMeetingByPdCode) {
        meetings = [...meetings, ...compact(result.data.cgMeetingByPdCode.items)];
        nextToken = result.data.cgMeetingByPdCode.nextToken || undefined;
      }
    } while (nextToken);
    // setFilteredCgMeetings(meetings);
    return meetings;
  };

  const queryResults = useQueries<CGMeetingType[]>({
    queries: selectedMenuKeys.keys.map((proj_dioc_code) => {
      return {
        queryKey: ['cgMeetings', proj_dioc_code],
        queryFn: () => getCgMeetings(proj_dioc_code),
      };
    }),
  });

  const resultData = useMemo(() => {
    let data: CGMeetingType[] | undefined = undefined;
    const isLoading = queryResults.some((result) => result.isLoading === true);
    const isError = queryResults.some((result) => result.isError === true);
    const isFetching = queryResults.some((result) => result.isFetching === true);
    const error = compact(queryResults.map((result) => result.error as Error));
    const refetch = () => {
      queryResults.forEach((result) => result.refetch());
    };
    if (!isLoading && !isError) {
      data = queryResults.reduce(
        (acc, cur) => [...acc, ...(cur.data as CGMeetingType[])],
        [] as CGMeetingType[]
      );
    }

    return {
      isLoading,
      isError,
      error,
      data,
      refetch,
      isFetching,
    };
  }, [queryResults]);

  useEffect(() => {
    // re-populate filtered records on page re-visit
    let tempCgMeetingList = resultData.data || [];
    Object.keys(cgMeetingFilters).forEach((filteringField) => {
      let indFilterList = cgMeetingFilters[filteringField as CGMFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempCgMeetingList = tempCgMeetingList.filter((meeting) =>
        filterMeeting(filteringField as CGMFilterType, indFilterList!, meeting)
      );
      // this effect is to create filters when a page has been returned from other pages.
      // the filters are to be used in summary and excel exporter
      drawDropDownForFilter(filteringField as CGMFilterType);
    });
    setFilteredCgMeetings(tempCgMeetingList);
  }, []);

  if (resultData.isError) {
    if (resultData.error.length > 0) {
      return <span>Error: {resultData.error.join('. ')} </span>;
    } else {
      return <span>Error in react query.</span>;
    }
  }

  const drawDropDownForFilter = (field: CGMFilterType) => {
    let tempCgMeetingList = resultData.data || []; // to filter the cgMeeting array with all filter parameters except value in field variable
    Object.keys(cgMeetingFilters).forEach((filteringField) => {
      if (filteringField === field) return; // filter except the current column / field

      let indFilterList = cgMeetingFilters[filteringField as CGMFilterType];
      if (!indFilterList) return; // skip field with no filter
      tempCgMeetingList = tempCgMeetingList.filter((meeting) =>
        filterMeeting(filteringField as CGMFilterType, indFilterList!, meeting)
      );
    });
    switch (field) {
      case 'project':
        const projectSet = new Set<string>();
        tempCgMeetingList.forEach((cgm) => {
          projectSet.add(cgm.care_group.project_diocese.project.name);
        });
        const projectFilter: TableFilterType[] = [];
        projectSet.forEach((key) => {
          projectFilter.push({ text: key, value: key });
        });
        setProjectFilter(projectFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'diocese':
        const dioceseSet = new Set<string>();
        tempCgMeetingList.forEach((cgm) => {
          dioceseSet.add(cgm.care_group.project_diocese.diocese.name);
        });
        const diocFilter: TableFilterType[] = [];
        dioceseSet.forEach((key) => {
          diocFilter.push({ text: key, value: key });
        });
        setDioceseFilter(diocFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'meeting_date':
        const dateMap: { [key: string]: { [key: string]: string } } = {};
        tempCgMeetingList.forEach((cgm) => {
          if (cgm.meeting_date) {
            const year = cgm.meeting_date.split('-')[0];
            const yearMonth = cgm.meeting_date.split('-', 2).join('-');
            if (!dateMap[year]) dateMap[year] = {};
            dateMap[year][yearMonth] = yearMonth;
          }
        });

        const dateFilter: TableFilterType[] = [];
        for (const outer in dateMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(dateMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = { text: inner, value: inner };
              outerFilter.children!.push(innerFilter);
            });
          dateFilter.push(outerFilter);
        }
        setDateFilter(dateFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'facilitators':
        const facilitatorMap = new Map<string, string>();
        tempCgMeetingList.forEach((cgm) => {
          if (!cgm.facilitators) return;
          cgm.facilitators.items.forEach((fac) => {
            if (!(fac && fac.staff)) return;
            facilitatorMap.set(
              fac.staff.id,
              `${fac.staff.first_name} ${fac.staff.last_name} : ${fac.staff.position.name
                .match(/[A-Z]/g)
                ?.join('')} : ${cgm.care_group.project_diocese.diocese.name}`
            );
          });
        });
        const facFilter: TableFilterType[] = [];
        for (const [key, value] of facilitatorMap) {
          facFilter.push({ text: value, value: key });
        }
        setFacilitatorFilter(facFilter.sort((a, b) => a.text.localeCompare(b.text)));
        break;
      case 'care_group':
        const cgNumberMap: { [key: string]: { [key: string]: string } } = {};
        tempCgMeetingList.forEach((cgm) => {
          const cgFirstNum = cgm.care_group.group_number.split('.')[0];
          if (!cgNumberMap[cgFirstNum]) cgNumberMap[cgFirstNum] = {};
          cgNumberMap[cgFirstNum][cgm.care_group.group_number] = cgm.care_group.group_number;
        });
        const cgNumberFilter: TableFilterType[] = [];
        for (const outer in cgNumberMap) {
          let outerFilter: TableFilterType = {
            text: outer,
            value: outer,
            children: [],
          };
          Object.keys(cgNumberMap[outer])
            .sort()
            .forEach((inner) => {
              const innerFilter = {
                text: inner,
                value: cgNumberMap[outer][inner],
              };
              outerFilter.children!.push(innerFilter);
            });

          cgNumberFilter.push(outerFilter);
        }
        setCgFilter(cgNumberFilter.sort());
        break;
      case 'topics':
        const topicMap: { [key: string]: string } = {};
        tempCgMeetingList.forEach((cgm) => {
          if (!cgm.topics) return;
          cgm.topics.items.forEach((topic) => {
            if (!(topic && topic.nutrition_topic)) return;
            topicMap[topic.nutrition_topic.id] = topic.nutrition_topic.topic_text;
          });
        });

        const topFilter: TableFilterType[] = [];
        for (const [key, value] of Object.entries(topicMap)) {
          topFilter.push({ text: value, value: key });
        }
        setTopicFilter(
          topFilter.sort((a, b) => {
            let A = parseInt(a.text.split('.')[0]);
            let B = parseInt(b.text.split('.')[0]);
            if (isNaN(A) || isNaN(B)) return 0;
            return A - B;
          })
        );
        break;
      default:
    }
  };

  const filterMeeting = (
    field: CGMFilterType,
    value: string | number | boolean | (string | number | boolean)[],
    record: CGMeetingType
  ): boolean => {
    switch (field) {
      case 'project':
        if (Array.isArray(value)) {
          return value.includes(record.care_group.project_diocese.project.name);
        }
        return record.care_group.project_diocese.project.name === value;
      case 'diocese':
        if (Array.isArray(value)) {
          return value.includes(record.care_group.project_diocese.diocese.name);
        }
        return record.care_group.project_diocese.diocese.name === value;
      case 'meeting_date':
        if (!record.meeting_date) return false;
        if (Array.isArray(value)) {
          const yearMonth = record.meeting_date.split('-', 2).join('-');
          return value.includes(yearMonth);
        }
        return record.meeting_date.split('-', 2).join('-') === value;
      case 'facilitators':
        if (!record.facilitators) return false;
        const facIDSet = new Set(record.facilitators.items.map((fac) => fac?.staff.id));
        if (Array.isArray(value)) {
          return value.some((val) => facIDSet.has(val as string));
        }
        return facIDSet.has(value as string);
      case 'care_group':
        if (Array.isArray(value)) {
          return value.includes(record.care_group.group_number);
        }
        return record.care_group.group_number === value;
      case 'topics':
        if (!record.topics) return false;
        const topicIDSet = new Set(
          record.topics.items.map((item) => {
            if (item && item.nutrition_topic) {
              return item.nutrition_topic.id;
            } else return null;
          })
        );
        if (Array.isArray(value)) {
          return value.some((val) => topicIDSet.has(val as string));
        }
        return topicIDSet.has(value as string);
      default:
        return false;
    }
  };

  const getTopicsByCgMeeting = async (cg_meeting_id: string) => {
    try {
      const data = await queryClient.fetchQuery(['topicByCgMeeting', cg_meeting_id], () => {
        return API.graphql({
          query: queries.topicByCgMeetingCustom,
          variables: {
            cg_meeting_id,
            limit: 100,
          },
        }) as Promise<GraphQLResult<TopicByCgMeetingCustomQuery>>;
      });
      if (data.data?.topicByCgMeeting) {
        setTopicPopover(
          compact(data.data.topicByCgMeeting.items).sort((a, b) => {
            const firstNum = parseInt(a!.nutrition_topic.topic_text.split('.')[0]) || false;
            const secondNum = parseInt(b!.nutrition_topic.topic_text.split('.')[0]) || false;
            if (firstNum && secondNum) {
              return firstNum - secondNum;
            }
            return a!.nutrition_topic.topic_text.localeCompare(b!.nutrition_topic.topic_text);
          })
        );
      } else {
        setTopicPopover([]);
      }
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  const getPlwsByCgMeeting = async (cg_meeting_id: string) => {
    try {
      const data = await queryClient.fetchQuery(['plwByCgMeeting', cg_meeting_id], () => {
        return API.graphql({
          query: queries.plwByCgMeetingCustom,
          variables: {
            cg_meeting_id,
            limit: 100,
          },
        }) as Promise<GraphQLResult<PlwByCgMeetingCustomQuery>>;
      });
      if (data.data?.plwByCgMeeting) {
        setPlwPopover(
          compact(data.data.plwByCgMeeting.items).sort((a, b) => {
            if (a.plw.neighbor_group.group_number === b.plw.neighbor_group.group_number) {
              return a.plw.member_number - b.plw.member_number;
            } else {
              return a.plw.neighbor_group.group_number.localeCompare(
                b.plw.neighbor_group.group_number
              );
            }
          })
        );
      } else {
        setPlwPopover([]);
      }
    } catch (error: any) {
      console.error(error.message || error);
    }
  };

  let columns: ColumnsType<CGMeetingType> = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      ellipsis: true,
      render: (_, record) => {
        return record.care_group.project_diocese.project.name;
      },
      filters: projectFilter,
      filteredValue: cgMeetingFilters['project'] || null,
      onFilter: (value, record) => filterMeeting('project', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('project'),
      sorter: (a, b) => {
        return a.care_group.project_diocese.project.name.localeCompare(
          b.care_group.project_diocese.project.name
        );
      },
    },
    {
      title: 'Diocese',
      dataIndex: 'diocese',
      key: 'diocese',
      render: (_, record) => {
        return record.care_group.project_diocese.diocese.name;
      },
      filters: dioceseFilter,
      filteredValue: (cgMeetingFilters['diocese'] as any) || null,
      // onFilter: (value, record) => record.careGroup.diocese!.name === value,
      onFilter: (value, record) => filterMeeting('diocese', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('diocese'),
      sorter: (a, b) => {
        return a.care_group.project_diocese.diocese.name.localeCompare(
          b.care_group.project_diocese.diocese.name
        );
      },
    },
    {
      title: 'Meeting Date',
      dataIndex: 'meeting_date',
      key: 'meeting_date',

      filters: dateFilter,
      filteredValue: (cgMeetingFilters['meeting_date'] as any) || null,
      // onFilter: (value, record) => record.meeting_date.split('-', 2).join('-') === value,
      onFilter: (value, record) => filterMeeting('meeting_date', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('meeting_date'),
      sorter: (a, b) => a.meeting_date!.localeCompare(b.meeting_date!),
    },
    {
      title: 'Facliltators',
      dataIndex: 'facilitators',
      key: 'facilitators',
      ellipsis: { showTitle: false },
      filters: facilitatorFilter,
      filteredValue: (cgMeetingFilters['facilitators'] as any) || null,
      onFilter: (value, record) => filterMeeting('facilitators', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('facilitators'),
      render: (_, record) => {
        const facs =
          record.facilitators?.items.map((fac, index) => {
            return {
              key: index,
              name: `${fac?.staff.first_name} ${fac?.staff.last_name}`,
              position: `${fac?.staff.position.name}`,
            };
          }) || [];

        return (
          <Popover
            placement='right'
            title={`${record.care_group.group_number} @ ${record.meeting_date}`}
            trigger={'click'}
            content={
              <Space direction='vertical'>
                {facs?.map((fac) => {
                  return <Space key={fac.key}>{`${fac.name} (${fac.position})`}</Space>;
                })}
              </Space>
            }
          >
            {facs.length > 0 ? (
              facs.length > 1 ? (
                <Link>{`${facs.length} staff facilitated`}</Link>
              ) : (
                facs[0].name
              )
            ) : (
              ''
            )}
          </Popover>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Care Group',
      dataIndex: 'care_group',
      key: 'care_group',
      render: (_, record) => {
        return record.care_group.group_number;
      },
      filters: cgFilter,
      filteredValue: (cgMeetingFilters['care_group'] as any) || null,
      onFilter: (value, record) => filterMeeting('care_group', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('care_group'),
      sorter: (a, b) => a.care_group.group_number.localeCompare(b.care_group.group_number),
    },
    {
      title: 'Topics',
      dataIndex: 'topics',
      key: 'topics',
      ellipsis: { showTitle: false },
      filters: topicFilter,
      filteredValue: (cgMeetingFilters['topics'] as any) || null,
      onFilter: (value, record) => filterMeeting('topics', value, record),
      onFilterDropdownOpenChange: (visible) => visible && drawDropDownForFilter('topics'),
      render: (_, record) => {
        return (
          <Popover
            destroyTooltipOnHide
            placement='left'
            title={`${record.care_group.group_number} @ ${record.meeting_date}`}
            trigger={'click'}
            content={
              <Space
                direction='vertical'
                style={{
                  maxWidth: 500,
                  maxHeight: 300,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {topicPopover ? (
                  topicPopover.map((topic) => (
                    <Space key={topic.nutrition_topic.topic_text}>
                      {topic.nutrition_topic.topic_text}
                    </Space>
                  ))
                ) : (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Spin size='small' />
                  </div>
                )}
              </Space>
            }
          >
            <Link onClick={() => getTopicsByCgMeeting(record.id)}>
              {record.topics?.items.length} {record.topics?.items.length === 1 ? 'topic' : 'topics'}{' '}
              covered
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Participants #',
      dataIndex: 'participants',
      key: 'participants',
      align: 'right',
      render: (value, record) => {
        return (
          <div style={{ paddingRight: '20px' }}>
            <Popover
              destroyTooltipOnHide
              // onOpenChange={(visible) => {
              //   if (!visible) {
              //     setPlwPopover(undefined);
              //   }
              // }}
              title={`${record.care_group.group_number} @ ${record.meeting_date}`}
              placement='left'
              trigger={'click'}
              overlayInnerStyle={{ minWidth: 200 }}
              content={
                <Space
                  direction='vertical'
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    padding: '10px',
                    width: '100%',
                  }}
                >
                  {plwPopover ? (
                    plwPopover.map((plw) => (
                      <Space key={plw.plw.id}>
                        <span>{`${record.care_group.group_number}.${plw.plw.neighbor_group.group_number}.${plw.plw.member_number}`}</span>
                        <span>{plw.plw.name}</span>
                      </Space>
                    ))
                  ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Spin size='small' />
                    </div>
                  )}
                </Space>
              }
            >
              <Link onClick={() => getPlwsByCgMeeting(record.id)}>
                {record.participants?.items.length}
              </Link>
            </Popover>
          </div>
        );
      },
    },
  ];

  // if (user.projectCount < 2) {
  //   columns = columns.filter((col: ColumnType<CGMeetingType>) => col.dataIndex !== 'project');
  // }

  const showSummaryDrawer = () => {
    setSummaryDrawerVisible(true);
  };

  const hideSummaryDrawer = () => {
    setSummaryDrawerVisible(false);
  };

  const reload = () => {
    resultData.refetch();
  };
  const clearAllFilters = () => {
    dispatch(setCgMeetingFilters(defaultCgmFilters));
    setFilteredCgMeetings(resultData.data || []);
  };
  const excelExportHandler = () => {
    exportToExcel(
      filteredCgMeetings.length > 0 ? filteredCgMeetings : resultData.data || [],
      cgMeetingFilters,
      facilitatorFilter,
      topicFilter
    );
  };

  const proj_dioc_menu_items = [
    ...user.groups.map((group) => {
      // if(projects && dioceses){
      const project = projects?.find((proj) => proj?.project_code === group.split('_')[0])?.name;
      const diocese = dioceses?.find((dioc) => dioc?.diocese_code === group.split('_')[1])?.name;
      if (project && diocese) {
        return {
          label: `${diocese} (${project})`,
          key: group,
        };
      } else {
        return {
          label: group,
          key: group,
        };
      }
    }),
    {
      label: 'All Dioceses',
      key: 'all',
    },
  ];

  const onMenuSelect = ({ key }: { key: string }) => {
    if (key === 'all') {
      dispatch(setSelectedMenuKeys({ keys: user.groups, selectAll: true }));
    } else {
      dispatch(setSelectedMenuKeys({ keys: [key], selectAll: false }));
    }
    dispatch(setCgMeetingFilters(defaultCgmFilters));
    setFilteredCgMeetings([]);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>CARE GROUP MEETINGS</div>
      <div className={Styles.contents}>
        <div className={Styles.selectionPanel}>
          <Menu
            items={proj_dioc_menu_items}
            onSelect={onMenuSelect}
            selectedKeys={selectedMenuKeys.selectAll ? ['all'] : selectedMenuKeys.keys}
          />
        </div>
        <div className={Styles.displayPanel}>
          <div
            className={Styles.buttonContainer}
            style={{
              display: !resultData.data || resultData.data.length === 0 ? 'none' : 'inherit',
            }}
          >
            <Space className={Styles.innerButtonContainer}>
              <Button
                className={Styles.syncButton}
                onClick={reload}
                loading={resultData.isLoading || resultData.isFetching}
                icon={<SyncOutlined />}
              ></Button>
              <Button
                className={Styles.clearFilterButton}
                onClick={clearAllFilters}
                disabled={resultData.isLoading || resultData.isFetching}
              >
                Clear All Filters
              </Button>
              <Button
                className={Styles.exportButton}
                onClick={excelExportHandler}
                disabled={resultData.isLoading || resultData.isFetching}
              >
                Export
              </Button>
              <Button
                className={Styles.summaryButton}
                onClick={showSummaryDrawer}
                disabled={resultData.isLoading || resultData.isFetching}
              >
                Show Summary
              </Button>
            </Space>
          </div>
          <div className={Styles.dataTable}>
            <Table
              dataSource={resultData.data}
              // dataSource={cgMeetings}
              columns={columns}
              loading={resultData.isLoading}
              rowKey='id'
              size='small'
              showSorterTooltip={false}
              pagination={{
                position: ['topRight'],
                defaultPageSize: 50,
                style: { marginRight: 25 },
                showTotal: (total, range) => `Total ${total}`,
              }}
              onChange={(_pagination, filters, _sorter, extra) => {
                if (extra.action === 'filter') {
                  dispatch(setCgMeetingFilters(filters));
                  setFilteredCgMeetings(extra.currentDataSource);
                }
              }}
              scroll={{ y: 'calc(100vh - 300px)' }}
            />
          </div>
        </div>
        <Drawer
          placement='right'
          closable={false}
          onClose={hideSummaryDrawer}
          open={summaryDrawerVisible}
          getContainer={false}
          width={1000}
          style={{ position: 'absolute' }}
        >
          <CGMSummary
            filteredCgMeetings={
              filteredCgMeetings.length > 0 ? filteredCgMeetings : resultData.data || []
            }
            cgMeetingFilters={cgMeetingFilters}
            facilitatorFilter={facilitatorFilter}
            topicFilter={topicFilter}
            hide={hideSummaryDrawer}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default CareGroupMeetings;
